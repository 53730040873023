export const insertPhoto = require("../assets/insert_photo.png");
export const noShyftIcon = require("../assets/image_Failed.svg");
export const chatIcon = require("../assets/chat.png");
export const searchIcon = require("../assets/search.png");
export const briefcaseGrey = require("../assets/briefcaseGrey.png");
export const hotel = require("../assets/hotel.png");
export const emptyChatPhoto = require("../assets/chat-snagashyft.png");
export const actionIcon = require("../assets/action-icon.png");
export const addIcon = require("../assets/add-icon.png");
export const actionTakenIcon = require("../assets/action-taken-icon.png");
export const closeIcon = require("../assets/close.png");
