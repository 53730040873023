import React from "react";
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  createStyles,
  withStyles,
} from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import Footer from "../../../components/src/Footer.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";
import {
  arrowRight,
  timmerBlank,
  FailedIcon,
  user,
  comment,
  meal,
  editMeal,
  SuccessIcon,
} from "./assets";
import ShyftSummary from "../../../components/src/ShyftSummary";
import TimerWithCircularProgressBar from "../../../components/src/TimerWithCircularProgressBar";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import MyShyftsController, {
  Props,
  TActivityRequest,
  TMyShyft,
  TShyftTimers,
} from "./MyShyftsController.web";
import {
  convertDateIntoDayDateMonthYearFormat,
  convertSecondsToHoursMinutesFormat,
  convertSecondsToHrsMinsSecWithHMSFormat,
  formatHoursWorked,
  summaryDataIntoStringWithDays,
} from "../../../components/src/Utilities";
import NoDataFound from "../../../components/src/NoDataFound.web";
import Spinner from "../../../components/src/Spinner";
import moment from "moment";
import FilterRecords from "../../filteritems/src/FilterRecords.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import MessagePanel from "../../../components/src/MessagePanel.web";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";

const isShyftEnded = (request?: TActivityRequest) => {
  if (request?.removal_reason) {
    return false;
  }

  return true;
};

const getAppliedShyftCTAText = (count: number) =>
  count !== 0 ? "Applied Shyfts" : "";

const getAppliedShyftTextColor = (count: number) =>
  count === 0 ? "#6f7c8e" : "#390879";

const getTimerHours = (
  isOngoingShyft: boolean,
  shyftPunchOutTime: string,
  currentHoursWorked: number
) =>
  convertSecondsToHoursMinutesFormat(
    isOngoingShyft || shyftPunchOutTime ? currentHoursWorked : 0
  );

export const MealBreakUI = ({
  mealTime,
  showEditMealBreak,
  onChangeMealTime,
  onAddMealBreak,
  onToggleEditMeal,
}: {
  mealTime: string;
  showEditMealBreak: boolean;
  onChangeMealTime: (value: string) => void;
  onAddMealBreak: () => void;
  onToggleEditMeal: (value: boolean) => void;
}) => {
  if (showEditMealBreak) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ gap: "12px" }}
      >
        <CustomSelect
          value={mealTime}
          handleChange={(e: any) => onChangeMealTime(e.target.value)}
          label=""
          name="meal-break"
          options={[
            { name: "15 Mins", value: "15 mins" },
            { name: "30 Mins", value: "30 mins" },
          ]}
          error={false}
        />
        <Box style={{ marginBottom: "16px", flexBasis: "40%" }}>
          <CustomButton
            label="Save"
            isOutlined={false}
            handleClick={onAddMealBreak}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="12px 24px"
      style={{
        gap: "8px",
        backgroundColor: "rgba(111, 124, 142, 0.10)",
        borderRadius: "26px",
      }}
    >
      <Typography
        style={{
          color: "#0a0412",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "18px",
        }}
      >
        You took{" "}
        <span
          style={{
            color: "rgb(57, 8, 121)",
            textDecoration: "underline",
          }}
        >
          {mealTime}
        </span>{" "}
        of meal break during this shyft
      </Typography>
      <img
        style={{
          width: "18px",
          height: "auto",
          cursor: "pointer",
        }}
        src={editMeal}
        alt="edit"
        onClick={() => onToggleEditMeal(true)}
      />
    </Box>
  );
};

export const ShyftSummaryUI = ({
  shyftPunchOutTime,
}: {
  shyftPunchOutTime: string;
}) => {
  if (shyftPunchOutTime) {
    return (
      <div
        style={{
          borderTop: "1px solid #e1e6ec",
          padding: "15px",
          backgroundColor: "#fff",
        }}
        data-test-id="punch-out-box"
      >
        <div
          style={{
            padding: "18px",
            display: "flex",
            alignItems: "center",
            gap: "15px",
            fontSize: "14px",
            backgroundColor: "rgba(111, 124, 142, 0.10)",
            borderRadius: "12px",
          }}
        >
          <img src={SuccessIcon} alt="success" width="36" height="auto" />
          <Box>
            <Typography
              style={{
                color: "#0a0412",
                fontSize: "13px",
                fontWeight: 400,
                lineHeight: "14px",
                marginBottom: "5px",
              }}
            >
              Working hours completed
            </Typography>
            <Typography
              style={{
                color: "#0a0412",
                fontSize: "11px",
                fontWeight: 400,
                lineHeight: "14px",
              }}
            >
              Punch in button will be active before 10 mins of your shyft timing
            </Typography>
          </Box>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        borderTop: "1px solid #e1e6ec",
        padding: "15px",
        backgroundColor: "#fff",
      }}
      data-test-id="punch-in-box"
    >
      <div
        style={{
          padding: "18px",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          fontSize: "14px",
          backgroundColor: "rgba(111, 124, 142, 0.10)",
          borderRadius: "12px",
        }}
      >
        <img src={FailedIcon} alt="success" width="36" height="auto" />
        <Box>
          <Typography
            style={{
              color: "#0a0412",
              fontSize: "13px",
              fontWeight: 400,
              lineHeight: "14px",
              marginBottom: "5px",
            }}
          >
            Shyft not started
          </Typography>
          <Typography
            style={{
              color: "#0a0412",
              fontSize: "11px",
              fontWeight: 400,
              lineHeight: "14px",
            }}
          >
            Punch in button will be active before 10 mins of your shyft timing
          </Typography>
        </Box>
      </div>
    </div>
  );
};

export const PunchingUI = ({
  isOngoingShyft,
  shyftPunchOutTime,
  mealTime,
  showEditMealBreak,
  onChangeMealTime,
  onAddMealBreak,
  onToggleEditMeal,
  onPunchOut,
  onPunchIn,
  currentShyft,
  nextShyftHoursLeft,
}: {
  isOngoingShyft: boolean;
  shyftPunchOutTime: string;
  mealTime: string;
  showEditMealBreak: boolean;
  onChangeMealTime: (value: string) => void;
  onAddMealBreak: () => void;
  onToggleEditMeal: (value: boolean) => void;
  onPunchOut: () => void;
  onPunchIn: () => void;
  currentShyft?: TMyShyft;
  nextShyftHoursLeft: TShyftTimers;
}) => {
  if (isOngoingShyft) {
    return (
      <>
        <Box padding="0px 15px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Divider style={{ width: "31%" }} />
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: "9px",
              }}
              margin={"3px 0"}
            >
              <img
                style={{
                  width: "19px",
                  height: "auto",
                }}
                src={meal}
                alt="meal"
              />
              <Typography
                style={{
                  color: "#0a0412",
                  fontSize: "13px",
                  fontWeight: 400,
                  lineHeight: "18px",
                }}
              >
                Add Meal Break
              </Typography>
            </Box>
            <Divider style={{ width: "31%" }} />
          </Box>

          <MealBreakUI
            mealTime={mealTime}
            showEditMealBreak={showEditMealBreak}
            onChangeMealTime={(value) => onChangeMealTime(value)}
            onAddMealBreak={() => onAddMealBreak()}
            onToggleEditMeal={(value) => onToggleEditMeal(value)}
          />
        </Box>

        <Box
          style={{
            borderTop: "1px solid #e1e6ec",
            width: "100%",
            padding: "10px 0",
            backgroundColor: "#fff",
          }}
          data-test-id="punch-out"
          onClick={onPunchOut}
        >
          <Box
            borderRadius="50px"
            margin="10px auto"
            maxWidth={"90%"}
            flexDirection={"row-reverse"}
            border="1px solid #ff375b"
            display={"flex"}
            padding={"5px"}
          >
            <Box
              height={"40px"}
              width={"40px"}
              bgcolor={"#ff375b"}
              borderRadius={"50px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DoubleArrowIcon
                style={{ color: "white", transform: "rotate(180deg)" }}
              />
            </Box>

            <Box>
              <Typography
                style={{
                  fontSize: "14px",
                  color: "#ff375b",
                  textAlign: "end",
                  paddingRight: "10px",
                }}
              >
                Punch Out
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  paddingRight: "10px",
                }}
              >
                Swipe left to punch out from the shyft
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (currentShyft && nextShyftHoursLeft[currentShyft.id] < 600) {
    return (
      <Box
        style={{
          borderTop: "1px solid #e1e6ec",
          width: "100%",
          padding: "10px 0",
          backgroundColor: "#fff",
        }}
        data-test-id="punch-in"
        onClick={onPunchIn}
      >
        <Box
          borderRadius="50px"
          margin="10px auto"
          maxWidth={"90%"}
          border="1px solid #390879"
          display={"flex"}
          padding={"5px"}
        >
          <Box
            height={"40px"}
            width={"40px"}
            bgcolor={"#390879"}
            borderRadius={"50px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <DoubleArrowIcon style={{ color: "white" }} />
          </Box>

          <Box>
            <Typography
              style={{
                fontSize: "14px",
                color: "#390879",
                fontWeight: 400,
                paddingLeft: "5px",
              }}
            >
              Punch In
            </Typography>
            <Typography
              style={{
                fontSize: "11px",
                paddingLeft: "5px",
              }}
            >
              Swipe right to punch in this shyft...
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return <ShyftSummaryUI shyftPunchOutTime={shyftPunchOutTime} />;
};

const styles = createStyles({
  scrollContainer: {
    overflowX: "hidden",
    overflowY: "auto",
    height: "fit-contain",
    padding: "15px",
    "::-webkit-scrollbar": {
      width: "0.4em",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "rgba(225, 230, 236, 0.3)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(111, 124, 142, 0.4)",
    },
  },
});

export class MyShyftsPage extends MyShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      myShyftsList,
      selectedMyShyft,
      summary,
      activityRecords,
      request,
      currentHoursWorked,
      shyftPunchOutTime,
      nextShyftHoursLeft,
      isShyftActivity,
      isLoading,
      isShowActivityFilter,
      appliedShyftsCount,
      mealTime,
      showEditMealBreak,
      isMessageOverlayVisible,
      isWorkSummaryVisible,
      workSummary,
      isAlreadyRunning,
    } = this.state;

    const { classes } = this.props;

    const myShyftOptions = myShyftsList.map((shift) => [
      {
        menuIcon: user,
        menuText: "View Profile",
        onClick: () => this.viewBusinessProfile(shift.businessId),
      },
      {
        menuIcon: comment,
        menuText: "Message",
        onClick: () => this.chatWithUser(shift.businessId),
      },
    ]);

    const currentShyft = myShyftsList.find(
      (shyft) => shyft.id === selectedMyShyft
    );

    const isOngoingShyft = currentShyft?.isOngoing === true;

    const renderActivityAndRecords = () => {
      if (isShyftActivity) {
        return (
          <Box
            borderRadius={"20px"}
            border={"1px solid #e1e6ec"}
            marginTop={"15px"}
            bgcolor="white"
            style={{
              position: "relative",
            }}
          >
            <PastShyftActivity
              summaryData={summary}
              activityData={activityRecords}
              isPastActivity={false}
              request={request}
              isShyftEndedSuccess={isShyftEnded(request)}
              isShyftActivity
              isMyShyftActivity
              backToMyShyfts={() => this.bactToMyshyfts()}
              handleFilterRecords={() => this.handleOpenFilterForCurrentShyft()}
              onNeedUpdate={() => this.toggleMessagePanel(true)}
              isStatusModalOpen={isWorkSummaryVisible}
              activityUpdateStatusData={workSummary}
              handleUpdateStatusCard={this.setWorkSummaryData}
              updateStatusModalSubmitButton={() =>
                this.toggleMessagePanel(true)
              }
              closeUpdateStatusModal={this.closeWorkSummaryPanel}
              updateStatusCtaText="Want an update?"
            />
            {currentShyft && isMessageOverlayVisible && (
              <MessagePanel
                onClose={() => this.toggleMessagePanel(false)}
                onBtnClick={() => this.chatWithUser(currentShyft.businessId)}
              />
            )}
          </Box>
        );
      }

      return (
        <Box
          borderRadius={"20px"}
          border={"1px solid #e1e6ec"}
          marginTop={"15px"}
          bgcolor="white"
        >
          <Box className={classes.scrollContainer}>
            <Box margin={"0px"}>
              {currentShyft && <ShyftCard {...currentShyft} isMyShyft={true} />}
            </Box>
            <Box>
              <ShyftSummary
                viewRecordActivity={() => this.viewRecordActivity()}
                hoursWorked={formatHoursWorked(summary?.hours_worked, true)}
                missedShifts={summaryDataIntoStringWithDays(
                  summary?.missed_shifts
                )}
                completedDays={summaryDataIntoStringWithDays(
                  summary?.completed_days
                )}
              />
            </Box>
            <Box marginY={"10px"}>Recent Status</Box>
            <Box
              borderRadius={"20px"}
              padding={"20px"}
              border={"1px solid #e1e6ec"}
              textAlign={"center"}
            >
              <Box>
                <Box
                  textAlign={"center"}
                  style={{
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "21px",
                  }}
                >
                  {convertDateIntoDayDateMonthYearFormat(
                    moment().toISOString()
                  )}
                </Box>
                <Box paddingY={"10px"}>
                  <TimerWithCircularProgressBar
                    percent={this.getTimerPercentage(isOngoingShyft)}
                    timer={getTimerHours(
                      isOngoingShyft,
                      shyftPunchOutTime,
                      currentHoursWorked
                    )}
                    isActive={isOngoingShyft || shyftPunchOutTime !== ""}
                    isShyftPage={true}
                    size={200}
                    colorCode="#b9df12"
                  />
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#6f7c8e",
                      marginTop: "-25px",
                      lineHeight: "16px",
                    }}
                  >
                    <Box>Punched in</Box>
                    <Box style={{ color: "#390879" }}>
                      {this.getPunchInTime()}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      fontSize: "12px",
                      fontWeight: 400,
                      color: "#6f7c8e",
                      marginTop: "-25px",
                      lineHeight: "16px",
                    }}
                  >
                    <Box>Punched out</Box>
                    <Box style={{ color: "#390879" }}>
                      {this.getPunchOutTime()}
                    </Box>
                  </Box>
                </Box>
              </Box>
              {currentShyft &&
                currentShyft.isOngoing !== true &&
                nextShyftHoursLeft[currentShyft.id] <= 600 && (
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems="center"
                    borderRadius={"10px"}
                    border={"1px solid #f7b500"}
                    bgcolor={"#f9f6ef"}
                    marginTop="20px"
                    padding={"10px"}
                  >
                    <Box display={"flex"}>
                      <img src={timmerBlank} alt="" height={"24px"} />
                      <Typography
                        style={{
                          marginLeft: "8px",
                          alignSelf: "center",
                          paddingTop: "2px",
                          color: "#0a0412",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        Shyft starts in:
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: "#f7b500",
                      }}
                    >
                      {convertSecondsToHrsMinsSecWithHMSFormat(
                        nextShyftHoursLeft[currentShyft.id]
                      )}
                    </Typography>
                  </Box>
                )}

              {currentShyft &&
                currentShyft.isOngoing !== true &&
                nextShyftHoursLeft[currentShyft.id] > 600 && (
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems="center"
                    borderRadius={"10px"}
                    border={"1px solid #2c85ff"}
                    bgcolor={"rgba(44,133,255,0.05)"}
                    marginTop="20px"
                    padding={"10px"}
                  >
                    <Box display={"flex"}>
                      <img src={timmerBlank} alt="" height={"24px"} />
                      <Typography
                        style={{
                          marginLeft: "8px",
                          alignSelf: "center",
                          paddingTop: "2px",
                          color: "#0a0412",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        Next shyft starts in:
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        fontSize: "20px",
                        color: "#2c85ff",
                      }}
                    >
                      {convertSecondsToHrsMinsSecWithHMSFormat(
                        nextShyftHoursLeft[currentShyft.id]
                      )}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Box>
          <PunchingUI
            isOngoingShyft={isOngoingShyft}
            mealTime={mealTime}
            nextShyftHoursLeft={nextShyftHoursLeft}
            onAddMealBreak={this.addMealBreak}
            onChangeMealTime={(value) => this.onChangeMealTime(value)}
            onPunchIn={() => this.punchInToTheShyft()}
            onPunchOut={() => this.punchOutFromTheShyft()}
            showEditMealBreak={showEditMealBreak}
            shyftPunchOutTime={shyftPunchOutTime}
            currentShyft={currentShyft}
            onToggleEditMeal={(value) => this.toggleEditMeal(value)}
          />
        </Box>
      );
    };

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isShowDetails={false}
          isUpdateUser={false}
        />
        {isLoading && <Spinner />}

        {myShyftsList.length === 0 && !isLoading && (
          <Container>
            <Box marginTop={"10%"}>
              <NoDataFound
                heading="No Shyfts Found!"
                paragraph="You've not assigned to any shyfts yet, Explore new shyfts to get the job"
                imgUrl={FailedIcon}
                primaryBtnText="Explore Shyfts"
                primaryBtnClick={() => this.navigateToPage("ExploreShyftsPage")}
                secondaryBtnText={getAppliedShyftCTAText(appliedShyftsCount)}
                secondaryBtnClick={() => this.navigateToPage("AppliedShyft")}
              />
            </Box>
          </Container>
        )}

        <Box marginTop={"5%"} width={"100%"}>
          <Container maxWidth="md">
            {myShyftsList.length !== 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    marginTop={"15px"}
                  >
                    <Box
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      My Shyfts
                    </Box>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      onClick={
                        appliedShyftsCount !== 0
                          ? () => this.navigateToPage("AppliedShyft")
                          : undefined
                      }
                      data-test-id="appliedShyft"
                    >
                      {appliedShyftsCount !== 0 && (
                        <Box
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          height={"20px"}
                          width={"20px"}
                          color="#fff"
                          bgcolor={"#390879"}
                          borderRadius={"50px"}
                          marginRight={"10px"}
                        >
                          {appliedShyftsCount}
                        </Box>
                      )}
                      <Box
                        style={{
                          fontSize: "14px",
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: getAppliedShyftTextColor(appliedShyftsCount),
                          marginRight: "5px",
                        }}
                      >
                        Applied Jobs
                      </Box>
                      <img src={arrowRight} alt="" height={"14px"} />
                    </Box>
                  </Box>
                  <Box>
                    {myShyftsList.map((item, index) => {
                      return (
                        <ShyftCard
                          {...item}
                          key={item.id}
                          isMenu={true}
                          isMyShyft={true}
                          isMyShyftList={true}
                          isActiveShyft={item.id === selectedMyShyft}
                          onCardClick={() => this.onSelectMyShift(item.id)}
                          isOngoing={item.isOngoing}
                          nextShyftTime={this.getNextShyftTime(
                            item.isOngoing,
                            item.id
                          )}
                          aboutToStart={
                            nextShyftHoursLeft[item.id] < 600 && !item.isOngoing
                          }
                          btnText={this.getShyftCardBtnText(
                            item.id,
                            item.isOngoing
                          )}
                          options={myShyftOptions[index]}
                        />
                      );
                    })}
                    <div ref={this.elementRef}></div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderActivityAndRecords()}
                </Grid>
              </Grid>
            )}
          </Container>
          <FilterRecords
            data-test-id="myShyftFilterRecordsId"
            id=""
            navigation={this.props.navigation}
            isOpenFilterDrawer={isShowActivityFilter}
            onCloseFilter={this.closeFilter}
            applyFilter={this.applyFilter}
          />
          {isAlreadyRunning && (
            <ShyfterFeedbackModal
              isOpen={isAlreadyRunning}
              title="Shift Already Running!"
              description="You can only punch into one shyft at a time, Please punch out of other shyft before punching in"
              imageSrc={FailedIcon}
              primaryButtonText="Okay"
              primaryButtonOnClick={this.closeAlreadyRunningModal}
              onClose={this.closeAlreadyRunningModal}
            />
          )}
        </Box>
        <Footer />
      </>
    );
  }
}

export default withStyles(styles)(MyShyftsPage);
