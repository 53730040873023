import React, { FunctionComponent, useRef } from "react";
import { Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const customStyles = makeStyles((them) => ({
  input: {
    display: "none",
  },
  button: {
    width: "100%",
    backgroundColor: "rgba(57, 8, 121, .04)",
    color: them.palette.primary.main,
    border: "1px dashed",
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: 12,
    fontWeight: 500,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(57, 8, 121, .04)",
    },
  },
  file: {
    backgroundColor: "#fff",
    color: them.palette.primary.main,
    border: "1px solid #e1e6ec",
    borderRadius: "50px",
    padding: "8px 11px",
    fontSize: 12,
    fontWeight: 500,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileText: {
    textDecoration: "underline",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cancel: {
    backgroundColor: "rgba(225, 230, 236, 0.3)",
    bordeRadius: "24px",
    padding: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backBtnText: {
    color: "black",
  },
  main: {
    marginBottom: 10,
  },
}));

type Props = {
  uploadLabel: string;
  id: string;
  inputName: string;
  onChange: (e: any) => void;
  error: string;
  selectedName: string;
  onReset?: () => void;
  disabled?: boolean;
};

const UploadButton: FunctionComponent<Props> = ({
  uploadLabel,
  id,
  inputName,
  onChange,
  error,
  selectedName,
  onReset,
  disabled
}) => {
  const classes = customStyles();
  const ref = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const fileType = file.type;
    const fileSize = file.size;
    if (file && fileType === "application/pdf" && fileSize < 2097152) {
      onChange(event);
    }
  };

  const resetFile = () => {
    if(!disabled) {
      if (ref.current) ref.current.value = "";
      onReset && onReset();
    }
  };

  return (
    <div className={classes.main}>
      <Typography
        style={{
          fontWeight: 500,
          fontSize: 12,
          letterSpacing: "-0.21px",
          color: "#0A0412",
          marginBottom: "5px",
        }}
        classes={{
          root: classes.backBtnText,
        }}
        variant="body1"
        color={"primary"}
      >
        {uploadLabel}
      </Typography>
      <input
        accept=".pdf, application/pdf"
        className={classes.input}
        id={id}
        type="file"
        ref={ref}
        name={inputName}
        onChange={handleFileChange}
        data-test-id="file-upload"
      />
      {selectedName ? (
        <Box className={classes.file}>
          <span className={classes.fileText}>{selectedName}</span>
          <span
            data-test-id="reset-file"
            className={classes.cancel}
            onClick={resetFile}
          >
            <img src={require("./cancel.png")} />
          </span>
        </Box>
      ) : (
        <label htmlFor={id}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            style={{ color: error ? "red" : "#390879" }}
            classes={{ root: classes.button }}
            disableElevation={true}
          >
            {!selectedName && (
              <img src={require("./assets/uploadButton.svg")} />
            )}
            <span>Upload Document</span>
          </Button>
        </label>
      )}
    </div>
  );
};
export default UploadButton;
