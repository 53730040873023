import React from "react";
import { TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    paddingBottom: "16px",
    "& input": {
      padding: "14px 18px",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "16px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    }
  },
}));

interface ITextAreaProps {
  value: string | number;
  label: string;
  name: string;
  error: boolean;
  maxLength?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomTextArea: React.FC<ITextAreaProps> = ({
  value,
  label,
  error,
  name,
  maxLength,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      className={classes.roundedTextField}
      inputProps={{ maxLength: maxLength }}
      variant="outlined"
      value={value}
      error={error}
      onChange={handleChange}
      multiline
      minRows={3}
      data-test-id={name}
    />
  );
};

export default CustomTextArea;
