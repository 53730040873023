import React from "react";

// Customizable Area Start
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import "./style.css";

// Customizable Area End

export interface Props {
  // Customizable Area Start
  message: string;
  onClose: () => void;
  // Customizable Area End
}

export class LoginAlert extends React.Component<Props> {
  message: string;
  onClose: () => void
  constructor(props: Props) {
    super(props);
    this.message = props.message;
    this.onClose = props.onClose;
  }

  render() {
    return (
      // Required for all blocks

      <div className={`alert error`}>
        <div style={{ marginTop: "23px" }}>
        <img
          style={{
            height: "35px",
            width: "35px",
          }}
          src={require("./assets/warning.png")}
        />
        </div>

        <div className={`alert-body`}>
          <strong style={{ lineHeight: "16px", fontSize: "15px", width: "300px" }}>
            Alert
          </strong>
          <span style={{ marginTop: "10px" , lineHeight: "15px", fontSize: "12px", fontWeight: 800}}>{this.message}</span>
        </div>
        <CloseRoundedIcon data-test-id="close-alert" onClick={this.onClose} style={{marginTop: "23px", backgroundColor: "#E1E6EC", cursor: "pointer", borderRadius: "15px"}} />
        
      </div>
    );
  }
}
export default LoginAlert;
