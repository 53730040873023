export const radioCheckedDarkBlueIcon = require("./assets/check_circle_dark_blue.png");
export const radioUnCheckedIcon = require("./assets/radio_button_unchecked.png");
export const storeIcon = require("./assets/storefront.png");
export const workIcon = require("./assets/work.png");
export const restaurantIcon = require("./assets/restaurant.png");
export const hotelIcon = require("./assets/hotel.png");
export const verifiedIcon = require("./assets/verified.png");
export const completedIcon = require("./assets/completedIcon.png");
export const fileCreateIcon = require("./assets/fileCreate.png");
export const ongoingIcon = require("./assets/ongoingIcon.png");
export const moneyIcon = require("./assets/images/billingIcon.png");
export const clockIcon = require("./assets/images/clock.png");
export const warningInfoIcon = require("./assets/images/image_Failed.svg");
export const briefcaseIcon = require("./assets/images/briefcase-blank.png");
export const redArrow = require("./assets/images/red-arrow.png");
export const greenArrow = require("./assets/images/green-arrow.png");
export const downArrow = require("./assets/downArrow.png");
export const blankStar = require("./assets/blankStar.png");
export const ProfileImg = require("./assets/images/ProfileImg.png");
export const briefcase = require("./assets/briefcase.png");
export const Pasted = require("./assets/Pasted.png");
export const clock = require("./assets/clock.png");
export const clockBlue = require("./assets/clockBlue.png");
export const calendar = require("./assets/calendar.png");
export const toparrow = require("./assets/toparrow.png");
export const verticalArrow = require("./assets/verticalArrow.png");
export const emojiIcon = require("./assets/images/emoji.png");
export const attachedIcon = require("./assets/images/attached.png");
export const uploadIcon = require("./assets/images/upload.png");
export const reportIcon = require("./assets/images/report.png");
export const userIcon = require("./assets/images/userBlack.png");
export const deleteIcon = require("./assets/images/delete.png");
export const briefIcon = require("./assets/images/brif.png");
export const briefCaseJobIcon = require("./assets/images/briefcase.png");
export const business = require("./assets/business.png");
export const camera = require("./assets/camera.png");
export const hotelDefault = require("./assets/images/hotelDefault.png");
export const close = require("./assets/close.png");
export const hourglass = require("./assets/hourglass.png");
export const chat = require("./assets/chat.png");
export const remove = require('./cancel.png');
export const noDocuments = require('./assets/noDocuments.png')
