import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  makeStyles,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  roundedSelectField: {
    paddingBottom: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & .MuiSelect-root": {
      padding: "14px",
      paddingBottom: "16px",
      position: "relative",
      paddingLeft: "65px"
    },
    "& input": {
      padding: "14px 32px 14px 14px",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "-internal-light-dark(rgb(255, 255, 255), rgba(70, 90, 126, 0.4)) !important",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
      paddingLeft: "54px",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    },
    "& label[data-shrink='true']": {
        paddingLeft: "0px"
    },
  },
}));

interface IDropdown {
  name: string;
  value: string;
}
interface ITextFieldProps {
  value: string | number;
  label: string;
  name: string;
  error: boolean;
  options: IDropdown[];
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const CustomSelectWithImage: React.FC<ITextFieldProps> = ({
  value,
  label,
  error,
  name,
  options,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.roundedSelectField}
    >
      <InputLabel error={error}>{label}</InputLabel>

      <img src={require("./lunchIcon.png")} alt="meal_icon" height="22px" width="28px" 
        style={{ top: "14px", left: "15px", position: "absolute"}} 
       />
      <Divider orientation="vertical" flexItem  style={{ top: "10px", left: "52px", position: "absolute", height: "30px" }}/>
      <Select
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={handleChange}
        style={{ borderRadius: '20px', height: '50px'}}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
        IconComponent={ExpandMoreIcon}
        data-test-id={name}
      >
        {options.map((item: IDropdown) => (
          <MenuItem key={item.value} value={item.value} disabled={item.value === "00 Mins"}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectWithImage;
