import React from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchWithFilterandLocation from "./SearchWithFilterandLocation";
import CallMadeIcon from "@material-ui/icons/CallMade";

const location = [
  {
    id: 1,
    country: "US",
    city: "New York",
    state: "Loss Engelish",
  },
  {
    id: 2,
    country: "Germany",
    city: "New York",
    state: "Anhd",
  },
];
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      fontSize: 20,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "12px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
    " & .MuiListItem-gutters": {
      paddingLeft: "5px"
    }
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  closeModal: (e: any) => void;
  onSearch: (value: string) => void;
  removeQueryFromSearch?: (query: string) => void;
  recentSearches?: string[];
  topHotelAndRestaData?: any[];
  onChangeSearch: any;
  isSearching?: boolean;
  filteredData?: any[];
  searchedText?: string;
}

const SearchShyftsModal: React.FC<ISuccessPopupDialogProps> = ({
  open,
  closeModal,
  onSearch,
  recentSearches = [],
  removeQueryFromSearch,
  topHotelAndRestaData,
  onChangeSearch,
  isSearching,
  filteredData,
  searchedText
}) => {
  const classes = useStyles();
  
  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="xs"
      scroll="body"
      fullWidth
    >
      <IconButton
        className="closeIcon"
        onClick={closeModal}
        data-test-id="closeModal"
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="successDialogTitle">Search for Shyfts</Box>
      <Box marginTop={"-20px"}>
        <SearchWithFilterandLocation 
          modal={true} 
          isOnChangeText={true} 
          handleChangeSearchText={onChangeSearch} 
        />
      </Box>
      { !isSearching ? 
        <>
        {recentSearches.length !== 0 && (
          <Box
            width={"100%"}
            maxWidth={"100%"}
            justifyContent="space-between"
            display="flex"
            alignItems={"center"}
            marginTop={"20px"}
          >
            <Divider style={{ width: "35%" }} />
            <Box textAlign={"center"} fontSize={"14px"} color={"gray"}>
              Recent Searches
            </Box>
            <Divider style={{ width: "35%" }} />
          </Box>
        )}
        <Box>
          <List>
            {recentSearches.slice(0, 4).map((search) => {
              return (
                <>
                  <ListItem key={search}>
                    <ListItemText primary={search} style={{cursor: "pointer"}} onClick={()=>onSearch(search)} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        data-test-id="remove-search"
                        onClick={() =>
                          removeQueryFromSearch && removeQueryFromSearch(search)
                        }
                      >
                        <CloseIcon style={{ height: "14px" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </List>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          marginTop={"8px"}
          marginBottom={"5px"}
        >
          <Divider style={{ width: "28%" }} />
          <Box textAlign={"center"} fontSize={"14px"} color={"gray"}>
            Top Hotels & Restaurants{" "}
          </Box>
          <Divider style={{ width: "28%" }} />
        </Box>

        <Box style={{ height: "190px", overflowY: "auto"}}>
          <List>
            { topHotelAndRestaData && topHotelAndRestaData.length > 0 ?
              topHotelAndRestaData.map((elm) => {
                return (
                  <>
                    <ListItem key={elm.id}>
                      <Box>
                        <Typography style={{fontSize: "14px"}}>
                          {elm.business_type === "hotel" ? elm.hotel_name : elm.restuarant_name}
                        </Typography>
                        <Typography style={{fontSize: "12px", color: "gray", textTransform: "capitalize"}}>
                          {`${elm.business_type} | ${elm.shift_count} Shyfts`}
                        </Typography>
                      </Box>
                      <ListItemSecondaryAction>
                        <IconButton 
                          edge="end" 
                          aria-label="top_hotel_restaurant" 
                          onClick={() => 
                            onSearch(elm.business_type === "hotel" ? elm.hotel_name : elm.restuarant_name)
                          }
                        >
                          <CallMadeIcon style={{color: "#390879"}} fontSize="small"/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
              :
              <Typography>No Data Found</Typography>
            }
          </List>
        </Box>
        </>

        :
        <>
          <Box style={{ height: "260px", overflowY: "auto", marginTop: "5px" }}>
          <List>
            {searchedText !== "" && filteredData && 
            filteredData.length > 0  ? 
              filteredData?.map((elm, index) => {
                return (
                  <>
                    <ListItem key={index}>
                      <Box>
                        <Typography style={{fontSize: "14px"}}>
                          {elm.name}
                        </Typography>
                        <Typography style={{fontSize: "12px", color: "gray", textTransform: "capitalize"}}>
                          {elm.count === 1 ? `${elm.count} Shyft` : `${elm.count} Shyfts`}
                        </Typography>
                      </Box>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => onSearch(elm.name)}>
                          <CallMadeIcon style={{color: "#390879"}} fontSize="small"/>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
            :
            <Box marginTop={"60px"} display={"flex"} alignItems={"center"} justifyContent={"center"} >
              <Typography style={{ textAlign: "center" }}>No Shyft Found</Typography>
            </Box>
          }
          </List>
        </Box>
        </>

      }

    </Dialog>
  );
};

export default SearchShyftsModal;
