import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  makeStyles,
  Box,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  roundedSelectField: {
    paddingBottom: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & .MuiSelect-root": {
        padding: "14px",
        paddingBottom: "16px",
        display: "flex",
        alignItems: "center",
    },
    "& input": {
      padding: "14px 32px 14px 14px",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "-internal-light-dark(rgb(255, 255, 255), rgba(70, 90, 126, 0.4)) !important",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    },
    "& li": {
        fontSize: "14px",
        fontWeight: 600
    },
  },
}));

interface IDropdown {
  name: string;
  value: string;
  boxBgColor: string;
  boxInitial: string;
}

interface ITextFieldProps {
  value: string | number;
  label: string;
  name: string;
  error: boolean;
  options: IDropdown[];
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  toDisabled: string;
}

const coloredBoxWithIntials = (baseColor: string, intial: string) => {
    return (
        <Box
            height={"32px"}
            width={"32px"}
            marginRight={"10px"}
            display={"flex"}
            borderRadius={"10px"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={baseColor}
        >
            <Typography style={{ fontWeight: "bold", fontSize: "16px", color: "#fff" }}>{intial}</Typography>
        </Box>
    )
}

const CustomSelectOptionsWithImage: React.FC<ITextFieldProps> = ({
  value,
  label,
  error,
  name,
  options,
  handleChange,
  toDisabled,
}) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.roundedSelectField}
    >
      <InputLabel error={error}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={value}
        error={error}
        onChange={handleChange}
        style={{ borderRadius: '20px', height: '50px'}}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
        IconComponent={ExpandMoreIcon}
        data-test-id={name}
      >
        {options.map((item: IDropdown) => (
          <MenuItem key={item.value} value={item.value} disabled={item.name === toDisabled}>
            {coloredBoxWithIntials(item.boxBgColor, item.boxInitial)}
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelectOptionsWithImage;
