import React from 'react'
import { Box, Button, Divider, makeStyles } from "@material-ui/core";
import QRCode from "react-qr-code";
const playstore=require('./assets/images/playstore.svg')
const appstore=require('./assets/images/appstore.svg')
const useStyles = makeStyles({
  
    heading1: {
      fontSize: "20px",
      color: "#000",
      fontWeight: 700,
    },
    downloadAppCardMainWrapper: {
      border: "1px solid #ddd",
      borderRadius: "14px",
      backgroundColor: "#fff",
      textAlign: "center",
      "@media(max-width:600px)": {
        width: "auto",
      },
    },
    imgFluid: {
      border: "1px solid #ddd",
      borderRadius: "14px",
      backgroundColor: "#fff",
      display: "inline-block",
      padding: "15px",
      marginTop: "20px",
    },
    contentWrap: {
      padding: "10px",
      "& p": {
        fontSize: "14px",
        color: "#6F7C8E",
        lineHeight: "20px",
      },
    },
    textCenter: {
      textAlign: "center",
    },
    storeBtn: {
      padding: "8px",
      backgroundColor: "#e1e6ec66",
      borderRadius: "8px",
      margin: "10px 8px",
    },
  });

export const DownloadAppCard = () => {
    const classes =useStyles()
    return (
      <div className={classes.downloadAppCardMainWrapper}>
        <Box>
          <Box className={`${classes.imgFluid} ${classes.textCenter}`}>
            <QRCode value="Hello Word" size={100} viewBox={`0 0 256 256`} />
          </Box>
          <Box className={classes.contentWrap}>
            <Box className={classes.heading1}>Download the App</Box>
            <p>Scan to download & Get the real-time updates from the our app</p>
            <Divider variant="middle" style={{ margin: "0px" }} />
            <Box display={"flex"} alignItems={"center"}>
              <Button fullWidth={true} className={classes.storeBtn}>
                <img src={playstore} alt="adsads" />
              </Button>
              <Button fullWidth={true} className={classes.storeBtn}>
                <img src={appstore} alt="dasdada" />
              </Button>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };