import React, { ChangeEvent, FunctionComponent } from "react";
import {
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

type Props = {
  title: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  state: boolean;
};

const CustomCheckbox: FunctionComponent<Props> = ({
  title,
  label,
  onChange,
  state,
}) => {
  const handleChange = (
    event: ChangeEvent<{}>,
    checked: any
  ) => {
    if (checked) {
      onChange(checked);
    }
    event.stopPropagation();
  };
  return (
    <FormControlLabel
      style={{
        borderColor: state ? "#390879" : "#E1E6EC",
      }}
      className="custom-checkbox-root"
      onChange={handleChange}
      data-test-id="custom-register-checkbox"
      labelPlacement="start"
      label={
        <div>
          {title === "Restaurant" ? (
            <img
              style={{
                height: "35px",
                width: "35px",
              }}
              src={require("./assets/restaurant.png")}
              alt="Restaurant"
            />
          ) : (
            <img
              style={{
                height: "35px",
                width: "35px",
              }}
              src={require("./assets/hotel.png")}
              alt="Hotel"
            />
          )}

          <Typography variant="h5" className="custom-checkbox-title">
            {title}
          </Typography>
        </div>
      }
      control={
        <Checkbox
          id={label}
          checked={state}
          disableRipple
          icon={<CircleUnchecked />}
          checkedIcon={<CircleCheckedFilled style={{ color: "black" }} />}
          name={"type"}
          value={label}
          key={"id"}
          className="custom-checkbox-root"
        />
      }
    />
  );
};
export default CustomCheckbox;
