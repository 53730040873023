import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "12px",
    padding: "11px 0",
    textTransform: "none",
    backgroundColor: "#b9df12 !important",
    fontSize: "13px",
    letterSpacing: "0px",
    lineHeight: "18px",
    color: "#0a0412",
    fontWeight: 500,
    fontFamily: "'Gotham', sans-serif",
    textAlign: "center",
    width: 125,
  },
}));

interface IButtonProps {
  label: string | undefined;
  handleClick: (e: any) => void;
}

const GreenButton: React.FC<IButtonProps> = ({ label, handleClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.button}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default GreenButton;
