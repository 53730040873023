import React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  Snackbar
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import BackButton from "../../../components/src/BackButton";
import UploadButton from "../../../components/src/UploadButton";
import { ArrowBack } from "@material-ui/icons";
import "./style.css";
import LoginWrapper from "../../../components/src/LoginWrapper";
import DividerWithText from "../../../components/src/CustomDevider";
import CustomCheckBox from "../../../components/src/CustomRegisterCheckBox";
import OptInPopUp from "../../../components/src/OtpPopUp";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";
import SubmittedFailedPopUp from "../../../components/src/SubmittedFailedPopUp";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import EmailAccountRegistrationController, {
  IHospitalityType,
  Props
} from "./EmailAccountRegistrationController";
import { withStyles } from "@material-ui/core/styles";
import { verifiedIcon } from "./assets";
import { setDisabledVerifyOTP } from "./ShyfterAccountRegistrationSteps.web";
import PhoneInput from "react-phone-input-2";
import CustomSelect from "../../../components/src/CustomSelectField.web";

const styles = {
  inputClasses2: {
    paddingBottom: "16px",
    width: "100%",
    "& input": {
      padding: "14px 18px",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
  inputLabelClasses: {
    transform: "translate(14px, 16px) scale(1)",
    fontSize: "13px",
    fontWeight: "100",
    fontStyle: "italic",
  },
  formWrapper: {
    maxWidth: 510,
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    backgroundColor: "white",
    padding: "50px 75px",
  },
  backBtn: {
    maxWidth: "36px",
    width: "100%",
    minWidth: "20px",
    height: "36px",
    borderRadius: "100%",
  },
  button2: {
    width: "100%",
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
  },
  buttonsBlock: {
    marginTop: 32,
  },
  gridContainer: {
    flexGrow: 1,
  },

  inputClasses1: {
    width: "100%",
    paddingBottom: "16px",
    "& input": {
      padding: "14px 18px",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
  selectClasses: {
    width: "100%",
    paddingBottom: "16px",
    padding: "14px",
    paddingRight: "32px",
  },
  selectRootClasses: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
  checkboxesBlock: {
    width: "100%",

    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
    paddingBottom: "16px",
  },
  button1: {
    width: "100%",
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    // textTransform: "none",
    marginTop: "32px",
  },
};

class AccountRegistrationSteps extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }

  setShowAlert = (showAlert: boolean) => this.setState({ showAlert });

  render() {
    const isVerified = this.getIsNumberVerified();
    const renderStep1 = () => (
      <LoginWrapper>
        <Box className={this.classes.formWrapper} id={"step1"}>
          <div className="back-button">
            <Button
              id="btnBack"
              className={this.classes.backBtn}
              variant="contained"
              onClick={this.backToLogin}
              startIcon={<ArrowBack style={{ marginLeft: "10px" }} />}
            />
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "16px",
                letterSpacing: "-0.31px",
                marginLeft: "5px",
              }}
              variant="body1"
              color={"primary"}
            >
              Back to login
            </Typography>
          </div>
          <div className="title" style={{ marginTop: "10%" }}>
            <span>Sign up as a</span>
            <span> Business</span>
          </div>
          <DividerWithText position={"left"} textStyles={{}}>
            Enter Business Details
          </DividerWithText>
          <form>
            <div className={this.classes.checkboxesBlock}>
              <CustomCheckBox
                title={"Restaurant"}
                label="restaurant"
                onChange={(e) => this.handleCheckBoxSelection(e, "restaurant")}
                state={this.state.formData.type === "restaurant"}
              />
              <CustomCheckBox
                title={"Hotel"}
                label="hotel"
                onChange={(e) => this.handleCheckBoxSelection(e, "hotel")}
                state={this.state.formData.type === "hotel"}
              />
            </div>

            <FormControl
              variant="outlined"
              style={{ paddingBottom: "16px", borderRadius: "50px" }}
              classes={{ root: this.classes.selectRootClasses }}
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                classes={{ root: this.classes.inputLabelClasses }}
              >
                Type of {this.state.formData.type}
              </InputLabel>
              <Select
                labelId="type-of-hotel-label"
                id="type-of"
                label={`Type of ${this.state.formData.type}`}
                name="typeOf"
                value={this.state.formData.typeOf}
                error={!!this.state.formDataErrors.typeOf}
                onChange={this.handleInputChange}
                classes={{
                  select: this.classes.selectClasses,
                }}
                MenuProps={{
                  anchorOrigin: { vertical: "bottom", horizontal: "left" },
                  transformOrigin: { vertical: "top", horizontal: "left" },
                  getContentAnchorEl: null
                }}
                inputProps={{
                  style: {
                    padding: "14px",
                    paddingRight: "32px",
                  },
                }}
              >
                {this.state.hospitalityCategories.map((item: IHospitalityType) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              classes={{
                root: this.classes.inputClasses1,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="legal-company-name"
              label="Legal Company Name"
              name="legalCompanyName"
              inputProps={{ maxLength: 30 }}
              error={!!this.state.formDataErrors.legalCompanyName}
              variant="outlined"
              value={this.state.formData.legalCompanyName}
              onChange={this.handleInputChange}
              data-test-id="legal-company-name"
            />

            <TextField
              classes={{
                root: this.classes.inputClasses1,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="dba-name"
              label="Enter DBA Name"
              inputProps={{ maxLength: 30 }}
              variant="outlined"
              name="dbaName"
              error={!!this.state.formDataErrors.dbaName}
              value={this.state.formData.dbaName}
              onChange={this.handleInputChange}
              data-test-id="dba-name"
            />

            <TextField
              classes={{
                root: this.classes.inputClasses1,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="address"
              label="Address"
              variant="outlined"
              error={!!this.state.formDataErrors.address}
              value={this.state.formData.address}
              inputProps={{ maxLength: 100 }}
              name="address"
              onChange={this.handleInputChange}
              data-test-id="address"
            />

            <Box display={"flex"} alignItems={"center"}>
              <PhoneInput
                  country={"in"}
                  data-test-id="country-code-telephone"
                  enableSearch={true}
                  value={this.state.countryCodeTelephone}
                  containerStyle={{
                    width: "22%",
                    height: "48px",
                    marginRight: "-35px",
                    marginTop: "-14px",
                  }}
                  inputStyle={{
                    borderRadius: "50px",
                    padding: "19px",
                    paddingLeft: "45px",
                    width: "100%",
                    display: "none"
                  }}
                  buttonStyle={{
                    backgroundColor:"white",
                    borderRadius: "50px",
                    width: "50%",
                  }}
                  placeholder="Country Code"
                  onChange={(event) => this.handleCountryCodeSelectionForTelephone(event)}
              />
              <TextField
                classes={{
                  root: this.classes.inputClasses1,
                }}
                InputLabelProps={{
                  className: this.classes.inputLabelClasses,
                }}
                inputProps={{ maxLength: 10 }}
                id="business-telephone"
                label="Business Telephone"
                variant="outlined"
                name="businessTelephone"
                error={!!this.state.formDataErrors.businessTelephone}
                value={this.state.formData.businessTelephone}
                onChange={this.handleInputChange}
                data-test-id="business-telephone"
              />
            </Box>


            <TextField
              classes={{
                root: this.classes.inputClasses1,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="business-website"
              label="Business Website"
              inputProps={{ maxLength: 60 }}
              variant="outlined"
              error={!!this.state.formDataErrors.businessWebsite}
              value={this.state.formData.businessWebsite}
              name="businessWebsite"
              onChange={this.handleInputChange}
              data-test-id="business-website"
            />

            <Button
              id="nextBtn"
              classes={{
                root: this.classes.button1,
              }}
              variant="contained"
              color={"primary"}
              onClick={this.moveEmployerNextStep}
              data-test-id="next-step"
            >
              Next
            </Button>
          </form>
        </Box>
      </LoginWrapper>
    );

    const renderStep2 = () => (
      <LoginWrapper>
        {!!this.state.snackbarMessage && (
          <Snackbar
            autoHideDuration={6000}
            open={!!this.state.snackbarMessage}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.handleSnackbarClose}
          >
            <Alert severity="warning" onClose={this.handleSnackbarClose}>
              {this.state.snackbarMessage}
            </Alert>
          </Snackbar>
        )}
        {this.state.signUpSuccessful && <SubmitSuccessPopUp
          open={this.state.signUpSuccessful}
          backToLogin={this.backToLogin}
          isEmpReg={true}
        />}
        <SubmittedFailedPopUp
          handleChangeNumber={() => {
            this.setShowAlert(false);
          }}
          handleToLogin={this.backToLogin}
          isOpen={Boolean(this.state.showAlert)}
          phone={this.state.formData.contactNumber}
        />
        {this.state.vafiyOpen &&
          <OptInPopUp
            isOpen={this.state.vafiyOpen}
            otp={this.state.otp}
            resend={this.handlePhoneVerification}
            handleClose={this.handleOTPDialogClose}
            phone={this.state.formData.contactNumber}
            setOtp={this.setOTP}
            seconds={this.state.seconds}
            handleChange={this.handleOTPDialogClose}
            handleSubmit={() =>
              this.verifyOTPRequest(
                this.state.formData.contactNumber,
                this.state.otp
              )
            }
            disabled={setDisabledVerifyOTP(
              this.state.otp,
              this.state.seconds
            )}
          />
        }
        <Box
          id={"step2"}
          style={{
            maxWidth: 510,
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            backgroundColor: "white",
            padding: "50px 75px",
          }}
          data-test-id="step-2-wrapper"
        >
          <BackButton
            onClick={this.backToLogin}
            icon={<ArrowBack style={{ marginLeft: "10px" }} />}
            text="Back to login"
          />
          <div className="title" style={{ marginTop: "10%" }}>
            <span>Sign up as a</span>
            <span> Business</span>
          </div>
          <DividerWithText position={"left"} textStyles={{ paddingLeft: 0 }}>
            Other details
          </DividerWithText>
          <form>
            <TextField
              classes={{
                root: this.classes.inputClasses2,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="contact-name"
              name="contactName"
              inputProps={{ maxLength: 30 }}
              label="Contact Name"
              error={!!this.state.formDataErrors.contactName}
              variant="outlined"
              value={this.state.formData.contactName}
              onChange={this.handleInputChange}
              data-test-id="contact-name"
            />
            <div style={{ position: "relative", display: 'flex', flexDirection: "row" }}>
              <PhoneInput
                country={"in"}
                data-test-id="country-code-selector-signup"
                enableSearch={true}
                value={this.state.countryCode}
                containerStyle={{
                  width: "29%",
                  height: "45px",
                  marginRight: "-78px",
                  marginTop: "1px",
                }}
                inputStyle={{
                  display: "none"
                }}
                buttonStyle={{
                  backgroundColor: "white",
                  borderRadius: "50px",
                  width: "36%",
                  borderColor: this.state.isCountryCodeFocused ? "#390897" : "",
                  borderWidth: this.state.isCountryCodeFocused ? "2px" : "",
                }}
                onFocus={() => this.handleCountryCodeFocus()}
                onChange={(event: string, data: any) => this.handleCountryCodeSelection(event, data)}
                placeholder="Country Code"
              />
              <TextField
                classes={{
                  root: this.classes.inputClasses2,
                }}
                InputLabelProps={{
                  className: this.classes.inputLabelClasses,
                }}
                className="contact-number-field"
                id="contact-number"
                name="contactNumber"
                error={!!this.state.formDataErrors.contactNumber}
                label="Contact Number"
                inputProps={{ maxLength: 10 }}
                variant="outlined"
                value={this.state.formData.contactNumber}
                onChange={this.handleInputChange}
                data-test-id="contact-number"
              />
              {isVerified ? (
                <img width={22} src={verifiedIcon} style={{
                  lineHeight: "18px",
                  color: "#390879",
                  cursor: "pointer",
                  fontWeight: 600,
                  position: "absolute",
                  top: "12px",
                  right: "14px"
                }} />
              ) : (
                <span
                  style={{
                    lineHeight: "18px",
                    fontSize: "12px",
                    color: "#390879",
                    cursor: "pointer",
                    fontWeight: 600,
                    position: "absolute",
                    top: "14px",
                    right: "14px"
                  }}
                  data-test-id="verify-btn"
                  onClick={this.handlePhoneVerification}
                >
                  Verify now
                </span>
              )}
            </div>
            <CustomSelect
              data-test-id='state'
              value={this.state.formData.state}
              label={"State"}
              name={"state"}
              error={!!this.state.formDataErrors.state}
              options={this.state.statesList}
              handleChange={this.handleInputChange}
            />
            <CustomSelect
              data-test-id='city'
              value={this.state.formData.city}
              label={"City"}
              name={"city"}
              error={!!this.state.formDataErrors.city}
              options={this.state.cityList}
              handleChange={this.handleInputChange}
            />
            <TextField
              classes={{
                root: this.classes.inputClasses2,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="contact-email-address"
              name="contactEmail"
              inputProps={{ maxLength: 30 }}
              error={!!this.state.formDataErrors.contactEmail}
              value={this.state.formData.contactEmail}
              label="Contact Email Address"
              variant="outlined"
              data-test-id="contact-email-address"
              onChange={this.handleInputChange}
            />

            <TextField
              classes={{
                root: this.classes.inputClasses2,
              }}
              InputLabelProps={{
                className: this.classes.inputLabelClasses,
              }}
              id="ein"
              label="EIN (Employer Identification Number)"
              name="contactEin"
              inputProps={{ maxLength: 11 }}
              error={!!this.state.formDataErrors.contactEin}
              value={this.state.formData.contactEin}
              variant="outlined"
              onChange={this.handleInputChange}
              data-test-id="ein"
            />

            <DividerWithText
              position="center"
              textStyles={{ fontSize: 14, fontWeight: 600, color: "#0A0412" }}
            >
              Upload docs
            </DividerWithText>
            <UploadButton
              uploadLabel={"Certificate of Organization"}
              id="certificate-of-organization"
              inputName="certificateOrganization"
              onChange={this.handleInputChange}
              error={this.state.formDataErrors.certificateOrganization}
              //@ts-ignore
              selectedName={this.state.formData.certificateOrganization?.name || ""}
              onReset={() => this.onResetFile("certificateOrganization")}
            />
            <UploadButton
              uploadLabel={"W-9 Form"}
              id="w9-form"
              inputName="w9"
              onChange={this.handleInputChange}
              error={this.state.formDataErrors.w9}
              //@ts-ignore
              selectedName={this.state.formData.w9?.name || ""}
              onReset={() => this.onResetFile("w9")}
            />

            <Grid container className={this.classes.buttonsBlock}>
              <Grid item xs={6}>
                <BackButton
                  onClick={this.movePrev}
                  icon={
                    <ArrowBackIosIcon
                      style={{ marginLeft: 18, fontSize: 15 }}
                    />
                  }
                  text="Prev"
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  id="submitSignUp"
                  classes={{
                    root: this.classes.button2,
                  }}
                  variant="contained"
                  color={"primary"}
                  onClick={this.employerSignUp}
                  data-test-id="submit-form"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </LoginWrapper>
    );

    return this.state.step === 1 ? renderStep1() : renderStep2();
  }
}

export const AccountRegistrationStepsWithoutStyles = AccountRegistrationSteps;
export { styles };
//@ts-ignore
export default AccountRegistrationSteps = withStyles(styles)(
  AccountRegistrationSteps
);
