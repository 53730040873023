import React, { Component } from "react";
import { Box, Typography,Button,  } from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles, createStyles } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";

const theme = createTheme({
    palette: {
        primary: {
            main: "#390879",
        },
        secondary: {
            main: "#6f7c8e",
        },
    },
    typography: {
        fontFamily: `"Gotham", sans-serif`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
});

interface IRecentStatusCardProps {
    classes: Record<string, string>;
    closeModalButton: () => void;
    inputReasonText: string | undefined;
    handleReasonText: (strValue: string) => any;
    handleRemoveBtn: () => any;
    inputReasonError: string | undefined;
}

class RemovePerson extends Component<IRecentStatusCardProps> {
    render() {
        const { classes,  closeModalButton, inputReasonText, handleReasonText, handleRemoveBtn, inputReasonError } = this.props;
         let styles = classes
        return (
            <ThemeProvider theme={theme}>
                <Box
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                className={styles.modalHeader}>
                <Box className={styles.modalContainer}>
                    <Box className={styles.modalCloseButton} data-test-id="closeReasonModalId"><Close onClick={closeModalButton} /></Box>
                    <Typography className={styles.removeFromShyft}>Remove from Shyft</Typography>
                    <Box className={styles.textFieldHeader}>
                        <input
                            required
                            placeholder='Enter a reason...'
                            className={inputReasonError && inputReasonError !== "" ?  styles.formFieldError : styles.formField}
                            type="text"
                            minLength={3}
                            maxLength={80}
                            value={inputReasonText}
                            data-test-id="inputReasonId"
                            onChange={(event:any) => handleReasonText(event.target.value)}
                        />
                        {/* { inputReasonError && inputReasonError !== "" && 
                            <span className={styles.errorTextStyle}>{inputReasonError}</span>
                        } */}
                    </Box>

                    <Box className={styles.buttonHeaderContain}>
                        <Button 
                            className={styles.cancelButton}
                            variant="outlined"
                            data-test-id="removeModalCloseBtnId"
                            onClick={() => closeModalButton()}
                        >
                            Cancel
                        </Button>
                        <Button 
                            className={styles.removeNowheader} 
                            variant="contained"
                            data-test-id="removeModalRemoveBtnId"
                            disabled={inputReasonText && inputReasonText?.trim()?.split("").length > 2 ? false : true}
                            onClick={()=> handleRemoveBtn()}
                        >
                            Remove Now
                        </Button>
                    </Box>
                </Box>
            </Box>
            </ThemeProvider>
        );
    }
}

const styles = () => createStyles({
    modalContainer: {
        backgroundColor: "#ffffff",
        height: "216px",
        width: "416px",
        borderRadius: "14px",
        position: "absolute",
        filter: "drop-shadow(0px -4px 6px rgba(23,24,29,0.04))",
    },

    modalCloseButton: {
        position: "fixed",
        color: "#fff",
        top: "-60px",
        left: "185px",
        backgroundColor: "rgba(10,4,18,0.5490196078431373)",
        borderColor: "#fff",
        borderWidth: "thin",
        borderRadius: "18px ",
        zIndex: 9999,
        height: "36px",
        width: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },

    removeFromShyft: {
        fontSize: "20px",
        lineHeight: "36px",
        color: "#0a0412",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: "10px",
        
    },

    formField: {
        width: "368px",
        height: "52px",
        fontSize: "14px",
        color: "#6f7c8e",
        borderRadius:"16px",
        padding:"14px",
        border:"2px solid #DDD",
        boxSizing:"border-box"
    },
    formFieldError: {
        width: "368px",
        height: "52px",
        fontSize: "14px",
        color: "#6f7c8e",
        borderRadius:"16px",
        padding:"14px",
        border:"1px solid red",
        boxSizing:"border-box"
    },

    cancelButton: {
        width: "178px",
        height: "48px",
        borderRadius: "24px",
        color: "#390879",
        border: "1.5px solid #390879",
        textTransform: "capitalize",
        fontSize: "14px",
    },

    removeNowheader: {
        width: "178px",
        height: "48px",
        borderRadius: "24px",
        color: "#ffffff",
        fontSize: "14px",
        // border: "1px solid #ff375b",
        textTransform: "capitalize",
        backgroundColor: "#ff375b",
        "&:hover": {
            backgroundColor: "#ff375b",
        }
    },

    modalHeader: {
        backgroundColor: "white",
        height: "216px",
        width: "100%",
        borderRadius:"14px 14px 0px 0px",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        flexDirection:"column",
        filter: "drop-shadow(0px -4px 6px rgba(23,24,29,0.04))",

    },
 
    textFieldHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "13px",
        flexDirection: "column"
    },

    buttonHeaderContain :{
        display: "flex",
        justifyContent: "space-evenly",
        margin: "25px 15px"
    },

    errorTextStyle: {
        color: "red",
        width: "84%",
        paddingTop: "2px",
        fontSize: "12px"
    }

});

export default withStyles(styles)(RemovePerson);
