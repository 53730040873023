import React, { ChangeEvent } from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import EmployerFormsContoller, {
  Props,
  TBusinessForm,
  TOtherForm,
  TDocumentsForm,
  TDropdown,
  TFormErrors,
} from "./EmployerFormsController.web";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import UploadButton from "../../../components/src/UploadButton";
import Notification from "../../../components/src/Notification";

import { arrowBack, notification } from "./assets";
import { capitalizeWords, removeUnderScoreFromString } from "../../../components/src/Utilities";

export const BusinessForm = ({
  businessDetails,
  onChange,
  onNextStep,
  categoryList,
  errors,
  isSubmitted,
}: {
  categoryList: TDropdown[];
  errors: TFormErrors;
  businessDetails: TBusinessForm;
  onNextStep: (step: number) => void;
  onChange: <T extends keyof TBusinessForm>(
    field: T,
    value: TBusinessForm[T]
  ) => void;
  isSubmitted: boolean;
}) => {
  const { address, companyName, dbaName, phoneNumber, type, website } =
    businessDetails;

  return (
    <Box>
      <Box marginBottom="5px">
        <Typography>Update Business Details</Typography>
      </Box>
      <Box
        marginY="10px"
        border="1px solid #e1e6ec"
        padding="30px 20px 10px 20px"
        borderRadius="10px"
        bgcolor="#fff"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomSelect
              value={removeUnderScoreFromString(capitalizeWords(type))}
              handleChange={(e: any) => onChange("type", e.target.value)}
              label="Type of Hotel"
              name="type"
              options={categoryList}
              error={isSubmitted && errors.type}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="company"
              label="Legal Company Name"
              value={companyName}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("companyName", e.target.value)
              }
              error={isSubmitted && errors.companyName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="dba"
              label="Enter DBA Name"
              value={dbaName}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("dbaName", e.target.value)
              }
              error={isSubmitted && errors.dbaName}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="telephone"
              label="Business Telephone"
              value={phoneNumber}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("phoneNumber", e.target.value)
              }
              maxLength={12}
              error={isSubmitted && errors.phoneNumber}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="address"
              label="Address"
              value={address}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("address", e.target.value)
              }
              error={isSubmitted && errors.address}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="website"
              label="Business Website"
              value={website}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("website", e.target.value)
              }
              error={isSubmitted && errors.website}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Box padding={"10px"} width={"30%"}>
          <CustomButton
            label="Next"
            isOutlined={false}
            handleClick={onNextStep}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const OtherForm = ({
  otherDetails,
  uploadDetails,
  onChange,
  onPrevStep,
  onSubmit,
  onUploadDocument,
  errors,
  isSubmitted,
}: {
  otherDetails: TOtherForm;
  uploadDetails: TDocumentsForm;
  onPrevStep: () => void;
  onSubmit: () => void;
  errors: TFormErrors;
  isSubmitted: boolean;
  onChange: <T extends keyof TOtherForm>(
    field: T,
    value: TOtherForm[T]
  ) => void;
  onUploadDocument: <T extends keyof TDocumentsForm>(
    field: T,
    file: File | null
  ) => void;
}) => {
  const { contactName, email, contactNumber, einNumber } = otherDetails;
  const { form, certificate } = uploadDetails;

  return (
    <Box>
      <Box marginBottom="5px">
        <Typography>Other Details</Typography>
      </Box>
      <Box
        marginY="10px"
        border="1px solid #e1e6ec"
        padding="30px 20px 10px 20px"
        borderRadius="10px"
        bgcolor="#fff"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="contact-name"
              label="Contact Name"
              value={contactName}
              maxLength={20}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("contactName", e.target.value)
              }
              error={isSubmitted && errors.contactName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="contact-email"
              label="Contact Email Address"
              value={email}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("email", e.target.value)
              }
              error={isSubmitted && errors.email}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="contact-number"
              label="Contact Number"
              value={contactNumber}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("contactNumber", e.target.value)
              }
              maxLength={12}
              error={isSubmitted && errors.contactNumber}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomInput
              name="ein-number"
              label="EIN (Employer Identification Number)"
              value={einNumber}
              handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange("einNumber", e.target.value)
              }
              error={isSubmitted && errors.einNumber}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box marginBottom="5px">
        <Typography>Official Docs</Typography>
      </Box>
      <Box
        marginY="10px"
        border="1px solid #e1e6ec"
        padding="30px 20px 10px 20px"
        borderRadius="10px"
        bgcolor="#fff"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <UploadButton
              uploadLabel={"Certificate of Organization"}
              id="certificate-of-organization"
              inputName="certificateOrganization"
              onChange={(e) =>
                onUploadDocument("certificate", e.target.files[0])
              }
              onReset={() => onUploadDocument("certificate", null)}
              selectedName={certificate ? certificate.name : ""}
              error={isSubmitted && errors.certificate ? "error" : ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UploadButton
              uploadLabel={"W-9 Form"}
              id="w9-form"
              inputName="w9"
              onChange={(e) => onUploadDocument("form", e.target.files[0])}
              onReset={() => onUploadDocument("form", null)}
              selectedName={form ? form.name : ""}
              error={isSubmitted && errors.form ? "error" : ""}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        style={{
          gap: "30px",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          padding={"10px"}
          style={{
            gap: "12px",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onPrevStep}
            style={{
              backgroundColor: "rgba(225, 230, 236, 0.45)",
              borderRadius: "24px",
              padding: "14px",
              cursor: "pointer",
            }}
          >
            <img src={arrowBack} alt="back" />
          </Box>
          <Typography
            style={{
              cursor: "pointer",
            }}
            onClick={onPrevStep}
          >
            Back
          </Typography>
        </Box>
        <Box padding={"10px"} width={"30%"}>
          <CustomButton
            label="Submit"
            isOutlined={false}
            handleClick={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const UploadForm = ({
  uploadDetails,
  onUploadDocument,
  onSubmit,
  errors,
}: {
  uploadDetails: TDocumentsForm;
  onUploadDocument: <T extends keyof TDocumentsForm>(
    field: T,
    file: File | null
  ) => void;
  errors: TFormErrors;
  onSubmit: () => void;
}) => {
  const { form, certificate } = uploadDetails;

  return (
    <Box>
      <Box marginBottom="5px">
        <Typography>Official Docs</Typography>
      </Box>
      <Box
        bgcolor="#fff"
        padding="30px 20px 10px 20px"
        marginY="10px"
        border="1px solid #e1e6ec"
        borderRadius="10px"
      >
        <Grid spacing={3} container>
          <Grid item xs={12} md={6}>
            <UploadButton
              id="certificate-of-organization"
              inputName="certificateOrganization"
              uploadLabel={"Certificate of Organization"}
              onChange={(e) =>
                onUploadDocument("certificate", e.target.files[0])
              }
              selectedName={certificate ? certificate.name : ""}
              onReset={() => onUploadDocument("certificate", null)}
              error={errors.certificate ? "error" : ""}
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UploadButton
              id="w9-form"
              uploadLabel={"W-9 Form"}
              inputName="w9"
              onChange={(e) => onUploadDocument("form", e.target.files[0])}
              selectedName={form ? form.name : ""}
              onReset={() => onUploadDocument("form", null)}
              error={errors.form ? "error" : ""}
              disabled={true}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        display="none"
        justifyContent="flex-end"
        alignItems="center"
        marginY="10"
        width="100%"
      >
        <Box width="30%" padding="10px">
          <CustomButton
            label="Submit"
            handleClick={onSubmit}
            isOutlined={false}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const NotificationsForm = ({
  onToggle,
  isChecked,
}: {
  isChecked: boolean;
  onToggle: (isChecked: boolean) => void;
}) => {
  return (
    <Box>
      <Box marginBottom="5px">
        <Typography>Push Notification Settings</Typography>
      </Box>
      <Box
        marginY="10px"
        border="1px solid #e1e6ec"
        padding="30px 20px 10px 20px"
        borderRadius="10px"
        bgcolor="#fff"
      >
        <Notification
          checked={isChecked}
          imageUrl={notification}
          title="Set Push Notifications"
          description="Click on toggle If you want to off any push notifications from Sanagashyft"
          onChange={onToggle}
        />
      </Box>
    </Box>
  );
};

export default class EmployerForms extends EmployerFormsContoller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      formStep,
      businessDetails,
      otherDetails,
      uploadDetails,
      categoryList,
      errors,
      isBusinessFormSubmitted,
      isUpdateFormSubmitted,
    } = this.state;

    return (
      <Box marginTop="20px">
        {this.props.activeTab === 1 &&
          (formStep === 1 ? (
            <BusinessForm
              businessDetails={businessDetails}
              onNextStep={() => this.changeFormStep(2)}
              onChange={this.onChangeBusinessFormInput}
              categoryList={categoryList}
              errors={errors}
              isSubmitted={isBusinessFormSubmitted}
            />
          ) : (
            <OtherForm
              otherDetails={otherDetails}
              uploadDetails={uploadDetails}
              onPrevStep={() => this.changeFormStep(1)}
              onChange={this.onChangeOtherFormInput}
              onUploadDocument={this.onUploadDocument}
              errors={errors}
              onSubmit={this.onSubmitForm}
              isSubmitted={isUpdateFormSubmitted}
            />
          ))}

        {this.props.activeTab === 2 && (
          <UploadForm
            uploadDetails={uploadDetails}
            onUploadDocument={this.onUploadDocument}
            errors={errors}
            onSubmit={this.onSubmitDocuments}
          />
        )}

        {this.props.activeTab === 3 && (
          <NotificationsForm
            onToggle={this.props.onToggleNotifications}
            isChecked={this.props.profile.allowNotifications}
          />
        )}
      </Box>
    );
  }
}
