import React, { Component } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles, createStyles } from "@material-ui/core/styles";
import { Close, QueryBuilderSharp } from "@material-ui/icons";
import { convertDateIntoDateMonthYearFormat, convertDateIntoDay, convertDateIntoTwelveHourFormat, convertMealBreakIntoTimeFormat, formatHoursWorked } from "./Utilities";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const theme = createTheme({
    palette: {
        primary: {
            main: "#390879",
        },
        secondary: {
            main: "#6f7c8e",
        },
    },
    typography: {
        fontFamily: `"Gotham", sans-serif`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    }
});

interface ISuccessPopupDialogProps {
  open: boolean;
  classes: Record<string, string>;
  handleCloseModal: any;
  handleUpdateStatusSubmit: () => void;
  currentStatusData: any;
  ctaText?: string;
}


class UpdateStatusPopUp extends Component<ISuccessPopupDialogProps> {
  render() {
    const { classes, handleCloseModal, handleUpdateStatusSubmit, currentStatusData, ctaText = "Update Status" } = this.props;    
    let styles = classes;

    const coloredBoxWithIntials = (baseColor: string, intial: string) => {
      return (
          <Box
            height={"52px"}
            width={"50px"}
            marginRight={"5px"}
            display={"flex"}
            borderRadius={"10px"}
            alignItems={"center"}
            justifyContent={"center"}
            bgcolor={baseColor}
          >
            <Typography className={classes.coloredBoxText}>{intial}</Typography>
          </Box>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <Box
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className={styles.updateStatusWrapper}
        >
          <Box className={styles.updateStatusModalContainer}>
            <Box className={styles.modalCrossButton}>
                <Close onClick={handleCloseModal}/>
            </Box>

            <Box width={"10%"} margin={"15px auto"} padding={"2px"}>
              {currentStatusData.attributes.status === "completed" && coloredBoxWithIntials("#2da406", "C")}
              {currentStatusData.attributes.status === "missed" && coloredBoxWithIntials("#ff375b", "M")}
            </Box>

            <Typography className={classes.cardBodyText}>
                {convertDateIntoDateMonthYearFormat(currentStatusData.attributes.created_at, true)}
            </Typography>
            <Typography className={classes.cardInactiveSubHeading}>
                {convertDateIntoDay(currentStatusData.attributes.created_at)}
            </Typography>

            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
              <Box className={classes.boxes} flexDirection={"row"} bgcolor={"rgba(45, 164, 6, 0.1)"}>
                <ArrowDownwardIcon style={{color: "#2da406"}}/>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography className={classes.boxTextTitle} style={{color: "#2da406"}}>
                    Punched In
                  </Typography>
                  <Typography className={classes.boxTextVal}>
                    {convertDateIntoTwelveHourFormat(currentStatusData.attributes.punch_in)}
                  </Typography>
                </Box>
              </Box>

              <Box className={classes.boxes} flexDirection={"row"} bgcolor={"rgba(255, 55, 91, 0.07)"}>
                <ArrowUpwardIcon style={{color: "#ff375b"}}/>
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography className={classes.boxTextTitle} style={{color: "#ff375b"}}>
                    Punched Out
                  </Typography>
                  <Typography className={classes.boxTextVal}>
                    {convertDateIntoTwelveHourFormat(currentStatusData.attributes.punch_out)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} marginBottom="15px">
              <Box className={classes.boxes} flexDirection={"column"} bgcolor={"rgba(57, 8, 121, 0.045)"}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <QueryBuilderSharp
                    style={{ height: "15px", width: "15px", color: "#0091ff", marginRight: "2px" }}
                  />
                  <Typography className={classes.boxTextVal} style={{color: "#0091ff"}}>
                  {formatHoursWorked(currentStatusData.attributes.hours_worked, false)}
                  </Typography>
                </Box>
                <Typography className={classes.boxTextTitle} style={{color: "black"}}>
                  Hours Worked
                </Typography>
              </Box>

              <Box className={classes.boxes} flexDirection={"column"} bgcolor={"rgba(251, 192, 0, 0.13)"}>
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <img src={require("./lunchIcon.png")} alt="meal_break" height={"16px"} style={{ marginRight: "2px"}}/>
                  <Typography className={classes.boxTextVal} style={{color: "#f5c42c"}}>
                    {convertMealBreakIntoTimeFormat(currentStatusData.attributes.meal_break)}
                  </Typography>
                </Box>
                <Typography className={classes.boxTextTitle} style={{color: "black"}}>
                  Meal Break
                </Typography>
              </Box>
            </Box>

            <Button
              className={styles.updateStatusButton}
              variant="contained"
              data-test-id="endShyftBtnId"
              onClick={handleUpdateStatusSubmit}
            >
              {ctaText}
            </Button>
          </Box>
        </Box>
      </ThemeProvider>
    );
  }
}

const styles = () => createStyles({
    updateStatusModalContainer: {
        width: "95%",
        borderRadius: "14px",
        filter: "drop-shadow(0px -4px 6px rgba(23,24,29,0.04))",
        backgroundColor: "#ffffff",
        height:"98%"
    },

    modalCrossButton: {
        position: "fixed",
        color: "#fff",
        top: "-45px",
        left: "186px",
        backgroundColor: "rgba(10,4,18,0.5490196078431373)",
        borderColor: "#fff",
        borderWidth: "thin",
        borderRadius: "18px ",
        zIndex: 9999,
        height: "36px",
        width: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },

    updateStatusWrapper: {
        backgroundColor: "#FFF",
        height: "370px",
        width: "100%",
        borderRadius: "14px 14px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        padding: "2px 0px"
    },

    updateStatusButton: {
      width: "100%",
      height: "48px",
      borderRadius: "24px",
      backgroundColor: "#390879",
      color: "#fff",
      textTransform: "initial",
      // top: "245px",
      "&:hover": {
        backgroundColor: "#390879",
        color: "#fff",
      },
      "& span": {
        fontSize: "14px"
      }
    },

  coloredBoxText: { 
    fontWeight: "bold", 
    fontSize: "26px" , 
    color: "#fff" 
  },

  cardInactiveSubHeading: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#6f7c8e",
    textAlign:"center",
    margin: "10px auto",
  },

  cardBodyText: {
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#000",
    textAlign: "center",
  },

  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "178px",
    // height: "85px",
    margin: "10px",
    borderRadius: "10px",
    padding: "5px 0px"
  },

  boxTextTitle: {
    fontWeight: 400,
    fontSize: "12px",
  },

  boxTextVal: {
    fontWeight: 400,
    fontSize: "16px",
  },

});

export default withStyles(styles)(UpdateStatusPopUp);

