import React from "react";
import { Box, makeStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import CustomButton from "./CustomButton.web";
import { chat } from "./assets";

const useStyles = makeStyles({
  backdrop: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(10, 4, 18, 0.4)",
    zIndx: 10,
  },
  container: {
    padding: "32px",
    borderRadius: "14px 14px 0 0",
    background: "#ffffff",
    boxShadow: "0px -4px 12px rgba(23,24,29,0.04)",
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    zIndx: 20,
  },
  closeIcon: {
    position: "absolute",
    border: "1px rgb(255, 255, 255, 0.3) solid",
    padding: "5px",
    top: "-40px",
    left: "50%",
    transform: "translateX(-50%)",
    borderRadius: "50%",
    backgroundColor: "rgb(0 0 0 / 55%)",
    color: "#fff",
  },
  image: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {},
  heading: {
    color: "#0a0412",
    fontSize: "22px",
    lineHeight: "26px",
    textAlign: "center",
    margin: 0,
    fontWeight: 400,
    marginTop: "20px",
  },
  text: {
    color: "#6f7c8e",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    margin: "10px auto 20px",
    width: "90%",
    fontWeight: 400,
  },
  buttons: {},
});

interface MessagePanelProps {
  onBtnClick: () => void;
  onClose: () => void;
}

const MessagePanel = ({ onBtnClick, onClose }: MessagePanelProps) => {
  const classes = useStyles();

  return (
    <Box data-test-id="message-panel">
      <Box className={classes.backdrop} />
      <Box className={classes.container}>
        <IconButton className={classes.closeIcon} onClick={onClose} data-test-id="close-message-panel">
          <CloseIcon width={20} height={20} />
        </IconButton>
        <Box className={classes.image}>
          <img src={chat} alt="" width={60} />
        </Box>
        <Box className={classes.content}>
          <h2 className={classes.heading}>Send a message</h2>
          <p className={classes.text}>
            If you need to make changes to your upcoming shyfts, you can message
            the business directly to let them know.
          </p>
        </Box>
        <Box className={classes.buttons}>
          <CustomButton label="Proceed to Chat" handleClick={onBtnClick} />
        </Box>
      </Box>
    </Box>
  );
};

export default MessagePanel;
