import React, { Component } from 'react'
import { Avatar, Box, Divider, Link, Typography, withStyles } from '@material-ui/core'

export const ProfileImg = require("./assets/ProfileImg.png");
export const placeIcon = require("./assets/placeIcon.png");
export const phone = require("./assets/phone-profile.png");
export const outgoingMail = require("./assets/OutgoingMail.png");

export const useStyles: any = () => ({
      card: {
        border: "1px solid #e1e6ec",
        borderRadius: "20px",
        backgroundColor: "#ffffff",
      },
      contentcenterItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      contentSpaceAound: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
      },
      contentSpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      m15: {
        margin: "15px",
      },
      mr10: {
        marginRight: "10px",
      },
      mr5: {
        marginRight: "5px",
      },
      heading1: {
        fontSize: "20px",
        color: "#0a0412",
        fontWeight: 700,
      },
      heading2: {
        fontSize: "14px",
        color: "#6f7c8e",
        fontWeight: 400,
      },
      heading3: {
        fontSize: "11px",
        color: "#6f7c8e",
        fontWeight: 400,
      },
      badgeIcon: {
        height: "18px",
      },
      userInfo: {
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
      },
      cardProfile: {
        display: "flex",
        height: "180px",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
      outLineBtn: {
        width: "90%",
        margin: "15px auto",
        borderRadius: "28px",
        border: "1px solid grey",
        cursor: "pointer",
      },
      outLineBtnText: {
        margin: "10px",
        textAlign: "center",
        color: "#390879",
      },
})

// interface props {
//    holtalImg: string;
//    hotalName: string;

// }

class UserProfileCard extends Component {
    
  render() {
    const { classes }: any = this.props;
    return (
      <div>
      <Box className={classes.card}>
        <Box>
          <div className={classes.cardProfile}>
            <Avatar alt="User Profile" src={ProfileImg} />
            <div>
              <div
                className={classes.userInfo}
                style={{ marginTop: "10px" }}
              >
                <Typography className={classes.heading2}>
                  WelCome
                </Typography>
                <Typography className={classes.heading1}>
                  Test User
                </Typography>
              </div>
              <div className={classes.contentcenterItem}>
                <img
                  src={placeIcon}
                  alt=""
                  className={`${classes.badgeIcon} ${classes.mr5}`}
                  style={{ height: "14px" }}
                />
                <Typography className={classes.heading3}>
                  Behrin
                </Typography>
                <Typography className={classes.heading3}>
                  New York
                </Typography>
              </div>
            </div>
          </div>
          <Divider variant="middle" />
          <div
            className={`${classes.contentSpaceBetween} ${
              classes.m15
            }`}
          >
            <div className={`${classes.contentSpaceAound}`}>
              <img
                src={phone}
                alt=""
                className={`${classes.badgeIcon} ${classes.mr10}`}
                style={{ height: "24px" }}
              />
              <Typography>9685741254</Typography>
            </div>
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => console.log("EditProfile")}
            >
              Edit
            </Link>
          </div>
          <div
            className={`${classes.contentSpaceBetween} ${
              classes.m15
            }`}
          >
            <div className={`${classes.contentSpaceAound}`}>
              <img
                src={outgoingMail}
                alt=""
                className={`${classes.badgeIcon} ${classes.mr10}`}
                style={{ height: "24px" }}
              />
              <Typography>
                {"contact@gmail.com".slice(0, 12)}...
              </Typography>
            </div>
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => console.log("EditProfile")}
            >
              Edit
            </Link>
          </div>
          <Divider variant="middle" />
          <Box className={classes.outLineBtn}>
            <Box className={classes.outLineBtnText}>
              View Profile
            </Box>
          </Box>
        </Box>
      </Box>
      </div>
    )
  }
}

export default withStyles(useStyles)(UserProfileCard)
