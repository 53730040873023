import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
  makeStyles,
} from "@material-ui/core";
import * as React from "react";
import MoreVertIcon from "@material-ui/icons/MoreHoriz";
import {
  briefCaseJobIcon,
  briefIcon,
  deleteIcon,
  reportIcon,
  userIcon,
} from "./assets";

interface IChatHeaderProps {
  jobTitle?: string;
  fullName?: string;
  image?: string;
  isEmployer: boolean;
  businessDetail: string;
  navigateToProfile: () => void;
  handleMenuClick: (type: string) => void;
  handleOpenReportModal: () => void;
}

const useStyle = makeStyles({
  headDetails: {
    justifyContent: "space-between",
    alignItems: "flex-start",
    display: "flex",
  },

  personal: {
    display: "flex",
    gap: "8px",
  },

  imgWrapper: {
    "& img": {
      width: "46px",
      height: "46px",
      borderRadius: "12px",
    },
  },

  messanger: {
    "& > div": {
      display: "flex",
      gap: "5px",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "& h3": {
      margin: "0px",
      fontFamily: "Gotham, sans-serif",
    },
    "& p": {
      margin: "0px",
      fontFamily: "Gotham, sans-serif",
    },
    "& svg": {
      fontSize: "15px",
    },
  },

  nameMessanger: {
    fontWeight: 500,
    lineHeight: "25px",
    color: "#0a0412",
    fontSize: "16px",
    fontFamily: "Gotham, sans-serif",

    "@media(max-width:375px)": {
      fontSize: "11px",
    },
  },

  manageMessage: {
    fontSize: "14px",
    marginLeft: "5px",
    marginTop: "5px",
    fontFamily: "Gotham, sans-serif",
    fontWeight: "bold",
  },

  appBar: {
    width: "100%",
    zIndex: 5,
    padding: '0 20px',
    background: "#fff",
    position: 'absolute',
    boxShadow: "none",
    borderBottom: "1px solid #E1E6EC",
  },

  toolBar: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },

  iconButton: {
    backgroundColor: "#e1e6ec",
    padding: "5px",
    borderRadius: "50%",
  },

  menuList: {
    boxShadow: "0px 4px 22px rgba(10,4,18,0.04)",
    border: "0.5px dashed #e1e6ec",
    borderRadius: "10px",
  },

  headerSpan: {
    height: "5px",
    width: "5px",
    display: "flex",
    background: "#6F7C8E",
    borderRadius: "50%",
    marginBottom: "-3px",
    marginRight: "4px",
  },

  subHeader: {
    color: "#6F7C8E",
    fontWeight: "bold",
    fontFamily: "Gotham, sans-serif",
    fontSize: "13px",
    display: "flex",
    alignItems: "center",
  },
});

function ChatHeader(props: IChatHeaderProps) {
  const { image, jobTitle, fullName, isEmployer, handleMenuClick } = props;
  const [open, setOpen] = React.useState(false);

  const classes = useStyle();

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any, type: string) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    if (type === 'profile') {
      props.navigateToProfile();
    }
    if (type === 'report') {
      props.handleOpenReportModal()
    }
    setOpen(false);
    handleMenuClick(type);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <AppBar className={classes.appBar} position="static">
      <Container style={{ padding: 0 }} maxWidth="xl">
        <Toolbar className={classes.toolBar}>
          <Box className={classes.headDetails}>
            <Box className={classes.personal}>
              <Box className={classes.imgWrapper}>
                <img src={image} alt="user image" />
              </Box>
              <Box className={classes.messanger}>
                <Box>
                  <Typography
                    className={classes.nameMessanger}
                    style={{
                      fontWeight: "bold",
                      fontFamily: "Gotham, sans-serif",
                    }}
                    variant="h6"
                  >
                    {fullName}
                  </Typography>
                  <Typography className={classes.subHeader}>
                    {!isEmployer && <>
                      <span className={classes.headerSpan} />
                      {props.businessDetail}
                    </>}
                    <img
                      src={briefCaseJobIcon}
                      width={"14px"}
                      alt="user icon"
                      style={{ paddingLeft: "7px", paddingRight: "5px" }}
                    />
                    {jobTitle}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box>
              <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? "composition-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                className={classes.iconButton}
                onClick={handleToggle}
                data-test-id="chat-menu"
              >
                <MoreVertIcon
                  style={{ color: "#000" }}
                  width={20}
                  height={20}
                />
              </IconButton>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
              >
                <Paper>
                  <ClickAwayListener onClickAway={(e: any) => handleClose(e, "")}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                      className={classes.menuList}
                    >
                      <MenuItem data-test-id='profile' onClick={(e: any) => handleClose(e, "profile")}>
                        <img src={userIcon} width={"20px"} alt="user icon" />
                        <Typography className={classes.manageMessage}>
                          View Profile
                        </Typography>
                      </MenuItem>
                      {!isEmployer && <MenuItem data-test-id='shyft' onClick={(e: any) => handleClose(e, "shyft")}>
                        <img src={briefIcon} width={"20px"} alt="user icon" />
                        <Typography className={classes.manageMessage}>
                          Your Shyft
                        </Typography>
                      </MenuItem>}
                      <MenuItem data-test-id="report-chat" onClick={(e: any) => handleClose(e, "report")}>
                        <img
                          src={reportIcon}
                          width={"20px"}
                          alt="report icon"
                        />
                        <Typography className={classes.manageMessage}>
                          Report
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={(e: any) => handleClose(e, "delete")} data-test-id="delete-chat">
                        <img
                          src={deleteIcon}
                          width={"20px"}
                          alt="delete icon"
                        />
                        <Typography className={classes.manageMessage}>
                          Clear Chat
                        </Typography>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ChatHeader;
