import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Divider, makeStyles } from "@material-ui/core";
import dayjs, { Dayjs } from "dayjs";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { convertDateIntoDateMonthYearFormat } from "./Utilities";

const useStyles = makeStyles({
  MainWrapper: {
    borderRadius: 10,
    padding: 10,
    backgroundColor: "white",
    boxShadow: "-6px 7px 54px -24px rgba(0,0,0,0.5)",
  },
  CalendarHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection:"column"
  },
  WeekDaysWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width:"90%",
  },
  WeekDayCell: {
    height: 30,
    width: 30,
    margin: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "black",
    fontSize: "14px"
  },
  CalendarContentWrapper1:{
    display: "flex",
    width: "100%",
    justifyContent:" space-between",
    flexDirection:"column"
  },
  CalendarContentWrapper: {
    display: "flex",
    width: "90%",
    justifyContent:" space-between",
  },
  headerText:{
    fontSize:"16px",
    fontWeight:700
  },
  CalendarDayCell: {
    height: 30,
    width: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 6,
    margin: 2,
    flexDirection: "column",
  },
    shyft:{
        border: "1px solid #F3FCF3",
        backgroundColor:"#F3FCF3",
    },
    leave:{
        border: "1px solid #E2D9FC",
        backgroundColor:"#E2D9FC",
    },
    currentDay:{
        border: "1px solid #390879",
        backgroundColor:"#390879",
    },

    calendarMonthYearWrapper: {
      display: "flex",
      alignItems: "center",
      width: "90%",
      justifyContent: "space-between",
    },
    calendarTopBoxWrapper: {
      width: "100%",
      display: "flex",
      borderRadius: "8px",
      padding: "10px 0px",
      alignItems: "center",
      flexDirection: "column",
      backgroundColor: "#f2f2f2",
      justifyContent: "space-between",
      marginBottom: "5px"
    },
    selectedDayCompleted:{
        border: "1px solid #2da406",
        backgroundColor: "#2da4060d",
    },
    selectedDayMissed:{
      border: "1px solid #ff375b",
      backgroundColor: "#ff375b0d",
    },
    selectedDayWeeklyOff:{
      border: "1px solid #390879",
      backgroundColor: "#f6edfd",
    },
    selectedDateDot: {
        width: '4px', 
        height: "4px", 
        borderRadius: '50px', 
    },
    dotColorWrapper: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "90%",
      alignContent: "center",
    },
    dotColorCompleted: {
      backgroundColor: "#2da406",
    },
    dotColorMissed: {
      backgroundColor: "#ff375b",
    },
    dotColorWeeklyOff: {
      backgroundColor: "#390879",
    }
});

interface CustomCalenDarProps{
  activityDates: any[];
  allActivityDates: string[];
  handleSelectedDateClick: (date: string) => void;
  selectedUpdateStatusDates: string[];
  isUpdateStatus: boolean | undefined;
}

export default function CustomCalender({
   activityDates,
   allActivityDates,
   handleSelectedDateClick,
   selectedUpdateStatusDates,
   isUpdateStatus,
}: CustomCalenDarProps){
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [completedDates, setCompletedDates] = useState<string[]>([])
  const [missedDates, setMissedDates] = useState<string[]>([])
  const [weeklyOffDates, setWeeklyOffDates] = useState<string[]>([])


  useEffect(() => {
    if(activityDates.length === 3) {
      const datesSelectedComplete = activityDates[0]
      const datesSelectedMissed = activityDates[1]
      const datesSelectedWeeklyOff = activityDates[2]

      setCompletedDates(datesSelectedComplete);
      setMissedDates(datesSelectedMissed)
      setWeeklyOffDates(datesSelectedWeeklyOff)
    }

    if(allActivityDates.length > 0) {
      setSelectedDate(dayjs(allActivityDates[0]))
    }
  }, [])

  const currentDay = useMemo(() => dayjs().toDate(), []);

  const firstDayOfTheMonth = useMemo(
    () => selectedDate.clone().startOf("month"),
    [selectedDate]
  );

  const firstDayOfFirstWeekOfMonth = useMemo(
    () => dayjs(firstDayOfTheMonth).startOf("week"),
    [firstDayOfTheMonth]
  );

  const generateFirstDayOfEachWeek = useCallback((day: Dayjs): Dayjs[] => {
    const dates: Dayjs[] = [day];
    for (let i = 1; i < 6; i++) {
      const date = day.clone().add(i, "week");
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeek = useCallback((day: Dayjs): Date[] => {
    const dates: Date[] = [];
    for (let i = 0; i < 7; i++) {
      const date = day
        .clone()
        .add(i, "day")
        .toDate();
      dates.push(date);
    }
    return dates;
  }, []);

  const generateWeeksOfTheMonth = useMemo((): Date[][] => {
    const firstDayOfEachWeek = generateFirstDayOfEachWeek(
      firstDayOfFirstWeekOfMonth
    );
    return firstDayOfEachWeek.map((date) => generateWeek(date));
  }, [generateFirstDayOfEachWeek, firstDayOfFirstWeekOfMonth, generateWeek]);

  const renderDotWithColor = (colorClass: any) => {
    return (
      <div className={`${classes.selectedDateDot} ${colorClass}`}/>
    )
  }

  const handleDayClick = (dateString: string) => {
    if(selectedUpdateStatusDates.length > 0) {
      selectedUpdateStatusDates.includes(dateString) &&  handleSelectedDateClick(dateString)
    }
  }

  const renderDates = (day: any, dayIndex: number) => {
    const dateString = convertDateIntoDateMonthYearFormat(day, false)
    console.log("render date", dateString, activityDates)
    return (
      <div 
        className={
          `${classes.CalendarDayCell} 
          ${completedDates.includes(dateString) && classes.selectedDayCompleted}
          ${missedDates.includes(dateString) && classes.selectedDayMissed}
          ${weeklyOffDates.includes(dateString) && classes.selectedDayWeeklyOff}`
          // ${dayjs(currentDay).isSame(day, "date")&&classes.currentDay}
        }
        key={`day-${dayIndex}`}
        onClick={() => isUpdateStatus && handleDayClick(dateString)}
        
      >
          {day.getDate()}
          <div className={classes.dotColorWrapper}>
            {completedDates.includes(dateString) && renderDotWithColor(classes.dotColorCompleted)}
            {missedDates.includes(dateString) && renderDotWithColor(classes.dotColorMissed)}
            {weeklyOffDates.includes(dateString) && renderDotWithColor(classes.dotColorWeeklyOff)}
          </div>
       </div>
    )
  }

  return (
    <div className={classes.MainWrapper}>
      <div className={classes.CalendarContentWrapper1}>

        <div className={classes.calendarTopBoxWrapper}>
          <div className={classes.calendarMonthYearWrapper}>
            <ArrowBackIosIcon style={{height:"14px"}} data-test-id="date-click-calendar"
              onClick={() => setSelectedDate((date) => date.subtract(1, "month"))}
              />
              <Box className={classes.headerText}>{selectedDate.clone().format("MMM")}</Box>
            <ArrowForwardIosIcon style={{height:"14px"}}
              onClick={() => setSelectedDate((date) => date.add(1, "month"))}
            />
            <ArrowBackIosIcon style={{height:"14px"}}
              onClick={() => setSelectedDate((date) => date.subtract(1, "year"))}
              />
              <Box className={classes.headerText}>{selectedDate.clone().format("YYYY")}</Box>
            <ArrowForwardIosIcon style={{height:"14px"}}
              onClick={() => setSelectedDate((date) => date.add(1, "year"))}
            />
          </div>

          <Divider style={{ width: "90%", marginTop: "14px", marginBottom: "6px" }}/>

          <div className={classes.WeekDaysWrapper}>
              {generateWeeksOfTheMonth[0].map((day, index) => (
                <div className={classes.WeekDayCell} key={`week-day-${index}`}>
                  {dayjs(day).format("dd").slice(0,1)}
                </div>
              ))}
          </div>
        </div>

        <div className={classes.CalendarHeaderWrapper}>
          {generateWeeksOfTheMonth.map((week, weekIndex) => (
            <div className={classes.CalendarContentWrapper} key={`week-${weekIndex}`}>
              {week.map((day, dayIndex) => (
                renderDates(day, dayIndex)
              ))}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};
