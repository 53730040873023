import React from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { ongoingIcon, fileCreateIcon, completedIcon } from "./assets";

const getStatsColor = (type: string) => {
  let color = "#6236ff";
  if (type === "complete") {
    color = "#35c206";
  } else if (type === "ongoing") {
    color = "#f5c42c";
  }
  return color;
};

const useStyles = makeStyles((theme) => ({
  statsContainer: (props: IStatsTileProps) => ({
    borderRadius: 12,
    border: "1px solid",
    cursor: "pointer",
    borderColor: props.isActive ? "#390879" : "transparent",
    backgroundColor: props.isActive
      ? "rgba(57,8,121,0.03137254901960784)"
      : "#fff",
    padding: 14,
    " & .stats-count-wrapper": {
      display: "flex",
      alignItems: "center",
    },
    " & .stats-count": {
      fontSize: 26,
      fontWeight: "bold",
      paddingLeft: 10,
      color: getStatsColor(props.type),
    },
    " & .stats-title": {
      color: props.isActive ? "#390879" : "#0a0412",
      fontSize: 13,
      fontWeight: "bold",
      padding: "10px 0px 10px",
    },
  }),
}));

interface IStatsTileProps {
  label: string;
  statsCount: number;
  isActive: boolean;
  type: "create" | "ongoing" | "complete";
  handleClick: (e: any) => void;
}

const StatsTile: React.FC<IStatsTileProps> = ({
  label,
  isActive,
  type,
  statsCount,
  handleClick,
}) => {
  const classes = useStyles({
    label,
    isActive,
    type,
    statsCount,
    handleClick,
  });

  const getStatsIcon = () => {
    let icon = fileCreateIcon;
    if (type === "ongoing") {
      icon = ongoingIcon;
    } else if (type === "complete") {
      icon = completedIcon;
    }
    return icon;
  };

  return (
    <Paper
      elevation={1}
      className={classes.statsContainer}
      onClick={handleClick}
      data-test-id="stats-tile"
    >
      <div className="stats-count-wrapper">
        <img src={getStatsIcon()} alt="stats-icon" width={26} height={26} />
        <Typography className="stats-count">{statsCount}</Typography>
      </div>
      <Typography className="stats-title">{label}</Typography>
    </Paper>
  );
};

export default StatsTile;
