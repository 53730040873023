import React from "react";
import { Box, Button,Typography } from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import { ArrowBackIos } from "@material-ui/icons";
import CustomInput from "../../../components/src/CustomInputField.web";

interface Props {
  user: any;
  userFormError: any;
  configJSON: any;
  handleShyfterInputChange: any;
  moveBack:any;
  submitBtn:any;
}
export const UpdateOtherDeatils = (props: Props) => {
  const {
    user,
    userFormError,
    configJSON,
    handleShyfterInputChange,
    moveBack,
    submitBtn
  } = props;
  return (
    <>
      <Box
        border="1px solid #e1e6ec"
       
        padding={"20px 20px 10px"}
        borderRadius={"10px"}
        bgcolor={"#fff"}
      >
        <Box>
          <Box >
            <Typography>Industry Computer Applications</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box  width={"95%"}>
            <CustomSelect
              value={user.industry_computer_application_pos === "null" ? "" : user.industry_computer_application_pos }
              handleChange={(e: any) => handleShyfterInputChange(e, "industry_computer_application_pos")}
              label={configJSON.pos}
              name="pos"
              error={!!userFormError.industry_computer_application_pos}
              options={configJSON.posList}
            />
            </Box>
          <Box padding={"10px"} width={"95%"}>
          <CustomSelect
              value={user.industry_computer_application_pms === "null" ? "" : user.industry_computer_application_pms}
              handleChange={(e: any) => handleShyfterInputChange(e,"industry_computer_application_pms")}
              label={configJSON.pms}
              name="pms"
              error={!!userFormError.industry_computer_application_pms}
              options={configJSON.pmsList}
            />
          </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
          <Box  width={"51%"}>
          {user.industry_computer_application_pos === "Other" && 
              <Box  width={"95%"}>
              <CustomInput
                value={ user.industry_computer_application_other_pos }
                handleChange={(e: any) => handleShyfterInputChange(e, "industry_computer_application_other_pos")}
                label="Other POS"
                name="Other"
                error={!!userFormError.industry_computer_application_other_pos}
                data-test-id="Other"
              />
              </Box>
            }
            </Box>
            </Box>
        <Box>
          <Box >
            <Typography>Work Authorization (Knockout Questions)</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} >
          <Box width={"95%"}>
            <CustomSelect
              value={user.work_authorization_knockout_question[0]}
              handleChange={(e: any) => handleShyfterInputChange(e,"work_authorization")}
              label={configJSON.auth}
              name="ques1"
              error={!!userFormError.work_authorization_knockout_question1}
              options={configJSON.ansList}
            />
            </Box>
            <Box padding={"10px"} width={"95%"}>
            <CustomSelect
              value={user.work_authorization_knockout_question[1]}
              handleChange={(e: any) => handleShyfterInputChange(e,"work_age")}
              label={configJSON.age}
              name="ques2"
              error={!!userFormError.work_authorization_knockout_question2}
              options={configJSON.ansList}
            />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Box width={"15%"} marginRight={"5px"}>
        <Button
          variant="contained"
          style={{ fontWeight:"bold", borderRadius: "50px",padding:"11px 28px",backgroundColor: 'transparent', boxShadow: 'none', textTransform: 'capitalize' }}
          onClick={moveBack}
        >
          <span style={{
            width: "35px",
            height: "35px",
            background: "#E1E6EC",
            borderRadius: "50%",
            marginRight: "7px"
          }}><ArrowBackIos style={{height:"16px", marginTop: "9px", marginLeft: "8px" }}/></span> Back
        </Button>
        </Box>
      <Box data-test-id='submit' padding={"10px"} width={"230px"} onClick={submitBtn}>
          <CustomButton label={"Submit"} isOutlined={false} />
        </Box>
        
      </Box>
    </>
  );
};
