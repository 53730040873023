import * as React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import moment from "moment";

const useStylesFacebook = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
    },
    container: {
      position: "relative",
    },
    top: {
      position: "absolute",
      top: 0,
      left: "50%",
      color: "#B9F70E",
      animationDuration: "550ms",
    },
    bottom: {
      color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    circle: {
      strokeLinecap: "round",
    },
    bodyText: {
      fontSize: "10px",
      color: "#0a0412",
      fontWeight: 400,
      lineHeight: "14px",
      textAlign: "center",
      marginTop: "5px",
      marginBottom: "8px",
    },
    timer: {
      fontSize: "24px",
      color: "#b9df12",
      marginBottom: "5px",
      lineHeight: "26px",
    },
    currentTime: {
      color: "#6f7c8e",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "14px",
      textAlign: "center",
    },
    backCircularStyle: {
      textAlign: "center",
    }
  })
);
interface props extends CircularProgressProps {
  progress: number;
  isShyft: boolean;
  timer?: string;
  colorCode?: string;
  isActive?: boolean;
}

function FacebookCircularProgress(props: props) {
  const { size, progress, isShyft, timer, colorCode, isActive } = props;
  const classes = useStylesFacebook();

  return (
    <div className={classes.root}>
      <Box id="circular-progress-wrapper" className={classes.backCircularStyle}>
        <CircularProgress
          variant="determinate"
          className={classes.bottom}
          size={size}
          thickness={3}
          value={100}
          style={{
            color: isActive
              ? "rgba(185, 223, 18, 0.4)"
              : "rgba(111, 124, 142, 0.20)",
          }}
        />
        <CircularProgress
          variant="determinate"
          disableShrink
          classes={{
            root: classes.top,
            circle: classes.circle,
          }}
          size={size}
          thickness={3}
          style={{
            transform: "translateX(-50%) rotate(-90deg)",
            color: colorCode ? colorCode : "#390879",
          }}
          value={progress}
        />
      </Box>
      <Box
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {isShyft ? (
          <>
            <img
              src={require("./assets/images/timmerBlank.png")}
              width="20px"
              height="auto"
            />
            <Box className={classes.bodyText}>Hours Worked</Box>
            <Box className={classes.timer}>{timer}</Box>
            <Box className={classes.currentTime}>
              {moment().format("HH:mma")}
            </Box>
          </>
        ) : (
          <Box>
            <Typography style={{ color: colorCode ? colorCode : "#390879" }}>
              {progress}%
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
}

interface Props {
  timer?: string;
  percent: any;
  size: number;
  isShyftPage: boolean;
  colorCode?: string;
  isActive?: boolean;
}
export default function TimerWithCircularProgressBar(props: Props) {
  const { timer, percent, size, isShyftPage, isActive, colorCode } = props;
  return (
    <Box style={{ flexGrow: 1 }}>
      <FacebookCircularProgress
        progress={percent}
        isShyft={isShyftPage}
        timer={timer}
        {...props}
        colorCode={colorCode}
        isActive={isActive}
      />
    </Box>
  );
}
