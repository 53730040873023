import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
import { TSideTab } from "./ShyfterBusinessProfileController";
import { ChangeEvent } from "react";
import {
  checkAuthorization,
  convertObjToFormData,
  getUserId,
  logoutUser,
} from "../../../components/src/Utilities";
import { TFormData } from "./EmployerFormsController.web";
import { setStorageData } from "../../../framework/src/Utilities";

export type TProfile = {
  id: number;
  imageURL: string;
  type: string;
  name: string;
  dbaName: string;
  address: string;
  websiteURL: string;
  tag: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  telephone: string;
  einNumber: string;
  form: string;
  certificate: string;
  companyName: string;
  isHotel: boolean;
  allowNotifications: boolean;
};

const generalTabs = [
  {
    id: 1,
    tab: "Edit & Update Profile",
    isActive: true,
    tabIcon: "",
    activeTabIcon: "",
  },
  {
    id: 2,
    tab: "Documents",
    isActive: false,
    tabIcon: "",
    activeTabIcon: "",
  },
  {
    id: 3,
    tab: "Notification",
    isActive: false,
    tabIcon: "",
    activeTabIcon: "",
  },
];

const otherTabs = [
  {
    id: 4,
    tab: "Help Center",
    isActive: false,
    tabIcon: "",
    activeTabIcon: "",
  },
  {
    id: 5,
    tab: "Term & Conditions",
    isActive: false,
    tabIcon: "",
    activeTabIcon: "",
  },
  {
    id: 6,
    tab: "Privacy Policy",
    isActive: false,
    tabIcon: "",
    activeTabIcon: "",
  },
];

const routeMap = new Map([
  [4, "HelpCentre"],
  [5, "TermsAndConditions"],
  [6, "PrivacyPolicy"],
]);

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  generalTabs: TSideTab[];
  otherTabs: TSideTab[];
  profile: TProfile | null;
  activeTab: number;
  isSuccessPopUpVisible: boolean;
  isDeletePopUpVisible: boolean;
  userUpdateStorageData: any;
  isUpdatingUser: boolean;
  isDeleteAccountError: boolean;
  deleteAccountErrorMessage: string;
}

interface SS {
  id: any;
}

export default class EmployerManageAccountContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getEmployerProfileId: string = "";
  patchProfileImageId: string = "";
  patchDocumentsId: string = "";
  patchProfileId: string = "";
  deleteAccountId: string = "";
  patchNotificationId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      generalTabs,
      otherTabs,
      profile: null,
      activeTab: 1,
      isSuccessPopUpVisible: false,
      isDeletePopUpVisible: false,
      userUpdateStorageData: {},
      isUpdatingUser: false,
      isDeleteAccountError: false,
      deleteAccountErrorMessage: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getProfileHandler() {
    const userId = getUserId();
    if (!userId) return;

    setStorageData("manageAccountTabId", "1")

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmployerProfileId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShyfteUserProfileEndPoint}/${userId}`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(request.id, request);
  }

  handleProfileData = (response: any) => {
    const isHotel = (response.type as string).toLowerCase() === "hotel";

    const profile: TProfile = {
      id: response.id,
      imageURL: response.profile_picture,
      name: response.full_name,
      dbaName: response.business_details.dba_name,
      companyName: isHotel
        ? response.business_details.hotel_name
        : response.business_details.restuarant_name,
      address: response.business_details.address,
      tag: response.type,
      phoneNumber: response.phone_number,
      telephone: response.business_details.telephone,
      websiteURL: response.business_details.website,
      einNumber: response.ein,
      form: response.official_docs.form,
      certificate: response.official_docs.certificate,
      email: response.email,
      contactName: response.full_name,
      isHotel,
      type: isHotel
        ? response.business_details.hotel_type
        : response.business_details.restaurant_type,
      allowNotifications: response.push_notification,
    };

    this.setState({
      profile,
    });
  };

  changeTab = (tabId: number) => {
    const updatedTabs = this.state.generalTabs.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      }

      return { ...tab, isActive: false };
    });

    this.setState({ generalTabs: updatedTabs, activeTab: tabId });
  };

  changePage = (tabId: number) => {
    const page = routeMap.get(tabId);
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), page);

    this.send(message);
  };

  updateProfile = (
    formData: FormData,
    responseType: "image" | "profile" | "document"
  ) => {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    if (responseType === "image")
      this.patchProfileImageId = reqMessage.messageId;

    if (responseType === "profile") this.patchProfileId = reqMessage.messageId;

    if (responseType === "document")
      this.patchDocumentsId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );

    const header = {
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  uploadProfileImage = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      isUpdatingUser: false,
    })
    const file = e.target.files && e.target?.files[0];
    if (!file) return;

    const payload = {
      user: {
        profile_picture: file,
      },
    };

    const formData = convertObjToFormData(payload);
    this.updateProfile(formData, "image");
  };

  setProfileImage = (imageURL: string) => {
    const updateStorageData = { ...this.state.userUpdateStorageData, profile_picture: imageURL }
    setStorageData('userData', JSON.stringify(updateStorageData))
    this.setState((prevState) => ({
      profile: prevState.profile ? { ...prevState.profile, imageURL } : null,
      isUpdatingUser: true
    }));
  };

  openSuccessPopUp = () => {
    this.setState({ isSuccessPopUpVisible: true });
  };

  closeSuccessPopUp = () => {
    this.setState({ isSuccessPopUpVisible: false });
    this.navigateToPage("EmployerBusinessProfile")
  };

  openDeletePopUp = () => {
    this.setState({ isDeletePopUpVisible: true, isDeleteAccountError: false, deleteAccountErrorMessage: "" });
  };

  closeDeletePopUp = () => {
    this.setState({ isDeletePopUpVisible: false });
  };

  handleProfileUpdate = () => {
    this.openSuccessPopUp();
    this.getProfileHandler();
  };

  uploadOfficialDocs = (form: File, certificate: File) => {
    const payload = {
      user: {
        ...(form.size && {
          form,
        }),
        ...(certificate.size && {
          certificate,
        }),
      },
    };

    if (Object.keys(payload.user).length === 0) {
      this.openSuccessPopUp();
    } else {
      const formData = convertObjToFormData(payload);
      this.updateProfile(formData, "document");
    }
  };

  onSubmitForm = (data: TFormData) => {
    const { profile } = this.state;
    if (!profile) return;

    const { businessDetails, otherDetails, uploadDetails } = data;

    const body = {
      user: {
        full_name: otherDetails.contactName,
        email: otherDetails.email,
        ...(uploadDetails?.certificate?.size && {
          certificate: uploadDetails.certificate,
        }),
        ...(uploadDetails?.form?.size && { form: uploadDetails.form }),
        ein: otherDetails.einNumber,
        phone_number: otherDetails.contactNumber,
      },
      [profile.isHotel ? "hotel" : "restaurant"]: {
        [profile.isHotel ? "hotel_type" : "restaurant_type"]:
          businessDetails.type,
        dba_name: businessDetails.dbaName,
        address: businessDetails.address,
        website: businessDetails.website,
        [profile.isHotel ? "hotel_name" : "restaurant_name"]:
          businessDetails.companyName,
        telephone: businessDetails.phoneNumber,
      },
    };

    const formData = convertObjToFormData(body);
    this.updateProfile(formData, "profile");
  };

  onDeleteAccount = () => {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAccountId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleAccountDeletion = (response: any) => {
    if (Array.isArray(response) && response.length > 0){
      this.setState({ isDeleteAccountError: true, deleteAccountErrorMessage: response[0].error})
    }
    if (response.message !== "User account deleted successfully") return;
    this.closeDeletePopUp();
    logoutUser();
    this.navigateToPage("Login");
  };

  navigateToPage = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  onTogglePushNotifications = (isChecked: boolean) => {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patchNotificationId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchPushNotificationsEndpoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        push_notification: isChecked,
        device_id: localStorage.getItem("fcmToken") || ""
      })
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleAllowNotifications = (responseJson: any) => {
    const allowNotifications =
      responseJson?.messages?.includes("Push notifications turned on") ||
      false;

    this.setState((prevState) => ({
      profile: prevState.profile
        ? { ...prevState.profile, allowNotifications }
        : null,
    }));
  };

  handleApiResponse(message: Message, responseJson: any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    

    if (apiRequestCallId === this.getEmployerProfileId) {
      this.handleProfileData(responseJson.user.data.attributes);
    }

    if (apiRequestCallId === this.patchProfileId) {
      this.handleProfileUpdate();
    }

    if (apiRequestCallId === this.patchProfileImageId) {
      this.setProfileImage(responseJson.user.profile_picture_url);
    }

    if (apiRequestCallId === this.patchDocumentsId) {
      this.handleProfileUpdate();
    }

    if (apiRequestCallId === this.deleteAccountId) {
      this.handleAccountDeletion(responseJson);
    }

    if (apiRequestCallId === this.patchNotificationId) {
      this.handleAllowNotifications(responseJson);
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.navigateToPage("Login");
        return;
      } else {
        this.handleApiResponse(message, responseJson);
      }
    }
  }

  componentDidMount = async () => {
    const isValidBusinessUser = checkAuthorization(true, false);
    if (!isValidBusinessUser) {
      this.navigateToPage("Login");
      return;
    }
    const employerUserData: any = localStorage.getItem("userData");
    const navigationTabId: any = localStorage.getItem("manageAccountTabId");

    this.setState({ 
      userUpdateStorageData: JSON.parse(employerUserData), 
      activeTab: JSON.parse(navigationTabId)
    })

    this.getProfileHandler();
    this.changeTab(JSON.parse(navigationTabId));
  };

  closeDeleteErrorPopup = () => {
    this.setState({ isDeleteAccountError: false, deleteAccountErrorMessage: "" });
  }
}
