import React, { Component } from "react";
import { Box, Typography } from "@material-ui/core";
import { ThemeProvider, createTheme, withStyles, createStyles } from "@material-ui/core/styles";
import { Close, DoubleArrow } from "@material-ui/icons";

const theme = createTheme({
    palette: {
        primary: {
            main: "#390879",
        },
        secondary: {
            main: "#6f7c8e",
        },
    },
    typography: {
        fontFamily: `"Gotham", sans-serif`,
        fontSize: 18,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    }
});

interface IRecentStatusCardProps {
    completedShyft?: string;
    hourWorked?: string;
    missedShyft?: string;
    classes: Record<string, string>;
    closeEndShyft(): void;
    handleEndShyft:()=> void;
}


class EditShifyButton extends Component<IRecentStatusCardProps> {
    render() {
        const { classes, closeEndShyft, handleEndShyft } = this.props;
        let styles = classes
        return (
            <ThemeProvider theme={theme}>
                    <Box
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                        className={styles.EndShiftHeader}>
                        <Box className={styles.modalContainerSkip}>
                            <Box className={styles.modalCloseButton}><Close
                                onClick={closeEndShyft}
                            /></Box>
                            <Typography className={styles.endShyftConfirmationText}>End Shyft Confirmation!</Typography>
                            <Typography className={styles.endShyftContentText}>Are you really want to end this shyft.<br />Swipe right to end this shyft now.</Typography>
                            <Box className={styles.skipContaner}>
                                <Box className={styles.boxWithBorderSkip}>
                                    <Box className={styles.circleBlueColor} onClick={handleEndShyft}><DoubleArrow /></Box>
                                    <Box style={{ marginLeft: "5px" }}>
                                        <Typography className={styles.endNowText}>End Now</Typography>
                                        <Typography className={styles.swipeRight}>Swipe right to end this shyft...</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
            </ThemeProvider>
        );
    }
}

const styles = () => createStyles({
    modalContainerSkip: {
        width: "416px",
        height: "188px",
        borderRadius: "14px",
        filter: "drop-shadow(0px -4px 6px rgba(23,24,29,0.04))",
        backgroundColor: "#ffffff",
    },

    endShyftConfirmationText: {
        fontSize: "20px",
        lineHeight: "36px",
        color: "#0a0412",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: "15px"
    },

    endShyftContentText: {
        fontSize: "12px",
        letterSpacing: "0px",
        lineHeight: "18px",
        color: "#6f7c8e",
        fontWeight: 500,
        textAlign: "center",
    },

    boxWithBorderSkip: {
        width: "368px",
        height: "52px",
        borderRadius: "26px",
        backgroundColor: "rgba(57,8,121,0.0196078431372549)",
        border: "1px solid #390879",
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
    },

    circleBlueColor: {
        width: "40px",
        height: "40px",
        borderRadius: "20px",
        backgroundColor: "#390879",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "5px",
        color: "#FFF"
    },

    endNowText: {
        fontSize: "14px",
        lineHeight: "16px",
        color: "#390879",
        fontWeight: 500,
    },

    swipeRight: {
        fontSize: "11px",
        lineHeight: "16px",
        color: "#0a0412",
        fontWeight: 500,
    },

    modalCloseButton: {
        position: "fixed",
        color: "#fff",
        top: "-60px",
        left: "185px",
        backgroundColor: "rgba(10,4,18,0.5490196078431373)",
        borderColor: "#fff",
        borderWidth: "thin",
        borderRadius: "18px ",
        zIndex: 9999,
        height: "36px",
        width: "36px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },

    EndShiftHeader: {
        backgroundColor: "#FFF",
        height: "216px",
        width: "100%",
        borderRadius: "14px 14px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },

    skipContaner: {
        display: "flex",
        justifyContent: "center"
    }
});

export default withStyles(styles)(EditShifyButton);
