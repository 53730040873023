import React from "react";
import { Box, Grid } from "@material-ui/core";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomDateInput from "../../../components/src/CustomDateInput.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import { verifiedIcon } from "../../email-account-registration/src/assets";
import OtpPopUp from "../../../components/src/OtpPopUp";
import CustomButton from "../../../components/src/CustomButton.web";
import { capitalizeWords } from "../../../components/src/Utilities";
import FormDialogBox from "../../../components/src/FormDialogBox";

interface Props {
  user: any;
  userFormError: any;
  configJSON: any;
  handleShyfterInputChange: any;
  setMaxDate: any;
  moveToNext: any;
  getIsNumberVerified: any;
  handlePhoneVerification: any;
  vafiyOpen: any;
  otp: any;
  handleOTPDialogClose: any;
  setOtp: any;
  seconds: any;
  verifyOTPRequest: any;
  setDisabledVerifyOTP: any;
  openChangeNumberModal?: any;
  closeChangeNumberModal?: any;
  isChangeNumber?: boolean
  handleCountryCodeChange?: any;
  countryCodeUpdate?: string;
  handlePhoneNumberUpdate?: any;
  updatedPhoneNumber?: any;
  errorMessage?: string;
  errorPopUp?: boolean;
}
export const UpdatePersonalDeatils = (props: Props) => {
  const {
    user,
    userFormError,
    configJSON,
    handleShyfterInputChange,
    setMaxDate,
    moveToNext,
    getIsNumberVerified,
    handlePhoneVerification,
    vafiyOpen,
    otp,
    handleOTPDialogClose,
    setOtp,
    seconds,
    verifyOTPRequest,
    setDisabledVerifyOTP,
    openChangeNumberModal,
    isChangeNumber,
    closeChangeNumberModal,
    handleCountryCodeChange,
    countryCodeUpdate,
    handlePhoneNumberUpdate,
    updatedPhoneNumber,
    errorMessage,
    errorPopUp
  } = props;
  return (
    <>
      <Box
        border="1px solid #e1e6ec"
        marginY={"10px"}
        padding={"30px 20px 10px 20px"}
        borderRadius={"10px"}
        bgcolor={"#fff"}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CustomInput
              value={capitalizeWords(user.full_name || "")}
              handleChange={(e) => handleShyfterInputChange(e, "full_name")}
              label={configJSON.fullName}
              name="full_name"
              maxLength={20}
              error={!!userFormError.full_name}
            />
            <CustomInput
              value={user.email}
              handleChange={(e) => handleShyfterInputChange(e, "email")}
              label={configJSON.emailAddress}
              name="email"
              type={'email'}
              maxLength={80}
              error={!!userFormError.email}
            />
            <CustomDateInput
              handleChange={(e) => handleShyfterInputChange(e, "date_of_birth")}
              value={user.date_of_birth}
              label={configJSON.dateOfBirth}
              name="date_of_birth"
              maxDate={setMaxDate()}
              error={!!userFormError.date_of_birth}
            />
            <CustomSelect
              value={user.dl_state}
              handleChange={(e) => handleShyfterInputChange(e, "dl_state")}
              label={configJSON.selectDLState}
              name="dl_state"
              error={!!userFormError.dl_state}
              options={configJSON.dlStateList}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div style={webStyle.mobileInputWrapper}>
              <CustomInput
                value={user.full_phone_number}
                handleChange={(e) =>
                  handleShyfterInputChange(e, "full_phone_number")
                }
                label={configJSON.mobileNumber}
                name="phone_number"
                maxLength={10}
                error={!!userFormError.full_phone_number}
                disabled={true}
              />
              {getIsNumberVerified() ? (
                <img
                  width={22}
                  src={verifiedIcon}
                  style={webStyle.verifiedIcon}
                />
              ) : (
                <span
                  style={webStyle.verifyNowText}
                  data-test-id="verify-btn"
                  onClick={openChangeNumberModal}
                >
                  Change Number
                </span>
              )}
            </div>
            <CustomInput
              value={capitalizeWords(user.address || "")}
              handleChange={(e) => handleShyfterInputChange(e, "address")}
              label={configJSON.address}
              name="address"
              maxLength={80}
              error={!!userFormError.address}
            />
            <CustomInput
              value={user.ssn}
              handleChange={(e) => handleShyfterInputChange(e, "ssn")}
              label={configJSON.enterSSN}
              name="ssn"
              maxLength={11}
              error={!!userFormError.ssn}
            />
            <CustomInput
              value={user.dl_number}
              handleChange={(e) => handleShyfterInputChange(e, "dl_number")}
              label={configJSON.dlNumber}
              name="dl_number"
              maxLength={11}
              error={!!userFormError.dl_number}
            />
          </Grid>
        </Grid>
        {vafiyOpen && (
          <OtpPopUp
            isOpen={vafiyOpen}
            otp={otp}
            resend={handlePhoneVerification}
            handleClose={handleOTPDialogClose}
            phone={updatedPhoneNumber}
            setOtp={setOtp}
            seconds={seconds}
            handleChange={handleOTPDialogClose}
            handleSubmit={() => verifyOTPRequest(updatedPhoneNumber, otp)}
            disabled={setDisabledVerifyOTP(otp, seconds)}
          />
        )}
        {isChangeNumber &&  
        <FormDialogBox
          name="phone"
          type="phone"
          title="Update Your Phone Number"
          isOpen={true}
          countryCode={countryCodeUpdate}
          value={updatedPhoneNumber}
          handleChange={handlePhoneNumberUpdate}
          handleSubmit={handlePhoneVerification}
          handleClose={closeChangeNumberModal}
          disabled={false}
          handleCountryCodeSelect={handleCountryCodeChange}
          errorMessage={errorMessage}
          errorPopUp={errorPopUp}
        />}
      </Box>
      <Box
        marginY={"10"}
        width={"100%"}
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Box padding={"10px"} width={"230px"}>
          <CustomButton
            label={"Next"}
            isOutlined={false}
            handleClick={moveToNext}
          />
        </Box>
      </Box>
    </>
  );
};

const webStyle = {
  backArrow: {
    backgroundColor: "#f2f2f9",
  },
  stepWrapper: {
    maxWidth: 510,
    display: "flex",
    flexGrow: 1,
    padding: "50px 75px",
    flexDirection: "column" as any,
    backgroundColor: "white",
  },

  loginText: {
    fontWeight: 600 as any,
    fontSize: "16px",
    letterSpacing: "-0.31px",
    marginLeft: "8px",
  },
  verifiedIcon: {
    lineHeight: "18px",
    color: "#390879",
    position: "absolute" as any,
    cursor: "pointer",
    fontWeight: 600 as any,
    top: "12px",
    right: "14px",
  },
  header: {
    marginTop: "10%",
    fontSize: 36,
    fontWeight: "bold" as any,
    marginBottom: 14,
    marginLeft: 5,
  },
  shyfterText: { color: "#B9DF12" },
  subHeader: { marginBottom: "22px" },
  subHeaderText: {
    width: "100%",
    paddingLeft: 5,
    fontSize: 16,
    fontWeight: 600 as any,
  },
  subHeaderLine: { width: "75%" },

  verifyNowText: {
    lineHeight: "18px",
    fontSize: "12px",
    right: "14px",
    color: "#390879",
    cursor: "pointer",
    fontWeight: 600 as any,
    position: "absolute" as any,
    top: "14px",
  },
  actionWrapper: {
    marginTop: "24px",
  },
  mobileInputWrapper: {
    position: "relative" as any,
  },

  errorText: {
    marginTop: "-10px",
    color: "#ff0000",
    fontSize: "12px",
  },
  prevArrow: {
    backgroundColor: "#f1f5f8",
  },
};
