import React from "react";

import {
  Box,
  Typography,

  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import TermsAndConditions3Controller, {
  Props
} from "./TermsAndConditions3Controller";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";

export default class PrivacyAndPolicy extends TermsAndConditions3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <GenericHeader
          navigation={this.props.navigation}
          onNavigate={this.onNavigateFromTNC}
          onSignUp={this.toggleSignUpForTNC}
        />
        {this.state.isOpenSignUpDialog && (
          <AccountTypeSelectionDialog
            isOpen={this.state.isOpenSignUpDialog}
            handleSave={this.redirectToSignUpPageForTNC}
            handleClose={this.toggleSignUpForTNC}
          />
        )}
         <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
          <Typography style={webStyle.headerText} variant="h5" className="heading-main" data-test-id="test-heading">Privacy <span>Policy</span></Typography>
          <label className="heading-mainsub" style={webStyle.linkTag}>
              <span
                data-test-id="home-page-link"
                className="header-home"
                onClick={() => this.redirectToSignUpPageForTNC("Home")}
              >
                Home
              </span>{" "}
              &nbsp;|&nbsp;{" "}
              <span
                data-test-id="privacy-policy-link"
                className="header-home"
                onClick={() =>
                  this.redirectToSignUpPageForTNC("PrivacyAndPolicy")
                }
              >
                Privacy Policy
              </span>
          </label>
          </Box>            
         </Box>
         <Box className="imageSection">
   
         </Box>
         <GenericFooter   navigation={this.props.navigation} onAboutClick={this.redirectToAbout}/>

      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  headerText:{
    fontWeight: 600,
    fontSize:"2.125rem",
  },
  linkTag: {
    color: "white"
  }
}

// Customizable Area End
