export const Benefits={
    "BusinessBenefits":[
        {   
            "title":"Get Premium",
            "imgUrl":"/diamond.png"
        },{
            "title":"Add Jobs",
            "imgUrl":"/page.png"
        },
        {
            "title":"Manage Multiple Shifts",
            "imgUrl":"/path.png"
        },
        {
            "title":"Select Best Shyfter",
            "imgUrl":"/analysis.png"
        },
        {
            "title":"Track Activities",
            "imgUrl":"/time.png"
        },{
            "title":"Connect With Chat",
            "imgUrl":"/communications.png"
        }
    ],
    "ShyfterBenifits":[
        {   
            "title":"Explore Jobs",
            "imgUrl":"/suitcase.png"
        },{
            "title":"Get the Best Payee",
            "imgUrl":"/money.png"
        },
        {
            "title":"Verified Business",
            "imgUrl":"/glass.png"
        },
        {
            "title":"Find Best Place to Work",
            "imgUrl":"/group_Hotel.png"
        },
        {
            "title":"Track Activities",
            "imgUrl":"/time.png"
        },{
            "title":"Connect With Chat",
            "imgUrl":"/communications.png"
        }
    ]
    
}
