// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Typography,
  Tabs,
  Tab,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";

import ContactusController, { Props, configJSON } from "./ContactusController";
import { storeFrontImg, workBlackImg } from "./assets";
import ShyfterContactUsForm from "./ShyfterContactUsForm.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTab = styled(Tab)({
  width: "100%",
  '&.tab_title > span':{
    color: "#390879",
    fontWeight: 'bold',
    flexDirection: 'row',
    textTransform: 'capitalize',
  }
})

export const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default class ReachUs extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Container>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "10px 0px"
            }}
          >
            <Box sx={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start', width: '100%', marginBottom: '20px' }}>
              <Typography variant="h5" component="h5" style={{ fontWeight: 'bold'}}>
                Reach <span style={{color: '#BFDC46' }}> Us </span>
              </Typography>
              <Typography variant="h6" component="h6" style={{ fontWeight: 'bold', fontSize: '12px', color: 'grey'}}>
                {configJSON.willBeInTouchText}
              </Typography>
            </Box>

            <Box sx={{ width: '100%' }} style={{ backgroundColor: "#fff" }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs 
                  data-test-id="tabsTestId" 
                  value={this.state.tabValue} 
                  aria-label="basic tabs example"
                  onChange={this.handleTabChange} 
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#390879'
                    }
                  }}
                >
                  <CustomTab 
                    data-test-id="tab_1Id"
                    className="tab_title"
                    icon={<img src={storeFrontImg} alt="store_img" style={{ width: '20px', height: '20px', marginRight: '8px'}} />} 
                    label={configJSON.tabBusinessTitle} 
                    {...a11yProps(0)} 
                  />
                  <CustomTab 
                    data-test-id="tab_2Id"
                    className="tab_title"
                    icon={<img src={workBlackImg} alt="store_img" style={{ width: '20px', height: '20px', marginRight: '8px'}} />} 
                    label={configJSON.tabShyfterTitle}
                    {...a11yProps(1)} 
                  />
                </Tabs>
              </Box>

              <CustomTabPanel data-test-id="businessTabRenderId" value={this.state.tabValue} index={0}>
                <ShyfterContactUsForm navigation={this.props.navigation} id={""} tabValue={this.state.tabValue}/>
              </CustomTabPanel>

              <CustomTabPanel data-test-id="shyfterTabRenderId" value={this.state.tabValue} index={1}>
                <ShyfterContactUsForm navigation={this.props.navigation} id={""} tabValue={this.state.tabValue}/>
              </CustomTabPanel>
            </Box>

          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}

// Customizable Area End
