import React, { useEffect } from "react";
import { Box, Container, Grid, Typography, styled } from "@material-ui/core";
import parse from "html-react-parser"

const CustomGrid = styled(Grid)({
    padding: "20px 0px",
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& .r-backgroundColor-14lw9ot':{
        border: '1px solid lightgrey',
        borderRadius: '12px',
    },
    '& .r-marginBottom-ku1wi2': {
        marginTop: '5px'
    },
    '& .css-text-901oao': {
       fontSize: '16px !important',
       color: 'black !important'
    },
    '& .r-13qz1uu': {
        border: '1px solid lightgrey',
    }
})

interface ITermsAndPolicyProps {
    title1: string;
    title2: string;
    renderData: any[];
    isLoading: boolean;
    apiError: string;
}

const markUpText = ({ title, description }: { title: string, description: string }) => {
    let value = "";
    if(title.includes("/>") || description.includes("/>")){
        value = title + description;
    } else {
        value = title +  "<br/>" + description;
    }
    return (
        { __html: value }
    ) 
}

const CommonTermsAndPolicy: React.FC<ITermsAndPolicyProps> = ({
 title1,
 title2,
 renderData,
 isLoading,
 apiError
}) => {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

  return (
    <Box width="100%" marginTop="8%">
          <Container maxWidth={"md"} style={webStyle.containerTagStyle}>
            <Box style={webStyle.titleWrapperStyle}>
              <Typography variant="h6" style={webStyle.title1Style}>{title1}</Typography>
              <Typography variant="h6" style={webStyle.title2Style}>{title2}</Typography>
            </Box>
            {
                apiError === "" && 
                <CustomGrid container spacing={2}>
                { 
                    isLoading && renderData.map((item, index) => {
                        return (
                            <div 
                                key={index}
                                style={webStyle.cardWrapper} 
                                // dangerouslySetInnerHTML={markUpText(item.attributes)} 
                            >
                                <h4 style={{ marginBottom: "-6px"}}>{parse(item.attributes.title)}</h4>
                                <p style={{color: 'gray' }}>{parse(item.attributes.description)}</p>
                            </div>
                        )  
                    })
                }
                </CustomGrid>
            }
            
          </Container>
        </Box>
  );
};

export default CommonTermsAndPolicy;


const webStyle = {
    containerTagStyle: {
        backgroundColor: '#f7faff', 
        height: "auto", 
        padding: '0px 0px',
        minHeight: '590px'
    },
    titleWrapperStyle: {
        display: 'flex', 
        flexDirection: 'row' as 'row', 
        width: '50%', 
        margin: '10px auto', 
        alignContent: 'center',
        // justifyContent: 'space-around'
    },
    title1Style: {
        color: 'black', 
        fontSize: '32px'
    },
    title2Style: {
        color: '#b9df12', 
        fontSize: '32px',
        marginLeft: '8px'
    },
    contentStyle: {
        marginTop: '0px', 
        marginBottom: '10px', 
        fontSize: '14px', 
        color: '#666464'
    },
    cardWrapper: {
        padding: '10px',
        margin: '10px 0px',
        backgroundColor: 'white',
        border: '1px solid lightgrey',
        borderRadius: '10px'
    }
}
