import moment from "moment";
import { removeStorageData } from "../../framework/src/Utilities";
import { TRatingsData } from "../../blocks/CustomisableUserProfiles/src/ShyfterBusinessProfileController";
import { AsyncStorage } from "react-native";
const mainConfigJson = require("../../framework/src/config");

export const setUserRole = (role: string) => {
  localStorage.setItem("userRole", role);
};

export const getUserRole = () => {
  return localStorage.getItem("userRole");
};

export const getNotificationStatus = () => {
  const getUser = localStorage.getItem("userData");
  if (getUser) {
    const userData = JSON.parse(getUser);
    const status = userData.user.push_notification;
    return status;
  }
}

export const setNotificationStatus = (status: boolean) => {
  const getUser = localStorage.getItem("userData");

  if (getUser) {
    const userData = JSON.parse(getUser);

    userData.user.push_notification = status;

    localStorage.setItem("userData", JSON.stringify(userData));
    if (Notification.permission !== 'granted') {
      Notification.requestPermission()
        .catch(error => {
          if (error) {
            localStorage.setItem("fcmToken", "")
          }
        })
    }
    return userData.user.push_notification;
  }
}

export const setSelectedSubsId = (id: any) => {
  return localStorage.setItem("subsId", JSON.stringify(id));
};
export const getSelectedSubscrptionId = () => {
  let id = localStorage.getItem("subsId");
  if (id) {
    return JSON.parse(id);
  }
};

export const convertMealBreakStringIntoNumber = (value: string) => {
  let breakVal = 0;
  if (value && value !== null && value !== "") {
    let mealBreakString = value.split(" ")[0]

    breakVal = Number(mealBreakString);
  }

  return breakVal;
}

export const convertMealBreakIntoTimeFormat = (value: number) => {
  let calcBreak = "00 Mins";

  if (!value || value === null) {
    return calcBreak;
  }

  if (value >= 0 && value < 10) {
    calcBreak = `0${value} Mins`
  }
  if (value >= 10) {
    calcBreak = `${value} Mins`
  }

  // const breakTime = value.split(":");

  // if (breakTime[0].slice(0, 2) !== "00") {
  //   calcBreak = `${breakTime[0].slice(0, 2)} Hrs`;
  // }
  // if (breakTime[0].slice(0, 2) === "00" && breakTime[1].slice(0, 2) !== "00") {
  //   calcBreak = `${breakTime[1].slice(0, 2)} Mins`;
  // }

  return calcBreak;
};

const calProgress = (val: string) => {
  let progressValue = 0;
  switch (val) {
    case "01":
      progressValue = 10;
      break;
    case "02":
      progressValue = 20;
      break;
    case "03":
      progressValue = 30;
      break;
    case "04":
      progressValue = 40;
      break;
    case "05":
      progressValue = 50;
      break;
    case "06":
      progressValue = 60;
      break;
    case "07":
      progressValue = 70;
      break;
    case "08":
      progressValue = 80;
      break;
    case "09":
      progressValue = 90;
      break;
  }
  return progressValue;
}

export const formatHoursWorkedProgress = (value: string) => {
  let pgrsValue = 0;

  if (value && value !== null) {
    let workHrs = value.split(":");
    const hrsVal = workHrs[0].slice(0, 2);
    const minsVAl = workHrs[1].slice(0, 2);

    if (hrsVal !== "00") {
      pgrsValue = calProgress(hrsVal)
    }
    if (hrsVal === "00" && minsVAl !== "00") {
      const minPrgVal = calProgress(minsVAl);
      pgrsValue = minPrgVal / 2;
    }
  }

  return pgrsValue;
}

export const summaryDataIntoStringWithDays = (value: any) => {
  if (value && value !== null) {
    return value > 1 ? `${value} Days` : `${value} Day`
  } else {
    return "0 Day"
  }
}

export const formatHoursWorked = (value: string | undefined | null, isSummary: boolean) => {
  if (value && value !== null) {
    let hours = value.split(":");
    return isSummary ? `${hours[0]} ${hours[1]}` : `${hours[0]}:${hours[1]}`;
  } else {
    return isSummary ? "00h 00m" : "00h:00m"
  }
};

export const convertDateIntoDay = (date: string) => {
  if (date && date !== null) {
    return moment(date).format("dddd");
  } else {
    return "-- --";
  }
};

export const convertDateIntoDateMonthYearFormat = (date: string, seperateMonth: boolean) => {
  if (date === undefined || date == null) {
    return "-- --";
  } else {
    return seperateMonth ? moment(date).format("D MMM, YYYY") : moment(date).format("D, MMM YYYY");
  }
};

export const convertDateIntoDayDateMonthYearFormat = (date: string) => {
  if (date === undefined || date == null) {
    return "-- --";
  } else {
    return moment(date).format("dddd, D MMM YYYY");
  }
};

export const convertDateIntoTwelveHourFormat = (date: string) => {
  if (date === undefined || date == null) {
    return "-- --";
  } else {
    return moment(date).format("hh:mm A");
  }
};

export const convertDateIntoFormattedString = (date: string) => {
  if (date === null || date === undefined) {
    return "-- -- ";
  }

  const targetDate = moment(date);
  const currentDate = moment();

  if (targetDate.isSame(currentDate, "day")) {
    const formattedTime = targetDate.format("hh:mm A");
    return `Today at ${formattedTime}`;
  } else {
    return targetDate.format("D MMM, YYYY [at] hh:mm A");
  }
};

export const convertTimeStringIntoDateObject = (
  hr: string,
  min: string,
  format: string
) => {
  let hours24 = Number(hr);
  if (format === "PM" && hours24 !== 12) {
    hours24 += 12;
  } else if (format === "AM" && hours24 === 12) {
    hours24 = 0;
  }
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  return new Date(year, month, day, hours24, Number(min));
};

export const covertHourMinIntoDateTimeString = (hrVal: string, minVal: string, frmtVal: string) => {

  const dateTimeObj = convertTimeStringIntoDateObject(hrVal, minVal, frmtVal);
  const dateTimeStringVal = moment(dateTimeObj).format();

  return dateTimeStringVal;
}

export const getTimeHoursFromDateString = (dateString: string) => {
  if (dateString && dateString !== null) {
    const momentDate = moment(dateString);

    const hours12h = momentDate.format("hh");
    const minutes = momentDate.format("mm");
    const amPm = momentDate.format("A");
    return { hours12h, minutes, amPm };

  }
  else {
    return { hours12h: "00", minutes: "00", amPm: "AM" }
  }

};

export interface BreadCrumType {
  title: string;
  link: string;
  active: boolean;
}

export const breadCrumManageAccountPath = [
  {
    title: "Your Account",
    link: "ShyfterProfilePage",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];


export const breadCrumEmployerManageAccountPath = [
  {
    title: "Your Account",
    link: "EmployerBusinessProfile",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];


export const breadCrumShyfterPathData = [
  {
    title: "Manage Account",
    link: "EditShyfterProfilePage",
    active: false,
  },
  {
    title: "Your Account",
    link: "ShyfterProfilePage",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

export const breadCrumPathData = [
  {
    title: "Manage Account",
    link: "EmployerManageAccount",
    active: false,
  },
  {
    title: "Your Account",
    link: "EmployerBusinessProfile",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

export const breadCrumUpdateStatusPath = [
  {
    title: "Ongoing Shyfts",
    link: "Home",
    active: false,
  },
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

export const breadCrumSubsPath = [
  {
    title: "Home",
    link: "Home",
    active: false,
  },
];

export const handleCondition = (cond: any, val1: any, val2: any) => {
  if (cond) {
    return val1;
  } else {
    return val2;
  }
};

export const extractListItems = (benifits: any) => {
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(benifits, "text/html");
  const listItems = Array.from(htmlDocument.querySelectorAll("ul > li"));
  const listItemTexts = listItems.map((item) => item.textContent);
  return listItemTexts;
};

export const goToScreenTop = () => {
  window.scrollTo(0, 10);
};

export const convertDateToCalenderFormat = (date: string) => {
  return moment(date).calendar(null, {
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "DD/MM/YYYY",
  });
};

export const getRelativeTimeFromDate = (date: string) => moment(date).fromNow();

export const setKeyInLocalStorage = (key: string, value: any) =>
  localStorage.setItem(key, JSON.stringify(value));

export const getKeyFromLocalStorage = (key: string, defaultValue = []) => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  }
  return defaultValue;
};

export const filterValueFromArray = (arr: string[], value: string) =>
  arr.filter((search) => search !== value);

interface TConstructSearchFilter {
  filter?: string[];
}

type Filters = {
  location?: string[];
  shift_type?: string[];
  time_filters?: string[];
  amount?: string[];
};

type FormattedFilters = {
  location?: string;
  shift_type?: string;
  time_filters?: { shift_from: string; shift_to: string; }[]
  amount?: { per_hour: string | undefined; }[];
};

interface TURLParams {
  endpoint: string;
  search?: string;
  page?: number;
  perPage?: number;
  orderBy?: string;
  sortBy?: string;
}

export const constructURL = ({
  endpoint,
  search,
  page = 1,
  perPage = 10,
  orderBy,
  sortBy,
}: TURLParams) => {
  const params = new URLSearchParams();

  params.append("page", `${page}`);
  params.append("per_page", `${perPage}`);
  if (sortBy) params.append("sort_column", sortBy);
  if (orderBy) params.append("sort_order", orderBy);
  if (search) params.append("search", search);

  let url = endpoint;
  const query = `${params.toString()}`;
  if (query) url += `?${query}`;
  return url;
};


export const constructSearchFilter = ({ filter }: TConstructSearchFilter) => {
  const filters: Filters = {};
  const formattedFilter: FormattedFilters = {}

  if (filter?.length)
    for (const item of filter) {
      const [key, value] = item.split(": ").map((str) => str.trim());
      const parts = key.split(" ");
      const typeName = parts.pop() || "";
      const filterValue = parts.join(" ");

      if (typeName.includes("location")) {
        filters.location = filters.location || [];
        filters.location.push(filterValue);
      } else if (typeName.includes("times")) {
        filters.time_filters = filters.time_filters || [];
        filters.time_filters.push(filterValue);
      } else if (typeName.includes("amount")) {
        filters.amount = filters.amount || [];
        filters.amount.push(filterValue);
      } else if (typeName.includes("position")) {
        filters.shift_type = filters.shift_type || [];
        filters.shift_type.push(filterValue);
      }
    }

  if (filters?.shift_type)
    formattedFilter.shift_type = filters.shift_type.join(", ");
  if (filters?.location)
    formattedFilter.location = filters.location.length > 1 ? undefined : filters.location.join(", ");
  if (filters.time_filters)
    formattedFilter.time_filters = filters.time_filters.map(time => {
      let [from, to] = time.split(" - ");
      from = moment(from, 'hha').format('HH:mm');
      to = moment(to, 'hha').format('HH:mm');
      return {
        shift_from: from,
        shift_to: to,
      };
    });
  if (filters.amount)
    formattedFilter.amount = filters.amount.map(rate => {

      return {
        per_hour: rate,
      }
    })

  return formattedFilter;
}

interface TShyftRequest {
  id: number;
  shift_id: number;
  worker_id: number;
  removal_reason: string;
  status: string;
  updated_at: string;
  created_at: string;
}

export const checkIfUserAppliedeForShyft = (request?: TShyftRequest) => {
  if (!request) return false;
  return request.status === "pending" || request.status === "rejected" || request.status === "expired" || request.status === 'accepted' || request.status === 'ended' || request.status === 'rejected';
};

export interface TAPIShyftDetails {
  status: string;
  attributes: {
    activated: boolean;
    business_details: {
      business: {
        created_at: string;
        dba_name: string;
        hotel_name: string;
        address: string;
        hotel_type: string;
        restuarant_name: string;
        restaurant_type: string;
        id: number;
        telephone: string;
        updated_at: string;
        user_id: number;
        website: string;
      };
      employer_profile_picture: string | null;
      city?: string | null;
      state?: string | null;
    };
    amount: number;
    amount_per: string;
    business_type: string;
    business_shifts: [];
    created_at: string;
    description: string;
    employer_id: number;
    expirence: string;
    id: number;
    isAvailable: boolean;
    requirements: string[];
    saved: boolean;
    shift_from: string;
    shift_to: string;
    shift_type: string;
    applied_status: string;
  };
}

export interface TShyftItem {
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  status: string;
  isAvailable: boolean;
  appliedStatus: string;
}

export const formatShiftsListResponse = (shyfts: TAPIShyftDetails[]) => {
  const list: TShyftItem[] = shyfts.map((shift) => {
    return {
      id: shift.attributes.id,
      shyft: shift.attributes.shift_type,
      shyftPlace: shift.attributes.business_type,
      county: getCityAndState(shift.attributes.business_details.city, shift.attributes.business_details.state),
      saved: shift.attributes.saved,
      employername: shift.attributes.business_details.business.hotel_name || shift.attributes.business_details.business.restuarant_name,
      billing: `$${shift.attributes.amount} Per Hr`,
      userImg: shift.attributes.business_details.employer_profile_picture || "",
      day: convertDateToCalenderFormat(shift.attributes.created_at),
      status: shift.status,
      appliedStatus: shift.attributes.applied_status,
      isAvailable: shift.attributes.isAvailable,
      syftTimimg: `${convertDateIntoTwelveHourFormat(
        shift.attributes.shift_from
      )} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
    };
  });

  return list;
};

export interface TCreatedShyft {
  id: number;
  syftTimimg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
  saved: boolean;
}

export const formatOthersShyftCreated = (shyfts: any[]) => {
  const shyftsList: TCreatedShyft[] = shyfts.map((shift) => {
    return {
      id: shift.id,
      shyft: shift.shift_type,
      shyftPlace: getRelativeTimeFromDate(shift.created_at),
      day: convertDateToCalenderFormat(shift.created_at),
      syftTimimg: `${convertDateIntoTwelveHourFormat(
        shift.shift_from
      )} - ${convertDateIntoTwelveHourFormat(shift.shift_to)}`,
      billing: `$${shift.amount} Per Hr`,
      saved: shift.saved
    };
  });
  return shyftsList;
};

export interface TReview {
  id: number;
  score: number;
  comment: string;
  shift_type: string;
  worker_name: string;
  worker_profile_picture: string;
  created_at: string;
}

export interface TRatings {
  ratings: TReview[];
  overall_rating: number;
  ratings_count: number;
  reviews_count: number;
}

export const formatShyftDetails = (
  shift: TAPIShyftDetails,
  ratings: TRatings
) => {
  const shyftDetails = {
    id: shift.attributes.id,
    description: shift.attributes.description,
    experience: shift.attributes.expirence,
    businessName:
      shift.attributes.business_type.toLowerCase() === "hotel"
        ? shift.attributes.business_details.business.hotel_name
        : shift.attributes.business_details.business.restuarant_name,
    businessType:
      shift.attributes.business_type.toLowerCase() === "hotel"
        ? shift.attributes.business_details.business.hotel_type
        : shift.attributes.business_details.business.restaurant_type,
    overallRatings: ratings.overall_rating,
    businessShifts: formatOthersShyftCreated(shift.attributes.business_shifts),
    businessId: shift.attributes.business_details.business.user_id,
    ratings: ratings.ratings.filter(Boolean),
    ratingsCount: ratings.ratings_count,
    reviewsCount: ratings.reviews_count,
    employername: shift.attributes.business_details.business.dba_name,
    userImg: shift.attributes.business_details.employer_profile_picture || "",
    billing: `$${shift.attributes.amount} Per Hr`,
    syftTimimg: `${convertDateIntoTwelveHourFormat(
      shift.attributes.shift_from
    )} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
    day: convertDateToCalenderFormat(shift.attributes.created_at),
    shyft: shift.attributes.shift_type,
    shyftPlace: shift.attributes.business_type,
    county: shift.attributes.business_details.business.address,
    saved: shift.attributes.saved,
    requirements: shift.attributes.requirements,
  };

  return shyftDetails;
};

export const formatRating = (responseJson: any) => {
  const rating = responseJson?.user?.data?.attributes?.ratings;

  const ratingsData: TRatingsData = {
    reviewsCount: rating.reviews_count,
    ratingsCount: rating.ratings_count,
    reviews: rating.ratings_data.map((review: any) => ({
      id: review.id,
      comment: review.comment,
      score: review.score,
      date: formatReviewDate(review.created_at),
      userImg: review.rated_by_profile_picture,
      userName: review.rated_by_name,
      ratedBy: review.rated_by_type,
      shiftType: review.shift_type,
      isShyfterProfile: responseJson.user.data.attributes.type === 'Shyfter'
    })),
    overallRating: rating.overall_rating,
  };
  return ratingsData;
}

export const copyDateObjWithTime = (dateString: string) => {
  const momentObj = moment(dateString).utc();
  const time = momentObj.format("HH:mm:ss.SSS");
  const todayWithGivenTime = moment().format("YYYY-MM-DD") + "T" + time + "Z";
  return moment(todayWithGivenTime);
};

export const convertSecondsToHoursMinutesFormat = (seconds: number) => {
  if (!seconds) return "00:00:00";
  const duration = moment.duration(seconds, "seconds");
  return moment(duration.asMilliseconds()).utc().format("HH:mm:ss");
};

export const convertSecondsToHoursMinutesTimeFormat = (seconds: number) => {
  if (!seconds) return "00:00:00";
  const duration = moment.duration(seconds, "seconds");
  return moment(duration.asMilliseconds()).utc().format('H[h]:m[m]:s[s]');
};

export const convertSecondsToHrsMinsSecWithHMSFormat = (seconds: number) => {
  if (!seconds) return "00h:00m:00s";
  const duration = moment.duration(seconds, "seconds");

  const formathoursWorked = moment(duration.asMilliseconds()).utc().format("HH:mm:ss")
  const hrsWrked = formathoursWorked.split(":");

  return `${hrsWrked[0]}h:${hrsWrked[1]}m:${hrsWrked[2]}s`;
};

export const convertTimeStringIntoDuration = (timestring: string) => {
  const duration = moment.duration(timestring.replace(/[hms]/g, ""));
  return duration.asSeconds();
};

export const formatReviewDate = (date: string) => moment(date).format("DD MMM, YYYY");

export const getUserId = () => {
  const cache = localStorage.getItem("userData");
  const userData = cache ? JSON.parse(cache) : null;

  if (userData) return userData.user.id;
  return null;
}

export const getCityAndState = (city: any, state: any) => {
  return `${city ? city : "--"}, ${state ? state : "--"}`
}

export const capitalizeWords = (str: any) => {
  return str ? str.replace(/\b\w/g, function (match: string) {
    return match.toUpperCase();
  }) : "";
}

export const removeUnderScoreFromString = (str: string) => {
  if (str) {
    return str?.replace(/_/g, " ");
  }
  else {
    return ""
  }
}

export const creditCardNumberFormat = (str: string) => {
  if(str){
    return str?.toString().replace(/(\d{4})/g, "$1 ").trim();
  }
  else {
    return ""
  }
}

export const formatStatesAndCities = (data: any) => {
  const formatted = data.map((e: any) => {
    return {
      ...e,
      value: e.name
    }
  })
  return formatted;
}

export const convertObjToFormData = (obj: any, formData: FormData | null = null, parentKey: string = ''): FormData => {
  if (formData === null) {
    formData = new FormData();
  }

  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      let value = obj[key];
      if (parentKey) {
        key = `${parentKey}[${key}]`;
      }

      if (typeof value === 'object' && !(value instanceof File)) {
        convertObjToFormData(value, formData, key);
      } else {
        formData.append(key, value);
      }
    }
  }
  return formData;
};

export const getFilenameFromURL = (url: string) => {
  if (!url) return null;
  const index = url.lastIndexOf("/");
  const filename = url.slice(index + 1);
  return filename;
};

export const createFileFromURL = (urlString: string) => {
  if (!urlString) return null;
  const filename = getFilenameFromURL(urlString);
  const newFile = new File([""], `${filename}`);
  return newFile;
};

export const truncateFilename = (filename: string, maxLength: number) => {
  if (filename.length <= maxLength) {
    return filename;
  }

  let name = filename.slice(0, filename.lastIndexOf('.'));
  let extension = filename.slice(filename.lastIndexOf('.'));
  let truncatedName = name.slice(0, maxLength - extension.length - 3);
  let truncatedFilename = truncatedName + '..' + extension;

  return truncatedFilename;
}

export const formatRelativeDate = (dateString: string) => {
  const currentDate = moment();
  const inputDate = moment(dateString);

  if (currentDate.isSame(inputDate, 'day')) {
    return 'Today';
  }

  if (currentDate.subtract(1, 'days').isSame(inputDate, 'day')) {
    return 'Yesterday';
  }

  return inputDate.format('D MMMM YYYY');
}

export const logoutUser = async () => {
  await Promise.all(
    [
      removeStorageData('authToken'),
      removeStorageData('userData'),
      removeStorageData('userRole'),
    ]
  );
}

export const sortBasedOnDateString = (data: any[]) => {
  return data.sort(
    (a: any, b: any) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
  );
}

export const sortBasedOnUpdatedTime = (data: any[]) => {
  return data.sort(
    (a: any, b: any) => new Date(a?.attributes?.updated_at).getTime() - new Date(b?.attributes?.updated_at).getTime()
  );
}

export const sortAllChats = (data: any[]) => {
  return data.sort(
    (a: any, b: any) => new Date(b?.attributes?.last_message?.attributes
      ?.updated_at).getTime() - new Date(a?.attributes?.last_message?.attributes
        ?.updated_at).getTime()
  );
}

export const getDocUrl = (url: any) => {
  const newUrl = url?.charAt(0) === "/" ? mainConfigJson.baseURL + url : url;
  return newUrl;
};

export const getYearArray = () => {
  const date = new Date();
  const currentYear = date.getUTCFullYear().toString();
  const dateYear = Array(9).fill(currentYear).map((year, index) => Number(year.slice(-2))+index)
  return dateYear;
}

export const checkAuthorization = (isEmployer: boolean, isShyfter: boolean) => {
  let isAuthorized = false;
  const token = localStorage.getItem("authToken");
  const userRole = localStorage.getItem("userRole");

  if (token) {
    if (isEmployer && isShyfter) {
      isAuthorized = true;
    }
    if (userRole === "1" && isEmployer && !isShyfter) {
      isAuthorized = true;
    }
    if (userRole === "2" && isShyfter && !isEmployer) {
      isAuthorized = true;
    }
  }
  return isAuthorized;
}

export const eventEmitter:any = {
  _events: {},
  dispatch(event: any, data: any) {
    if (!this._events[event]) return;
    this._events[event].forEach((callback: any) => callback(data))
  },
  subscribe(event: any, callback: (data: any) => any) {
    if (!this._events[event]) this._events[event] = [];
    this._events[event].push(callback);
  },
  unsubscribe(event: any) {
    if (!this._events[event]) return;
    delete this._events[event];
  }
}

export const clearUserLoginData = () => {
  eventEmitter.unsubscribe("new_notification")
  localStorage.clear();
  AsyncStorage.clear();
}

export const setAboutLoading = (isAboutLoad: boolean) => {
  localStorage.setItem("isAboutLoad", isAboutLoad ? "true": "");
}

export const getAboutLoading = () => {
  return localStorage.getItem("isAboutLoad");
}