import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import moment from "moment";
import { formatStatesAndCities,capitalizeWords, setUserRole } from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
export interface IHospitalityType {
  name: string;
  value: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  classes?: any;
  step: number;
  vafiyOpen: boolean;
  otp: string;
  resendDisabled: boolean;
  seconds: number;
  error: string;
  showAlert: boolean;
  signUpSuccessful: boolean;
  recivedOpt: string | undefined;
  formData: {
    type: string,
    typeOf: string,
    legalCompanyName: string,
    dbaName: string,
    address: string,
    businessTelephone: string,
    businessWebsite: string,
    contactName: string,
    contactNumber: string,
    contactEmail: string,
    contactEin: string,
    certificateOrganization: Blob | null,
    w9: Blob | null
    state:string;
    city:string;
  },
  formDataErrors: {
    typeOf: string,
    legalCompanyName: string,
    dbaName: string,
    address: string,
    businessTelephone: string,
    businessWebsite: string,
    contactName: string,
    contactNumber: string,
    contactEmail: string,
    contactEin: string,
    certificateOrganization: string,
    w9: string,
    state:string;
    city:string;
  },
  hospitalityCategories: IHospitalityType[],
  verifiedNumber: string;
  isNumberVerified: boolean;
  otpSentCount: number;
  shyfterFormData: {
    fullName: string;
    mobileNumber: string;
    email: string;
    address: string;
    dateOfBirth: null | Date;
    ssn: string;
    dlNumber: string;
    dlState: string;
    experience: string;
    languageSkills: string[];
    hotelSkills: string[];
    restaurantSkills: string[];
    state:string;
    city:string;
  };
  shyfterFormDataErrors: {
    fullName: string;
    mobileNumber: string;
    email: string;
    address: string;
    dateOfBirth: string;
    ssn: string;
    dlNumber: string;
    dlState: string;
    experience: string;
    languageSkills: string;
    hotelSkills: string;
    restaurantSkills: string;
    state:string;
    city:string,
  };
  shyfterStep: number;
  openSkillSetDialog: string;
  isShyfterFirstStepSaved: boolean;
  isShyfterSecondStepSaved: boolean;
  isEmployerFirstStepSaved: boolean;
  isEmployerSecondStepSaved: boolean;
  snackbarMessage: string;
  isCountryCodeFocused: boolean;
  countryCode: string;
  statesList:any[];
  cityList:any[];
  apiCountryabbreviation:string;
  countryCodeTelephone: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  phoneOtpVarifyApiCallId: string = "";
  verifyOTPApiCallId: string = "";
  signUpApiCallId: string = "";
  shyfterSignUpApiCallId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  getCountriesAPICallId:string = "";
  getCitiesByStateAPICallId:string = "";
  getStatesByCountryAPICallId:string = "";

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  classes: any;
  currentCountryCode: any;
  countInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      step: 1,
      vafiyOpen: false,
      otp: "",
      resendDisabled: false,
      seconds: 120,
      error: "",
      showAlert: false,
      recivedOpt: undefined,
      signUpSuccessful: false,
      statesList:[],
      cityList:[],
      apiCountryabbreviation:'IN',
      formData: {
        type: "restaurant",
        typeOf: configJSON.restaurantList[0].value,
        legalCompanyName: "",
        dbaName: "",
        address: "",
        businessTelephone: "",
        businessWebsite: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
        contactEin: "",
        certificateOrganization: null,
        w9: null,
        state:"",
        city:""
      },
      formDataErrors: {
        typeOf: '',
        legalCompanyName: '',
        dbaName: '',
        address: '',
        businessTelephone: '',
        businessWebsite: '',
        contactName: '',
        contactNumber: '',
        contactEmail: '',
        contactEin: '',
        certificateOrganization: '',
        w9: '',
        state:'',
        city:''
      },
      hospitalityCategories: configJSON.restaurantList,
      verifiedNumber: "",
      isNumberVerified: false,
      otpSentCount: 0,
      shyfterFormData: {
        fullName: "",
        mobileNumber: "",
        email: "",
        address: "",
        dateOfBirth: null,
        ssn: "",
        dlNumber: "",
        dlState: "",
        experience: "",
        languageSkills: [],
        hotelSkills: [],
        restaurantSkills: [],
        state:'',
        city:''
      },
      shyfterFormDataErrors: {
        fullName: "",
        mobileNumber: "",
        email: "",
        address: "",
        dateOfBirth: "",
        ssn: "",
        dlNumber: "",
        dlState: "",
        experience: "",
        languageSkills: "",
        hotelSkills: "",
        restaurantSkills: "",
        state:'',
        city:''
      },
      shyfterStep: 1,
      openSkillSetDialog: "",
      isShyfterFirstStepSaved: false,
      isShyfterSecondStepSaved: false,
      isEmployerFirstStepSaved: false,
      isEmployerSecondStepSaved: false,
      snackbarMessage: "",
      isCountryCodeFocused: false,
      countryCode: "91",
      countryCodeTelephone: "91"
      // Customizable Area End
    };

    // Customizable Area Start
    //@ts-ignore
    this.classes = props.classes;
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      this.handleNavigationPayLoad(message);
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      this.handleCountryCode(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if (apiRequestCallId === this.phoneOtpVarifyApiCallId) {
      this.handleOtpVarifyResponse(responseJson);
    } else if (apiRequestCallId === this.signUpApiCallId) {
      this.handleSignUpResponse(responseJson);
    } else if (apiRequestCallId === this.validationApiCallId) {
      this.handleValidationResponse(responseJson);
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountResponse(responseJson);
    } else if (apiRequestCallId === this.shyfterSignUpApiCallId) {
      this.handleShyfterSignUpResponse(responseJson);
    } else if (apiRequestCallId === this.verifyOTPApiCallId) {
      this.handleVerifyOTPResponse(responseJson);
    } else {
      this.handleAddressApiResponse(responseJson,apiRequestCallId)
    }
    console.log(responseJson, errorResponse);
  }

  async componentDidMount(){
    this.fetchStateByCountry(this.state.apiCountryabbreviation);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if(prevState.apiCountryabbreviation!==this.state.apiCountryabbreviation){
      this.fetchStateByCountry(this.state.apiCountryabbreviation);
    }

    if(prevState.shyfterFormData.state !== this.state.shyfterFormData.state){
      this.setState({shyfterFormData:{...this.state.shyfterFormData,city:""}})
      this.handleGetCityApi('shyfter')
    }

    if(prevState.formData.state !== this.state.formData.state){
      this.setState({formData:{...this.state.formData,city:""}})
      this.handleGetCityApi('employer')
    } 
  }

  handleAddressApiResponse(responseJson:any,apiRequestCallId:any){
    if(apiRequestCallId===this.getStatesByCountryAPICallId){
      this.setState({statesList:formatStatesAndCities(responseJson)})
    }
    if(apiRequestCallId === this.getCitiesByStateAPICallId){
      const isEmpty = Array.isArray(responseJson);
      if(!isEmpty){
      this.setState({
        cityList:this.state.statesList,
        shyfterFormData:{...this.state.shyfterFormData,city:this.state.shyfterFormData.state},
        formData:{...this.state.formData,city:this.state.formData.state}
       })
      } else {
        this.setState({cityList:formatStatesAndCities(responseJson)})
      }
    }
  }

  handleOtpVarifyResponse(responseJson: any) {
    if (responseJson.message && responseJson.message === "OTP sent successfully") {
      this.setOTPTimer();
    } else {
      this.setState({ error: responseJson.message || responseJson.error, showAlert: true, resendDisabled: false });
    }
  }

  handleGetCityApi = (type:string)=>{
    let comparVar = type==='shyfter' ? this.state.shyfterFormData.state : this.state.formData.state;
    const filteredObj = this.state.statesList.filter((state:any)=>state.name === comparVar);
    const stateCode = filteredObj.length>0 ? filteredObj[0].iso2 : null;
    this.fetchCitiesByCountryAndState(stateCode)
  }

  handleShyfterSignUpResponse = (responseJson: any) => {
    if (responseJson.token) {
      const tokenSaveMsg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      tokenSaveMsg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      tokenSaveMsg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.token
      );

      this.send(tokenSaveMsg);
      setUserRole(responseJson.user.role_id);
      setStorageData('userData',JSON.stringify(responseJson));
      this.redirectToShyfterDashboard();
    } else {
      let errorMessage = "Something went wrong!";
      if (responseJson?.errors && responseJson?.errors?.length > 0) {
        errorMessage = responseJson?.errors[0];
      }
      this.setState({ error: responseJson?.message || (responseJson.errors?.length && responseJson.errors.join(' ')), snackbarMessage: errorMessage });
    }
  }

  redirectToShyfterDashboard = () => {
    const shyfterRegistrationMsg = new Message(getName(MessageEnum.NavigationMessage));
    shyfterRegistrationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ShyfterLandingPage");
    shyfterRegistrationMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.ShyfterRegistrationMessage)
    );
    raiseMessage.addData(getName(MessageEnum.ShyfterRegistrationMessage), "Login Successfully");
    shyfterRegistrationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(shyfterRegistrationMsg);
  }

  handleVerifyOTPResponse = (responseJson: any) => {
    let isVerified = false;
    if (responseJson && responseJson.message === "Phone number verified successfully") {
      isVerified = true;
    }
    let userNumber = this.state.formData.contactNumber || this.state.shyfterFormData.mobileNumber;
    this.setState({
      isNumberVerified: isVerified, 
      resendDisabled: false,
      verifiedNumber: isVerified ? userNumber : "",
      vafiyOpen: false,
      otp: "",
      seconds: 120,
      snackbarMessage: isVerified ? "" : "Verification failed. Please enter the correct pin!"
    });
    clearInterval(this.countInterval);
  }

  handleSignUpResponse(responseJson: any) {
    if (responseJson.token) {
      this.setState({ signUpSuccessful: true });
      console.log("SUCCESS SIGN UP");
    } else {
      let errorMsg = "Something went wrong!";
      if (responseJson?.errors && responseJson?.errors?.length > 0) {
        errorMsg = responseJson?.errors[0];
      }
      this.setState({ error: responseJson?.message || (responseJson.errors?.length && responseJson.errors.join(' ')), snackbarMessage: errorMsg });
    }
  }

  handleValidationResponse(responseJson: any) {
    this.arrayholder = responseJson.data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      const regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleCreateAccountResponse(responseJson: any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(getName(MessageEnum.AccoutResgistrationSuccess));
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(responseJson);
  }

  handleNavigationPayLoad(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }

  handleCountryCode(message: Message) {
    const selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      console.log("selectedCode", selectedCode)
      this.setState({
        countryCodeSelected: selectedCode.indexOf("+") > 0 ? selectedCode.split("+")[1] : selectedCode,
      });
    }
  }

  fetchStateByCountry = (countryCode:string) => {
    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStatesByCountryAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${countryCode}/states`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchCitiesByCountryAndState = (stateCode:string|null) => {
    if(!stateCode) return;

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCitiesByStateAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${this.state.apiCountryabbreviation}/states/${stateCode}/cities`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCode,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCode + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  sendPhoneSignUpNumberOtpVarify(phone: string) {
    const headers = {
      "Content-Type": configJSON.phoneNumberOptVarifyContentType,
    };

    const getPhoneOtpMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneOtpVarifyApiCallId = getPhoneOtpMsg.messageId;

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberOptVarifyUrl + `%2B${this.state.countryCode}${phone}`
    );

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.phoneNumberOptVarifyMethodType
    );
    runEngine.sendMessage(getPhoneOtpMsg.id, getPhoneOtpMsg);
  }

  verifyOTPRequest = (phone: string, pin: string) => {
    const headers = {
      "Content-Type": configJSON.phoneNumberOptVarifyContentType,
    };

    const validateOTPRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPApiCallId = validateOTPRequest.messageId;

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyOTP
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpMethodType
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({phone_number: `+${this.state.countryCode}${phone}`, pin: pin})
    );
    runEngine.sendMessage(validateOTPRequest.id, validateOTPRequest);
  }


  sendSignUpUserRequest() {

    const createUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.signUpApiCallId = createUserMsg.messageId;


    createUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );


    createUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpUrl
    );
  
    createUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpMethodType
    );
    const formDataObject = {
      'role_id': '1',
      '[user]full_name': capitalizeWords(this.state.formData.contactName),
      '[user]phone_number': `+91${this.state.formData.contactNumber}`,
      '[user]email': this.state.formData.contactEmail,
      '[user]ein': this.state.formData.contactEin,
      '[user]certificate': this.state.formData.certificateOrganization,
      '[user]form': this.state.formData.w9,
      '[user]state': this.state.formData.state,
      '[user]city' : this.state.formData.city,
      [`[${this.state.formData.type}]${this.state.formData.type}_type`]: this.state.formData.typeOf,
      [`[${this.state.formData.type}]${this.state.formData.type === "hotel" ? this.state.formData.type : "restuarant"}_name`]: capitalizeWords(this.state.formData.legalCompanyName),
      [`[${this.state.formData.type}]dba_name`]: capitalizeWords(this.state.formData.dbaName),
      [`[${this.state.formData.type}]address`]: this.state.formData.address,
      [`[${this.state.formData.type}]telephone`]: this.state.countryCodeTelephone + this.state.formData.businessTelephone,
      [`[${this.state.formData.type}]website`]: this.state.formData.businessWebsite,
      'type': this.state.formData.type,
      'device_id': localStorage.getItem("fcmToken")
    };
    const formData = new FormData();

    for (const key in formDataObject) {
      const value = formDataObject[key];
      if (formDataObject.hasOwnProperty(key)) {
        if(value){
          formData.append(key, value);
        }
      }
  }

    createUserMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(createUserMsg.id, createUserMsg);
  }

  sendShyfterSignUpRequest = () => {
    const createShyfterUser = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.shyfterSignUpApiCallId = createShyfterUser.messageId;

    createShyfterUser.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpUrl
    );

    createShyfterUser.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
  
    createShyfterUser.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.signUpMethodType
    );

    const device_id_shyfter: string = localStorage.getItem("fcmToken") || ""

    const formData = new FormData();
    formData.append('role_id', '2');
    formData.append('shyfter[full_name]', capitalizeWords(this.state.shyfterFormData.fullName));
    formData.append('shyfter[email]', this.state.shyfterFormData.email);
    formData.append('shyfter[phone_number]', `+${this.state.countryCode}${this.state.shyfterFormData.mobileNumber}`);
    formData.append('shyfter[address]', this.state.shyfterFormData.address);
    formData.append('shyfter[state]', this.state.shyfterFormData.state);
    formData.append('shyfter[city]', this.state.shyfterFormData.city);
    formData.append('shyfter[ssn]', this.state.shyfterFormData.ssn);
    formData.append('shyfter[dl_state]', this.state.shyfterFormData.dlState);
    formData.append('shyfter[dl_number]', this.state.shyfterFormData.dlNumber);
    formData.append('shyfter[experience]', this.state.shyfterFormData.experience);
    formData.append('shyfter[language_skill][]', this.state.shyfterFormData.languageSkills.join(","));
    formData.append('shyfter[restaurant_skills][]', this.state.shyfterFormData.restaurantSkills.join(","));
    formData.append('shyfter[hotel_skills][]', this.state.shyfterFormData.hotelSkills.join(","));
    formData.append('shyfter[date_of_birth]', moment(this.state.shyfterFormData.dateOfBirth).format('DD/MM/YYYY'));
    formData.append('device_id', device_id_shyfter);

    createShyfterUser.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(createShyfterUser.id, createShyfterUser);
  }

  handleCheckBoxSelection = (e: any, type: string) => {
    if (type === "hotel") {
      this.setState({...this.state, 
        formData: {...this.state.formData, type: type, typeOf: configJSON.hotelList[0].value},
        hospitalityCategories: configJSON.hotelList,
      });
    } else {
      this.setState({...this.state, 
        formData: {...this.state.formData, type: type, typeOf: configJSON.restaurantList[0].value},
        hospitalityCategories: configJSON.restaurantList,
      });
    }
  }

  getInitialValidationMsg = () => {
    let errorObj = {
      typeOf: '',
      legalCompanyName: '',
      dbaName: '',
      address: '',
      businessTelephone: '',
      businessWebsite: '',
      contactName: '',
      contactNumber: '',
      contactEmail: '',
      contactEin: '',
      certificateOrganization: '',
      w9: '',
      state:'',
      city:''
    }
    return errorObj;
  }

  getInitialValidationMsgForShyfter = () => {
    let errorObj = {
      fullName: "",
      mobileNumber: "",
      email: "",
      address: "",
      dateOfBirth: "",
      ssn: "",
      dlNumber: "",
      dlState: "",
      experience: "",
      languageSkills: "",
      hotelSkills: "",
      restaurantSkills: "",
      state:'',
      city:''
    }
    return errorObj;
  }

  getIsNumberVerified = () => {
    const enteredNumber = this.state.formData.contactNumber || this.state.shyfterFormData.mobileNumber;
    return this.state.isNumberVerified && (this.state.verifiedNumber === enteredNumber);
  }

  handleSnackbarClose = () => {
    this.setState({...this.state, snackbarMessage: ""});
  }
  
  handlePhoneVerification = () => {
    const enteredNumber = this.state.shyfterFormData.mobileNumber || this.state.formData.contactNumber;
    let isEnable =  enteredNumber && (enteredNumber.toString().length === 10);
    if (isEnable) {
      if (this.state.otpSentCount < 5) {
        if (!this.state.resendDisabled) {
          this.setState({resendDisabled: true});
          this.sendPhoneSignUpNumberOtpVarify(enteredNumber);
        }
      } else {
        this.setState({...this.state, 
          snackbarMessage: "Maximum OTP generation attempts reached. For a fresh start, consider refreshing the page."});
      }
    }
  }

  setOTP = (otp: string) => {
    this.setState({...this.state, otp: otp});
  }

  onCloseAlert = () => {
    this.setState({...this.state, showAlert: false});
  }

  handleOTPDialogClose = () => {
    this.setState({
      vafiyOpen: false,
      otp: "",
      resendDisabled: false,
      seconds: 120,
    });
    clearInterval(this.countInterval);
  };

  setOTPTimer = () => {
    this.setState({ seconds: 120, 
      otpSentCount: this.state.otpSentCount + 1,
      otp: "",
      vafiyOpen: true }, () => {
          this.countInterval = setInterval(() => {
            const { seconds } = this.state;
            if (seconds > 0) {
              this.setState((prevState) => ({
                seconds: prevState.seconds - 1,
              }));
            } else {
              clearInterval(this.countInterval);
              this.setState({ resendDisabled: false });
            }
          }, 1000);
    });
    
  }

  ifFullNameValid = (firstStepValues:any)=>{
    return !firstStepValues.fullName || 
    !configJSON.fullNameRegex.test(firstStepValues.fullName) || 
    !configJSON.minMaxCharLimitRegex.test(firstStepValues.fullName)
  }

  firstStepValidationForShyfter = (isNextClicked: boolean) => {
    let errorObj = this.getInitialValidationMsgForShyfter();

    const firstStepValues = {...this.state.shyfterFormData};

    if (this.ifFullNameValid(firstStepValues)) {
        errorObj.fullName = "Full Name is required";
    }
    if (!firstStepValues.mobileNumber || !configJSON.phoneNumberRegex.test(firstStepValues.mobileNumber)) {
      errorObj.mobileNumber = "Mobile Number is required";
    }
    if(!firstStepValues.state){
      errorObj.state = "State is required";
    }
    if(!firstStepValues.city){
      errorObj.city = "City is required";
    }
    if (!firstStepValues.email || !configJSON.emailRegex.test(firstStepValues.email)) {
      errorObj.email = "Email is required";
    }
    if (!firstStepValues.address || !configJSON.minMaxCharLimitForAddressRegex.test(firstStepValues.address)) {
      errorObj.address = "Address Field is required";
    }
    if (!firstStepValues.dateOfBirth) {
      errorObj.dateOfBirth = "Date Of Birth is required";
    }
    if (!firstStepValues.ssn || !configJSON.minMaxCharLimitRegex.test(firstStepValues.ssn)) {
      errorObj.ssn = "SSN is required";
    }
    if (!firstStepValues.dlState) {
      errorObj.dlState = "DL State is required";
    }
    if (!firstStepValues.dlNumber || !configJSON.minMaxCharLimitRegex.test(firstStepValues.dlNumber)) {
      errorObj.dlNumber = "DL Number is required";
    }
    this.setShyfterFirstStepValidation(errorObj, isNextClicked);
  }

  secondStepValidationForShyfter = (isNextClicked: boolean) => {
    let errorObjForStepTwo = this.getInitialValidationMsgForShyfter();

    const secondStepValues = {...this.state.shyfterFormData};

    if (!secondStepValues.experience) {
      errorObjForStepTwo.experience = "Experience is required";
    }
    if (!secondStepValues.languageSkills?.length) {
      errorObjForStepTwo.languageSkills = "Language Skill is required";
    }
    if (!secondStepValues.hotelSkills.length) {
      errorObjForStepTwo.hotelSkills = "Please select atleast 1 hotel skill";
    }
    if (!secondStepValues.restaurantSkills.length) {
      errorObjForStepTwo.restaurantSkills = "Please select atleast 1 restaurant skill";
    }

    const isValid = Object.values(errorObjForStepTwo).every((value: any)=> {
      if (value === "") {
        return true;
      }
      return false;
    });
    this.setState({...this.state, shyfterFormDataErrors: errorObjForStepTwo});
    if (isValid && isNextClicked) {
      this.sendShyfterSignUpRequest();
    }
  }

  setShyfterFirstStepValidation = (errorObj: any, isNext: boolean) => {
    const isValidData = Object.values(errorObj).every((value: any)=> {
      if (value === "") {
        return true;
      }
      return false;
    });
    if (!isValidData) {
      this.setState({...this.state, shyfterFormDataErrors: errorObj});
    } else {
      this.setShyfterFirstStepValidationStates(isNext);
    }
  }

  setShyfterFirstStepValidationStates = (isNext: boolean) => {
    const initialErrorOb = this.getInitialValidationMsgForShyfter();
    if (!this.getIsNumberVerified()) {
      this.setState({...this.state, shyfterFormDataErrors: initialErrorOb, snackbarMessage: isNext ? "Please verify your number!" : ""});
    } else {
      this.setState({...this.state, shyfterFormDataErrors: initialErrorOb, shyfterStep: isNext ? 2 : 1}, () => {
        if (this.state.isShyfterSecondStepSaved) {
          this.secondStepValidationForShyfter(false);
        }
      });
    }
  }

  firstStepValidation = (isNext: boolean) => {
    let errorObj = this.getInitialValidationMsg();
    const firstStepValues = {...this.state.formData};
    if (!firstStepValues.typeOf) {
      errorObj.typeOf = "Type is required";
    }
    if (!firstStepValues.legalCompanyName || !configJSON.minMaxCharLimitRegex.test(firstStepValues.legalCompanyName)) {
      errorObj.legalCompanyName = "Company name is required";
    }
    if (!firstStepValues.dbaName || !configJSON.minMaxCharLimitRegex.test(firstStepValues.dbaName)) {
      errorObj.dbaName = "DBA name is required";
    }
    if (!firstStepValues.address || !configJSON.minMaxCharLimitForAddressRegex.test(firstStepValues.address)) {
      errorObj.address = "Address is required";
    }
    if(firstStepValues.businessWebsite && !configJSON.website.test(firstStepValues.businessWebsite)){
      errorObj.businessWebsite = "Entered url is invalid"
    }
    if (!firstStepValues.businessTelephone || !configJSON.phoneNumberRegex.test(firstStepValues.businessTelephone)) {
      errorObj.businessTelephone = "Business telephone is required";
    }

    const isValidData = Object.values(errorObj).every((value: any)=> {
      if (value === "") {
        return true;
      }
      return false;
    });
    if (!isValidData) {
      this.setState({...this.state, formDataErrors: errorObj});
    } else {
      this.setFirstStepValidationStates(isNext);
    }
  }

  setFirstStepValidationStates = (isNext: boolean) => {
    const initialErrorOb = this.getInitialValidationMsg();
    this.setState({...this.state, formDataErrors: initialErrorOb, step: isNext ? 2 : 1}, () => {
      if (this.state.isEmployerSecondStepSaved) {
        this.secondStepValidation(false);
      }
    });
  }

  secondStepValidation = (isSaveClick: boolean) => {
    let errorObj = this.getInitialValidationMsg();
    const secondStepValues = {...this.state.formData};
    if (!secondStepValues.contactName || !configJSON.minMaxCharLimitRegex.test(secondStepValues.contactName)) {
      errorObj.contactName = "Contact Name is required";
    }
    if (!secondStepValues.contactNumber || !configJSON.phoneNumberRegex.test(secondStepValues.contactNumber)) {
      errorObj.contactNumber = "Contact Number is required";
    }
    if(!secondStepValues.state){
      errorObj.state = "State is required"
    }
    if(!secondStepValues.city){
      errorObj.city = "City is required"
    }
    if (!secondStepValues.contactEmail || !configJSON.emailRegex.test(secondStepValues.contactEmail)) {
      errorObj.contactEmail = "Company Email is required";
    }
    if (!secondStepValues.contactEin || !configJSON.einNumberRegex.test(secondStepValues.contactEin)) {
      errorObj.contactEin = "EIN is required";
    }
    if (!secondStepValues.certificateOrganization) {
      errorObj.certificateOrganization = "Certificate is required";
    }
    if (!secondStepValues.w9) {
      errorObj.w9 = "W9 is required";
    }
    const isValid = Object.values(errorObj).every((value: any)=> {
      if (value === "") {
        return true;
      }
      return false;
    });
    this.setSecondStepValidationStates(isValid, isSaveClick, errorObj);
  }

  setSecondStepValidationStates = (isValid: boolean, isSavedClick: boolean, errorObj: any) => {
    this.setState({...this.state, formDataErrors: errorObj, snackbarMessage: isValid && isSavedClick && !this.getIsNumberVerified() ? "Please verify your number!" : ""});
    if (isValid && this.getIsNumberVerified() && isSavedClick) {
      this.sendSignUpUserRequest();
    }
  }

  handleInputChange = (event: any) => {
    const { name, value, type, maxlength } = event.target;
    if (type === "file") {
      const file = event.target.files[0];
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: file,
        },
      }),() => {
        this.employerInputCallback();
      });
    } else {
      let updatedValue = value;
      switch (name) {
        case "contactName":
          updatedValue = updatedValue.replace(/[^a-zA-Z\s]/g, "");
          break;
        case "contactEmail":
          updatedValue = updatedValue.replace(/[^a-zA-Z0-9@.]/g, '');
          break;
        case "contactNumber":
        case "businessTelephone":
          const numberValue = updatedValue.toString();
          const sanitizedValue = numberValue.replace(/[+e-]/g, '').replace(/\D/g, '');
          updatedValue = sanitizedValue.slice(0, 10);
          break;
        case "contactEin":
          const einValue = updatedValue.toString();
          const sanitizedEIN = einValue.replace(/[+e-]/g, '').replace(/\D/g, '');
          updatedValue = sanitizedEIN.slice(0, 11);
          break;
        }
        if (!maxlength || String(updatedValue).length <= maxlength){
          this.setState((prevState) => ({
            formData: {
              ...prevState.formData,
              [name]: updatedValue
            },
          }), () => {
            this.employerInputCallback();
          });
        }
    }
  };

  handleShyfterInputChange = (event: any, name: any) => {
    const { value } = event.target;
    let newValue = value;
    switch (name) {
      case "fullName": 
        newValue = newValue.replace(/[^a-zA-Z\s]/g, '');
        break;
      case "mobileNumber": 
        const numberValue = newValue.toString();
        const sanitizedValue = numberValue.replace(/[+e-]/g, '').replace(/\D/g, '');
        newValue = sanitizedValue.slice(0, 10);
        break;
      case "email":
        newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, '');
        break;
    }

    this.setState((prevState) => ({
      shyfterFormData: {
        ...prevState.shyfterFormData,
        [name]: newValue,
      },
    }), () => {
      if (this.state.isShyfterFirstStepSaved && this.state.shyfterStep === 1) {
        this.firstStepValidationForShyfter(false);
      }
      if (this.state.isShyfterSecondStepSaved && this.state.shyfterStep === 2) {
        this.secondStepValidationForShyfter(false);
      }
    });
  };

  employerInputCallback = () => {
    if (this.state.isEmployerFirstStepSaved && this.state.step === 1) {
      this.firstStepValidation(false);
    }
    if (this.state.isEmployerSecondStepSaved && this.state.step === 2) {
      this.secondStepValidation(false);
    }
  }

  handleLanguageChange = (data: any) => {
    this.setState({
      ...this.state,
      shyfterFormData: {
        ...this.state.shyfterFormData,
        languageSkills: data,
      },
    }, () => {
      if (this.state.isShyfterSecondStepSaved) {
        this.setState({...this.state, shyfterFormDataErrors: 
          {...this.state.shyfterFormDataErrors, 
            languageSkills: !data?.length ? 
            "Language Skill is required": ""
          }})
      }
    });
  };

  backToLogin = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), "");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  shyfterSignUp = () => {
    this.setState({...this.state, isShyfterSecondStepSaved: true}, () => {
      this.secondStepValidationForShyfter(true);
    })
  }

  employerSignUp = () => {
    this.setState({...this.state, isEmployerSecondStepSaved: true}, () => {
      this.secondStepValidation(true);
    })
  }

  moveShyfterNextStep = () => {
    this.setState({...this.state, isShyfterFirstStepSaved: true }, () => {
      this.firstStepValidationForShyfter(true);
    });
  }

  moveEmployerNextStep = () => {
    this.setState({...this.state, isEmployerFirstStepSaved: true }, () => {
      this.firstStepValidation(true);
    });
  }

  moveShyfterPrevStep = () => {
    this.setState({...this.state, shyfterStep: 1});
  }

  movePrev = () => {
    this.setState({...this.state, step: 1});
  }

  getSubHeaderText = () => {
    const currentStep = this.state.shyfterStep;
    return currentStep === 1 ? configJSON.enterPersonalDetails : configJSON.enterProfessionalDetails;
  }

  setSelectedSkills = () => {
    return this.state.openSkillSetDialog === "hotel" ?
     this.state.shyfterFormData.hotelSkills : 
     this.state.shyfterFormData.restaurantSkills;
  }

  toggleSkillSetDialog = (type: string) => {
    this.setState({...this.state, openSkillSetDialog: type});
  }

  saveSkillData = (type: string, list: string[]) => {
    this.setState({...this.state, openSkillSetDialog: "", shyfterFormData: {
      ...this.state.shyfterFormData,
      [`${type}Skills`]: list,
    }}, () => {
      if (this.state.isShyfterSecondStepSaved) {
        this.setState({...this.state, 
        shyfterFormDataErrors: {...this.state.shyfterFormDataErrors,
        [`${type}Skills`]: !list.length ? `Please select atleast 1 ${type} skill`: ""}})
      }
    });
  }

  deleteSkillItem = (type: string, skillItem: string) => {
    if (type === "hotel") {
      this.setState({...this.state, openSkillSetDialog: "", shyfterFormData: {
        ...this.state.shyfterFormData,
        hotelSkills: this.state.shyfterFormData.hotelSkills.filter((i) => i !== skillItem)
      }});
    } else {
      this.setState({...this.state, openSkillSetDialog: "", shyfterFormData: {
        ...this.state.shyfterFormData,
        restaurantSkills: this.state.shyfterFormData.restaurantSkills.filter((i) => i !== skillItem)
      }});
    }
  }

  handleCountryCodeFocus = () => {
    this.setState({
      ...this.state,
      isCountryCodeFocused: true,
    })
  }

  handleCountryCodeSelection = (value: string,data:any) => {
    const code = data.countryCode.toUpperCase();
    this.setState({
      apiCountryabbreviation:code,
      countryCode: value,
      statesList:[],
      cityList:[],
      shyfterFormData:{...this.state.shyfterFormData,city:'',state:''},
      formData:{...this.state.formData,state:'',city:''}
    })
  }

  onResetFile = (type: string) => {
    this.setState({ ...this.state, formData: {...this.state.formData, [type]: null }});
  }

  handleCountryCodeSelectionForTelephone = (event: string) => {
    this.setState({ countryCodeTelephone: event})
  }

  // Customizable Area End
}
