Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "helpcentre";
exports.labelBodyText = "helpcentre Body";

exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.btnExampleTitle = "CLICK ME";


exports.resolveYourissueText = "Complete the fields below. We will resolve your issue"
exports.within24hrsText = "in within 24 hours."

exports.fullNameTitle = "Full Name";
exports.reasonTitle = "Select Reason";
exports.messageTitle = "Enter a message...";

exports.reasonList = [
  { name: "I need help", value: "I need help" },
  { name: "I have a question", value: "I have a question" },
  { name: 'I have a problem',value: 'I have a problem' },
  { name: 'i have a suggestion',value: 'i have a suggestion' },
  { name: 'other',value: 'other' }
];

exports.shyftersPara = [
  "Hey, superstar! Ready to take your hospitality career to the next level? Welcome to our on-demand staffing platform, where your talent can shine brighter than a diamond.",
  "Here's why you'll love being part of our crew:"
];
exports.shyftersBenefits = [
  "Flexibility that puts Rihanna's dance moves to shame: Tired of rigid schedules? With us, you're the boss! Choose when and where you want to work, whether it's a trendy hotel downtown or a highly-sought out restaurant. Work hard, play hard, and still have time for those impromptu karaoke nights.",
  "Say goodbye to those job-hunting headaches: No more stalking job boards or submitting resumes into the abyss. Our platform brings the jobs to you! Get notified about exciting opportunities that match your skills and experience. It's like having a personal agent who knows your dance routine.",
  "Build your A-team network: We've got a community of rockstar professionals like you. Connect with fellow hospitality maestros, share stories, and even team up for gigs. It's the perfect chance to expand your social circle and find your partner-in-crime for those epic weekend adventures.",
  "Get paid like the superstar you are: We believe in fair compensation for your exceptional skills. Earn top dollar for your expertise and hard work. Plus, with our streamlined payment system, you'll never have to chase down those elusive paychecks again. Cha-ching!",
  "Access to exclusive perks: Ready for the red carpet treatment? Enjoy special perks like commuter benefits,, access to industry events, and professional development opportunities. We're all about making your career journey as glamorous as a Hollywood premiere.",
  "Unleash your creative genius: You're not just a cog in the machine; you're an artist! Show off your talents, try new roles, and explore different areas of the hospitality industry. From mixologist to master chef, our platform lets you spread your wings and dazzle the crowd."
];
exports.shyftersSubPara = [
  "So, ready to join the league of extraordinary hospitality legends? Step into the spotlight and let's rock the stage together. Your future is calling, and it's time to take center stage!"
];
exports.businessPara = [
  "Lights, camera, action! It's time to roll out the red carpet and bring the glitz and glamour of Hollywood to your staffing needs. Join Snagashyft and let us be your star-studded solution for your business.",
  "We know that finding great staff can be like searching for a needle in a haystack, but fear not - we've got your back. Our platform makes it easy to find top-notch servers, bartenders, cooks, and all the other hospitality hotshots you need to keep your business running smoothly.",
  "Here's your VIP pass to success:"
];
exports.businessBenefits = [
  "Cast A-List Talent: Discover a lineup of top-notch professionals who will shine bright like diamonds in your business.",
  "Oscar-Worthy Efficiency: Say goodbye to time-consuming hiring processes. Our platform makes finding and hiring staff as effortless as a blockbuster hit.",
  "Flexibility Fit for a Star: Customize your workforce to match your needs. Whether it's a small cameo or a leading role, we've got the talent to make your production flawless.",
  "Paparazzi-Proof Support: Our dedicated support team will be your backstage crew, ensuring your experience is as smooth as a Hollywood smile."
];
exports.businessSubPara = [
  "Don't just watch the action unfold - be part of it! Join our on-demand staffing platform today and let's create a hospitality experience that deserves a standing ovation.",
  "Roll out the Red Carpet and Join the A-List Now!"
];
exports.TopFeatureTxt = 'Top features of the app which help you to get your job easy and more efficient with app managing features';
exports.minMaxFullNameLimitCheck = /^.{4,40}$/;
exports.minMaxMessageLimitCheck = /^.{4,200}$/;

exports.helpusApiEndPoint = "bx_block_settings/help_us";
exports.reasonsListApiEndPoint = "bx_block_settings/reasons";
// Customizable Area End
