import React from "react";
import {
    createTheme,
    ThemeProvider,
    makeStyles,
    styled,
    createStyles,
    StyleRules
} from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Dialog,
    DialogTitle,
    Drawer,
    Modal,
    Button
} from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import ShyfterRequestCard from "./ShyfterRequestCard";
import { downArrow, ProfileImg, briefcase } from "./assets";
import { Close } from "@material-ui/icons";
import moment from "moment";
const SettingSliderIcon = require("./assets/images/settings-sliders.png");

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: "70%" }}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const theme = createTheme({
    palette: {
        primary: {
            main: "rgb(57, 8, 121)",
            contrastText: "rgb(111, 124, 142)",
        },
    },
    typography: {
        h6: {
            fontSize: '24px',
            fontFamily: 'Gotham',
            fontWeight: 700,
        },
    },

});

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        padding: '15px',
        height:"300px",
        '& > div': {
            margin: '7px 0px'
        }
    },
    listroot: {
        width: "100%",
        maxWith: "360px"
    },
    dialog: {
        position: "absolute",
        right: 0,
        top: 0,
        margin: "0px",
        width: "25%",
        height: "100%",
        minHeight: "100%",
        borderRadius: "20px 0px 0px 20px !important"
    },
    flexContainer: {
        gap: "0px",
    },
    customDialogTitle: {
        fontSize: '24px',
        fontWeight: 700,
        textAlign: "center"
    },
    indicator: {
        left: 0,
    },
    Button: {
        margin: "10px",
        padding: "10px 50px",
        borderRadius: "50px"
    },
    topHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#e1e6ec',
        '& p': {
            fontSize: '14px',
            color: '#6F7C8E',
            letterSpacing: 0,
            lineHeight: '16px',
            margin: '0px',
            fontWeight: 600,
        },
        '& h2': {
            fontSize: '20px',
            color: '#0A0412',
            letterSpacing: '0',
            lineHeight: '26px',
            margin: '0px',
        },
    },
    headerChipWrap: {
        "& .MuiChip-root": {
          backgroundColor: "#B9DF12",
          borderRadius: "12px",
          fontSize: "12px",
          color: "#0A0412",
          letterSpacing: "0.22px",
          textAlign: "right",
          height: "23px",
          fontWeight: 800,
          "& .MuiChip-avatar": {
            height: "12px",
            width: "11px",
            marginRight: "-8px",
            marginLeft: "8px",
          },
        },
        '& svg': {
            color: '#0A0412 !important',
            height: '18px !important',
        },
        '& span': {
            fontSize: '12px',
            color: '#0A0412',
            letterSpacing: '0.22px',
            textAlign: 'right',
        }
    },

    allRequestsText: {
        fontSize: "14px",
        fontWeight: 500
    },

    closeButton: {
        position: "fixed",
        right: "400px",
        backgroundColor: "#26202e",
        borderColor: "#fff",
        borderWidth: "thin",
        color: "#fff",
        borderRadius: "50% ",
        zIndex: 9999,
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "@media(max-width:450px)": {
            right: "355px",
        },
        "@media(max-width:375px)": {
            right: "287px",
        }
    },
    modalCloseButton: {
        position: "absolute",
        color: "#fff",
        top: "-45px",
        right: "0",
        backgroundColor: "#26202e",
        borderColor: "#fff",
        borderWidth: "thin",
        borderRadius: "50% ",
        zIndex: 9999,
        height: "35px",
        width: "35px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },

    modalContainer: {
        position: "relative",
        backgroundColor: "#ffffff",
        width: "530px",
        borderRadius: 20,
        padding: "20px",
        "@media (max-width: 680px)": {
            width: "50%",
        },
    },

    userTittleText: {
        textAlign: "center",
        fontWeight: "bold",
        marginTop: "10px",
        fontSize: "22px",
        lineHeight: "36px",
        color: "#0a0412",
    },

    imgIconHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

    userImageIcon: {
        width: "62px",
        height: "62px",
        marginTop: "16px"
    },

    userNameText: {
        textAlign: "center",
        fontSize: "16px",
        lineHeight: "25px",
        fontWeight: 500,
        marginTop: "5px",
        color: "#0a0412",
    },

    briefcaseIcon: {
        width: "15px",
        height: "15px",
    },

    doorManText: {
        fontWeight: 500,
        marginLeft: "5px",
        color: "#4f3472",
        fontSize: "14px",
        lineHeight: "15px",
    },

    ShyftContain: {
        textAlign: "center",
        color: "#6f7c8e",
        marginTop: "15px",
        fontWeight: 500,
        fontSize: "12px",
        letterSpacing: "0px",
        lineHeight: "18px",
    },

    viewButton: {
        backgroundColor: "#390879",
        letterSpacing: "0px",
        lineHeight: "18px",
        color: "#FFF",
        fontSize: "14px",
        borderRadius: "24px",
        textTransform: "capitalize",
        width: "250px",
        height: "48px",
        marginTop: "15px",
        marginBottom: "10px",
        fontWeight: 500,
        "&:hover": {
            backgroundColor: "#390879",
            color: "#FFF",
        }
    },

    iconWithCalender: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },

});
export const CustomDrawer = styled(Drawer)({
    borderTopLeftRadius: "25px",
        borderBottomLeftRadius: "25px",
        width: "375px",
    "& .MuiDrawer-paperAnchorRight": {
        borderTopLeftRadius: "25px",
        borderBottomLeftRadius: "25px",
        width: "375px"
    },
    "@media(max-width:450px)": {
        "& .MuiDrawer-paperAnchorRight": {
            width: "350px"
        },
    }, "@media(max-width:375px)": {
        "& .MuiDrawer-paperAnchorRight": {
            width: "280px"
        },
    }
})

interface Props {
}

interface RequestsDialogProps {
    handleOpenDialog(): void;
    handleignoreRequest: (data: any) => void;
    handleacceptRequest: (data: any) => void;
    handleCloseDialog(): void;
    openDialog: boolean;
    showFilterText?: boolean;
    shyfDrawerData: Array<object>;
    closeModal(): void;
    isModalOpen: boolean;
    shyftName: string;
    acceptedShyfter: string;
    viewOngoingShyfts: () => void;
    navigateApplicantProfile?: (shyfter: any) => void;
}

const requestCount = (list: any[]) => {
  return list.length > 9 ? list.length : `0${list.length}`;
}

const getTimeAgoString = (inputDate: string) => {
    const currentDate = moment();
    const inputMoment = moment(inputDate);

    const minutesDiff = currentDate.diff(inputMoment, 'minutes');
    const hoursDiff = currentDate.diff(inputMoment, 'hours');
    const daysDiff = currentDate.diff(inputMoment, 'days');

    if (minutesDiff < 1) {
        return 'Just now';
    } else if (minutesDiff < 60) {
        return `${minutesDiff} minute${minutesDiff !== 1 ? 's' : ''} ago`;
    } else if (hoursDiff < 1) {
        return `${hoursDiff} hour${hoursDiff !== -1 ? 's' : ''} ago`;
    } else if (hoursDiff >= 1 && daysDiff < 1) {
        return `${hoursDiff} hour${hoursDiff !== 1 ? 's' : ''} ago`;
    } else if (daysDiff === 1) {
        return '1 day ago';
    } else {
        return `${daysDiff} days ago`;
    }
}

const RequestsDialog = (props: RequestsDialogProps) => {
    const classes = useStyles();
    return (
        <ThemeProvider theme={theme}>
            <CustomDrawer
                anchor='right'
                open={props.openDialog}
            >
                <Box className={classes.topHeader}>
                    <Box className={classes.closeButton}><Close data-test-id="close-view-request" onClick={props.handleCloseDialog} /></Box>
                    <Box>
                        <p className={classes.allRequestsText} >All Requests for</p>
                        <h2>{props.shyftName}</h2>
                    </Box>
                    <Box className={classes.headerChipWrap}>
                        <Chip
                        avatar={<img src={downArrow} alt="downArrow" /> } 
                            label={requestCount(props.shyfDrawerData)}
                        />
                    </Box>
                </Box>
                <Box height="300px">
                    <div className={classes.root}>

                        {
                            props.shyfDrawerData.map((item: any) => 
                                {
                                
                                      let   updateTime= getTimeAgoString(item.request.created_at)
                                    
                                return (
                                    <ShyfterRequestCard
                                        key={item.request.id}
                                        shyfterName={item.worker.full_name}
                                        shyfterExperence={item.worker.experience}
                                        time={updateTime}
                                        rating={item.worker_rating ? item.worker_rating : "0"}
                                        handleAcceptBtn={() => props.handleacceptRequest(item)}
                                        handleIgnoreBtn={() => props.handleignoreRequest(item)}
                                        imgUrl={item.worker_profile_picture ? item.worker_profile_picture : ProfileImg}
                                        navigateApplicantProfile={props.navigateApplicantProfile}
                                        shyfter={item}
                                        shyftName={props.shyftName}
                                    />
                                )
                            }
                            )

                        }
                    </div>
                </Box>
            </CustomDrawer>

            <Box>
                <Modal
                    open={props.isModalOpen}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                    className={classes.iconWithCalender}>
                    <Box className={classes.modalContainer}>
                        <Typography variant='h5' className={classes.userTittleText}>Shyft Accepted</Typography>
                        <Box className={classes.modalCloseButton}><Close onClick={props.closeModal} /></Box>
                        <Box className={classes.imgIconHeader}>
                            <img className={classes.userImageIcon}
                                src={ProfileImg} alt="profile" />
                        </Box>

                        <Typography variant='h6' className={classes.userNameText} >{props.acceptedShyfter}</Typography>
                        <Box className={classes.iconWithCalender}>
                            <img src={briefcase} alt="briefcase"
                                className={classes.briefcaseIcon}
                            />
                            <Typography
                                className={classes.doorManText}
                            >{props.shyftName}</Typography>
                        </Box>
                        <Typography variant="body2" className={classes.ShyftContain}>Shyft for {props.shyftName} job is been accepted. You can view<br />activity of shyfter in ongoing shyft tab.</Typography>

                        <Box className={classes.iconWithCalender}>
                            <Button data-test-id="view-ongoing-shyft" className={classes.viewButton} onClick={props.viewOngoingShyfts}>View Ongoing Shyfts</Button>
                        </Box>
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>
    );
};
export default RequestsDialog;
