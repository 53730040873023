import React from "react";
import {
  Box,
  Container,
  Dialog,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
});

import MessagesController, { Props, configJSON } from "./MessagesController";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import {
  briefcaseGrey,
  chatIcon,
  hotel,
  searchIcon,
} from "./assets";
import CustomButton from "../../../components/src/CustomButton.web";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ActiveMessages from "./ActiveMessages.web";
import Spinner from "../../../components/src/Spinner";
import { capitalizeWords } from "../../../components/src/Utilities";

interface INewMessageProps {
  isEmployer: boolean;
  open: boolean;
  chatList: any[];
  handleClose: () => void;
  handleSelectChat: (id: string) => void;
  newMsgSearchText: string;
  handleSearchTextUpdate: (e: any) => void;
}

interface IMessageCardProps {
  name: string;
  shyftName: string;
  type?: string;
  image?: string;
  onClick: () => void;
}

const setSearchPlaceholder = (isEmployer: boolean) => {
  return isEmployer
    ? configJSON.searchPlaceholderForEmployer
    : configJSON.searchPlaceholderForShyfter;
};

const startMessagingDescription = (isEmployer: boolean) => {
  return isEmployer ? configJSON.startMessagingDescriptionForEmployer : configJSON.startMessagingDescriptionForShyfter;
}

export const MessageCard = (props: IMessageCardProps) => {
  return (
    <Box
      border="1px solid #E1E6EC"
      borderRadius={12}
      display="flex"
      alignItems="center"
      padding="10px"
      margin="10px 0px"
      data-test-id="message-card"
      style={{ cursor: "pointer" }}
      onClick={props.onClick}
    >
      <Box marginRight="8px">
        <img
          style={webStyle.messageImage}
          src={props.image}
          height={42}
          width={42}
          alt=""
        />
      </Box>
      <Box>
        <Box
          color="#0A0412"
          fontSize="14px"
          fontWeight={600}
          paddingBottom="3px"
        >
          {props.name}
        </Box>
        <Box
          color="#6F7C8E"
          fontSize="12px"
          fontWeight={500}
          display="flex"
          alignItems="center"
        >
          {props.type && (
            <Box padding="0px 10px 0px 2px" display="flex" alignItems="center">
              <Box style={webStyle.dot} />
              {props.type}
            </Box>
          )}
          <Box display="flex" alignItems="center">
            <Box paddingRight="3px">
              <img src={briefcaseGrey} height={11} width={11} alt="shift" />
            </Box>
            {props.shyftName}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const NewMessage = (props: INewMessageProps) => {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          width: "530px",
          borderRadius: "20px",
          overflowY: "visible",
        },
      }}
    >
      <IconButton
        data-test-id="close-dialog"
        onClick={props.handleClose}
        style={{
          border: "1px rgb(255, 255, 255, 0.3) solid",
          padding: "5px",
          position: "absolute",
          top: "-40px",
          right: 0,
          borderRadius: "50%",
          backgroundColor: "rgb(0 0 0 / 55%)",
        }}
      >
        <CloseIcon style={{ color: "#fff" }} height={20} width={20} />
      </IconButton>
      <Box
        fontSize="22px"
        color="#0A0412"
        textAlign="center"
        padding="20px 0px"
        fontWeight={600}
      >
        New Message
      </Box>
      <Box padding="0px 20px 20px">
        <TextField
          fullWidth
          data-test-id="new-chat-search-input"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={searchIcon} height={18} alt="profile" />
              </InputAdornment>
            ),
            style: { borderRadius: "20px" },
          }}
          onChange={props.handleSearchTextUpdate}
          value={props.newMsgSearchText}
          placeholder={setSearchPlaceholder(props.isEmployer)}
          inputProps={{
            style: {
              paddingTop: "14px",
              paddingBottom: "14px",
            },
          }}
        />
      </Box>
      <Box style={webStyle.newMessagesWrapper}>
        {props.chatList.map((item) => (
          <MessageCard
            key={item.id}
            name={capitalizeWords(item?.attributes?.user?.full_name)}
            shyftName={capitalizeWords(item?.attributes?.shift_type)}
            type={item?.attributes?.business_details}
            image={item?.attributes?.profile_picture || hotel}
            onClick={() => props.handleSelectChat(item?.attributes?.user?.id)}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default class Messages extends MessagesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isShowNoChatsPage,
      isEmployer,
      isOpenNewMessageDialog,
      activeMessagesView,
      isLoading,
      newChatUserList,
      newChatId,
      newMsgDialogSearchText
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} isShowDetails={isEmployer} isUpdateUser={false}/>
        {isOpenNewMessageDialog && (
          <NewMessage
            open={isOpenNewMessageDialog}
            chatList={newChatUserList}
            isEmployer={isEmployer}
            handleClose={this.handleCloseNewMessageDialog}
            handleSelectChat={this.redirectToActiveMessages}
            newMsgSearchText={newMsgDialogSearchText}
            handleSearchTextUpdate={this.handleUpdateNewChatSearchText}
          />
        )}
        {isLoading ? <Spinner /> : <>
          {!activeMessagesView ? <Container>
            <Box minHeight={"95vh"} marginTop={"10%"}>
              {isShowNoChatsPage && (
                <Box
                  border="1px solid #E1E6EC"
                  borderRadius="0.875rem"
                  height="678px"
                  width="100%"
                  bgcolor="#fff"
                  display="flex"
                  marginBottom="30px"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Box>
                      <img
                        height={60}
                        src={chatIcon}
                        width={60}
                        alt="chat"
                      />
                    </Box>
                    <Box
                      color="#0A0412"
                      fontSize="20px"
                      padding="16px 0px"
                      fontWeight={600}
                      fontFamily='"Gotham", sans-serif'
                      data-test-id="no-data-title"
                      textAlign="center"
                    >
                      {configJSON.startMessaging}
                    </Box>
                    <Box
                      fontWeight={500}
                      color="#6F7C8E"
                      fontFamily='"Gotham", sans-serif'
                      fontSize="12px"
                      textAlign="center"
                      width="245px"
                    >
                      {startMessagingDescription(isEmployer)}
                    </Box>
                    <Box width="200px" paddingTop="30px">
                      <CustomButton
                        label={configJSON.addChat}
                        handleClick={this.handleActionBtnClick}
                        disabled={!newChatUserList.length}
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Container> :
          <ActiveMessages 
            navigation={this.props.navigation} 
            id="" 
            isEmployer={isEmployer}
            openNewMessageDialog={this.handleActionBtnClick}
            newChatId={newChatId}
          />}
        </>}
        <Footer />
      </ThemeProvider>
    );
  }
}

const webStyle = {
  newMessagesWrapper: {
    overflowY: "auto" as any,
    padding: "0px 20px 20px",
  },
  messageImage: {
    borderRadius: "12px",
  },
  dot: {
    width: "5px",
    height: "5px",
    backgroundColor: "#6F7C8E",
    borderRadius: "50%",
    marginRight: "4px",
  },
};
