import React, { Component } from "react";
import { Box, Button } from "@material-ui/core";
import { RemoveCircleOutline } from "@material-ui/icons";
import { verticalArrow } from "./assets";
import { createStyles, withStyles } from "@material-ui/core/styles";

interface IButtonProps {
  labelName?: string;
  endShyft?: string;
  classes:Record<string,string>;
  openModal?:()=> void
  openendShyftModal?:()=> void
}

const styles = createStyles ({
  cardFooter: {
    // borderTop: "1px solid #e1e6ec",
    display: "flex",
    justifyContent: "space-evenly",
    backgroundColor: "#ffffff",
    padding: "10px",
    width: "92%"
  },
  removeButton: {
    // width: "178px",
    height: "48px",
    borderRadius: "24px",
    color: "red",
    border: "1px solid red",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "& span": {
      fontSize: "14px"
    }
  },
  endButton: {
    width: "178px",
    height: "48px",
    borderRadius: "24px",
    backgroundColor: "#390879",
    textTransform: "capitalize",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#390879",
      color: "#fff",
    },
    "& span": {
      fontSize: "14px"
    }
  },
  verticalButtonIcon: {
    color: "#fff",
    width: "16px",
    height: "15px",
    textTransform: "capitalize",
  },
  whiteImage: {
    filter: "invert(100%)",
  },
});

class OngoingShyftCustomButton extends Component<IButtonProps> {
  render() {
    const {
      labelName,
      endShyft
    } = this.props;

    const { classes } = this.props;

    return (
      <Box className={classes.cardFooter}>
        <Button
          className={classes.removeButton}
          variant="outlined"
          startIcon={<RemoveCircleOutline />}
          data-test-id="removeBtnId"
          onClick={this.props.openModal}
        >
          {labelName}
        </Button>
        <Button
          className={classes.endButton}
          variant="contained"
          data-test-id="endShyftBtnId"
          onClick={this.props.openendShyftModal}
          startIcon={
            <img
              src={verticalArrow}
              className={`${classes.verticalButtonIcon} ${classes.whiteImage}`}
              alt="White Vertical Arrow"
            />
          }
        >
          {endShyft}
        </Button>
      </Box>
    );
  }
}

export default withStyles(styles)(OngoingShyftCustomButton);
