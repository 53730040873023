import React, { FunctionComponent } from "react";
import { Typography, Box, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const customStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: 10,
    backgroundColor: "white",
  },
  content: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  icon: {
    width: "62px",
    height: "62px",
    borderRadius: "14px",
    background: "rgba(225, 230, 236, 0.3)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "#0a0412",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    marginBottom: "5px",
  },
  description: {
    color: "#6f7c8e",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
    maxWidth: "364px",
  },

  root: {
    width: 48,
    height: 24,
    padding: 0,
    margin: theme.spacing(1),
    overflow: "visible",
  },
  switchBase: {
    padding: 5,
    "&$checked": {
      transform: "translateX(22px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#b9df12",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#b9df12",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 14,
    height: 14,
  },
  track: {
    borderRadius: 24 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

type Props = {
  imageUrl: string;
  title: string;
  checked: boolean;
  description: string;
  onChange: (checked: boolean) => void;
};

const Notification: FunctionComponent<Props> = ({
  imageUrl,
  title,
  description,
  checked,
  onChange,
}) => {
  const classes = customStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.icon}>
          <img src={imageUrl} alt={title} />
        </Box>
        <Box>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.description}>{description}</Typography>
        </Box>
      </Box>

      <Box>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          data-test-id="notification-switch"
          checked={checked}
          onChange={(e, checked) => onChange(checked)}
        />
      </Box>
    </Box>
  );
};
export default Notification;
