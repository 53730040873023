import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
// @ts-ignore
import * as config from "../../../framework/src/config";
import { setStorageData } from "../../../framework/src/Utilities";


export interface Props {
  navigation: any;
  id: string;
  isEmployer: boolean;
  openNewMessageDialog: () => void;
  newChatId: string;
}

interface S {
  isActionNeeded: boolean;
  selectedItems: string[];
  deleteModalOpen: boolean;
  renderChatId: number;
  userMessages: any[];
  userChatsData: any[];
  chatLoader: boolean;
  msgText: string;
  allChatSearchText: string;
  openReportModal: boolean;
  reason: string;
  comment: string;
  reasonsList: any;
  reportUserId: number | null;
  reasonNotAdded: boolean;
  commentNotAdded: boolean;
  attachment:any;
  recentShyftStatus:string;
  recentShyftId:number|null;
  openSuccessModal:boolean;
  isSuccess:boolean;
  activeChatDetails: any;
}

interface SS {}

export default class ActiveMessagesController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllChatsApiId: string = "";
  deleteChatsApiId: string = "";
  deleteChatDataApiId: string = "";
  userChatDataApiId: string = "";
  createChatApiId: string = "";
  uploadDocumentApiId: string = "";
  getAllReasonsApiId: string = "";
  reportUserApiId: string = "";
  socket: WebSocket;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    const wsUrl =
      config.baseURL.replace("https", "wss") +
      `/cable?token=${localStorage.getItem("authToken")}`;
    this.socket = new WebSocket(wsUrl);
    this.socket.onopen = this.onopen;
    this.socket.onmessage = this.handleMessage;

    this.state = {
      isActionNeeded: false,
      selectedItems: [],
      deleteModalOpen: false,
      renderChatId: -1,
      userMessages: [],
      userChatsData: [],
      chatLoader: false,
      msgText: "",
      allChatSearchText: "",
      openReportModal: false,
      reason: '',
      comment: '',
      reasonsList: [],
      reportUserId: null,
      reasonNotAdded: false,
      commentNotAdded: false,
      attachment:null,
      recentShyftId:null,
      recentShyftStatus:'',
      openSuccessModal:false,
      isSuccess:false,
      activeChatDetails: null
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (prevProps.newChatId !== this.props.newChatId && this.props.newChatId) {
      this.createNewChat(this.props.newChatId);
    }
  }

  onopen = () => {
    setTimeout(() => this.subscribeToMessage(), 500);
  };

  checkOpen = () => this.socket.readyState === WebSocket.OPEN;

  handleMessage = (e: any) => {
    const message = JSON.parse(e?.data || "{}");
    if (message.identifier) {
      const identifier = JSON.parse(message.identifier);
      if (identifier.channel === "ChatChannel") {
        if (!message.message) {
          return;
        }
        if (identifier?.id === this.state.renderChatId) {
          this.getUserChatData(this.state.renderChatId);
        }
        this.getAllChatList("");
      }
    }
  };

  onSubscribe = (ids?: any) => {
    if (this.checkOpen()) {
      const data = JSON.stringify({
        command: "subscribe",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: ids,
        }),
        data: JSON.stringify({
          id: ids,
        }),
      });
      this.socket.send(data);
    }
  };

  onUnSubscribe = (ids:any)=>{
    if (this.checkOpen()) {
      const data = JSON.stringify({
        command: "unsubscribe",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: ids,
        }),
      });
      this.socket.send(data);
    }
  }

  subscribeToMessage = () => {
    let token: any = localStorage.getItem("authToken");
    if (!token || !this.state.userMessages.length) {
      return;
    }
    this.state.userMessages.forEach((item) => {
      this.onSubscribe(item?.id);
    });
  };

  unsubscribeToMessage = () => {
    let token: any = localStorage.getItem("authToken");
    if (!token || !this.state.userMessages.length) {
      return;
    }
    this.state.userMessages.forEach((item) => {
      this.onUnSubscribe(item?.id);
    });
  };

  sendNewMessage = () => {
    if(this.state.attachment){
      if (this.state.attachment && this.state.attachment.size < 2097152) {
        this.uploadDocuments(this.state.renderChatId, this.state.attachment);
      }
      return;
    }
    if (this.checkOpen() && this.state.msgText) {
      const data = JSON.stringify({
        command: "message",
        identifier: JSON.stringify({
          channel: "ChatChannel",
          id: this.state.renderChatId,
        }),
        data: JSON.stringify({
          message: this.state.msgText,
          action: "speak",
        }),
      });
      this.socket.send(data);
      this.setState({msgText: ""});
    }
  };

  async componentDidMount() {
    this.getAllChatList("");
    this.getReasonsList();
    const receiverId = localStorage.getItem('receiverId');
    if (this.props.newChatId) {
      this.createNewChat(this.props.newChatId);
    } else if(receiverId) {
      let parsedId = JSON.parse(receiverId);
      this.createNewChat(parsedId);
    }
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getAllChatsApiId) {
      this.setState({ ...this.state, userMessages: responseJson?.data ?? [] });
      const activeChatId = localStorage.getItem("activeChatId");
      if (activeChatId) {
        this.handleRenderChat(activeChatId);
        localStorage.setItem("activeChatId", "");
      }
    }

    if (apiRequestCallId === this.deleteChatsApiId) {
      this.setState({
        deleteModalOpen: false,
        selectedItems: [],
        isActionNeeded: false,
        renderChatId: -1,
      });
      this.getAllChatList("");
    }

    if (apiRequestCallId === this.userChatDataApiId) {
      this.handleUsersChatResponse(responseJson);
    }

    if (apiRequestCallId === this.createChatApiId) {
      localStorage.removeItem('receiverId')
      this.setCreateChatResponse(responseJson);
    }

    if (apiRequestCallId === this.deleteChatDataApiId) {
      if (responseJson && responseJson.message) {
        this.getUserChatData(this.state.renderChatId);
        this.getAllChatList("");
      }
    }

    if (apiRequestCallId === this.getAllReasonsApiId) {
      this.setState({
        reasonsList: responseJson.data.length > 0 && responseJson.data.map((reason: any) => {
          return { name: reason?.attributes?.name, value: reason?.attributes?.name, id: reason.id }
        })
      });
    }
    this.handleReportUserResponse(apiRequestCallId,responseJson);
  }

  handleUsersChatResponse = (responseJson: any) => {
    this.setState({
      ...this.state,
      userChatsData: responseJson?.response_data ?? [],
      chatLoader: false,
      recentShyftStatus:responseJson.status,
      activeChatDetails: responseJson
    });
    if (responseJson?.response_data?.length > 0) {
      this.getAllChatList("");
    }
  }

  handleReportUserResponse=(apiRequestCallId:any,responseJson:any)=>{
    if(apiRequestCallId === this.reportUserApiId){
      if(responseJson.message){
        this.setState({isSuccess:true,openSuccessModal:true,openReportModal:false,reason:"",comment:'',reportUserId: null})
      } else if(responseJson.error){
        this.setState({isSuccess:false,openSuccessModal:true,openReportModal:false,reason:"",comment:'',reportUserId: null})
      }
    }
  }

  setCreateChatResponse = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      this.onSubscribe(responseJson.data.id);
      const index = this.state.userMessages.findIndex((item) => item.id === responseJson.data.id);
      if (index === -1) {
        let data = this.state.userMessages;
        data.push(responseJson.data);
        this.setState({ userMessages: data });
      }
      this.handleRenderChat(responseJson.data?.id);
    }
  }

  handleAction = () => {
    this.setState({
      isActionNeeded: !this.state.isActionNeeded,
      selectedItems: [],
    });
  };

  handleOpenReportModal = (userId: number) => {
    this.setState({ openReportModal: true, reportUserId: userId });
  }
  
  handleOpenSuccessModal = ()=>{
    this.setState({openSuccessModal:true})
  }

  handleCancelSuccessModal = ()=>{
    this.setState({openSuccessModal:false,isSuccess:false})
  }

  handleCloseReportModal = () => {
    this.setState({ openReportModal: false, reportUserId: null, reasonNotAdded: false, commentNotAdded: false,reason:"",comment:'' });
  }

  handleReportModalFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let errorKey = "reasonNotAdded"
    if (name !== 'reason') {
      errorKey = 'commentNotAdded'
    }
    this.setState({ ...this.state, [name]: value, [errorKey]: false })
  }

  selectedItem = (event: any, chatId: string) => {
    const isEventActive = event.target.checked;

    isEventActive &&
      this.setState({
        selectedItems: [...this.state.selectedItems, chatId],
      });

    !isEventActive &&
      this.setState((prevState) => ({
        selectedItems: prevState.selectedItems.filter(
          (item) => item !== chatId
        ),
      }));
  };

  handleCloseAction = () => {
    this.setState({
      isActionNeeded: false,
      selectedItems: [],
    });
  };

  handleDeleteModal = () => {
    this.setState({
      deleteModalOpen: true,
    });
  };

  handleDeleteChat = () => {
    this.deleteMultipleChat(this.state.selectedItems);
  };

  handleDeleteModalClose = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  handleRenderChat = (chatId: any) => {
    this.setState({
      renderChatId: chatId,
      chatLoader: true,
      msgText: ""
    });
    this.getUserChatData(chatId);
  };

  handleMsgTextInput = (e: any) => {
    this.setState({msgText: e.target.value});
  }

  handleAllChatSearchUpdate = (e: any) => {
    this.setState({ allChatSearchText: e.target.value }, () => {
      this.getAllChatList(this.state.allChatSearchText);
    })
  }

  handleEmojiUpdate = (e: any) => {
    this.setState((prevState) => ({ msgText: `${prevState.msgText}${e.emoji}`}));
  }

  handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    this.setState({attachment:file});    
  }

  removeAttachment = () => {
    this.setState({attachment:null})
  }

  handleChatMenuClick = (type: string) => {
    if (type === "delete") {
      this.deleteChatContent(this.state.renderChatId);
    } else if(type === "shyft"){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        this.state.recentShyftStatus === 'ongoing' ? "MyShyftsPage":'PastShyftPage'
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);
    }
  };

  getAllChatList(searchText: string) {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const allChatListRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllChatsApiId = allChatListRequestMsg.messageId;

    allChatListRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allChatsApiEndPoint+`?search=${searchText}`
    );

    allChatListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    allChatListRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(allChatListRequestMsg.id, allChatListRequestMsg);
  }

  deleteMultipleChat = (chatIds: any[]) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const deleteChatsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteChatsApiId = deleteChatsRequestMsg.messageId;

    deleteChatsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteChatApiEndPoint
    );

    deleteChatsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteChatsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    deleteChatsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        chat_ids: chatIds,
      })
    );

    runEngine.sendMessage(deleteChatsRequestMsg.id, deleteChatsRequestMsg);
  };

  deleteChatContent = (chatId: number | string) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const deleteChatDataApiRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteChatDataApiId = deleteChatDataApiRequestMsg.messageId;

    deleteChatDataApiRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteChatDataApiEndPoint
    );

    deleteChatDataApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteChatDataApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    deleteChatDataApiRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        message: {
          chat_id: chatId
        },
      })
    );

    runEngine.sendMessage(deleteChatDataApiRequestMsg.id, deleteChatDataApiRequestMsg);
  };

  createNewChat = (chatId: any) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const createChatRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createChatApiId = createChatRequestMsg.messageId;

    createChatRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allChatsApiEndPoint
    );

    createChatRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    createChatRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    createChatRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        chat: {
          receiver_id: chatId
        },
      })
    );

    runEngine.sendMessage(createChatRequestMsg.id, createChatRequestMsg);
  };

  getUserChatData = (chatId: string | number) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const userChatDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userChatDataApiId = userChatDataRequestMsg.messageId;

    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allChatsApiEndPoint + `/${chatId}`
    );

    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(userChatDataRequestMsg.id, userChatDataRequestMsg);
  };

  uploadDocuments = (chatId: string | number, document: any) => {
    this.setState({attachment:null,msgText:""})
    const headers = {
      token: localStorage.getItem("authToken")
    };

    const formData = new FormData();
    formData.append("message[attachments][]", document);
    formData.append("message[chat_id]", chatId as string);

    const userChatDataRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.uploadDocumentApiId = userChatDataRequestMsg.messageId;

    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadDocumentEndPoint
    );

    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    userChatDataRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(userChatDataRequestMsg.id, userChatDataRequestMsg);
  };

  getReasonsList = () => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const getReasonsListApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllReasonsApiId = getReasonsListApiMsg.messageId;

    getReasonsListApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reasonListEndPoint
    );

    getReasonsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getReasonsListApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(getReasonsListApiMsg.id, getReasonsListApiMsg);
  }

  isValidReportForm = () => {
    const { comment, reason } = this.state;
    this.setState({ reasonNotAdded: reason.length < 1, commentNotAdded: comment.length < 1 });
    return reason.length > 0 && comment.length > 0
  }

  submitReportUser = () => {
    const { reasonsList, reason, reportUserId } = this.state;
    if (!this.isValidReportForm()) {
      return;
    }
    const selectedReason = reasonsList.filter((reasons: any) => reasons.value === reason)
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const submitReportUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportUserApiId = submitReportUserMsg.messageId;

    submitReportUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportUserEndPoint
    );

    submitReportUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    submitReportUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    submitReportUserMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        report: {
          report_reason_id: selectedReason[0].id,
          comment: this.state.comment,
          reported_user_id: reportUserId
        }
      })
    );
    runEngine.sendMessage(submitReportUserMsg.id, submitReportUserMsg);
    this.handleCloseReportModal();
  }

  viewBusinessProfile = (userId: number) => {
    if (this.props.isEmployer) {
      const applicantProfileMsg = new Message(getName(MessageEnum.NavigationMessage));
      applicantProfileMsg.addData(getName(MessageEnum.NavigationTargetMessage), "ApplicantProfile");
      applicantProfileMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      localStorage.setItem("applicantId",JSON.stringify(userId));
      const activeUser = this.state.userMessages.find((user) => user.id === this.state.renderChatId);
      const shyftType = activeUser.attributes.shift_type;
      localStorage.setItem("shiftType", shyftType);
      this.send(applicantProfileMsg);
    } else {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(
        getName(MessageEnum.NavigationTargetMessage),
        "BusinessProfile"
      );
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      setStorageData("businessId", userId);
      this.send(message);
    }
  };
}
