import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkAuthorization, getUserRole } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isShowNoChatsPage: boolean;
  isEmployer: boolean;
  isOpenNewMessageDialog: boolean;
  activeMessagesView: boolean;
  isLoading: boolean;
  allChatList: any[];
  newChatUserList: any[];
  newChatId: string;
  newMsgDialogSearchText: string;
}

interface SS {}

export default class MessagesController extends BlockComponent<Props, S, SS> {
  allChatsApiId: string = "";
  newChatsApiId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isShowNoChatsPage: true,
      isEmployer: false,
      isOpenNewMessageDialog: false,
      activeMessagesView: false,
      isLoading: true,
      allChatList: [],
      newChatUserList: [],
      newChatId: "",
      newMsgDialogSearchText: ""
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const isAuthorized = checkAuthorization(true, true);
    if (!isAuthorized) {
      this.loginNavigator();
      return;
    }
    this.getAllChats();
    this.getNewChatUserList("");
    const getRole = getUserRole();
    const receiverId = localStorage.getItem('receiverId');
    let parsedId;
    if(receiverId){
      parsedId= JSON.parse(receiverId);
      this.setState({newChatId: parsedId,activeMessagesView:true})
    }
    getRole !== "2" &&  this.setState({ isEmployer: true });
  }

  loginNavigator = () => {
    const chatNavigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    chatNavigationMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    chatNavigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(chatNavigationMsg);
  }

  async componentWillUnmount() {
    localStorage.removeItem('receiverId')
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
      this.loginNavigator();
    } else {
      if (apiRequestCallId ===  this.allChatsApiId) {
        this.setAllChatListResponse(responseJson);
      }
      if (apiRequestCallId === this.newChatsApiId) {
        this.setState({ ...this.state, newChatUserList: responseJson?.data ?? [], isLoading: false });
      }
    }

  }
  
  setAllChatListResponse = (responseJson: any) => {
    if (responseJson?.data && responseJson.data.length > 0 ) {
      this.setState({ isShowNoChatsPage: false, isLoading: false, allChatList: responseJson.data, activeMessagesView: true });
    } else {
      this.setState({ isLoading: false, allChatList: [] });
    }
  }

  getNewChatUserList = (searchText: string) => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const newChatListRequestApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.newChatsApiId = newChatListRequestApiMsg.messageId;

    newChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.newChatListApiEndPoint+`?search=${searchText}`
    );

    newChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    newChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(newChatListRequestApiMsg.id, newChatListRequestApiMsg);
  }

  handleActionBtnClick = () => {
    this.setState({ isOpenNewMessageDialog: true });
  };

  handleCloseNewMessageDialog = () => {
    this.setState({ ...this.state, isOpenNewMessageDialog: false, newMsgDialogSearchText: "" });
  };

  handleUpdateNewChatSearchText = (e: any) => {
    this.setState({ newMsgDialogSearchText: e.target.value }, () => {
      this.getNewChatUserList(this.state.newMsgDialogSearchText);
    });
  }

  getAllChats() {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken"),
    };

    const allChatListRequestApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allChatsApiId = allChatListRequestApiMsg.messageId;

    allChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allChatsApiEndPoint
    );

    allChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    allChatListRequestApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(allChatListRequestApiMsg.id, allChatListRequestApiMsg);
  }

  redirectToActiveMessages = (id: string) => {
    this.setState({ ...this.state, 
      isOpenNewMessageDialog: false, 
      activeMessagesView: true,
      newChatId: id,
      newMsgDialogSearchText: ""
    }, () => {
      setTimeout(() => {
        this.setState({ newChatId: "" })
      }, 1000);
    });
  }
}
