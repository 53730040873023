import React, { Component } from 'react';
// Customizable Area Start
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import { Box, Typography } from '@material-ui/core';
import GenericFooter from './GenericFooter.web';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import './style.css';
// Customizable Area End
// Customizable Area Start
interface Props {

    navigation: any,
    goToSignUp: () => void;
    goToContactUs: () => void;
    dataTestId: any;
    onNavigate: (path: string) => void;
    isAboutHighlighted: boolean;
}

const webStyle = {
    rightCard: {
        height: "190px"
    },
    contentLeft: {
        gap: "130px",
        gridGap: "130px"
    },
    contentRight: {
        gap: "40px",
        gridGap: "40px"
    },
    jobSeekersContentDetails: {
        height: "90px",
        width: "400px"
    },
    rightArrow: {
        borderRadius: "8px",
        color: "white",
        backgroundColor: "rgba(255, 255, 255, 0.2)"
    },
    downloadIcon: {
        borderRadius: "8px",
        color: "#390879",
        fontSize: "30px",
        cursor: "pointer"
    },
    contentInfoDetails: {
        width: "400px"
    }
}
// Customizable Area End
// Customizable Area Start
export default class LandingScreen extends Component<Props, any>{
    constructor(props: Props) {
        super(props);
    }

    async componentDidUpdate(prevProps: Props, prevState: any) {
        if (this.props.isAboutHighlighted !== prevProps.isAboutHighlighted && this.props.isAboutHighlighted) {
            this.onAboutRedirection();
        }
    }


    onAboutRedirection = () => {
        const element = document.getElementById('about-us');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    render() {

        return (
            <>
                <NavigationMenu navigation={this.props.navigation} id={''} onSignUp={this.props.goToSignUp} />
                <Box className='body-firstsection'>
                    <div className='body-section1'>
                        <span className='body-logo'><img src={require('./logo2.png')} /></span>
                        <span className='body-title'>Explore Snagashyft to Get <span>Hospitality</span> Services Easy</span>
                        <span className='body-subtitle'>Ready to revolutionize your staffing process and take your business to new heights? Join Snagashyft today and experience the future of hiring in the hospitality industry.</span>
                        <span className='header-buttongrp'>
                            <span className='header-login body-join' onClick={this.props.goToSignUp} data-testid="join1-test">Join Now</span>
                            <span className='header-signup body-reach' onClick={() => this.props.goToContactUs()} data-testid="contact1-test">Reach Us</span>
                        </span>
                    </div>
                    <div className='body-section2'>
                        <img src={require('./body-iphone.png')} alt="" />
                    </div>
                </Box>
                <Box className='body-secondsection' id="about-us">
                    <div className='secondsection-heading'>
                        <Typography variant='h4' className='heading-main'> About <span>Snagashyft</span></Typography>
                        <span className='heading-mainsub'>Snagashyft is the on-demand staffing platform for the hospitality industry.</span>
                    </div>
                    <div className='secondsection-content'>
                        <img className='content-image' src={require('./bodysecondsection.png')}></img>
                        <div className='content-info'>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Fill your open shifts quickly
                                </span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Matching algorithm makes sure you get the right talent for the right shift
                                </span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Two-way reviews let's the cream rise to the top
                                </span>
                            </span>
                            <span className='content-item'>
                                <span className='contentinfo-circle lastitem'>
                                    <img className='.contentinfo-check' src={require('./checkmark.png')}></img>
                                </span>
                                <span className='contentinfo-details'>
                                    Subscription model makes budgeting a breeze
                                </span>
                            </span>
                        </div>
                    </div>

                </Box>
                <Box className='body-thirdsection' height={"100%"}>
                    <div className='thirdsection-heading'>
                        <Typography variant='h4' className='heading-main'><span>Benefits</span> at-a-Glance</Typography>
                        <span className='heading-mainsub'>Top features of the app which helps you to get your jobs easy and more efficient with app the managing features</span>
                    </div>
                    <div className='thirdsection-content'>
                        <div className='content-left' style={webStyle.contentLeft}>
                            <span className='contentLeft-item'>
                                For <span className='snagashyft-secondary-color'> Job Seekers </span> (aka <span className='snagashyft-secondary-color'> Shyfters</span>)
                            </span>
                            <span className='contentLeft-item'>
                                For <span className='snagashyft-secondary-color'> Businesses</span>
                            </span>
                        </div>
                        <div className='content-right' style={webStyle.contentRight}>
                            <div className='contentright-first'>
                                <span className='right-card'>
                                    <img src={require('./sectionicon1.png')}></img>
                                    <label className='card-heading'>Flexible Schedule</label>
                                    <label className='card-details'>Work when & where you want</label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon2.png')}></img>
                                    <label className='card-heading'>W-2 Employment</label>
                                    <label className='card-details'>The flexibility of gig work with the security of W-2 employment. Full slate of benefits available for those who maintain full-time status on the app.</label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon3.png')}></img>
                                    <label className='card-heading'>Earning Power</label>
                                    <label className='card-details'>Democratizing hospitality work means all positions will pay above industry standards</label>
                                </span>
                                <span className='rightcontent-icon'><KeyboardArrowRightIcon style={webStyle.rightArrow} onClick={() => this.props.onNavigate("ShyfterBenifits")}></KeyboardArrowRightIcon></span>
                            </div>
                            <div className='contentright-second'>
                                <span className='right-card'>
                                    <img src={require('./sectionicon4.png')}></img>
                                    <label className='card-heading'>Shifts</label>
                                    <label className='card-details'>Post shifts in seconds for a flat monthly fee</label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon5.png')}></img>
                                    <label className='card-heading'>Peace of Mind</label>
                                    <label className='card-details'>Picture this - no-shows, last-minute cancellations, and that feeling of impending doom before a big event. Not anymore! With our platform, you'll have a reliable crew at your fingertips, ensuring smooth sailing even during the stormiest of times. We've got your back like the world's most supportive office chair.</label>
                                </span>
                                <span className='right-card'>
                                    <img src={require('./sectionicon6.png')}></img>
                                    <label className='card-heading'>Manage Overhead</label>
                                    <label className='card-details'>Slash those staffing costs like a boss: Say "good-bye" to unnecessary overheads. Our on-demand staffing solution means you only pay for the staff you need, when you need them. No more keeping a full-time team on standby, wasting precious resources. It's like having a magical money-saving wand in your pocket.</label>
                                </span>
                                <span className='rightcontent-icon'><KeyboardArrowRightIcon style={webStyle.rightArrow} onClick={() => this.props.onNavigate("BusinessBenifits")}></KeyboardArrowRightIcon></span>
                            </div>
                        </div>
                    </div>
                </Box>
                <Box className='body-forthsection' padding={'50px 150px'}>
                    <div className='forthsection-left'>
                        <div className='forthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join as a <span>Business</span></Typography>
                            <span className='heading-mainsub'>Lights, camera, action! It's time to roll out the red carpet and bring the glitz and glamour of Hollywood to your staffing needs. Join Snagashyft and let us be your star-studded solution for your business.</span>
                            <span className='heading-mainsub'>We know that finding great staff can be like searching for a needle in a haystack, but fear not - we've got your back. Our platform makes it easy to find top-notch servers, bartenders, cooks, and all the other hospitality hotshots you need to keep your business running smoothly.</span>
                            <span className='heading-mainsub'>Here's your VIP pass to success:</span>
                        </div>
                        <div className='forthleft-items'>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details' >
                                    Cast A-List Talent: Discover a lineup of top-notch professionals who will shine bright like diamonds in your business.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details' >
                                    Oscar-Worthy Efficiency: Say goodbye to time-consuming hiring processes. Our platform makes finding and hiring staff as effortless as a blockbuster hit.
                                </label>
                            </div>
                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details' >
                                    Flexibility Fit for a Star: Customize your workforce to match your needs. Whether it's a small cameo or a leading role, we've got the talent to make your production flawless.
                                </label>
                            </div>

                            <div className='forthsection-listitem'>
                                <img src={require('./greencheck.png')}></img>
                                <label className='contentinfo-details' >
                                    Paparazzi-Proof Support: Our dedicated support team will be your backstage crew, ensuring your experience is as smooth as a Hollywood smile.
                                </label>
                            </div>
                        </div>
                        <div className='forthsection-heading common-heading'>
                            <span className='heading-mainsub'>Don't just watch the action unfold - be part of it! Join our on-demand staffing platform today and let's create a hospitality experience that deserves a standing ovation.</span>
                            <span className='heading-mainsub'>Roll out the Red Carpet and Join the A-List Now!</span>
                        </div>
                        <span className='header-buttongrp btgrp-other'>
                            <span data-test-id="employer-signup" className='header-signup' onClick={() => this.props.onNavigate("EmployerSignUp")} data-testid="join2-test">Join Now</span>
                            <span className='header-login' onClick={() => this.props.goToContactUs()} data-testid="contact2-test">Contact Us</span>
                        </span>
                    </div>
                    <div className='forthsection-right'>
                        <img alt='' height={500} src={require('./iphone2.png')}></img>
                    </div>
                </Box>
                <Box className='body-fifthsection'>
                    <div className='fifthsection-left'>
                        <img alt='' src={require('./iphone3.png')}></img>
                    </div>
                    <div className='fifthsection-right'>
                        <div className='fifthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join as a <span>Job Seeker</span></Typography>
                            <span className='fifth-section-heading'>Hey, superstar! Ready to take your hospitality career to the next level? Welcome to our on-demand staffing platform, where your talent can shine brighter than a diamond.</span>
                            <span className='fifth-section-heading'>Here's why you'll love being part of our crew:</span>
                        </div>
                        <div className='fifthleft-items'>
                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Flexibility that puts Rihanna's dance moves to shame: Tired of rigid schedules? With us, you're the boss! Choose when and where you want to work, whether it's a trendy hotel downtown or a highly-sought out restaurant. Work hard, play hard, and still have time for those impromptu karaoke nights.
                                </label>
                            </div>
                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Say goodbye to those job-hunting headaches: No more stalking job boards or submitting resumes into the abyss. Our platform brings the jobs to you! Get notified about exciting opportunities that match your skills and experience. It's like having a personal agent who knows your dance routine.
                                </label>
                            </div>
                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Build your A-team network: We've got a community of rockstar professionals like you. Connect with fellow hospitality maestros, share stories, and even team up for gigs. It's the perfect chance to expand your social circle and find your partner-in-crime for those epic weekend adventures.
                                </label>
                            </div>

                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Get paid like the superstar you are: We believe in fair compensation for your exceptional skills. Earn top dollar for your expertise and hard work. Plus, with our streamlined payment system, you'll never have to chase down those elusive paychecks again. Cha-ching!
                                </label>
                            </div>

                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Access to exclusive perks: Ready for the red carpet treatment? Enjoy special perks like commuter benefits,, access to industry events, and professional development opportunities. We're all about making your career journey as glamorous as a Hollywood premiere.
                                </label>
                            </div>

                            <div className='fifthsection-listitem'>
                                <img src={require('./yellowcheck.png')}></img>
                                <label className='fifth-contentinfo-details' >
                                    Unleash your creative genius: You're not just a cog in the machine; you're an artist! Show off your talents, try new roles, and explore different areas of the hospitality industry. From mixologist to master chef, our platform lets you spread your wings and dazzle the crowd.
                                </label>
                            </div>
                        </div>
                        <div className='fifthsection-heading common-heading'>
                            <span className='fifth-section-heading' >So, ready to join the league of extraordinary hospitality legends? Step into the spotlight and let's rock the stage together. Your future is calling, and it's time to take center stage!</span>
                        </div>
                        <span className='header-buttongrp btgrp-other fifth-section-action-btns'>
                            <span className='header-signup body-reach' onClick={() => this.props.onNavigate("ShyfterSignUp")}>Join Now</span>
                            <span className='header-login body-join' onClick={() => this.props.goToContactUs()}>Contact Us</span>
                        </span>

                    </div>
                </Box>
                <Box className='body-sixthsection imageSection'>

                    <div className='sixthsection-heading common-heading'>

                        <Typography variant='h5' className='heading-main'><span>Download </span>the Application</Typography>

                        <span className='heading-mainsub'>
                            Get the free Mobile App of <strong>Snagashyft</strong> from Play Store or
                            The App Store to get the jobs or manage the services of your Hotels
                            & Restaurants easy.
                        </span>
                    </div>
                    <div className='downloadsection-content'>

                        <div className='download-playstore download-section'>
                            <span className='heading-image left-image'>
                                <img src={require('./assets/arrowleft.png')} alt="" />
                            </span>
                            <label className='dowloadforandroid'>
                                Download for Android
                            </label>
                            <span className='download-icon download'
                                onClick={() => { window.open("https://play.google.com/store/apps?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Dec1721&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-ap-Evergreen-Dec1721-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700058906730295_creativeid_480915691141_device_c&gclid=CjwKCAjwt52mBhB5EiwA05YKo1erl8AwBa9aWdXWaW39_x4ATuF-WnOtjSXEXC_80-d7VE-bw21M7xoCaY8QAvD_BwE&gclsrc=aw.ds&pli=1", "_blank") }}>
                                <GetAppOutlinedIcon style={webStyle.downloadIcon} />
                            </span>
                            <span className='download-playstorebutton storebutton'
                                onClick={() => { window.open("https://play.google.com/store/apps?utm_source=apac_med&hl=en-IN&utm_medium=hasem&utm_content=Dec1721&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-ap-Evergreen-Dec1721-Text_Search_BKWS-BKWS%7CONSEM_kwid_43700058906730295_creativeid_480915691141_device_c&gclid=CjwKCAjwt52mBhB5EiwA05YKo1erl8AwBa9aWdXWaW39_x4ATuF-WnOtjSXEXC_80-d7VE-bw21M7xoCaY8QAvD_BwE&gclsrc=aw.ds&pli=1", "_blank") }}>
                                <img alt="" src={require('./assets/playstore.png')}></img>
                            </span>
                        </div>
                        <div className='download-iphone '>
                            <img src={require('./assets/iphonehalf.png')} onClick={() => window.open("https://www.apple.com/in/app-store/", "_blank")}></img>
                        </div>
                        <div className='download-apple download-section'>
                            <span className='heading-image right-image'>
                                <img src={require('./assets/arrowright.png')} alt="" />
                            </span>
                            <label className='dowloadforandroid'>
                                Download for IOS
                            </label>
                            <span className='download-icon download' onClick={() => window.open("https://www.apple.com/in/app-store/", "_blank")}>
                                <GetAppOutlinedIcon style={webStyle.downloadIcon} />
                            </span>
                            <span className='download-playstorebutton storebutton'>
                                <img src={require('./assets/applestore.png')}></img>
                            </span>
                        </div>
                    </div>
                </Box>
                <GenericFooter onAboutClick={this.onAboutRedirection} dataTestId="footer-testing" navigation={this.props.navigation} />
            </>
        )
    }
}
// Customizable Area End