import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkAuthorization, getSelectedSubscrptionId, getYearArray } from "../../../components/src/Utilities";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes?: any;
}
interface S {
  subscribeNowDialog: boolean;
  addCardDialog: boolean;
  transitionSuccessDialog: boolean;
  month: string;
  monthSelect: boolean;
  year: string;
  yearSelect: boolean;
  userData: any;
  allSubscriptions: any;
  selectedSubs: any;
  cardNumber: any;
  cardHolder: any;
  cvv: any;
  expMonth: any;
  expYear: any;
  years: number[];
  months: number[];
  isEmployer: any;
  upgradedPlan: any;
  cardData: any;
  selectedSubId: any;
  upgradeModal: boolean;
  cardsData: any[];
  cardNumbererror: boolean;
  cardCvverror: boolean;
  errorMsg: string;
  nameErrorMsg: string;
  cvvErrorMsg: string;
  cardNamererror: boolean;
  yearError: boolean;
  monthError: boolean;
}

interface SS {
  id: any;
}

export default class SubsCheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllInsurersListId: any;
  getSelectedSubsApiCallId: string = "";
  getAllSubsApiCallId: string = "";
  buySubsApiCallId: string = "";
  getUpgratedSubsApiCallId: string = "";
  UpgradeSubsApiCallId: string = "";
  archiveInsurerId: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationSubsCheckoutMessage),
    ];
    this.state = {
      allSubscriptions: "",
      subscribeNowDialog: false,
      addCardDialog: false,
      transitionSuccessDialog: false,
      month: "",
      monthSelect: false,
      year: "",
      yearSelect: false,
      userData: "",
      selectedSubs: "",
      cardNumber: "",
      cardHolder: "",
      cvv: "",
      expMonth: "",
      expYear: "",
      isEmployer: "",
      upgradedPlan: "",
      cardData: "",
      selectedSubId: "",
      upgradeModal: false,
      cardsData: [],
      errorMsg: "",
      nameErrorMsg: "",
      cvvErrorMsg: "",
      cardNamererror: false,
      cardNumbererror: false,
      cardCvverror: false,
      monthError: true,
      yearError: true,
      years: [24, 25, 26, 27, 28, 29, 30, 31, 32],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    this.setState({
      ...this.state,
      years: getYearArray()
    })

    const isAuthorizedEmployerUser = checkAuthorization(true, false);
    if (!isAuthorizedEmployerUser) {
      this.moveToLoginPage();
      return;
    }
    const subsId = getSelectedSubscrptionId();
    this.getSelectedSubs(subsId && subsId.id);
    this.getUpgratedtedSubs(subsId?.id);
    this.getAllSubscription();

    const userData = localStorage.getItem("userData");
    if (!!userData) {
      this.setState({
        isEmployer: JSON.parse(userData).user.role_id,
      });
    }
  };

  moveToLoginPage = () => {
    const checkOutMsg = new Message(getName(MessageEnum.NavigationMessage));
    checkOutMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    checkOutMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(checkOutMsg);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.moveToLoginPage();
        return;
      } else {
        this.handleApiResponse(message);
      }
    }
    if (getName(MessageEnum.NavigationSubsCheckoutMessage) === message.id) {
      const subsPlanData = message.getData(
        getName(MessageEnum.NavigationSubsCheckoutMessage)
      );
      const subsId = getSelectedSubscrptionId();
      if (subsPlanData) {
        let id = subsPlanData?.planId?.id;
        this.getUpgratedtedSubs(id);
      } else if (!!subsId) {
        this.getSelectedSubs(subsId.id);
      }
    }
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getSelectedSubsApiCallId) {
      this.setState({
        selectedSubs: responseJson.subscription,
      });
    }

    if (apiRequestCallId === this.getAllSubsApiCallId) {
      this.setState({
        allSubscriptions: responseJson.subscriptions,
      });
    }
    if (apiRequestCallId === this.buySubsApiCallId) {
      if (responseJson.data) {
        this.setState({ transitionSuccessDialog: true });
      }
    }

    if (apiRequestCallId === this.UpgradeSubsApiCallId) {
      if (responseJson.data) {
        this.setState({ transitionSuccessDialog: true });
      }
    }

    if (apiRequestCallId === this.getUpgratedSubsApiCallId) {
      this.setState({
        selectedSubId: responseJson.data.attributes.subscription.data.attributes.name,
        selectedSubs:
          responseJson.data.attributes?.subscription?.data?.attributes,
        upgradedPlan: responseJson?.meta?.upgrade_to,
      });
      if (responseJson.data.attributes.card_number) {
        let expDate = responseJson.data.attributes.expiry_date?.split("/");
        this.setState({
          cardNumber: responseJson.data.attributes.card_number,
          year: !!expDate && expDate[1],
          month: !!expDate && expDate[0],
          cvv: responseJson.data.attributes.cvv,
          cardHolder: "Akash",
        });
        let cardInfo = {
          name: "Test user",
          cardNumber: responseJson.data.attributes.card_number,
          year: !!expDate && expDate[1],
          month: !!expDate && expDate[0],
          cvv: responseJson.data.attributes.cvv,
        };
        this.setState((prevState) => ({
          cardsData: [...prevState.cardsData, cardInfo],
        }));
      }
    }
  }

  upgradeSubscription = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CurrentSubscription"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationYourSubsCriptionMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationYourSubsCriptionMessage),
      "Your Subscription"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  backToHome = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmployerHomePage"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationToEmployerLandingPageMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationToEmployerLandingPageMessage),
      "/LandingPage"
    );
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  };

  handleAddCardDialog = () => {
    this.setState({ 
      addCardDialog: !this.state.addCardDialog,
      cardNumber: "",
      cardHolder: "",
      cvv: "",
      month: "",
      year: "",
      nameErrorMsg: "",
      cvvErrorMsg: "",
      cardNamererror: false,
      cardNumbererror: false,
      cardCvverror: false,
      monthError: true,
      yearError: true,
    });
  };

  getSubsType = (planType: any) => {
    if (planType?.includes("Basic")) {
      return "basic";
    } 
    if (planType?.includes("Standard")) {
      return "standard";
    } else {
      return "gold";
    }
  };

  handleTransitionSuccessDialog = () => {
    this.setState({
      transitionSuccessDialog: !this.state.transitionSuccessDialog,
    });
  };

  handleSubscribeNowBtn = () => {
    this.setState({ subscribeNowDialog: !this.state.subscribeNowDialog });
  };

  handleCardNumber = (e: any) => {
    let cardNumber = e.target.value.replace(/\D/g, "");
    if (cardNumber.length === 16) {
      this.setState({ errorMsg: "", cardNumbererror: false });
    } else {
      this.setState({
        errorMsg: "Card number must be a 16-digit numeric value",
        cardNumbererror: true,
      });
    }
    this.setState({ cardNumber: cardNumber });
  };

  handleCardName = (e: any) => {
    let cardName = e.target.value.replace(/[^A-Za-z\s]/g, "");
    if (cardName.length >= 5) {
      this.setState({ nameErrorMsg: "", cardNamererror: false });
    } else {
      this.setState({
        nameErrorMsg: "Please use only letters and spaces in between 5-20.",
        cardNamererror: true,
      });
    }
    this.setState({ cardHolder: cardName });
  };
  handleChangeMonth = (e: any) => {
    this.setState({
      month: e.target.value,
      monthSelect: false,
      monthError: !!e.target.value,
    });
  };

  handleChangeYear = (e: any) => {
    this.setState({
      year: e.target.value,
      yearSelect: false,
      yearError: !!e.target.value,
    });
  };
  handleChangeCVV = (e: any) => {
    let cvv = e.target.value.replace(/\D/g, "");
    if (cvv.length === 3) {
      this.setState({ cvvErrorMsg: "", cardCvverror: false });
    } else {
      this.setState({ cvvErrorMsg: "CVV must be 3-digit", cardCvverror: true });
    }
    this.setState({ cvv: cvv });
  };

  handleChangeSubscription = (subs: any) => {
    this.setState({
      subscribeNowDialog: false,
      upgradeModal: false,
    });
    if (!!this.state.upgradedPlan) {
      this.getUpgratedtedSubs(subs ? subs.id : 1);
    } else {
      this.getSelectedSubs(subs ? subs.id : 1);
    }
  };

  handleMonthClose = () => {
    this.setState({ monthSelect: false });
  };

  handleMonthOpen = () => {
    this.setState({ monthSelect: true });
  };

  handleYearClose = () => {
    this.setState({ yearSelect: false });
  };

  handleYearOpen = () => {
    this.setState({ yearSelect: true });
  };

  addCardProceed = () => {
    this.setState({ cardsData: [] });
    let cardInfo = {
      name: this.state.cardHolder,
      cardNumber: this.state.cardNumber,
      year: this.state.year,
      month: this.state.month,
      cvv: this.state.cvv,
    };
    this.setState((prevState) => ({
      cardsData: [...prevState.cardsData, cardInfo],
    }));
    this.setState({ addCardDialog: false });
  };

  handleEditUpgradeModal = () => {
    this.setState({
      upgradeModal: !this.state.upgradeModal,
    });
  };

  upgradePlan = (upgradedPlan: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UpgradeSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upgradeUserSubsEndpont
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );
    let expDate = this.state.month + "/" + this.state.year;
    const reqbody = JSON.stringify({
      subscription: {
        subscription_id: upgradedPlan.id,
        cvv: this.state.cvv,
        card_number: this.state.cardNumber,
        expiry_date: expDate,
      },
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqbody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  buyPlan = (subsPlan: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.buySubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.buySubscriptionEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPostMethodType
    );
    let expDate = this.state.month + "/" + this.state.year;
    const reqBody = JSON.stringify({
      subscription: {
        subscription_id: subsPlan.id,
        card_number: this.state.cardNumber,
        cvv: this.state.cvv,
        expiry_date: expDate,
      },
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      reqBody
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSelectedSubs(SubsId: any) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSelectedSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSelectedSubsEndpoint + `/${SubsId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllSubscription() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSubsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUpgratedtedSubs(SubsId: any) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUpgratedSubsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUpgradedSubsEndpont + `?upgrade_to_id=${SubsId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handlevalidation = () => {
    const { cardCvverror, cardNamererror, cardNumbererror } = this.state;
    if (cardCvverror || cardNamererror || cardNumbererror) {
      return true;
    } else {
      return false;
    }
  };
  handleDatavalidation = () => {
    const { cardNumber, cardHolder, cvv, year, month } = this.state;
    if (!!cardNumber && !!cardHolder && !!cvv && !!year && !!month) {
      return false;
    } else {
      return true;
    }
  };
}
