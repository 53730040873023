export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const dbaIcon=require('../assets/building.png')
export const calendar=require('../assets/calendar.png')
export const checkmark=require('../assets/checkmark.png')
export const dlIcon=require('../assets/id-badge.png')
export const userIcon=require('../assets/user.png')
export const globleIcon=require('../assets/globeIcon.png')
export const emailIcon=require('../assets/envelope.png')
export const hastagIcon=require('../assets/hastagIcon.png')
export const phoneIcon=require('../assets/phone-flip.png')
export const marker=require('../assets/marker.png')
export const hotelIcon=require('../assets/hotel.png')
export const restaurantIcon=require('../assets/restaurant.png')