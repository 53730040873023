import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { camera } from "./assets";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
    photo: {
      width: "18px",
    },
    icon:{
      color:"#fff",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
  })
);

export default function UploadPicButtons({ handleFileChange }: any) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <input
        onChange={(e: any) => handleFileChange(e)}
        accept="image/*"
        className={classes.input}
        id="icon-button-file"
        type="file"
        data-test-id="updateProfilePic"
      />
      <label htmlFor="icon-button-file">
        <IconButton
          className={classes.icon}
          aria-label="upload picture"
          component="span"
        >
          <img src={camera} alt="camera" />
        </IconButton>
      </label>
    </div>
  );
}
