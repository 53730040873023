import React from "react";
import { Box, makeStyles, IconButton, Dialog, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  handleCancle?: (e: any) => void;
  handleAction?: (e: any) => void;
  isAccDelete?: boolean;
}

const WarningPopup: React.FC<ISuccessPopupDialogProps> = ({
  open,
  handleCancle,
  handleAction,
  isAccDelete,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={handleCancle}>
        <CloseIcon height={20} width={20} />
      </IconButton>

      {isAccDelete && (
        <>
          <Box justifyContent="center" display="flex">
            <img src={require("./assets/images/close.png")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Delete Account!</Box>
          <Box className="description">
          Are you sure want to delete your account? This action cannot be undone and you won't be able to continue working on the plateform
          </Box>
        </>
      )}
      <Grid container className="actionWrapper" spacing={2}>
        <Grid item xs={6}>
          <CustomButton
            label="Cancel"
            isOutlined={true}
            handleClick={handleCancle}
          />
        </Grid>
        <Grid item xs={6}>
          <CustomButton
            isDeleteBtn={true}
            isDanger={true}
            isOutlined={true}
            label={!!isAccDelete ? "Delete Now" : "Logout"}
            handleClick={handleAction}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default WarningPopup;
