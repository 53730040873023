// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { BreadCrumType, breadCrumPathData, breadCrumShyfterPathData, checkAuthorization, getUserRole } from "../../../components/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
    userRoleText: string;
    policyForTitle: string;
    privacyPolicyApiResponseData: any;
    policyBreadCrumPath: BreadCrumType[];
    currentPage: string;
    loading: boolean;
    policyApiError: string
}

interface SS {
  id: any;
}

export default class PrivacyPolicyController extends BlockComponent<
  Props,
  S,
  SS
> {
  privacyPolicyApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
          userRoleText: configJSON.businessRole,
          privacyPolicyApiResponseData: {},
          policyForTitle: configJSON.businessTileText,
          policyBreadCrumPath: breadCrumPathData,
          currentPage: 'Privacy Policy',
          loading: false,
          policyApiError: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount() {
    const isAuthorizedUser = checkAuthorization(true, true);
    if (!isAuthorizedUser) {
      this.navigateToLogin();
      return;
    }
    const getRole = getUserRole();

    if(getRole !== "1") {
      this.setState({ userRoleText: configJSON.shyfterRole, policyForTitle: configJSON.snagShyftTitleText, policyBreadCrumPath: breadCrumShyfterPathData });
      this.getPrivacyPolicyApiCall(configJSON.shyfterRole);
    } else{
      this.getPrivacyPolicyApiCall(this.state.userRoleText);
    }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
          this.navigateToLogin();
          return;
        } else if (apiRequestCallId === this.privacyPolicyApiCallId) {
            if(responseJson && responseJson.data) {
              this.setState({ privacyPolicyApiResponseData: responseJson.data, loading: true })
            } 
            if(responseJson.error) {
              this.setState({  policyApiError: responseJson.error })
            }
          }
    }
  }

  navigateToLogin = () => {
    const privacyPolicyMsg = new Message(getName(MessageEnum.NavigationMessage));
    privacyPolicyMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    privacyPolicyMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(privacyPolicyMsg);
  }

  getPrivacyPolicyApiCall = (userRoleText: string) => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: localStorage.getItem('authToken'),
        };
    
        const policyRequestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.privacyPolicyApiCallId = policyRequestMessage.messageId;
    
        policyRequestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.privacyPolicyEndPoint}${userRoleText}`
        );

        policyRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        
        policyRequestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        
        runEngine.sendMessage(policyRequestMessage.id, policyRequestMessage);
        return true;
  }


  // Customizable Area End
}
