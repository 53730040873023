import React from "react";
import { Box, Checkbox, Typography, makeStyles } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import parser from "html-react-parser";
import { hotelDefault } from "./assets";

const useStyle = makeStyles({
  chatCardWrapper: {
    border: "1px solid #E1E6EC",
    borderRadius: "14px",
    marginBottom: "15px",
    marginRight: "5px",
    padding: "10px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(57, 8, 121,0.1)",
    },
  },

  selected: {
    backgroundColor: "rgb(57, 8, 121,0.1)",
  },

  unSelected: {
    backgroundColor: "#FFFFFF",
  },

  headerDetails: {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
  },

  personalInfo: {
    gap: "8px",
    display: "flex",
  },

  chatImgWrapper: {
    "& img": {
      width: "46px",
      borderRadius: "12px",
      height: "46px",
    },
  },

  messangerDetail: {
    "& > div": {
      display: "flex",
      alignItems: "center",
      gap: "5px",
    },
    "& h3": {
      margin: "0px",
    },
    "& p": {
      margin: "0px",
    },
    "& svg": {
      fontSize: "15px",
    },
  },

  timeBox: {
    flexDirection: "column",
    gap: "5px",
    fontWeight: 800,
    display: "flex",
    justifyContent: "center",
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    overflow:'hidden',

    "& span": {
      fontSize: "11px",

      lineHeight: "16px",
      color: "#6F7C8E",
      letterSpacing: "0",
      textAlign: "right",
    },
    "& .MuiChip-root": {
      background: "#F7B500",
      borderRadius: "12px",
      color: "#FFFFFF",
      fontFamily: "Gotham-Bold",
      height: "27px",
      fontSize: "11px",

      textAlign: "right",
      letterSpacing: "0.2px",
      lineHeight: "18px",

      "& .MuiChip-avatar": {
        height: "12px",
        width: "11px",
        marginRight: "-8px",

        marginLeft: "8px",
      },
      "& span": {
        color: "#FFFFFF",
      },
    },
  },

  ratingTime: {
    fontSize: "11px",
    color: "#6f7c8e",
    fontFamily: "Gotham, sans-serif",
    fontWeight: 500,

    "@media(max-width:375px)": {
      fontSize: "9px",
    },
  },

  messangerName: {
    fontWeight: 500,
    fontFamily: "Gotham, sans-serif",
    fontSize: "16px",
    lineHeight: "25px",
    color: "#0a0412",
    marginRight:'6px',
    maxWidth:'92px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    '&:hover':{
      maxWidth:'none',
    },
    "@media(max-width:375px)": {
      fontSize: "11px",
    },
  },
  messangerTitle: {
    color: "#6F7C8E",
    fontWeight: 400,
    fontFamily: "Gotham, sans-serif",
    fontSize: "14px",
    maxWidth:'150px',
    overflow:'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    '&:hover':{
      maxWidth:'none',
    },
  },
  lastMessage: {
    fontSize: "13px",
    lineHeight: "15px",
    color: "#6f7c8e",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "220px"
  },
  lastUnreadMessage: {
    fontSize: "13px",
    lineHeight: "15px",
    color: "#390879",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "220px"
  },
  unreadCount: {
    fontSize: "10px",
    lineHeight: "15px",
    color: "#0a0412",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    backgroundColor: "#b9df12",
    width: "19px",
    height: "19px",
    borderRadius: "10px",
  }
});

interface ChatCardProps {
  jobTitle: string;
  senderName: string;
  time: string;
  imgUrl: string;
  lastChat: any;
  isActionNeeded?: boolean;
  chatId: number;
  activeId: number;
  unreadCount: number;
  selectedItem: (e: any, chatId: string) => void;
  handleRenderChat: (chatId: number) => void;
}
const ChatCard = (props: ChatCardProps) => {
  const classes = useStyle();

  const getLastMessage = () => {
    let msg = "";
    if (props.lastChat?.attachments && props.lastChat?.attachments.length > 0) {
      msg = props.lastChat.attachments[0]?.file_name;
    } else if (props.lastChat.message) {
      msg = props.lastChat.message;
    }
    return msg;
  }

  return (
    <Box display={"flex"} justifyContent={"space-between"}>
      <Box
        data-test-id="startChatBtn"
        sx={{ width: props.isActionNeeded ? "90%" : "100%" }}
        className={
          props.chatId === props.activeId
            ? `${classes.selected} ${classes.chatCardWrapper}`
            : `${classes.unSelected} ${classes.chatCardWrapper}`
        }
        onClick={() => props.handleRenderChat(props.chatId)}
      >
        <Box className={classes.headerDetails}>
          <Box className={classes.personalInfo} width="100%">
            <Box className={classes.chatImgWrapper} >
              <img src={props.imgUrl || hotelDefault} alt="profile image" />
            </Box>
            <Box className={classes.messangerDetail} width="100%">
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                <Typography className={classes.messangerName} variant="h6">
                {/* {props.senderName.length < 13 ? props.senderName : `${props.senderName?.slice(0, 13)}...`} */}
                {props.senderName}
                </Typography>
                <Typography className={classes.messangerTitle} variant="h6">
                  | {props.jobTitle}
                </Typography>
                </Box>
                <Box className={classes.timeBox}>
                  <Typography className={classes.ratingTime}>{props.time}</Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                {props.lastChat && <Box className={props.unreadCount > 0 ? classes.lastUnreadMessage: classes.lastMessage}>{parser(getLastMessage())}</Box>}
                {props.unreadCount > 0 && <Box display="flex" alignItems="center" justifyContent="center" className={classes.unreadCount}>{props.unreadCount}</Box>}
              </Box>
            </Box>
          </Box>
          
        </Box>
      </Box>
      {props.isActionNeeded && (
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleIcon />}
          style={{ color: "#390879", marginBottom: "10px" }}
          key={props.chatId}
          value={props.chatId}
          onChange={(event) => props.selectedItem(event, String(props.chatId))}
          data-test-id="onclickRadio"
        />
      )}
    </Box>
  );
};

export default ChatCard;
