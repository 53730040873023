import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";

import {
  ThemeProvider,
  createTheme,
  makeStyles,
  createStyles,
  StyleRules,
} from "@material-ui/core/styles";
import CustomButton from "./CustomButton.web";
import { capitalizeWords } from "./Utilities";
const Briefcase = require("./assets/images/briefcase-blank.png");
const PlaceIcon = require("./assets/images/placeIcon.png");
const billingIcon = require("./assets/images/money-bills-simple.png");
const checkmark = require("./assets/verified.png");
const clockIcon = require("./assets/images/clock3.png");
const experienceIcon = require("./assets/images/experience.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "14px",
    backgroundColor: "#fff",
  },
  cardContent: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "688px",
    padding: "10px",
  },
  cardFooter: {
    borderTop: "1px solid #e1e6ec",
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "10px 0",
    backgroundColor: "#fff",
  },
  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "178px",
    height: "87px",
    margin: "10px",
    borderRadius: "10px",
  },
  cardHeading: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#0a0412",
    padding:"5px 0px"
  },
  cardSubHeading: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#000",
  },
  cardActiveHeading: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#390879",
  },
  cardInactiveSubHeading: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
  },
  amountAndTimingLabel: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
    padding: "5px 0px"
  },
  amountAndTimingValue: {
    fontSize: "13px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#0a0412",
  },
  cardBodyText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#0a0412",
  },
  descriptionText: {
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "18px",
    color: "#0a0412",
  },
  experienceHeading: {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#0a0412",
  },
  experienceText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",
    color: "#390879",
  }
}));

export const getExperienceText = (exp: any) => {
  if (exp) {
    return parseInt(exp) > 1 ? `${parseInt(exp)} Yrs` : `${parseInt(exp)} Yr`;
  }
  return "";
};

function getProperties(styles: StyleRules): any {
  const properties: any = {};

  for (const [key, value] of Object.entries(styles)) {
    properties[key] = value;
  }

  return properties;
}

const styles = createStyles({
  "::-webkit-scrollbar": {
    width: "0.4em",
  },
  "::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
});
const properties = getProperties(styles);
interface IShyftDetailCardWithoutTabsProps {
  handleActionButtonClick: () => void;
  shyftType?: string;
  shyftTitle?: string;
  address?: string;
  amount?: string;
  amountPer?: string;
  shyftTimeFrom?: string;
  shyftTimeTo?: string;
  experience?: string;
  shyftRequirements?: string[];
  shyftDescription?: string;
  actionBtnTitle?: string;
  isActionDisabled?: boolean;
}

const ShyftDetailCardWithoutTabs = ({
  shyftDescription,
  shyftRequirements,
  shyftTimeFrom,
  shyftTimeTo,
  shyftTitle,
  shyftType,
  experience,
  actionBtnTitle,
  address,
  amount,
  amountPer,
  handleActionButtonClick,
  isActionDisabled
}: IShyftDetailCardWithoutTabsProps) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box className={`${classes.card}`} sx={properties}>
        <Box className={`${classes.cardContent}`}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            padding="10px"
          >
            <Box
              height={"24px"}
              width={"24px"}
              bgcolor={"rgba(57, 8, 121, 0.05)"}
              borderRadius="10px"
              padding="10px"
              margin="10px"
            >
              <img src={Briefcase} alt="" height="24px" width="24px" />
            </Box>
            <Typography className={classes.cardActiveHeading}>{shyftType}</Typography>
            <Typography className={classes.cardHeading}>{capitalizeWords(shyftTitle)}</Typography>
            {address && <Box display="flex" justifyContent="center" alignItems="center">
              <Box marginRight={"5px"}>
                <img src={PlaceIcon} alt="" height="15px" />
              </Box>
              <Typography className={classes.cardInactiveSubHeading}>
                {address}
              </Typography>
            </Box>}
          </Box>
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
          >
            <Box className={classes.boxes} bgcolor={"#2da4060d"}>
              <img src={billingIcon} alt="" height="22px" />
              <Typography className={classes.amountAndTimingLabel}>
                Amount
              </Typography>
              <Typography className={classes.amountAndTimingValue}>
                ${amount} Per {amountPer}
              </Typography>
            </Box>
            <Box className={classes.boxes} bgcolor={"#f7b5000d"}>
              <img src={clockIcon} alt="" height="22px" />
              <Typography className={classes.amountAndTimingLabel}>
                Shyft Timing
              </Typography>
              <Typography className={classes.amountAndTimingValue}>
                {shyftTimeFrom} - {shyftTimeTo}
              </Typography>
            </Box>
          </Box>
          <Box padding="0 10px">
            <div className={classes.root}>
              <Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Divider style={{ width: "31%" }} />
                  <Box margin={"3px 0"}>
                    <Typography className={classes.amountAndTimingValue}>
                      Shyft Overview
                    </Typography>
                  </Box>
                  <Divider style={{ width: "31%" }} />
                </Box>
                <Box margin={"10px 0px"}>
                  <Typography className={classes.experienceHeading}>
                    Experience Required
                  </Typography>
                </Box>

                <Box
                  border="1px solid #e1e6ec"
                  borderRadius="14px"
                  padding="14px"
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <img
                    src={experienceIcon}
                    alt=""
                    height="22px"
                    style={{ marginRight: "10px" }}
                  />
                  <Typography className={classes.experienceText}>
                    {getExperienceText(experience)}
                  </Typography>
                </Box>
                <Box margin={"10px 0px"}>
                  <Typography className={classes.experienceHeading}>
                    Shyft Requirements
                  </Typography>
                </Box>
                <Box
                  border="1px solid #e1e6ec"
                  width="100%"
                  borderRadius="14px"
                >
                  <Grid container style={{ padding: "10px" }}>
                    {shyftRequirements?.map((item: string) => (
                      <Grid item xs={6} key={item+"- shyft-requirement"}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-start"
                        padding="3px"
                      >
                        <img
                          src={checkmark}
                          alt=""
                          height="13px"
                          style={{ marginRight: "5px" }}
                        />
                        <Typography className={classes.cardBodyText}>
                          {item}
                        </Typography>
                      </Box>
                    </Grid>
                    ))}
                  </Grid>
                </Box>
                <Box margin={"10px 0px"}>
                  <Typography className={classes.cardSubHeading}>
                    Shyft Description
                  </Typography>
                </Box>
                <Box
                  border="1px solid #e1e6ec"
                  borderRadius="14px"
                  padding={"10px"}
                >
                  <Typography className={classes.descriptionText}>
                    {shyftDescription}
                  </Typography>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
        <Box className={classes.cardFooter}>
          <Box borderRadius="50px" textAlign="center" margin="10px">
            <CustomButton
              label={actionBtnTitle}
              handleClick={handleActionButtonClick}
              disabled={isActionDisabled}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ShyftDetailCardWithoutTabs;
