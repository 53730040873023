import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import CustomButton from "./CustomButton.web";

const useStyles = makeStyles({
  mainWrapperSubscriptionFlow: {
    width: "100%",
    height: "95vh",
    border: "1px solid #ddd",
    borderRadius: "14px",
    backgroundColor: "#fff",
    color: "",
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 70px 0px",
    justifyContent: "center",
  },
  contentWrapSubscriptionFlow: {
    width: "75%",
    margin: "auto",
    textAlign: "center",
  },
  heading: {
    color: "#0a0412",
    fontSize: "20px",
    lineHeight: "26px",
    textAlign: "center",
    margin: 0,
    fontWeight: 400,
    marginTop: "20px",
  },
  text: {
    color: "#6f7c8e",
    fontSize: "12px",
    lineHeight: "17px",
    textAlign: "center",
    margin: "10px auto 20px",
    width: "90%",
  },
  textCenterSubscriptionFlow: {
    textAlign: "center",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "12px",
    maxWidth: "82%",
    margin: "0px auto",
  },
});

interface MyProps {
  imgUrl: string;
  heading: string;
  paragraph: string;
  primaryBtnText?: string;
  primaryBtnClick?: () => void;
  secondaryBtnText?: string;
  secondaryBtnClick?: () => void;
}

const NoDataFound = ({
  heading,
  paragraph,
  imgUrl,
  primaryBtnClick,
  primaryBtnText,
  secondaryBtnClick,
  secondaryBtnText,
}: MyProps) => {
  const classes = useStyles();

  return (
    <div className={classes.mainWrapperSubscriptionFlow}>
      <Box>
        <Box className={classes.textCenterSubscriptionFlow}>
          <img src={imgUrl} alt="" width={60} />
        </Box>
        <Box className={classes.contentWrapSubscriptionFlow}>
          <h2 className={classes.heading}>{heading}</h2>
          <p className={classes.text}>{paragraph}</p>
        </Box>
        <Box className={classes.buttons}>
          {primaryBtnText && (
            <CustomButton
              label={primaryBtnText}
              handleClick={primaryBtnClick}
            />
          )}
          {secondaryBtnText && (
            <CustomButton
              isOutlined
              label={secondaryBtnText}
              handleClick={secondaryBtnClick}
            />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default NoDataFound;
