// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  tabValue: number;
}

export interface S {
    formData: {
        businessName?: string;
        firstName: string;
        lastName: string;
        email: string;
        mobileNumber: string;
        zipCode: string;
        city: string;
        state: string;
        skills: [];
        comments: string;
      };
      businessFormDataErrors: {
        businessName?: string;
        firstName: string;
        lastName: string;
        email: string;
        mobileNumber: string;
        zipCode: string;
        city: string;
        state: string;
        skills: string;
        comments: string;
      };
      shyfterFormDataErrors: {
        firstName: string;
        lastName: string;
        email: string;
        mobileNumber: string;
        zipCode: string;
        city: string;
        state: string;
        skills: string;
        comments: string;
      }
      isSubmitClicked: boolean;
      openPopup: boolean;
      contactApiError: boolean; 
      isSuccess: boolean;
      countryCodeValue: string;
}

export interface SS {
  id: any;
}

export default class ShyfterContactUsFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  contactUsApiCallId: string = "";
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.receive = this.receive.bind(this);
    this.handleShyfterDelete =  this.handleShyfterDelete.bind(this);

    this.state = {
        formData: {
            businessName: "" ,
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            zipCode: "",
            city: "",
            state: "",
            skills: [],
            comments: '',
        },
        businessFormDataErrors: {
            businessName: "",
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            zipCode: "",
            city: "",
            state: "",
            skills: "",
            comments: '',
        },
        shyfterFormDataErrors: {
            firstName: "",
            lastName: "",
            email: "",
            mobileNumber: "",
            zipCode: "",
            city: "",
            state: "",
            skills: "",
            comments: '',
        },
        isSubmitClicked: false,
        openPopup: false,
        contactApiError: false, 
        isSuccess: false,
        countryCodeValue: "91"
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
  }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
    
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            runEngine.debugLog("API Message Recived", message);
             
            if (!apiRequestCallId || !responseJson) {
                console.log("Error in API Message Recived- Contacus");
                return;
            }

            if (apiRequestCallId === this.contactUsApiCallId) {
                if(responseJson.errors){
                    this.setState({ contactApiError: true, isSuccess: false })
                }
                if(responseJson &&  !responseJson.errors) {
                    this.setState({
                        businessFormDataErrors: this.initialBusinessErrorObject(),
                        shyfterFormDataErrors: this.initialShyfterErrorObject(),
                        formData: {
                            businessName: "" ,
                            firstName: "",
                            lastName: "",
                            email: "",
                            mobileNumber: "",
                            zipCode: "",
                            city: "",
                            state: "",
                            skills: [],
                            comments: '',
                        },
                        contactApiError: false, 
                        isSuccess: true
                    })
                }
                this.setState({ openPopup: true })
            }
        }
    }

    handlePopUpModalClose = () => {
        this.setState({ openPopup: false, isSuccess: false, contactApiError: false })
    }

    initialBusinessErrorObject = () => {
        const errObject = {
                businessName: "",
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                zipCode: "",
                city: "",
                state: "",
                skills: "",
                comments: '',
        }
        return errObject
    }

    initialShyfterErrorObject = () => {
        const errObject = {
                firstName: "",
                lastName: "",
                email: "",
                mobileNumber: "",
                zipCode: "",
                city: "",
                state: "",
                skills: "",
                comments: '',
        }
        return errObject
    }


    contactUsApiCall = () => {
        const { formData } = this.state;
        const header = {
            "Content-Type": configJSON.contactUsApiContentType,
        };

        let body = {
            help_us: {
                business_name: this.props.tabValue === 0 ? formData.businessName: "", 
                full_name: formData.firstName + " " + formData.lastName, 
                email: formData.email, 
                phone_number: this.state.countryCodeValue + formData.mobileNumber,
                state: formData.state, 
                city: formData.city,  
                zipcode: formData.zipCode, 
                hiring_or_experience: formData.skills, 
                message: formData.comments.trim(),
                user_type: this.props.tabValue === 0 ? "business" : "shyfter",
            }
        };
    
         
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.contactUsApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.contactUsApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
        );
        
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    handleShyfterDelete = (event: any, value?: string) => {
        event.preventDefault();
        const selectedValues: any = this.state.formData.skills.filter((item) => item !== value);
        this.setState((prevState) => (
            {
                formData: {
                    ...prevState.formData,
                    skills: selectedValues,
                },
            })
        )
    }

    handleShyfterInputChange = (event: any, name: string) => {
        const { value } = event.target;
        let newValue = value;
        switch (name) {
        case "businessName": 
            const tempBusinessValue = newValue.replace(/[^a-zA-Z\s]/g, '');
            newValue = tempBusinessValue.slice(0, 30);
            break;
        case "firstName": 
            const tempFirstValue = newValue.replace(/[^a-zA-Z\s]/g, '');
            newValue = tempFirstValue.slice(0, 15).trim();
            break;
        case "lastName": 
            const tempLastValue = newValue.replace(/[^a-zA-Z\s]/g, '');
            newValue = tempLastValue.slice(0, 15).trim();
            break;
        case "mobileNumber": 
            const numberValue = newValue.toString();
            const sanitizedValue = numberValue.replace(/[+e-]/g, '').replace(/\D/g, '');
            newValue = sanitizedValue.slice(0, 10);
            break;
        case "zipCode": 
            const zipCodeValue = newValue.toString();
            const sanitizedCodeValue = zipCodeValue.replace(/[+e-]/g, '').replace(/\D/g, '');
            newValue = sanitizedCodeValue.slice(0, 5);
            break;
        case "email":
            newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, '');
            break;
        case "skills":
            const selectedSkills = [...value]
            newValue = selectedSkills
            break;
        case "comments": 
            const tempCommentsValue = newValue.slice(0, 200);
            newValue = tempCommentsValue;
            break;
        }

        this.setState((prevState) => (
            {
                formData: {
                    ...prevState.formData,
                    [name]: newValue,
                },
                businessFormDataErrors: {
                    ...prevState.businessFormDataErrors,
                    [name]: "",
                },
                shyfterFormDataErrors: {
                   ...prevState.shyfterFormDataErrors,
                    [name]: "",
                },
            })
        )



    };

    handleFormErrors = (value: any, message: string) => {
        if(this.props.tabValue === 0) {
            this.setState((prevState) => 
                ({ 
                    businessFormDataErrors: {
                        ...prevState.businessFormDataErrors, 
                        [value]: `*Required,${message}` 
                    }
                })
            );
        } else {
            this.setState((prevState) => 
                ({ 
                    shyfterFormDataErrors: {
                        ...prevState.shyfterFormDataErrors, 
                        [value]: `*Required,${message}` 
                    },
                })
            );
        }
       
    }

    checkValid = (ErrorObj: any) => {
        const isSubmitValid  = Object.values(ErrorObj).every((value: any)=> {
            if (value === "") {
              return true;
            }
            return false;
        });
        return isSubmitValid;
    }

    isFormValid = () => {
        let isValid = false;
        if(this.props.tabValue === 0) {
            isValid = this.checkValid(this.state.businessFormDataErrors)
        } else {
            isValid = this.checkValid(this.state.shyfterFormDataErrors)
        }

        if(isValid) {
            this.setState({
                businessFormDataErrors: this.initialBusinessErrorObject(),
                shyfterFormDataErrors: this.initialShyfterErrorObject(),
            })
            this.contactUsApiCall();
        } else {
            this.checkValidation()
        }
    }

    isValuesNonEmpty = () =>  {
        const { 
            businessName, firstName, lastName, email, mobileNumber, 
            state, city, zipCode, skills, comments 
        } = this.state.formData;

        if(firstName !== "" && lastName !== "" && email !== "" &&
            mobileNumber !== "" && state !== "" && city !== "" && 
            zipCode !== "" && skills.length !== 0 && comments !== ""
        ) {
                if(this.props.tabValue === 0 && businessName !== "") {
                    this.setState({
                        businessFormDataErrors : this.initialBusinessErrorObject()
                    })
                } 
                if(this.props.tabValue === 1) {
                    this.setState({
                        shyfterFormDataErrors : this.initialShyfterErrorObject()
                    })
                }
            this.isFormValid()
        }
    }

    checkValidation = () => {
        const { 
            businessName, firstName, lastName, email, mobileNumber, 
            state, city, zipCode, skills, comments 
        } = this.state.formData;

        if(!configJSON.minMaxBusinessNameCharLimitRegex.test(businessName?.trim())  && this.props.tabValue === 0) {
            this.handleFormErrors("businessName", configJSON.businessErrorMsg);
        }
        if(!configJSON.minMaxNameCharLimitRegex.test(firstName)) {
            this.handleFormErrors("firstName", configJSON.nameErrorMsg);
        }
        if(!configJSON.minMaxNameCharLimitRegex.test(lastName)) {
            this.handleFormErrors("lastName", configJSON.nameErrorMsg);
        }
        if(!configJSON.emailRegex.test(email)) {
            this.handleFormErrors("email", configJSON.emailErrormsg);
        }
        if(!configJSON.phoneNumberRegex.test(mobileNumber)) {
            this.handleFormErrors("mobileNumber", configJSON.phoneNumberErrorMsg);
        }
        if(state === "") {
            this.handleFormErrors("state", configJSON.stateListErrorMsg);
        }
        if(city === "") {
            this.handleFormErrors("city", configJSON.cityListErrorMsg);
        }
        if(!configJSON.zipCodeRegex.test(zipCode)) {
            this.handleFormErrors("zipCode", configJSON.zipCodeErrorMsg);
        }
        if(skills.length === 0) {
            this.handleFormErrors("skills", configJSON.skillErrorMsg);
        }
        if(!configJSON.minMaxCommentsCharLimitRegex.test(comments)) {
            this.handleFormErrors("comments", configJSON.commentsErrorMsg);
        }

        this.isValuesNonEmpty()
    }


    onSubmitHandler = () => {
        this.setState({ isSubmitClicked: true})
        this.checkValidation()
    }

    handleCountryCodeSelectionContactUs = (event: string) => {
        this.setState({ countryCodeValue: event})
    }

  // Customizable Area End
}
