import React, { Component } from "react";
import { Box, withStyles } from "@material-ui/core";
import BlueButton from "./BlueButton";

export const useStyles: any = () => ({
  mainWrapperSubscriptionFlow: {
    width: "100%",
    height: "600px",
    border: "1px solid #ddd",
    borderRadius: "14px",
    backgroundColor: "#fff",
    color: "",
    display: "flex",
    alignItems: "center",
    padding: "20px 0px 70px 0px",
    justifyContent: "center",
  },
  contentWrapSubscriptionFlow: {
    width: "75%",
    margin: "auto",
    textAlign: "center",
    "& h2": {
      fontSize: "22px",
      color: "#0A0412",
      lineHeight: "26px",
      fontWeight: 700,
    },
    "& p": {
      fontSize: "14px",
      color: "#6F7C8E",
      lineHeight: "20px",
      width: "90%",
      margin: "14px auto",
    },
  },
  textCenterSubscriptionFlow: {
    textAlign: "center",
  },
});

interface MyProps {
  handleClick: any;
  imgUrl: string;
  heading: string;
  paragraph: string;
  btnText: string;
  btnIcon?: any;
}

type MyState = {
  // faq: any,
};

export class EmployerSubscriptionFlow extends Component<MyProps, MyState> {
  render() {
    const { classes }: any = this.props;

    const handleClick = () => {
      this.props.handleClick();
    };

    return (
      <div className={classes.mainWrapperSubscriptionFlow}>
        <Box>
          <Box className={classes.textCenterSubscriptionFlow}>
            <img src={this.props.imgUrl} alt="" width={400} />
          </Box>
          <Box className={classes.contentWrapSubscriptionFlow}>
            <h2 className={classes.heading}>{this.props.heading}</h2>
            <p className={classes.text}>{this.props.paragraph}</p>
            <Box>
              <BlueButton
                data-test-id="subscriptionBtn"
                btnText={this.props.btnText}
                btnIcon={this.props.btnIcon}
                handleClick={handleClick}
              />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

export default withStyles(useStyles)(EmployerSubscriptionFlow);
