import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";

interface TProfileBlock {
  icon: string;
  title: string;
  value: string;
}

interface Props {
  blockTitle: string;
  blockData: TProfileBlock[];
}

const styles = {
  title: {
    color: "#000",
    fontWieght: 700,
    fontSize: "16px",
    lineHeight: "18px",
  },
  container: {
    marginY: "10px",
    bgcolor: "#fff",
    borderRadius: "20px",
    border: "1px solid #e1e6ec",
    padding: "0 10px",
  },
  fadeText: {
    color: "#6f7c8e",
    fontWieght: 500,
    fontSize: "16px",
    lineHeight: "18px",
    marginBottom: "5px",
  },
  contentText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    fontWieght: 500,
  },
};

const addDivider = (index: number, length: number) => {
  if ((index + 1) % 2 === 0 && index !== length - 1) {
    return (
      <Grid item xs={12}>
        <Divider />
      </Grid>
    );
  }

  return null;
};

export default function ProfileBlock(props: Props) {
  const { blockTitle, blockData } = props;

  return (
    <Box marginBottom="20px">
      <Box marginBottom="10px">
        <Typography style={styles.title}>{blockTitle}</Typography>
      </Box>
      <Box style={styles.container} bgcolor="#fff">
        <Grid container>
          {blockData.map((item, index) => (
            <>
              <Grid key={item.title} item xs={12} md={6}>
                <Box display="flex" alignItems="center" padding="20px">
                  <img src={item.icon} alt="" width="22px" />
                  <Box marginLeft="10px">
                    <Typography style={styles.fadeText}>
                      {item.title}
                    </Typography>
                    <Typography style={styles.contentText}>
                      {item.value || "NA"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {addDivider(index, blockData.length)}
            </>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
