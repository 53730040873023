import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function Footer() {
  return (
    <Box
      bgcolor="#fff"
      border="1px solid #e1e6ec"
      padding="20px"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop="30px"
    >
      <Typography variant="subtitle2">
        {" "}
        Copyright © All right reserved
      </Typography>
    </Box>
  );
}
