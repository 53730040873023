import React from "react";

import {
  Container,
  Box,
  Tabs,
  Tab,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import StorefrontTwoTone from "@material-ui/icons/StorefrontTwoTone";
import WorkTwoTone from "@material-ui/icons/WorkTwoTone";
import './style.css';
import parse from "html-react-parser"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const termsStyle = {
  titleWrapperStyle: {
    display: 'flex', 
    flexDirection: 'row' as 'row', 
    margin: '5px auto', 
    alignContent: 'center',
  },
  title1Style: {
      color: 'black', 
      fontSize: '32px'
  },
  title2Style: {
      color: '#b9df12', 
      fontSize: '32px',
      marginLeft: '8px'
  },
  heading: {
    display: "flex", 
    alignItems: "center", 
    margin: "0 0 -18px 0", 
    fontSize: "16px"
  },
  description: {
    color: "gray", 
    fontSize: "15px"
  }
}
// Customizable Area End

import TermsAndConditions3Controller, {
  Props,
  configJSON,
} from "./TermsAndConditions3Controller";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";

export default class TermsAndConditions3 extends TermsAndConditions3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTermsAndConditions = (titleName: string, data: any, ) => {
    return (
      <>
        <Box style={termsStyle.titleWrapperStyle}>
          <Typography variant="h6" style={termsStyle.title1Style}>Terms of Use for</Typography>
          <Typography variant="h6" style={termsStyle.title2Style}>{titleName}</Typography>
        </Box>
        {data && data.map((item: any, index: number) => (
            <>
              <h4 style={termsStyle.heading}>
                <p style={{ marginRight: "5px" }}>{index + 1})</p> 
                {parse(item.attributes.title)}
              </h4>
              <p style={termsStyle.description}>{parse(item.attributes.description)}</p>
            </>
         ))}
      </>
    )
  }
  // Customizable Area End

  render() {
    const { isOpenSignUpDialog } = this.state;
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <GenericHeader
          navigation={this.props.navigation}
          onSignUp={this.toggleSignUpForTNC}
          onNavigate={this.onNavigateFromTNC}
        />
        {isOpenSignUpDialog && (
          <AccountTypeSelectionDialog
            handleSave={this.redirectToSignUpPageForTNC}
            isOpen={isOpenSignUpDialog}
            handleClose={this.toggleSignUpForTNC}
          />
        )}
        <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
            <Typography style={webStyle.headerTitle} variant="h5" className="heading-main" data-test-id="test-heading">Terms & <span>Conditions</span></Typography>
            <label className="heading-mainsub" style={webStyle.linkTag}>
              <span
                data-test-id="home-page-link"
                className="header-home"
                onClick={() => this.redirectToSignUpPageForTNC("Home")}
              >
                Home
              </span>{" "}
              &nbsp;|&nbsp;{" "}
              <span
                data-test-id="terms-and-conditions-link"
                className="header-home"
                onClick={() =>
                  this.redirectToSignUpPageForTNC("TermsAndConditions3")
                }
              >
                Terms & Conditions
              </span>
            </label>
          </Box>
        </Box>
        <Box className="imageSection tabsection">
          <nav className="terms-nav">
            <Tabs aria-label="lab API tabs example" value={this.state.tabsValue} onChange={this.handleChange}>
              <Tab className="TabItem" label="Business T&C" value="Business" icon={<StorefrontTwoTone />} />
              <Tab className="TabItem" label="Shyfter T&C" value="Shyfter" icon={<WorkTwoTone />} />
            </Tabs>
          </nav>
          <div className="business-body" data-test-id="content">
            {this.state.tabsValue === "Business" ? 
              (
                this.renderTermsAndConditions(configJSON.businessTileText, this.state.businessTermsData)
              ) : (
                this.renderTermsAndConditions(configJSON.shyfterTitleText, this.state.shyfterTermsData)
              )
            }
          </div>
        </Box>
        <GenericFooter
          navigation={this.props.navigation}
          onAboutClick={this.redirectToAbout}
        />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  iconLabelWrapper: {
    flexDirection: "column"
  },
  headerTitle:{
    fontSize:"2.125rem",
    fontWeight: 600
  },
  linkTag: {
    color: "white"
  }
};
// Customizable Area End
