// Customizable Area Start
import React from "react";
import DashboardStatsController, { Props, configJSON } from "./DashboardStatsController";
import StatsTile from "../../../components/src/StatsTile.web";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Grid, Container, Box } from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import NoShyftDataCard from "../../../components/src/NoShyftDataCard.web";
import EmployerShyftCard from "../../../components/src/EmployerShyftCard.web";
import { convertDateIntoFormattedString, convertDateIntoTwelveHourFormat } from "../../../components/src/Utilities";
import { formatAmountPer, getHotelOrRestaurantName, getRecentActivityType, showAddress, showUserImage } from "./EmployerHomeAfterSubscription.web";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ShyftDetailCardWithoutTabs from "../../../components/src/ShyftDetailCardWithoutTabs.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";
import SortFilter from "../../filteritems/src/SortFilter.web";
import FilterRecords from "../../filteritems/src/FilterRecords.web";
import ShyftMenu from "../../../components/src/ShyftMenu.web";
import AddShyftDialog from "../../../components/src/AddShyftDialog.web";
import CustomSuccessDialog from "../../../components/src/CustomSuccessDialog";
import RequestsDialog from "../../../components/src/RequestsDialog";
import Spinner from "../../../components/src/Spinner";
import moment from "moment";

export const dummyRecentActivity = {
    id: 94,
    punch_in: "--",
    punch_out: null,
    meal_break: null,
    status: "weekly_off",
    user_id: 836,
    shift_id: 334,
    created_at: "--",
    updated_at: "--",
    hours_worked: "--"
}

export const activityData = {
  activities: {
      data: [
          {
              id: "1",
              type: "activity",
              attributes: {
                  id: 1,
                  punch_in: "2023-10-19T08:00:00.000Z",
                  punch_out: "2023-10-19T17:00:00.000Z",
                  meal_break: "00h:30m:00s",
                  status: "completed",
                  user_id: 2,
                  shift_id: 1,
                  created_at: "2023-10-23T12:44:30.902Z",
                  updated_at: "2023-10-23T12:44:30.902Z",
                  hours_worked: "04h:45m:30s"
              }
          },
          {
            id: "2",
            type: "activity",
            attributes: {
                id: 2,
                punch_in: "2023-10-19T08:00:00.000Z",
                punch_out: "2023-10-19T17:00:00.000Z",
                meal_break: "00h:00m:00s",
                status: "completed",
                user_id: 2,
                shift_id: 1,
                created_at: "2023-06-23T12:44:30.902Z",
                updated_at: "2023-06-23T12:44:30.902Z",
                hours_worked: "06h:05m:00s"
            }
          },
          {
            id: "3",
            type: "activity",
            attributes: {
                id: 3,
                punch_in: "2023-10-19T08:00:00.000Z",
                punch_out: "2023-10-19T17:00:00.000Z",
                meal_break: "00h:30m:00s",
                status: "weekly_off",
                user_id: 2,
                shift_id: 1,
                created_at: "2023-10-22T12:44:30.902Z",
                updated_at: "2023-10-22T12:44:30.902Z",
                hours_worked: "07h:15m:00s"
            }
          },
          {
            id: "4",
            type: "activity",
            attributes: {
                id: 4,
                punch_in: "2023-10-19T08:00:00.000Z",
                punch_out: "2023-10-19T17:00:00.000Z",
                meal_break: "01h:20m:00s",
                status: "completed",
                user_id: 2,
                shift_id: 1,
                created_at: "2023-09-23T12:44:30.902Z",
                updated_at: "2023-09-23T12:44:30.902Z",
                hours_worked: "08h:30m:00s"
            }
          }
      ]
  },
  summary: {
      completed_days: 5,
      missed_shifts: 1,
      hours_worked: "14h:30m:00s"
  },
  request: {
      id: 6,
      status: "ended",
      removal_reason: "bad performance",
      updated_at: "2023-11-10T14:46:56.694Z"
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e"
    },
    secondary: {
      main: "#0a0412"
    }
  }
});
const styles = {
  statsContainer: {
    border: "1px solid #e1e6ec",
    borderRadius: "14px",
    padding: 14,
    height: "fit-content"
  },
  statsHeader: {
    fontSize: 15,
    color: "#0a0412",
    fontWeight: "bold" as any,
    padding: "0px 0px 20px 0px"
  },
  shyftTitle: {
    fontSize: 15,
    color: "#0a0412",
    fontWeight: "bold" as any
  },
  sortByTitle: {
    fontSize: 14,
    color: "#390879",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    textDecoration: "underline",
    cursor: "pointer"
  },
  activeShyftDataCard: {
    paddingTop: 38,
  },
  activeCompleteShyftDataCard: {
    paddingTop: 40,
    position: 'relative' as 'relative',
    marginBottom: '10px'
  },
  ongoingActivityWrapper: {
    height: "800px",
    width: "100%",
    borderRadius: "10px",
    position: "relative" as "relative",
    border: "1px solid #DDD",
    overflow: "hidden",
    marginTop: "28px",
  },
  footerActivityModalWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    borderRadius: "10px",
    zIndex: 5,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-end",
  },
  filterBoxWrapper: {
    position: "absolute" as "absolute",
    right: "60px",
    bottom: "135px",
    backgroundColor: "#390879",
    borderColor: "#fff",
    borderWidth: "thin",
    color: "#fff",
    borderRadius: "50% ",
    zIndex: 4,
    height: "47px",
    width: "47px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  ongoingActivityCardWrapper: {
    height: "90%",
    width: "100%",
    overflowY: "scroll" as "scroll",
    padding: "15px",
    boxSizing: "border-box" as "border-box",
    backgroundColor: "#FFF",
    position: "relative" as "relative",
  },
};

const showNoShyftComponent = (
  selectedStats: string,
  createShyftList: any[],
  ongoingShyftList: any[],
  completedShyftList: any[]
) => {
  return (
    (selectedStats === "create" && !createShyftList.length) ||
    (selectedStats === "ongoing" && !ongoingShyftList.length) ||
    (selectedStats === "complete" && !completedShyftList.length)
  );
};

const showCurrentTabShyftList = (selectedStats: string, stats: string, list: any[]) => {
  return selectedStats === stats && list.length > 0;
};

const getActiveTabNumber = (stats: string) => {
  let tabCount = 0;
  if (stats === "ongoing") {
    tabCount = 1;
  } else if (stats === "complete") {
    tabCount = 2;
  }
  return tabCount;
}

const setActiveFilter = (
  tab: string, 
  createdFilter: string, 
  ongoingFilter: string, 
  completedFilter: string
) => {
  let activeFilter = createdFilter;
  if (tab === "ongoing") {
    activeFilter = ongoingFilter;
  } else if (tab === "complete") {
    activeFilter = completedFilter;
  }
  return activeFilter;
}

const setActiveShyftData = (type: string, data: any) => {
  return type === "edit" || type === "republish" ? data?.shift : {};
};

const editShyftDialogTitle = (type: string) => {
  let title = "Add Shyft";
  if (type === "edit") {
    title = "Edit Shyft";
  } else if (type === "republish") {
    title = "Republish Shyft";
  }
  return title;
};

const checkForEditShyft = (type: string) => {
  return (type === "edit" || type === "republish");
}

const isShyftEndedOrRemoved = (data: any) => {
  return data?.removal_reason !== null ? false: true;
}

export default class DashboardStats extends DashboardStatsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { 
      activeStats, createdShyftList, ongoingShyftList, completedShyftList,
      activeShyftData, activityRecordList, shyftSummaryData, activityEndRequest,
      isOpenSortFilter, createdShyftFilter, ongoingShyftFilter, completedShyftFilter,
      isOpenActivityFilterDrawer, activityRecordBoxVisible, ongoingShyftId,
      dashboardMenuAnchor, isOpenEditShyftDialog, isOpenShyftEditSuccessDialog,
      successDialogTitle, successDialogDescription, removeReason,
      isEndOrRemoveSuccessModal, endOrRemoveSuccessModalTitle, endOrRemoveSuccessModalDesc,
      isopenRatingSucessModal, ratingsModalData, isopenReviewAndRatingModal, createdShyftsCount,
      ongoingShyftsCount, completedShyftsCount, openUpdateStatusModal, activityRecordSingleItemData,
      completedShyftId, completedActivityRecordBoxVisible, completedActivityRecordList, completedShyftSummaryData,
      isLoading
    } = this.state;
    
    return (
      <>
      <ThemeProvider theme={theme}>
        <LandingPageHeader {...this.props} isShowDetails={true} isUpdateUser={false}/>
        {!!isOpenEditShyftDialog && (
          <AddShyftDialog
            open={!!isOpenEditShyftDialog}
            saveShyft={this.saveShyftDetails}
            title={editShyftDialogTitle(isOpenEditShyftDialog)}
            isEdit={checkForEditShyft(isOpenEditShyftDialog)}
            onCloseDialog={this.closeEditShyftDialog}
            editShyftData={setActiveShyftData(
              isOpenEditShyftDialog,
              activeShyftData
            )}
            isHotelType={this.isHotelUser}
          />
        )}
        {isOpenShyftEditSuccessDialog && (
          <CustomSuccessDialog
            open={isOpenShyftEditSuccessDialog}
            title={successDialogTitle}
            description={successDialogDescription}
            positiveAction={() => this.toggleEditShyftSuccessDialog(false)}
            positiveActionText="Okay"
            negativeAction={this.openAddShyftDialog}
            closeDialog={() => this.toggleEditShyftSuccessDialog(false)}
            negativeActionText="+ Add More"
          />
        )}
        <Container>
          <Box marginTop={"10%"} minHeight={"95vh"}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <Grid item xs={12} sm={12} md={3} style={styles.statsHeader}>
                  Stats
                </Grid>
                <Grid container spacing={2} style={styles.statsContainer}>
                  <Grid item xs={12}>
                    <StatsTile
                      type="create"
                      label={configJSON.createdShyftLabel}
                      isActive={activeStats === "create"}
                      handleClick={() => this.handleStatsChange("create")}
                      statsCount={createdShyftsCount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StatsTile
                      type="complete"
                      label={configJSON.completedShyftLabel}
                      isActive={activeStats === "complete"}
                      handleClick={() => this.handleStatsChange("complete")}
                      statsCount={completedShyftsCount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <StatsTile
                      type="ongoing"
                      label={configJSON.ongoingShyftLabel}
                      isActive={activeStats === "ongoing"}
                      handleClick={() => this.handleStatsChange("ongoing")}
                      statsCount={ongoingShyftsCount}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {isLoading ? <Grid item xs={12} sm={12} md={9}> <Spinner /> </Grid> : <Grid item xs={12} sm={12} md={9}>
                {showNoShyftComponent(activeStats, createdShyftList, ongoingShyftList, completedShyftList) &&
                  <Box margin="30px 0px">
                    <NoShyftDataCard
                      title={this.getNoShyftsTitle()}
                      description={this.getNoShyftsDescription()}
                      handleClick={this.openAddShyftDialog}
                    />
                  </Box>
                }
                {showCurrentTabShyftList(activeStats, "create", createdShyftList) &&
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom="5px">
                        <Box style={styles.shyftTitle}>{configJSON.createdShyftLabel}</Box>
                        <Box 
                          style={styles.sortByTitle} 
                          display="flex" 
                          data-test-id="created-sort"
                          alignItems="center"
                          onClick={this.openShyftFilter}
                          >{configJSON.sortByLabel} <ImportExportIcon />
                        </Box>
                      </Box>
                      {createdShyftList.map((item: any) => (
                        <EmployerShyftCard
                          key={item.shift.id + "dashboard-created-shift"}
                          amount={item.shift.amount}
                          userRole={item.shift.shift_type}
                          toTime={convertDateIntoTwelveHourFormat(item.shift.shift_to)}
                          fromTime={convertDateIntoTwelveHourFormat(item.shift.shift_from)}
                          shyftCreationTime={convertDateIntoFormattedString(
                            item.shift.created_at
                          )}
                          isActive={
                            item.shift.id ===
                            activeShyftData?.shift?.id
                          }
                          showTimingAndAmountLabel={false}
                          handleClick={() =>
                            this.getActiveShyftDetails(item.shift.id)
                          }
                          type="create"
                          handleMenuClick={this.openShyftMenu}
                          workerId={"1"}
                          shiftId={item.shift.id}
                          amountType={formatAmountPer(
                            item.shift.amount_per
                          )}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={6} style={styles.activeShyftDataCard}>
                      {activeShyftData?.shift?.id && (
                        <ShyftDetailCardWithoutTabs
                          handleActionButtonClick={this.handleViewRequestForDashboard}
                          shyftType={getHotelOrRestaurantName(activeShyftData)}
                          shyftTitle={
                            activeShyftData?.shift?.shift_type
                          }
                          address={showAddress(activeShyftData)}
                          amount={activeShyftData?.shift?.amount}
                          amountPer={formatAmountPer(
                            activeShyftData?.shift?.amount_per
                          )}
                          shyftTimeFrom={convertDateIntoTwelveHourFormat(activeShyftData.shift.shift_from)}
                          shyftTimeTo={convertDateIntoTwelveHourFormat(activeShyftData.shift.shift_to)}
                          experience={
                            activeShyftData?.shift?.expirence
                          }
                          shyftRequirements={
                            activeShyftData?.shift?.requirements
                          }
                          shyftDescription={
                            activeShyftData?.shift?.description
                          }
                          actionBtnTitle={`View Requests (${activeShyftData?.request_count})`}
                          isActionDisabled={!activeShyftData?.request_count}
                        />
                      )}
                    </Grid>
                  </Grid>}
                  {showCurrentTabShyftList(activeStats, "complete", completedShyftList) &&
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom="5px">
                        <Box data-test-id="callActivityRecordId" style={styles.shyftTitle}>
                          {configJSON.completedShyftLabel}
                        </Box>
                        <Box 
                          style={styles.sortByTitle} 
                          alignItems="center"
                          display="flex" 
                          data-test-id="completed-sort"
                          onClick={this.openShyftFilter}
                          >{configJSON.sortByLabel} <ImportExportIcon />
                        </Box>
                      </Box>
                      {completedShyftList.map((item: any) => (
                        <EmployerShyftCard
                          toTime={convertDateIntoTwelveHourFormat(item.shift.shift_to)}
                          fromTime={convertDateIntoTwelveHourFormat(item.shift.shift_from)}
                          shyftCreationTime={convertDateIntoFormattedString(
                            item.shift?.created_at
                          )}
                          key={item.shift.id + "dashboard-completed-shift"}
                          userName={item?.worker?.full_name}
                          userRole={item.shift?.shift_type}
                          amount={item.shift?.amount}
                          showTimingAndAmountLabel={true}
                          handleClick={() => this.getCompletedShyftActivityRecordList(item.shift.id, item.worker.id, item, false)}
                          isActive={item.shift.id ===  completedShyftId}
                          handleMenuClick={this.openShyftMenu}
                          workerId={item?.worker.id}
                          shiftId={item?.shift?.id}
                          type="complete"
                          showUsername={true}
                          amountType={formatAmountPer(
                            item.shift?.amount_per
                          )}
                          userImage={showUserImage(
                            item?.worker_profile_picture
                          )}
                          shyftActivityTime={
                            moment(item.request.updated_at).format("D MMM, YYYY [at] hh:mma")
                          }
                          recentActivityType={
                            getRecentActivityType(
                              item?.worker?.recent_login,
                              item?.worker?.recent_logout
                            ).activityType
                          }
                          isRemovedFromShyft={isShyftEndedOrRemoved(item.request)}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={6} style={styles.activeCompleteShyftDataCard}>
                      { completedActivityRecordBoxVisible  &&
                        <PastShyftActivity 
                          isCompletedShyft={true} 
                          isShyftEndedSuccess={isShyftEndedOrRemoved(activityEndRequest)} 
                          activityData={completedActivityRecordList} 
                          summaryData={completedShyftSummaryData}
                          request={activityEndRequest}
                          handleFilterRecords={this.openActivityFilterDrawer}
                          handleRepublishShyft={this.handleRepublishCompletedShyft}
                          handleMessage={this.handleCompletedShyftMessage}
                          handleUpdateStatusCard={this.handleUpdateActivityStatusCard}
                          isStatusModalOpen={openUpdateStatusModal}
                          closeUpdateStatusModal={this.handleCloseUpdateModal}
                          updateStatusModalSubmitButton={this.handleCloseUpdateModal}
                          activityUpdateStatusData={activityRecordSingleItemData}
                          updateStatusCtaText={"Okay"}
                        />
                      }
                    </Grid>
                  </Grid>}
                  {showCurrentTabShyftList(activeStats, "ongoing", ongoingShyftList) && (
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" paddingBottom="5px">
                          <Box style={styles.shyftTitle}>{configJSON.ongoingShyftLabel}</Box>
                          <Box 
                            style={styles.sortByTitle} 
                            alignItems="center"
                            display="flex" 
                            data-test-id="ongoing-sort"
                            onClick={this.openShyftFilter}
                            >{configJSON.sortByLabel} <ImportExportIcon />
                          </Box>
                        </Box>
                        {ongoingShyftList.map((item: any) => (
                          <EmployerShyftCard
                            toTime={convertDateIntoTwelveHourFormat(item.shift.shift_to)}
                            fromTime={convertDateIntoTwelveHourFormat(item.shift.shift_from)}
                            shyftCreationTime={convertDateIntoFormattedString(
                              item.shift?.created_at
                            )}
                            key={item.shift.id + "dashboard-ongoing-shift"}
                            userRole={item.shift?.shift_type}
                            userName={item?.worker?.full_name}
                            amount={item.shift?.amount}
                            showTimingAndAmountLabel={true}
                            isActive={item.shift.id === ongoingShyftId}
                            handleClick={() => this.getOngoingShyftActivityRecordList(item.shift.id, item.worker.id, item, false)}
                            handleMenuClick={this.openShyftMenu}
                            workerId={item.worker.id}
                            shiftId={item?.shift?.id}
                            type="ongoing"
                            showUsername={true}
                            amountType={formatAmountPer(
                              item.shift?.amount_per
                            )}
                            userImage={showUserImage(
                              item?.worker_profile_picture
                            )}
                            recentActivityType={
                              getRecentActivityType(
                                item?.recent_activity?.punch_in,
                                item?.recent_activity?.punch_out
                              ).activityType
                            }
                            shyftActivityTime={
                              getRecentActivityType(
                                item?.recent_activity?.punch_in,
                                item?.recent_activity?.punch_out
                              ).activityTime
                            }
                          />
                        ))}
                      </Grid>
                      
                      <Grid item xs={6} style={styles.activeCompleteShyftDataCard}>
                        { activityRecordBoxVisible && 
                          <PastShyftActivity 
                            isOngoingShyftActivity={true}
                            activityData={activityRecordList} 
                            summaryData={shyftSummaryData}
                            removeText={removeReason}
                            removeTextError={this.state.removeFieldErr}
                            handleRemoveReason={this.handleRemoveShyfterReason}
                            isRemoveModalOpen={this.state.isRemoveModalOpen}
                            isEndShyftModalOpen={this.state.isEndShyft}
                            closeRemoveModal={this.closeOngoingActivityRemoveModal}
                            closeEndShyftModal={this.handleIsCloseEndShyft}
                            handleFilterRecords={this.openActivityFilterDrawer}
                            handleRemoveShyft={this.openOngoingActivityRemoveModal}
                            handleEndShyft={this.handleIsEndShyft}
                            handleRemoveSubmit={this.handleRemoveSubmit}
                            handleEndShyftSubmit={this.handleEndShyftSubmit}
                            recentStatusData={this.state.ongoingRecentActivityData}
                            isRatingSuccess={isopenRatingSucessModal}
                            isReviewAndRate={isopenReviewAndRatingModal}
                            onSubmitRating={this.handleSubmitReviewAndRating}
                            ratingsData={ratingsModalData}
                            closeReviewAndRateModal={this.handleCloseReviewAndRatingModal}
                            closeRatingSuccessModal={this.handleCloseRateSuccessModal}
                            handleUpdateStatusCard={this.handleUpdateActivityStatusCard}
                            isStatusModalOpen={openUpdateStatusModal}
                            closeUpdateStatusModal={this.handleCloseUpdateModal}
                            updateStatusModalSubmitButton={this.handleUpdateStatusModalSubmit}
                            activityUpdateStatusData={activityRecordSingleItemData}
                          />
                        }
                      </Grid>
                    </Grid>
                  )}
              </Grid>}
            </Grid>
          </Box>
        </Container>
        <Footer />
        <SortFilter
          isOpen={isOpenSortFilter}
          handleClose={this.closeShyftFilter}
          activeFilter={setActiveFilter(
            activeStats,
            createdShyftFilter,
            ongoingShyftFilter,
            completedShyftFilter
          )}
          applyFilter={this.applyFilter}
        />
        {isOpenActivityFilterDrawer && (
          <FilterRecords
            id=""
            navigation={this.props.navigation}
            isOpenFilterDrawer={isOpenActivityFilterDrawer}
            onCloseFilter={this.closeActivityFilterDrawer}
            applyFilter={this.applyActivityFilterDrawer}
          />
        )}
        { isEndOrRemoveSuccessModal && (
          <CustomSuccessDialog
            data-test-id="EndOrremoveSuccessModalId"
            open={isEndOrRemoveSuccessModal}
            closeDialog={() => this.closeEndOrRemoveSuccessModal()}
            title={endOrRemoveSuccessModalTitle}
            description={endOrRemoveSuccessModalDesc}
            positiveActionText="Rate Shyfter"
            positiveAction={() => this.handleEndOrRemoveRateShyt()}
            negativeActionText="Done"
            negativeAction={() => this.closeEndOrRemoveSuccessModal()}
          />
        )}
        <ShyftMenu
          anchorEl={dashboardMenuAnchor}
          handleMenuItemClick={this.handleMenuItemClick}
          selectedTab={getActiveTabNumber(activeStats)}
          handleClose={this.closeShyftMenu}
        />
      </ThemeProvider>
        <Box data-test-id="requestDialogID">
          <RequestsDialog
            openDialog={this.state.isOpenViewRequestDrawer}
            handleCloseDialog={this.handleViewRequestDialogClose}
            handleOpenDialog={this.handleViewRequestForDashboard}
            handleignoreRequest={this.ignoreShyftRequest}
            shyfDrawerData={this.state.viewRequestData}
            handleacceptRequest={this.acceptShyftRequest}
            closeModal={() => this.closeAcceptSuccessModal(false)}
            isModalOpen={this.state.isOpenViewRequestSuccessModal}
            shyftName={activeShyftData?.shift?.shift_type}
            acceptedShyfter={this.state.acceptedShyfterName}
            viewOngoingShyfts={() => this.closeAcceptSuccessModal(true)}
          />
        </Box>
      </>
    );
  }
}

// Customizable Area End
