import React from "react";
import { Box, makeStyles, IconButton, Dialog, Grid, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
import TimerWithCircularProgressBar from "./TimerWithCircularProgressBar";
const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 500,
    },
    "& div[role='dialog']": {
      padding: "20px 25px",
      borderRadius: "20px",
      overflowY: "visible",
      maxWidth: "500px",
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-50px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
    " & .singleAction": {
      width: "50%",
      margin: "auto",
    },
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  backToLogin: (e: any) => void;
  exploreShyfts?: (e: any) => void;
  completeRegistration?: (e: any) => void;
  isRating?: boolean;
  isDoubleAction?: boolean;
  isShyfterRefg?: boolean;
  isEmpReg?: boolean;
  isRegProgress?: number;
  isFromOngoingActivity?: boolean;
  isUpdateSatus?: boolean;
  isUpdateProfile?: boolean;
  isUpdateDocs?: boolean;
  statusDate?: string;
}

const SubmitSuccessPopUp: React.FC<ISuccessPopupDialogProps> = ({
  open,
  isShyfter,
  backToLogin,
  exploreShyfts,
  completeRegistration,
  isRating,
  isDoubleAction,
  isShyfterRefg,
  isEmpReg,
  isRegProgress,
  isFromOngoingActivity,
  isUpdateSatus,
  isUpdateProfile,
  isUpdateDocs,
  statusDate,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton
        className="closeIcon"
        onClick={backToLogin}
        data-test-id="closeBtn"
      >
        <CloseIcon height={20} width={20} />
      </IconButton>

      {isShyfterRefg && (
        <>
          <Box justifyContent="center" display="flex">
            <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Sign up Successfully</Box>
          <Box className="description">
            Your account has been created successfully, Complete your
            registration process to apply shyfts as per your availability
          </Box>
        </>
      )}
      {isEmpReg && (
        <Box
          justifyContent="center"
          display="flex"
          width={"100%"}
          flexDirection={"column"}
        >
          <Box textAlign={"center"}>
            <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Submitted Successfully</Box>
          <Box className="description">
            Your Business details have been submitted successfully. You can
            login to your account after the admin has approved your request.
          </Box>
        </Box>
      )}

      {isRating && (
        <Box
          justifyContent="center"
          display="flex"
          width={"100%"}
          flexDirection={"column"}
        >
          <Box textAlign={"center"}>
            <img src={require("./assets/images/star.png")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Rating Submitted</Box>
          <Box className="description">
            {isFromOngoingActivity
              ? `Your rating and review has been submitted successfully. Go back to home 
              and add more shyfts to get shyfters for your business.`
              : `Your rating and review has been submitted successfully. Explore more
              jobs and join businesses to get more shyfts.`}
          </Box>
        </Box>
      )}
      {!!isRegProgress && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box display={"flex"} justifyContent={"center"} marginBottom={"10px"}>
            <TimerWithCircularProgressBar
              percent={isRegProgress}
              size={100}
              isShyftPage={false}
              colorCode="#b9df12"
            />
          </Box>
          <Typography style={{marginTop: '15px', marginBottom:'10px', fontWeight:'bold'}}>
            {isRegProgress === 100 ? 'Registration Done' : 'Profile Updated'}
          </Typography>
          <Box className="description">
          {isRegProgress === 100 ? 'Your registration has been done successfully. You are ready to pick-up shyfts at hotels or restaurants' : 'Your information has been updated successfully, please connect with admin and upload your documents to apply any shyfts.'}
          </Box>
        </Box>
      )}

      {isUpdateSatus && (
        <Box
          justifyContent="center"
          display="flex"
          width={"100%"}
          flexDirection={"column"}
        >
          <Box textAlign={"center"}>
            <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Status Updated</Box>
          <Box className="description">
            {`The status for ${statusDate} has been submitted successfully.
              You can check that in activity record listing.
            `}
          </Box>
        </Box>
      )}

      {isUpdateProfile && (
        <Box
          justifyContent="center"
          display="flex"
          width={"100%"}
          flexDirection={"column"}
        >
          <Box textAlign={"center"}>
            <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Submitted Successfully</Box>
          <Box className="description">
            Your Updated Business details has been submitted successfully. The
            info will be updated after the verification of the admin.
          </Box>
        </Box>
      )}

      {isUpdateDocs && (
        <Box
          justifyContent="center"
          display="flex"
          width={"100%"}
          flexDirection={"column"}
        >
          <Box textAlign={"center"}>
            <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
          </Box>
          <Box className="successDialogTitle">Submitted Successfully</Box>
          <Box className="description">
            Your Updated Official documents has been submitted successfully. The
            Documents will be updated after the verification of the admin.
          </Box>
        </Box>
      )}

      {isDoubleAction ? (
        <Grid container className="actionWrapper" spacing={2}>
          <Grid item xs={6}>
            <CustomButton
              label={!!isRegProgress ? "Back to Profile" : "Explore Shyfts"}
              isOutlined={true}
              handleClick={exploreShyfts}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              label={
                !!isRegProgress ? "Explore Shyfts" : "Complete Registration"
              }
              handleClick={completeRegistration}
            />
          </Grid>
        </Grid>
      ) : (
        <Box className="actionWrapper singleAction">
          <CustomButton
            label={(isRating || isUpdateProfile || isUpdateDocs || isUpdateSatus) ? "Okay" : "Back to Login"}
            handleClick={backToLogin}
          />
        </Box>
      )}
    </Dialog>
  );
};

export default SubmitSuccessPopUp;
