import React, { Children, FunctionComponent }from "react";
import { makeStyles, Typography } from "@material-ui/core";

type Props = {
  children: string | JSX.Element | JSX.Element[],
  position: string
  textStyles: any
}
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: "22px",
  },
  border: {
    borderBottom: "2px solid lightgray",
    width: "100%",
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "-0.25px",
    width: "80%",
  },
}));


const DividerWithText: FunctionComponent<Props> = ({ children, position = "left", textStyles = {} }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
          {position === "center" && <div className={classes.border} />}
          <Typography
            classes={{ root: classes.content }}
            //@ts-ignore-nextline
            style={{textAlign: position}}
            variant="body1"
          >
            {children}
          </Typography>
          <div className={classes.border} />
        </div>
      );
  };
  export default DividerWithText;