import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
export const configJSON = require("./config");
import moment from "moment";
import { ProfileImg } from "./assets";
import {
  convertDateIntoTwelveHourFormat,
  convertDateToCalenderFormat,
  setKeyInLocalStorage,
  getKeyFromLocalStorage,
  filterValueFromArray,
  TCreatedShyft,
  formatOthersShyftCreated,
  checkAuthorization,
  clearUserLoginData,
  getCityAndState,
} from "../../../components/src/Utilities";
import { removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ChangeEvent } from "react";

interface ShyftType {
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
}

interface ShyftDetailsType {
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  isSavedShyft: boolean;
  requirements: string[];
  description: string;
  experience: string;
  businessName: string;
  businessType: string;
  overallRatings: number;
  ratings: TComment[];
  businessId: number;
  ratingsCount: number;
  reviewsCount: number;
  businessShifts: TCreatedShyft[];
}

interface TComment {
  id: number;
  comment: string;
  score: number;
  shift_type: string;
  worker_name: string;
  created_at: string;
  worker_profile_picture: string;
}

interface APIShyftDetailsType {
  attributes: {
    activated: boolean;
    amount: number;
    amount_per: string;
    business_details: {
      business: {
        address: string;
        created_at: string;
        dba_name: string;
        hotel_name: string;
        hotel_type: string;
        restuarant_name: string;
        restaurant_type: string;
        id: number;
        telephone: string;
        updated_at: string;
        user_id: number;
        website: string;
      };
      employer_profile_picture: string | null;
      city?: string | null;
      state?: string | null;
    };
    business_shifts: TCreatedShyft[],
    business_type: string;
    created_at: string;
    description: string;
    employer_id: number;
    expirence: string;
    id: number;
    isAvailable: boolean;
    requirements: string[];
    saved: boolean;
    shift_from: string;
    shift_to: string;
    shift_type: string;
  };
}

interface RatingsType {
  overall_rating: number;
  ratings: TComment[];
  ratings_count: number;
  reviews_count: number;
}

interface TShyftRequest {
  id: number;
  status: string;
  removal_reason: string;
  updated_at: string;
  created_at: string;
  shift_id: number;
  worker_id: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  isShowLocationModal: boolean;
  isShowSearchModal: boolean;
  signUpMessage: string;
  userData: any;
  profileComplition: number;
  shyftsList: ShyftType[];
  recommendedShyftsList: ShyftType[];
  selectedShyft: number;
  saveShyftId?: number;
  shyftDetails: ShyftDetailsType | null;
  shyftRequest?: TShyftRequest;
  isRegisterationPendingModalOpen: boolean;
  recentSearches: string[];
  isAppliedModalOpen: boolean;
  currentLatitude: number;
  currentLongitude: number;
  searchedText: string;
  locationCountryData: any[];
  locationStateData: any[];
  locationCityData: any[];
  locationData: any[];
  countryCode: string;
  countryName: string;
  stateName: string;
  cityName: string;
  isCountrySelected: boolean;
  isStateSelected: boolean;
  isCitySelected: boolean;
  locationBreadCrumData: string[];
  isSearchingLocation: boolean;
  searchedLocationData: any[];
  ongoingShyft: any[];
  remainingTime: number;
  nearestTime: string;
  activeShyft: number;
  shyftEndTime: number;
  timePercent: number;
  clickType: string;
  myShyfts: any[];
  topHotelAndRestaurantsData: any[];
  isBusinessSearch: boolean;
  searchAllShyftsData: any;
  filteredDataForSearchModal: any;
  updateEmailPopUp: boolean;
  updatePhonePopUp: boolean;
  updatedPhoneNumber: string;
  updatedEmail: string;
  isEmailOtpPopUpOpen: boolean;
  isPhoneOtpPopUpOpen: boolean;
  phoneNumberOTP: string;
  emailOTP: string;
  seconds: number;
  phoneCountryCode: string;
  errorMessageModal: string;
  errorPopUp: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface TSearchParams {
  page: number;
  perPage?: number;
}

export default class ShyfterLandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getShyfterApiCallId: string = "";
  getAllShiftsAPICallId: string = "";
  getRecommnededShiftsAPICallId: string = "";
  getShiftDetailsAPICallId: string = "";
  postApplyShyftAPICallId: string = "";
  postWithdrawShyftAPICallId: string = "";
  postSaveShyftAPICallId: string = "";
  deleteSaveShyftAPICallId: string = "";
  getCountriesAPICallId: string = "";
  getStatesByCountryAPICallId: string = "";
  getCitiesByStateAPICallId: string = "";
  getLocationUsingLatLongAPICallId: string = "";
  getLocationPredictionsAPICallId: string = "";
  getMyShyftsAPICallId: string = "";
  getNextMyShyftsAPICallId: string = "";
  timer: any = null;
  patchLocationUpdateAPICallId: string = "";
  getTopHotelsAndRestaAPICallId: string = "";
  getShyfterPhoneOTPId: string = "";
  otpTimer?: number;
  patchUserProfleShyfterId: string = "";
  getEmailOTPId: string = "";
  getAllSearchShyftsInHomeAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.ShyfterRegistrationMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      profileComplition: 0,
      isShowLocationModal: false,
      isShowSearchModal: false,
      signUpMessage: "",
      userData: "",
      shyftsList: [],
      recommendedShyftsList: [],
      selectedShyft: -1,
      shyftDetails: null,
      isRegisterationPendingModalOpen: false,
      isAppliedModalOpen: false,
      recentSearches: [],
      currentLatitude: 0,
      currentLongitude: 0,
      countryCode: "",
      countryName: "",
      stateName: "",
      cityName: "",
      searchedText: "",
      locationCountryData: [],
      locationStateData: [],
      locationCityData: [],
      locationData: [],
      isCountrySelected: false,
      isStateSelected: false,
      isCitySelected: false,
      locationBreadCrumData: [],
      isSearchingLocation: false,
      searchedLocationData: [],
      ongoingShyft: [],
      remainingTime: 0,
      nearestTime: "",
      activeShyft: 0,
      shyftEndTime: 0,
      timePercent: 0,
      myShyfts: [],
      topHotelAndRestaurantsData: [],
      isBusinessSearch: false,
      searchAllShyftsData: [],
      filteredDataForSearchModal: [],
      clickType: 'explore',
      updateEmailPopUp: false,
      updatePhonePopUp: false,
      updatedPhoneNumber: "",
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      phoneNumberOTP: "",
      emailOTP: "",
      seconds: 120,
      updatedEmail: "",
      phoneCountryCode: "91",
      errorMessageModal: "",
      errorPopUp: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const isAuthorizedUser = checkAuthorization(false, true);
    if (!isAuthorizedUser) {
      this.handleMoreOptionsNavigation("Login");
      return;
    }
    this.getShyfterProfile();
    this.getAllShifts(false);
    this.getRecommendedShifts();
    this.getRecentSearches();
    this.fetchCountries();
    this.getAllMyShyfts();
    this.getTopHotelsAndRestaurants();
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (this.state.selectedShyft !== prevState.selectedShyft) {
      if (this.state.clickType === configJSON.Explore) {
        const shift = this.state.shyftsList[this.state.selectedShyft];
        if (shift) this.getExploreShiftsDetails(shift.id);
      } else {
        this.getExploreShiftsDetails(this.state.selectedShyft)
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ShyfterRegistrationMessage) === message.id) {
      const signUpMessage = message.getData(
        getName(MessageEnum.ShyfterRegistrationMessage)
      );
      this.setState({ signUpMessage: signUpMessage });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.handleMoreOptionsNavigation("Login");
        return;
      } else {
        this.handleApiResponse(message);
      }
    }
  }

  handleUpdateLocationData = (data: any) => {
    if (data && data.length > 0) {
      this.setState({
        locationData: data,
      });
    }
  };

  locationUpdateRefresh = () => {
    this.getShyfterProfile();
    this.getAllShifts(false);
    this.getRecommendedShifts();
    this.getRecentSearches();
    this.fetchCountries();
    this.getAllMyShyfts();
    this.getTopHotelsAndRestaurants();
  };

  handleLocationApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCountriesAPICallId) {
      this.setState({
        locationCountryData: responseJson,
        isCountrySelected: true,
        isStateSelected: false,
        isCitySelected: false
      });
      this.handleUpdateLocationData(responseJson);
    }
    if (apiRequestCallId === this.getStatesByCountryAPICallId) {
      this.setState({
        locationStateData: responseJson,
        isStateSelected: true,
        isCountrySelected: false,
        isCitySelected: false,
      });
      this.handleUpdateLocationData(responseJson);
    }
    if (apiRequestCallId === this.getCitiesByStateAPICallId) {
      this.setState({
        locationCityData: responseJson,
        isCountrySelected: false,
        isStateSelected: false,
        isCitySelected: true,
      });
      this.handleUpdateLocationData(responseJson);
    }

    if (apiRequestCallId === this.getLocationUsingLatLongAPICallId) {
      const addressComponents = responseJson?.results[0]?.address_components;
      const city = addressComponents.find((component: any) => component.types.includes('locality'));
      const state = addressComponents.find((component: any) => component.types.includes('administrative_area_level_1'));
      const country = addressComponents.find((component: any) => component.types.includes('country'));

      this.setState({
        cityName: city.long_name,
        stateName: state.long_name,
        countryName: country.long_name
      });

      this.updateLocation(city.long_name, state.long_name, country.long_name)
    }

    if (apiRequestCallId === this.getLocationPredictionsAPICallId) {
      if (responseJson?.predictions && responseJson?.predictions.length > 0) {
        this.setState({
          searchedLocationData: responseJson.predictions
        });
      }
    }

    if (apiRequestCallId === this.patchLocationUpdateAPICallId) {
      this.setState({
        selectedShyft: -1,
        shyftDetails: null
      })
      this.locationUpdateRefresh();
    }

  };

  handleTopHotelAndRestaurantApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getTopHotelsAndRestaAPICallId) {
      if (responseJson && responseJson?.top_hotel_restaurants.length > 0) {
        this.setState({
          topHotelAndRestaurantsData: responseJson.top_hotel_restaurants
        })
      }
    }

    if (this.getAllSearchShyftsInHomeAPICallId === apiRequestCallId) {
      if(responseJson && responseJson?.count){
        this.handleSearchHotelRestraOrShyft(responseJson.count)
      }
    }
  }

  handleSearchHotelRestraOrShyft = (response: any) => {
    let countValue:any = [];

    Object.values(response).forEach((item: any) => {
      Object.entries(item).forEach(([key, value]) => {
        if (key.toLowerCase().includes(this.state.searchedText.toLowerCase())) {
          let obj: any = {};
          obj.name = key;
          obj.count = value;
          countValue.push(obj);
        }
      });
    });

    this.setState({
      filteredDataForSearchModal: countValue
    })
  }

  handleEditPhoneAndEmailApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getShyfterPhoneOTPId) {
      if (responseJson?.message?.toLowerCase() === "otp sent successfully") {
        this.setState({ seconds: 120, isPhoneOtpPopUpOpen: true, phoneNumberOTP: "", errorPopUp: false });
        this.startTimerForOtp();
      } else  {
        this.setState({ 
          errorMessageModal: responseJson?.message || responseJson?.error || responseJson?.errors[0] , 
          errorPopUp: true 
        });
      }
    }

    if (apiRequestCallId === this.patchUserProfleShyfterId) {
      this.handleUpdateSuccess();
    }

    if (apiRequestCallId === this.getEmailOTPId) {
      if (responseJson?.message?.toLowerCase().includes("otp sent")) {
        this.setState({ seconds: 120, isEmailOtpPopUpOpen: true, emailOTP: "", errorPopUp: false  });
        this.startTimerForOtp();
      } else {
        this.setState({ errorMessageModal: responseJson?.message || responseJson?.error || responseJson?.errors?.email[0], errorPopUp: true });
      }
    }
  } 

  handleUpdateSuccess = () => {
    this.setState({
      updatedEmail: "",
      updatedPhoneNumber: "",
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      emailOTP: "",
      phoneNumberOTP: "",
      updateEmailPopUp: false,
      updatePhonePopUp: false,
    });

    this.clearOTPTimer();
    this.handleShyfterLogout()
  };

  startTimerForOtp = () => {
    this.clearOTPTimer();
    this.otpTimer = window.setInterval(() => {
      this.setState((prev) => {
        if (prev.seconds > 0) {
          return { seconds: prev.seconds - 1 };
        }

        this.clearOTPTimer();
        return { seconds: prev.seconds };
      });
    }, 1000);
  };

  clearOTPTimer = () => {
    if (this.otpTimer) {
      clearInterval(this.otpTimer);
    }
  };

  closePhoneOTPModal = () => {
    this.setState({ isPhoneOtpPopUpOpen: false});
    this.clearOTPTimer();
  };

  closeEmailOTPModal = () => {
    this.setState({ isEmailOtpPopUpOpen: false });
    this.clearOTPTimer();
  };

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getShyfterApiCallId) {
      this.getShyfterProfileRes(responseJson);
    }

    if (this.getAllShiftsAPICallId === apiRequestCallId) {
      this.handleShyfts(responseJson.explore_shifts.data, "explore");
      this.setState({
        searchAllShyftsData: { ...responseJson?.count?.shift_types_count }
      })
    }

    if (this.getRecommnededShiftsAPICallId === apiRequestCallId) {
      this.handleShyfts(responseJson.recommended_shifts.data, "recommended");
    }

    if (this.getMyShyftsAPICallId === apiRequestCallId) {
      this.handleMyShyfts(responseJson.data)
    }

    if (this.getShiftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetails(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (this.postApplyShyftAPICallId === apiRequestCallId) {
      this.setRequestData(responseJson.data);
    }

    if (this.postWithdrawShyftAPICallId === apiRequestCallId) {
      this.setState({
        shyftRequest: undefined,
      });
    }

    if (this.postSaveShyftAPICallId === apiRequestCallId) {
      this.setSaveShiftData("save");
    }

    if (this.deleteSaveShyftAPICallId === apiRequestCallId) {
      this.setSaveShiftData("unsave");
    }

    this.handleLocationApiResponse(apiRequestCallId, responseJson);
    this.handleTopHotelAndRestaurantApiResponse(apiRequestCallId, responseJson);
    this.handleEditPhoneAndEmailApiResponse(apiRequestCallId, responseJson)

  }

  // Customizable Area Start
  getRecentSearches = () => {
    const recentSearches = getKeyFromLocalStorage("recentSearches") as string[];
    this.setState({ recentSearches });
  };

  addQueryToRecentSearches = (query: string) => {
    const updatedSearches = [
      query,
      ...filterValueFromArray(this.state.recentSearches, query),
    ].slice(0, 5);

    setKeyInLocalStorage("recentSearches", updatedSearches);
  };

  removeQueryFromRecentSearches = (query: string) => {
    const updatedSearches = filterValueFromArray(
      this.state.recentSearches,
      query
    );
    this.setState({ recentSearches: updatedSearches });
    setKeyInLocalStorage("recentSearches", updatedSearches);
  };

  setSaveShiftData = (type: "save" | "unsave") => {
    const { recommendedShyftsList, shyftsList, saveShyftId, shyftDetails } = this.state;

    const exploreList = [...shyftsList];
    const recommendedList = [...recommendedShyftsList];
    const updatedShyftDetails = { ...shyftDetails! };

    const exploreShyftIndex = shyftsList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );
    const recommendedShyftIndex = recommendedList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );
    const detailsShiftIndex = updatedShyftDetails.businessShifts.findIndex(shift => shift.id === saveShyftId);

    if (exploreShyftIndex !== -1) {
      exploreList[exploreShyftIndex].saved = type === "save";
    }

    if (recommendedShyftIndex !== -1) {
      recommendedList[recommendedShyftIndex].saved = type === "save";
    }

    if (detailsShiftIndex !== -1) {      
      updatedShyftDetails.businessShifts[detailsShiftIndex].saved = type === "save";
    }

    this.setState({
      recommendedShyftsList: recommendedList,
      shyftsList: exploreList,
      shyftDetails:updatedShyftDetails
    });
  };

  navigateToMyShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyShyftsPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/MyShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  navigateToManageShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "EditShyfterProfilePage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/ManageShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  setRequestData = (data: any) => {
    this.setState({
      isAppliedModalOpen: true,
      shyftRequest: {
        id: data.attributes.id,
        status: data.attributes.status,
        removal_reason: data.attributes.removal_reason,
        updated_at: data.attributes.updated_at,
        created_at: data.attributes.created_at,
        shift_id: data.attributes.id,
        worker_id: data.attributes.worker_id,
      },
    });
  };

  navigateToShyfterProfile() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ShyfterProfilePage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigatoShyfterProfileMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigatoShyfterProfileMessage),
      "Your Account"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  navigateToPastShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PastShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "PastShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleViewAllRecomendedShyfts() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RecomendedShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPastShyftMessage), "");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleExploreShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  getAllShifts(isSearch: boolean, searchText?: string) {
    const header = {
      "Content-Type": configJSON.getShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let endpoint = `${configJSON.getShiftsApiEndPoint}?sort_order=desc&sort_column=created_at`;

    if(isSearch) {
      endpoint = `${endpoint}&search=${searchText}`
      this.getAllSearchShyftsInHomeAPICallId = requestMessage.messageId;
    } else {
      this.getAllShiftsAPICallId = requestMessage.messageId;
    }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRecommendedShifts() {
    const header = {
      "Content-Type": configJSON.getRecommnededShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = `${configJSON.getRecommnededShiftsApiEndPoint}?sort_order=desc&sort_column=created_at&page=1&per_page=3`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRecommnededShiftsApiMethodType
    );

    this.getRecommnededShiftsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getExploreShiftsDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getShiftDetailsAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  setSelectedShyft(shyftIndex: number, clickType: string) {
    this.setState({ selectedShyft: shyftIndex, clickType: clickType });
  }

  showLocationModal = () => {
    this.setState({
      isShowLocationModal: true,
    });
  };
  closeLocationModal() {
    this.setState({
      isShowLocationModal: false,
      isCountrySelected: true,
      isStateSelected: false,
      isCitySelected: false,
      locationData: this.state.locationCountryData,
      locationBreadCrumData: [],
      isSearchingLocation: false,
      searchedLocationData: [],
    });
  }
  showSearchModal() {
    this.setState({
      isShowSearchModal: true,
    });
  }
  closeSearchModal() {
    this.setState({
      isShowSearchModal: false,
      isBusinessSearch: false,
    });
  }

  closeAppliedShiftModal = () => {
    this.setState({
      isAppliedModalOpen: false,
    });
  };

  closeRegistrationModal = () => {
    this.setState({
      isRegisterationPendingModalOpen: false,
    });
  };

  handleCompleteNowRedirection = () => {
    this.closeRegistrationModal();
    this.navigateToManageShyft();
  }

  getShyfterProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShyfterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShyfterProfileRes(responseJson: any) {
    this.setState({
      userData: responseJson.data.attributes,

      profileComplition: responseJson.data.attributes.profile_completion,
    });
  }

  handleMoreOptionsNavigation = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  };

  handleShyfts(shifts: APIShyftDetailsType[], type: "recommended" | "explore") {
    const shyftsList: ShyftType[] = shifts.map((shift) => {
      return {
        id: shift.attributes.id,
        employername: shift.attributes.business_details.business.hotel_name || shift.attributes.business_details.business.restuarant_name,
        userImg: shift.attributes.business_details.employer_profile_picture
          ? shift.attributes.business_details.employer_profile_picture
          : ProfileImg,
        syftTimimg: `${convertDateIntoTwelveHourFormat(shift.attributes.shift_from)} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
        billing: `$${shift.attributes.amount} Per Hr`,
        day: convertDateToCalenderFormat(shift.attributes.created_at),
        shyft: shift.attributes.shift_type,
        shyftPlace: shift.attributes.business_type,
        county: getCityAndState(shift.attributes.business_details.city, shift.attributes.business_details.state),
        saved: shift.attributes.saved,
      };
    });

    if (type === "explore") {
      this.setState({ shyftsList, selectedShyft: 0 });
    } else {
      this.setState({ recommendedShyftsList: shyftsList });
    }
  }

  onSearchShifts = (value: string) => {
    if (!value) return;
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSearchShyftsMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationSearchShyftsMessage), {
      search: value,
    });
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.addQueryToRecentSearches(value);
    this.send(msg);
  };

  navigateToExploreShift = (filter: string[]) => {
    if (!filter.length) return;
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationSearchShyftsMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationSearchShyftsMessage), {
      filter,
    });
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  handleShyftDetails(
    shift: APIShyftDetailsType,
    ratings: RatingsType,
    shyftRequest?: TShyftRequest
  ) {
    const shyftDetails = {
      id: shift.attributes.id,
      employername: shift.attributes.business_details.business.dba_name,
      userImg: shift.attributes.business_details.employer_profile_picture
        ? shift.attributes.business_details.employer_profile_picture
        : ProfileImg,
      syftTimimg: `${convertDateIntoTwelveHourFormat(
        shift.attributes.shift_from
      )} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
      billing: `$${shift.attributes.amount} Per Hr`,
      day: convertDateToCalenderFormat(shift.attributes.created_at),
      shyft: shift.attributes.shift_type,
      shyftPlace: shift.attributes.business_type,
      county: shift.attributes.business_details.business.address,
      isSavedShyft: shift.attributes.saved,
      requirements: shift.attributes.requirements,
      description: shift.attributes.description,
      experience: shift.attributes.expirence,
      businessId: shift.attributes.business_details.business.user_id,
      businessShifts: formatOthersShyftCreated(shift.attributes.business_shifts),
      businessName: ["hotel", "Hotel"].includes(shift.attributes.business_type)
        ? shift.attributes.business_details.business.hotel_name
        : shift.attributes.business_details.business.restuarant_name,
      businessType: ["hotel", "Hotel"].includes(shift.attributes.business_type)
        ? shift.attributes.business_details.business.hotel_type
        : shift.attributes.business_details.business.restaurant_type,
      overallRatings: ratings.overall_rating,
      ratings: ratings.ratings.filter(Boolean),
      ratingsCount: ratings.ratings_count,
      reviewsCount: ratings.reviews_count,
    };
    this.setState({ shyftDetails, shyftRequest });
  }

  handleSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unSaveTheShyft(shiftId);
    } else {
      this.saveTheShyft(shiftId);
    }
  };

  saveTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postSaveShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(request.id, request);
  };

  unSaveTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSaveShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  applyForTheShyft = (shiftId: number) => {
    if (this.state.profileComplition < 100) {
      this.setState({ isRegisterationPendingModalOpen: true });
      return;
    }

    const header = {
      "Content-Type": configJSON.postApplyForShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postApplyShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyForShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyForShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  withdrawFromTheShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawFromShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawFromShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: id,
        },
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawFromShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  navigateToBusinessProfile = (businessId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );

    setStorageData("businessId", businessId);
    removeStorageData("visitedFrom");
    this.send(message);
  };

  handleCurrentLocation = (latitude: number, longitude: number) => {
    this.setState({
      isSearchingLocation: false,
    });

    let url = `${configJSON.getGoogleMapsApiEndPoint}/geocode/json?latlng=${latitude},${longitude}&key=${configJSON.getGoogleMapsApiKey}`;

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLocationUsingLatLongAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);

    this.setState({
      ...this.state,
      currentLatitude: latitude,
      currentLongitude: longitude,
    });

    this.closeLocationModal();
  };

  handleBreadCrumData = (data: string[]) => {
    this.setState({
      locationBreadCrumData: [...this.state.locationBreadCrumData, ...data],
    });
  };

  fetchCountries = () => {
    this.setState({
      locationBreadCrumData: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleLocationBreadCrumAllStates = () => {
    this.setState({
      locationBreadCrumData: []
    }, () => {
      this.fetchStateByCountry(this.state.countryCode, this.state.countryName);
    })
  }

  fetchStateByCountry = (locationCode: any, selectedCountryName: string) => {
    this.handleBreadCrumData([selectedCountryName, "All States"]);

    this.setState({
      countryCode: locationCode,
      countryName: selectedCountryName,
      locationData: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStatesByCountryAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${locationCode}/states`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchCitiesByCountryAndState = (
    locationCode: any,
    selectedStateName: string
  ) => {
    this.handleBreadCrumData([selectedStateName, "All Cities"]);

    this.setState({
      stateName: selectedStateName,
      locationData: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCitiesByStateAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${this.state.countryCode}/states/${locationCode}/cities`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleGoogleSearchPlacesApiCall = (value: string) => {
    this.setState({ searchedLocationData: [] })

    let url = `${configJSON.getGoogleMapsApiEndPoint}/place/autocomplete/json?input=${value}&types=(cities)&key=${configJSON.getGoogleMapsApiKey}`;

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLocationPredictionsAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleSearchForLocation = (value: any) => {
    const searchText = value;
    this.setState({
      isSearchingLocation: true,
      searchedText: searchText,
    });

    clearTimeout(this.timer);

    if (value !== "") {
      this.timer = setTimeout(() => {
        this.handleGoogleSearchPlacesApiCall(value)
      }, 500)
    } else {
      this.setState({
        isSearchingLocation: false
      })
    }


  };

  handleLocationModalCitySelection = (selectedCityName: string) => {
    this.setState({
      cityName: selectedCityName
    });

    this.closeLocationModal();
    this.updateLocation(selectedCityName, this.state.stateName, this.state.countryName)
  };

  navigateToAppliedShyts = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AppliedShyft"
    );

    this.send(message);
  };

  getAllMyShyfts() {
    const header = {
      "Content-Type": configJSON.getMyShyftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = `${configJSON.getMyShyftsApiEndPoint}?sort_order=desc&sort_column=created_at&page=1&per_page=15`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMyShyftsApiMethodType
    );

    this.getMyShyftsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleTime = () => {
    const { myShyfts, ongoingShyft } = this.state

    const shyftEndTime = ongoingShyft.map((shyft: any) => shyft.attributes.shift.attributes.shift_to);
    const punchIn = ongoingShyft.map((shyft: any) => shyft.attributes.activity.attributes.punch_in);

    const current = moment().format('HH:mm:ss')
    const currentTime = moment(current, 'HH:mm:ss');

    const timeDiff = (startTime: moment.Moment, endTime: moment.Moment) => {
      const start = moment(startTime).format('HH:mm:ss');
      const end = moment(start, 'HH:mm:ss');
      const duration = moment.duration(end.diff(endTime));
      return duration.asSeconds();
    }

    const shyftTime = myShyfts.map((shift: any) => {
      const remainingTime = timeDiff(shift.attributes.shift.attributes.shift_from, currentTime);

      const formattedSec = remainingTime <= 0 ? (remainingTime + 86400) : remainingTime
      return formattedSec;
    })

    const nearTime = Math.min(...shyftTime);
    const momentTime = moment();
    const nearestTime = momentTime.add(nearTime, 'seconds');

    const runingShyft = myShyfts.map((shift: any) => {
      const startTime = convertDateIntoTwelveHourFormat(
        shift.attributes.shift.attributes.shift_from
      )

      const endTime = convertDateIntoTwelveHourFormat(
        shift.attributes.shift.attributes.shift_to
      )

      const isBetween = moment().isBetween(moment(startTime, 'hh:mm A'), moment(endTime, 'hh:mm A'));
      return isBetween && shift.attributes.shift.attributes.id;
    }).filter((value: any) => typeof value !== 'boolean');

    const remainingTimeShyft = timeDiff(currentTime, punchIn[0]);
    const dutyTime = timeDiff(shyftEndTime[0], punchIn[0]);

    const timePercent = (remainingTimeShyft / dutyTime) * 100

    this.setState({
      remainingTime: nearTime == Infinity ? 0 : nearTime,
      nearestTime: nearestTime.format("HH:mm:ss"),
      activeShyft: runingShyft[0],
      shyftEndTime: remainingTimeShyft,
      timePercent: Math.round(timePercent),
    });
  }

  handleMyShyfts = (responseJson: any) => {
    const ongoingShyft = responseJson.filter((shyft: any) => shyft.attributes?.activity?.attributes.status === 'ongoing');

    this.setState({
      ongoingShyft,
      myShyfts: responseJson,
    });

    setInterval(this.handleTime, 1000);
  }

  updateLocation = (cityName: string, stateName: string, countryName: string) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      shyfter: {
        shyfter_city: cityName,
        shyfter_state: stateName,
        shyfter_country: countryName
      }
    }

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patchLocationUpdateAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchLocationUpdateApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawFromShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  getTopHotelsAndRestaurants() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTopHotesAndRestaApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    this.getTopHotelsAndRestaAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchForTopShyfts = (value: string) => {
    const searchText = value.trim();
    
    clearTimeout(this.timer);

    this.setState({
      isBusinessSearch: true,
      searchedText: searchText,
    });
    
    if (searchText === "") {
      this.setState({
        isBusinessSearch: false,
      });
    } else {
      this.timer = setTimeout(() => {
        this.getAllShifts(true, searchText);
      }, 500)
    }

  };

  handleUpdateSearchedLocation = (searchedCityName: string, searchedStateName: string, searchedCountryName: string) => {
    this.setState({
      cityName: searchedCityName,
      stateName: searchedStateName,
      countryName: searchedCountryName
    }); 

    this.closeLocationModal();
    this.updateLocation(searchedCityName, searchedStateName, searchedCountryName)
  }

  showUpdateEmailPopUpShyfter=()=>{
    this.setState({updateEmailPopUp: !this.state.updateEmailPopUp, updatedEmail: "", errorPopUp: false })
  }

  showUpdatePhonePopUpShyfter=()=>{
    this.setState({updatePhonePopUp: !this.state.updatePhonePopUp, updatedPhoneNumber: "", errorPopUp: false})
  }

  onChangePhoneNumber = (event: any) => {
    const { value } = event.target;
    let newValue = value;
      
    let changePhoneNumber = newValue.toString();
    const sanitizedValue = changePhoneNumber
      .replace(/[+e-]/g, "")
      .replace(/\D/g, "");
    newValue = sanitizedValue.slice(0, 10);
  
    this.setState({
      ...this.state,
      updatedPhoneNumber: newValue,
      errorPopUp: false
    })
  };

  handleCountryCodeSelectionShyfter = (event: string) => {
    this.setState({ phoneCountryCode: event})
  }

  getPhoneNumberOtpShyfter = () => {
    const { updatedPhoneNumber } = this.state;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPhoneOTPEndpoint}?phone_number=${this.state.phoneCountryCode}${updatedPhoneNumber}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getShyfterPhoneOTPId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  onChangePhoneNumberOTP = (phoneNumberOTP: string) => {
    this.setState({ phoneNumberOTP });
  };

  updateUserPhoneNumber = () => {
    const { updatedPhoneNumber, phoneNumberOTP } = this.state;

    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putUpdatePhoneEndpoint
    );
    this.patchUserProfleShyfterId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      phone_number: `${this.state.phoneCountryCode}${updatedPhoneNumber}`,
      pin: phoneNumberOTP,
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putAPIMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  handleShyfterLogout = () => {
    clearUserLoginData();

    const loginMsg = new Message(getName(MessageEnum.NavigationMessage));
    loginMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    loginMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(loginMsg);
  };

  onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newValue = value;

    newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, "");

    this.setState({ updatedEmail: newValue, errorPopUp: false });
  };

  onChangeEmailOTP = (emailOTP: string) => {
    this.setState({ emailOTP });
  };

  getEmailOtp = () => {
    const { updatedEmail } = this.state;

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getEmailOTPId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postEmailOTPEndpoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email: updatedEmail,
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(request.id, request);
  };

  updateUserEmail = () => {
    const { updatedEmail, emailOTP } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email: updatedEmail,
      pin: emailOTP,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawFromShyftApiMethodType
    );

    this.patchUserProfleShyfterId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };
}
