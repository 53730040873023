import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef, RefObject } from "react";
export const configJSON = require("./config");
import {
  TCreatedShyft,
  TRatings,
  TReview,
  constructURL,
  formatShyftDetails,
  formatShiftsListResponse,
  TAPIShyftDetails,
  constructSearchFilter,
  checkAuthorization,
} from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export interface TShyftItem {
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
  id: number;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  isAvailable?: boolean;
  appliedStatus:string;
}

export interface TShyftRequest {
  id: number;
  removal_reason: string;
  status: string;
  created_at: string;
  updated_at: string;
  worker_id: number;
  shift_id: number;
}

export interface TShyftDetails {
  id: number;
  shyftPlace: string;
  syftTimimg: string;
  saved: boolean;
  requirements: string[];
  description: string;
  employername: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  shyft: string;
  experience: string;
  businessName: string;
  businessId: number;
  businessType: string;
  businessShifts: TCreatedShyft[];
  ratings: TReview[];
  overallRatings: number;
  ratingsCount: number;
  reviewsCount: number;
}

export interface TQueryParams {
  search?: string;
  page?: number;
  perPage?: number;
  filter?: string[];
}

export interface TPagination {
  page_number: number;
  total_records: number;
  records_in_this_page: number;
  records_per_page: number;
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  // Customizable Area Start
  search: string;
  shyftsList: TShyftItem[];
  page: number;
  perPage: number;
  totalRecords: number;
  selectedShyft: number | null;
  totalPages: number;
  shyftDetails: TShyftDetails | null;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  saveShyftId?: number;
  isRegisterationPending: boolean;
  profileComplition: number;
  isLocationModalOpen: boolean;
  filter: string[];
  isLocationSearch: boolean;
  currentLatitude: number;
  currentLongitude: number;
  locationDataBreadCrum: string[],
  locationCountries: [],
  isCountrySelectedForModal: boolean;
  locationDataExplore: [],
  locationStateDataForModal: any[];
  locationCityDataForModal: any[];
  isStateSelectedForModal: boolean;
  isCitySelectedForModal: boolean;
  countryCodeForLocation: string;
  searchedLocationData: [];
  searchTextForLocationPrediction: string;
  countryName: string;
  stateName: string;
  cityName: string;
  userCity: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyShyftsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  scrollObserver: IntersectionObserver | null = null;
  elementRef: RefObject<HTMLDivElement>;
  skipFirstCall = true;
  getAllShyftsAPICallId: string = "";
  getNextShyftsAPICallId: string = "";
  getShyftDetailsAPICallId: string = "";
  getCurrentUserApiCallId: string = "";
  postBookmarkShyftAPICallId: string = "";
  deleteBookmarkShyftAPICallId: string = "";
  postApplyShyftAPICallId: string = "";
  postWithdrawShyftAPICallId: string = "";
  getCurrentLocationUsingLatLongAPICallId: string = "";
  getCountriesDataAPICallId: string = "";
  getStatesDataByCountryAPICallId: string = "";
  getCitiesDataByStateAPICallId: string = "";
  getLocationPredictionsForSearchAPICallId: string = "";
  timer: any = null;
  patchLocationUpdateInExploreAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [
      getName(MessageEnum.NavigationSearchShyftsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      search: "",
      shyftsList: [],
      page: 1,
      perPage: 15,
      totalPages: 1,
      totalRecords: 0,
      shyftDetails: null,
      selectedShyft: null,
      profileComplition: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
      isLocationModalOpen: false,
      filter: [],
      isLocationSearch: false,
      currentLatitude: 0,
      currentLongitude: 0,
      locationDataBreadCrum: [],
      locationCountries: [],
      isCountrySelectedForModal: false,
      locationDataExplore: [],
      locationStateDataForModal: [],
      locationCityDataForModal: [],
      isStateSelectedForModal: false,
      isCitySelectedForModal: false,
      countryCodeForLocation: "",
      searchedLocationData: [],
      searchTextForLocationPrediction: "",
      countryName: "",
      stateName: "",
      cityName: "",
      userCity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.NavigationSearchShyftsMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationSearchShyftsMessage)
      );
      this.setState({ filter: data.filter });
      this.setState({ search: data.search });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.backToLogin();
        return;
      } else {
        this.handleRestAPIResponses(responseJson, apiRequestCallId);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleUpdateLocationData = (data: any) => {
    if (data && data.length > 0) {
      this.setState({
        locationDataExplore: data,
      });
    }
  };

  locationUpdatedRefreshing = () => {
    const { filter, search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getAllShifts({ search, page, perPage, filter }, "initial");
    this.getCountriesForModal();
    this.listenForScrollIntersection();
  }

  handleLocationApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCountriesDataAPICallId) {
      this.setState({
        locationCountries: responseJson,
        isCountrySelectedForModal: true,
        isStateSelectedForModal: false,
        isCitySelectedForModal: false
      });
      this.handleUpdateLocationData(responseJson);
    }

    if (apiRequestCallId === this.getStatesDataByCountryAPICallId) {
      this.setState({
        locationStateDataForModal: responseJson,
        isCountrySelectedForModal: false,
        isStateSelectedForModal: true,
        isCitySelectedForModal: false
      });
      this.handleUpdateLocationData(responseJson);
    }
    if (apiRequestCallId === this.getCitiesDataByStateAPICallId) {
      this.setState({
        locationCityDataForModal: responseJson,
        isCountrySelectedForModal: false,
        isStateSelectedForModal: false,
        isCitySelectedForModal: true,
      });
      this.handleUpdateLocationData(responseJson);
    }

    if (apiRequestCallId === this.getCurrentLocationUsingLatLongAPICallId) {
      const addressComponents = responseJson.results[0].address_components;
      const city = addressComponents.find((component: any) => component.types.includes('locality'));
      const state = addressComponents.find((component: any) => component.types.includes('administrative_area_level_1'));
      const country = addressComponents.find((component: any) => component.types.includes('country'));

      this.setState({
        cityName: city.long_name,
        stateName: state.long_name,
        countryName: country.long_name
      });

      this.updateLocationInExplore(city.long_name, state.long_name, country.long_name)
    }

    if (apiRequestCallId === this.getLocationPredictionsForSearchAPICallId) {
      if (responseJson?.predictions && responseJson?.predictions.length > 0) {
        this.setState({
          searchedLocationData: responseJson.predictions
        });
      }
    }

    if (apiRequestCallId === this.patchLocationUpdateInExploreAPICallId) {
      this.locationUpdatedRefreshing();
    }
  };

  handleRestAPIResponses = (responseJson: any, apiRequestCallId: string) => {
    if (
      [this.getAllShyftsAPICallId, this.getNextShyftsAPICallId].includes(
        apiRequestCallId
      )
    ) {
      this.handleShyftsListingResponse(responseJson, apiRequestCallId);
    }

    if (
      [this.postApplyShyftAPICallId, this.postWithdrawShyftAPICallId].includes(
        apiRequestCallId
      )
    ) {
      this.handleShyftsApplyResponse(responseJson, apiRequestCallId);
    }

    if (
      [
        this.postBookmarkShyftAPICallId,
        this.deleteBookmarkShyftAPICallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleSaveShyftsResponse(apiRequestCallId);
    }

    if (this.getShyftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetailsData(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (this.getCurrentUserApiCallId === apiRequestCallId) {
      this.setCurrentProfileCompletion(
        responseJson.data.attributes.profile_completion,
        responseJson.data.attributes.shyfter_city
      );
    }

    this.handleLocationApiResponse(apiRequestCallId, responseJson);
  };

  handleShyftsListingResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (this.getAllShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.explore_shifts.data,
        responseJson.pagination_details,
        "initial"
      );
    }

    if (this.getNextShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.explore_shifts.data,
        responseJson.pagination_details,
        "next"
      );
    }
  };

  handleShyftsApplyResponse = (response: any, apiRequestCallId: string) => {
    if (this.postApplyShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData(response);
    }

    if (this.postWithdrawShyftAPICallId === apiRequestCallId) {
      this.setState({
        request: undefined,
      });
    }
  };

  handleSaveShyftsResponse = (apiRequestCallId: string) => {

    if (this.postBookmarkShyftAPICallId === apiRequestCallId) {
      this.setExploreSaveShiftData("save");
    }

    if (this.deleteBookmarkShyftAPICallId === apiRequestCallId) {
      this.setExploreSaveShiftData("unsave");
    }
  };

  getCurrentUserProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getCurrentUserApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setCurrentProfileCompletion(profileComplition: number, userCity: string) {
    this.setState({
      profileComplition,
      userCity
    });
  }

  getAllShifts(
    { search, page, filter }: TQueryParams,
    type: "initial" | "next"
  ) {
    const header = {
      "Content-Type": configJSON.getShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getShiftsApiEndPoint,
      search,
      page,
      sortBy: "created_at",
      orderBy: "desc",
    });

    const requestBody = { filter: constructSearchFilter({ filter }) };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftsApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    if (type === "initial") {
      this.getAllShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextShyftsAPICallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShiftsData(
    shifts: TAPIShyftDetails[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const shyftsList = formatShiftsListResponse(shifts);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        shyftsList,
        selectedShyft: shyftsList[0]?.id,
        totalRecords: pagination.total_records,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        shyftsList: [...prevState.shyftsList, ...shyftsList],
      }));
    }
  }

  onSearchShifts = (value: string) => {
    this.setState({ search: value });
  };

  onSelectShift = (shyftId: number) => {
    this.setState({ selectedShyft: shyftId });
  };

  handleExploreFilterShifts = (filter: string[], reset?: string) => {
    const { search, perPage } = this.state;
    this.getAllShifts({ search, page: 1, perPage, filter }, "initial");
    if (reset) {
      this.getAllShifts({ search, page: 1, perPage }, "initial");
    }
  };

  listenForScrollIntersection() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipFirstCall) {
          this.skipFirstCall = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.scrollObserver = new IntersectionObserver(callback);
    if (this.elementRef.current)
      this.scrollObserver.observe(this.elementRef.current);
  }

  async componentDidMount() {
    const isShyfterUser = checkAuthorization(false, true);
    if (!isShyfterUser) {
      this.backToLogin();
      return;
    }
    const { filter, search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getAllShifts({ search, page, perPage, filter }, "initial");
    this.getCountriesForModal();
    this.listenForScrollIntersection();
  }

  backToLogin = () => {
    const exploreShyftMsg = new Message(getName(MessageEnum.NavigationMessage));
    exploreShyftMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    exploreShyftMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(exploreShyftMsg);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.search !== prevState.search) {
      const { search, page, perPage, filter } = this.state;
      this.getAllShifts({ search, page, perPage, filter }, "initial");
    }

    if (this.state.page !== prevState.page) {
      const { search, page, perPage } = this.state;
      this.getAllShifts({ search, page, perPage }, "next");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getShiftsDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.scrollObserver) this.scrollObserver.disconnect();
  }

  getShiftsDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    this.getShyftDetailsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const shyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ shyftDetails, request });
  }

  applyForShyft = (shiftId: number) => {
    if (this.state.profileComplition < 100) {
      this.setState({ isRegisterationPending: true });
      return;
    }

    const header = {
      "Content-Type": configJSON.postApplyShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyShyftApiMethodType
    );

    this.postApplyShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setShyftRequestData = (responseJson: any) => {
    this.setState({
      isShyftApplied: true,
      request: {
        id: responseJson.data.attributes.id,
        status: responseJson.data.attributes.status,
        removal_reason: responseJson.data.attributes.removal_reason,
        updated_at: responseJson.data.attributes.updated_at,
        created_at: responseJson.data.attributes.created_at,
        shift_id: responseJson.data.attributes.id,
        worker_id: responseJson.data.attributes.worker_id,
      },
    });
  };

  withdrawFromShyft = (shiftId: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    this.postWithdrawShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleExploreSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unSaveExploreShyft(shiftId);
    } else {
      this.saveExploreShyft(shiftId);
    }
  };

  saveExploreShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postBookmarkShyftAPICallId = requestMessage.messageId;
    this.setState({ saveShyftId: id });

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unSaveExploreShyft = (id: number) => {

    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteBookmarkShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setExploreSaveShiftData = (type: "save" | "unsave") => {
    const { shyftsList, saveShyftId, shyftDetails } = this.state;
    const updatedList = [...shyftsList];
    const updatedShyftDetails = { ...shyftDetails!};
    const shyftIndex = shyftsList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );

    const detailsShiftIndex = updatedShyftDetails.businessShifts.findIndex(shift => shift.id === saveShyftId);
    
    if (shyftIndex !== -1) {
      updatedList[shyftIndex].saved = type === "save";
    }

    if (detailsShiftIndex !== -1) {      
      updatedShyftDetails.businessShifts[detailsShiftIndex].saved = type === "save";
    }

    this.setState({
      shyftsList: updatedList,
      shyftDetails: updatedShyftDetails
    });
  };

  closeApplyShiftModal = () => {
    this.setState({ isShyftApplied: false });
  };

  closePendingRegistrationModal = () => {
    this.setState({ isRegisterationPending: false });
  };

  handleCompleteNowBtnRedirection = () => {
    this.closePendingRegistrationModal();
    this.navigateExploreToPath("EditShyfterProfilePage");
  }

  openLocationModal = () => {
    this.setState({ isLocationModalOpen: true });
  };

  closeLocationModal = () => {
    this.setState({
      isLocationModalOpen: false,
      locationDataBreadCrum: [],
      locationDataExplore: this.state.locationCountries,
      isCountrySelectedForModal: true,
      isStateSelectedForModal: false,
      isCitySelectedForModal: false,
      isLocationSearch: false,
      searchedLocationData: [],
    });
  };

  onViewBusinessProfile = (businessId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "Explore Shyfts",
        link: "ExploreShyftsPage",
        active: false,
      })
    );
    this.send(msg);
  };

  getCurrentLocation = (latitude: number, longitude: number) => {
    let url = `${configJSON.getGoogleMapsApiEndPoint}/geocode/json?latlng=${latitude},${longitude}&key=${configJSON.getGoogleMapsApiKey}`;

    this.setState({
      isLocationSearch: false,
    });

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCurrentLocationUsingLatLongAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);

    this.setState({
      ...this.state,
      currentLatitude: latitude,
      currentLongitude: longitude,
    });

    this.closeLocationModal();
  };

  getBreadCrumDataForLocation = (data: string[]) => {
    this.setState({
      locationDataBreadCrum: [...this.state.locationDataBreadCrum, ...data],
    });
  };

  getCountriesForModal = () => {
    this.setState({
      locationDataBreadCrum: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesDataAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryStateCityApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleLocationBreadCrumAllStatesInExplore = () => {
    this.setState({ locationDataBreadCrum: [] }, () => {
      this.fetchStatesDataByCountry(this.state.countryCodeForLocation, this.state.countryName);
    });
  }

  fetchStatesDataByCountry = (locationCode: any, selectedCountryName: string) => {
    this.getBreadCrumDataForLocation([selectedCountryName, "All States"]);

    this.setState({
      countryCodeForLocation: locationCode,
      countryName: selectedCountryName,
      locationDataExplore: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStatesDataByCountryAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${locationCode}/states`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  fetchCitiesDataByCountryAndState = (
    locationCode: any,
    selectedStateName: string
  ) => {
    this.getBreadCrumDataForLocation([selectedStateName, "All Cities"]);

    this.setState({
      stateName: selectedStateName,
      locationDataExplore: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCitiesDataByStateAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${this.state.countryCodeForLocation}/states/${locationCode}/cities`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleGoogleSearchingPlacesApiCall = (value: string) => {
    this.setState({ searchedLocationData: [] })

    let url = `${configJSON.getGoogleMapsApiEndPoint}/place/autocomplete/json?input=${value}&types=(cities)&key=${configJSON.getGoogleMapsApiKey}`;

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLocationPredictionsForSearchAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleSearchingForLocation = (value: any) => {
    const searchText = value;
    this.setState({
      isLocationSearch: true,
      searchTextForLocationPrediction: searchText,
    });

    clearTimeout(this.timer);

    if (value !== "") {
      this.timer = setTimeout(() => {
        this.handleGoogleSearchingPlacesApiCall(value)
      }, 500)
    } else {
      this.setState({
        isLocationSearch: false
      })
    }
  };

  handleLocationModalCitySelected = (cityName: string) => {
    this.setState({
      cityName: cityName
    });

    this.closeLocationModal();
    this.updateLocationInExplore(cityName, this.state.stateName, this.state.countryName);
  };

  updateLocationInExplore = (cityName: string, stateName: string, countryName: string) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      shyfter: {
        shyfter_city: cityName,
        shyfter_state: stateName,
        shyfter_country: countryName
      }
    }

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patchLocationUpdateInExploreAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchLocationUpdateEventsBlockApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  handleUpdateSearchedLocationInExplore = (searchedCityName: string, searchedStateName: string, searchedCountryName: string) => {

    this.setState({
      cityName: searchedCityName,
      stateName: searchedStateName,
      countryName: searchedCountryName
    }); 

    this.closeLocationModal();
    this.updateLocationInExplore(searchedCityName, searchedStateName, searchedCountryName)
  }

  navigateExploreToPath = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }
  // Customizable Area End
}
