Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.renderEmpTypeTitle = (type, dataType) => {
  const hotelList = [
    { name: "Luxury", value: "luxury" },
    { name: "Economy", value: "economy" },
    { name: "Mid Scale", value: "midscale" },
    { name: "Resort", value: "resort" },
  ];
  const restaurantList = [
    { name: "Quick Serve", value: "quick_serve" },
    { name: "Casual", value: "casual" },
    { name: "Up Scale", value: "upscale" },
    { name: "Fine Dining", value: "fine_dining" },
  ];
  let arr = [];
  arr = dataType === 'hotel'? hotelList : restaurantList;
  const filtteredObj = arr.filter((e) => e.value === type);
  return filtteredObj.length && filtteredObj[0].name;
};

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.deleteApiMethodType = "DELETE";
exports.userLogoutApiEndPoint = "account_block/logout"

exports.getUserProfileEndPoint = "account_block/current_user";
exports.patchNewNotificationIndicatorEndPoint="account_block/new_notification_false";
exports.patchApiMethodType = "PATCH";

// Customizable Area End