export const ProfileImg = require("../assets/images/ProfileImg.png");

export const dashboardCodebar = require("../assets/images/dashboard-codebar.svg");
export const HomeLogo = require("../assets/images/HomeLogo.png");
export const briefcaseBlank = require("../assets/images/briefcase-blank.png");
export const comments = require("../assets/images/comments.png");
export const dashboardIcon = require("../assets/images/dashboard-icon.svg");
export const headset = require("../assets/images/headset.png");
export const document = require("../assets/images/document.png");
export const shieldCheck = require("../assets/images/shield-check.svg");
export const playstore = require("../assets/images/playstore.svg");
export const placeIcon = require("../assets/images/placeIcon.png");
export const appstore = require("../assets/images/appstore.svg");
export const dashboardImg = require("../assets/images/dashboard-img.svg");
export const crown = require("../assets/images/crown.svg");
export const employerShyftsLandingPage = require("../assets/images/employer-shyfts-landing-page.svg");
export const paymentIcon = require("../assets/images/payment-icon.svg");
export const creditCardBlue = require("../assets/images/credit-card-blue.svg");
export const checkCircleBlue = require("../assets/images/check_circle_dark_blue.png");
export const crownBlue = require("../assets/images/crown-blue.svg");
export const pointerImg = require("../assets/images/pointer-img.svg");
export const settingsSliders = require("../assets/images/settingsSliders.png");
export const NoShyftImg = require("../assets/images/Add_shyft.png");
export const NoSubscriptionImg = require("../assets/images/No_subscription_found.png");
export const successTickIcon = require("../assets/images/image_Accept.svg");

export const loopArrow = require("../assets/images/published_with_changes_black_24dp.png");
export const mealIcon = require("../assets/images/lunchIcon.png");

