// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
export const configJSON = require("./config");

export interface Props {
  isOpen: any;
  activeFilter: string;
  handleClose: () => void;
  applyFilter: (filter: string) => void;
  isFromPastShyft?: boolean;
}

interface S {
  appliedFilter: string;
}

interface SS {
  id: any;
}

export default class SortFilterController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      appliedFilter: "default"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  }

  async componentDidMount() {
    if (this.props.activeFilter) {
      this.setState({ ...this.state, appliedFilter: this.props.activeFilter });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.activeFilter !== prevProps.activeFilter) {
      this.setState({ ...this.state, appliedFilter: this.props.activeFilter });
    }
  }

  onChangeFilter = (filter: string) => {
    this.setState({ ...this.state, appliedFilter: filter });
  };

  handleCloseFilter = () => {
    this.setState({ ...this.state, appliedFilter: this.props.activeFilter });
    this.props.handleClose();
  };

  handleApplyFilter = () => {
    this.props.applyFilter(this.state.appliedFilter);
  };
}
// Customizable Area End
