// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import LandingScreen from '../../components/src/LandingScreen.web';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Favourites from '../../blocks/favourites/src/Favourites';
import AddFavourites from '../../blocks/favourites/src/AddFavourites';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import HelpCentre from '../../blocks/helpcentre/src/HelpCentre';
import HelpCentreQA from '../../blocks/helpcentre/src/HelpCentreQA';
import HelpCentreSub from '../../blocks/helpcentre/src/HelpCentreSub';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import StripeIntegration from '../../blocks/stripepayments/src/StripeIntegration';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import Analytics from '../../blocks/analytics/src/Analytics';
import Customisableusersubscriptions from '../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions';
import SubscriptionDetails from '../../blocks/customisableusersubscriptions/src/SubscriptionDetails';
import LandingPage from '../../blocks/splashscreen/src/LandingPage.web';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import { UserProfileBasicBlock } from '../../blocks/user-profile-basic/src/UserProfileBasicBlock.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3.web';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Share from '../../blocks/share/src/Share';
import ReviewApprovalAdmin from '../../blocks/reviewandapproval/src/ReviewApprovalAdmin';
import ReviewApprovalBasicUser from '../../blocks/reviewandapproval/src/ReviewApprovalBasicUser';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Location from '../../blocks/location/src/Location';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import ApiIntegration from '../../blocks/apiintegration/src/ApiIntegration';
import ContactusWeb from '../../blocks/contactus/src/ContactUsWeb.web';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import StripeGatewayApiFrontend from '../../blocks/StripeGatewayApiFrontend/src/StripeGatewayApiFrontend';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import AccountRegistrationSteps from '../../blocks/email-account-registration/src/AccountRegistrationSteps.web';
import ShyfterAccountRegistrationSteps from '../../blocks/email-account-registration/src/ShyfterAccountRegistrationSteps.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';

import Splashscreen from '../../blocks/splashscreen/src/Splashscreen';
import FileAttachment from '../../blocks/FileAttachment/src/FileAttachment';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import AccountLogin from '../../blocks/email-account-login/src/AccountLogin';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';

import Chat from '../../blocks/chat/src/Chat';
import ChatView from '../../blocks/chat/src/ChatView';
import Search from '../../blocks/search/src/Search';
import CommunityForum from '../../blocks/CommunityForum/src/CommunityForum';
import StoreLocator from '../../blocks/StoreLocator/src/StoreLocator';
import PrivacyAndPolicy from '../../blocks/TermsAndConditions3/src/PrivacyAndpolicy.web.tsx';
import FAQ from '../../blocks/helpcentre/src/FAQ.web';
import Benifits from '../../blocks/helpcentre/src/Benifits.web';
import EmployerHomeAfterSubscription from '../../blocks/landingpage/src/EmployerHomeAfterSubscription.web';

import ShyfterLandingPage from '../../blocks/landingpage/src/ShyfterLandingPage.web';
import PastShyftPage from '../../blocks/events/src/PastShyftPage.web';
import SavedShyftPage from '../../blocks/events/src/SavedShyftePage.web';
import ShyftDetailsPage from '../../blocks/events/src/ShyftDetailsPage.web';
import ExploreShyftsPage from '../../blocks/events/src/ExploreShyftsPage.web';
import RecomendedShyfts from '../../blocks/events/src/RecomendedShyftsPage.web';
import DashboardStats from '../../blocks/landingpage/src/DashboardStats.web';
import MyShyftsPage from '../../blocks/events/src/MyShyftsPage.web';
import AppliedShyft from '../../blocks/events/src/AppliedShyfts';
import ShyfterProfilePage from '../../blocks/CustomisableUserProfiles/src/ShyfterProfilePage.web';
import HelpCenterWeb from '../../blocks/helpcentre/src/HelpCenterWeb.web';
import PrivacyPolicy from '../../blocks/TermsAndConditions3/src/PrivacyPolicy.web';
import EditShyfterProfilePage from '../../blocks/CustomisableUserProfiles/src/EditShyfterProfile.web';
import SubsCheckout from '../../blocks/customisableusersubscriptions/src/SubsCheckout.web';
import { CurrentSubscription } from '../../blocks/customisableusersubscriptions/src/CurrentSubscription.web';
import TermsAndConditionWeb from '../../blocks/TermsAndConditions3/src/TermsAndConditionWeb.web';
import BusinessProfile from '../../blocks/CustomisableUserProfiles/src/ShyfterBusinessProfile.web';
import ApplicantProfilePage from '../../blocks/events/src/ApplicantProfilePage.web';
import Messages from '../../blocks/chat/src/Messages.web';
import UpdateStatusPage from '../../blocks/landingpage/src/UpdateStatusPage.web';
import EmployerBusinessProfile from '../../blocks/CustomisableUserProfiles/src/EmployerBusinessProfile.web';
import EmployerManageAccount from '../../blocks/CustomisableUserProfiles/src/EmployerManageAccount.web';
import { setStorageData } from '../../framework/src/Utilities';
import { eventEmitter } from '../../components/src/Utilities';

const routeMap = {
  ShyfterBenifits: {
    component: Benifits,
    path: '/ShyfterBenifits'
  },
  BusinessProfile: {
    component: BusinessProfile,
    path: '/BusinessProfile'
  },
  EmployerBusinessProfile: {
    component: EmployerBusinessProfile,
    path: '/EmployerBusinessProfile'
  },
  EmployerManageAccount: {
    component: EmployerManageAccount,
    path: '/EmployerManageAccount'
  },
  BusinessBenifits: {
    component: Benifits,
    path: '/BusinessBenifits'
  },
  EmployerSignUp: {
    component: AccountRegistrationSteps,
    path: '/EmployerSignUp'
  },
  ShyfterSignUp: {
    component: ShyfterAccountRegistrationSteps,
    path: '/ShyfterSignUp'
  },
  FAQ: {
    component: FAQ,
    path: '/FAQ'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },
  TermsAndConditions: {
    component: TermsAndConditionWeb,
    path: '/TermsAndConditions'
  },
  Login: {
    component: AccountLogin,
    path: '/Login'
  },
  PrivacyAndPolicy: {
    component: PrivacyAndPolicy,
    path: '/PrivacyAndPolicy'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  ContactUS: {
    component: ContactusWeb,
    path: '/ContactUS'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  AddFavourites: {
    component: AddFavourites,
    path: '/AddFavourites'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  HelpCentre: {
    component: HelpCenterWeb,
    path: '/HelpCentre'
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: '/HelpCentreQA'
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: '/HelpCentreSub'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: '/Customisableusersubscriptions'
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: '/SubscriptionDetails'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: '/ReviewApprovalAdmin'
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: '/ReviewApprovalBasicUser'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  StripeGatewayApiFrontend: {
    component: StripeGatewayApiFrontend,
    path: '/StripeGatewayApiFrontend'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  FileAttachment: {
    component: FileAttachment,
    path: '/FileAttachment'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Chat: {
    component: Chat,
    path: '/Chat'
  },
  ChatView: {
    component: ChatView,
    path: '/ChatView'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  CommunityForum: {
    component: CommunityForum,
    path: '/CommunityForum'
  },
  StoreLocator: {
    component: StoreLocator,
    path: '/StoreLocator'
  },

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },

  EmployerHomePage: {
    component: EmployerHomeAfterSubscription,
    path: '/EmployerHomePage'
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },

  ShyfterLandingPage: {
    component: ShyfterLandingPage,
    path: '/ShyfterDashboard'
  },
  PastShyftPage: {
    component: PastShyftPage,
    path: '/PastShyft'
  },
  SavedShyftPage: {
    component: SavedShyftPage,
    path: '/SavedShyft'
  },
  ShyftDetailsPage: {
    component: ShyftDetailsPage,
    path: '/ShyftsDetails'
  },
  ExploreShyftsPage: {
    component: ExploreShyftsPage,
    path: '/ExploreShyfts'
  },
  RecomendedShyfts: {
    component: RecomendedShyfts,
    path: '/RecomendedShyfts'
  },
  DashboardStats: {
    component: DashboardStats,
    path: '/DashboardStats'
  },
  MyShyftsPage: {
    component: MyShyftsPage,
    path: '/MyShyfts'
  },
  AppliedShyft: {
    component: AppliedShyft,
    path: '/AppliedShyft'
  },
  ShyfterProfilePage: {
    component: ShyfterProfilePage,
    path: '/ShyfterProfile'
  },
  EditShyfterProfilePage: {
    component: EditShyfterProfilePage,
    path: '/ManageShyft'
  },

  SubsCheckout: {
    component: SubsCheckout,
    path: '/Checkout'
  },
  CurrentSubscription: {
    component: CurrentSubscription,
    path: '/CurrentPlan'
  },
  Messages: {
    component: Messages,
    path: '/Messages'
  },

  ApplicantProfile: {
    component: ApplicantProfilePage,
    path: '/ApplicantProfile'
  },

  UpdateStatusPage: {
    component: UpdateStatusPage,
    path: '/UpdateStatus'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

const firebaseCloudMessage = firebase.initializeApp(
  {
    apiKey: 'AIzaSyATftEazCZRS7UmpJ14ZhCzEC3tj3JTp_Q',
    authDomain: 'snagashyft-fdefb.firebaseapp.com',
    projectId: 'snagashyft-fdefb',
    storageBucket: 'snagashyft-fdefb.appspot.com',
    messagingSenderId: '343277764209',
    appId: '1:343277764209:web:b07872881afc0e6d5be5d0',
    measurementId: 'G-BV24L7P2QH'
  },
  'secondary'
);

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    const pushnotifications = firebaseCloudMessage.messaging();

    const intMessaging = pushnotifications.onMessage;

    Notification.requestPermission()
      .then(() => {
        return pushnotifications.getToken(intMessaging, {
          vapidKey:
            'BOsxAD2DHa2CzB3vPgvZysfIIkMpXkEvzc1z6-bdil9MjoAhaygS5uTRFfOUh1fJpN4XPb41LW4PUBR3DNb5Z'
        });
      })
      .then(currentToken => {
        if (currentToken) {
          localStorage.setItem("fcmToken",currentToken)
        }
      })

    pushnotifications.onMessage(function(payload) {
      const { notification } = payload;
      if (Notification.permission === 'granted') {
        const notificationOptions = {
          body: notification.body,
          icon: notification.icon
        };
      const showNotification = new Notification(notification.title, notificationOptions);
      eventEmitter.dispatch("new_notification", showNotification)
      if(notification.click_action){
        showNotification.addEventListener('click', function(event) {
          window.open(notification.click_action, '_blank');
        });
      }
      } 
    });

    return (
      <View
        style={{
          minHeight: '100vh',
          height: 'auto',
          width: '100vw',
          backgroundColor: '#f7faff'
        }}
      >
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
