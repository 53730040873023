import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { createRef, RefObject } from "react";
import {
  TPagination,
  TShyftDetails,
  TShyftRequest,
  TQueryParams,
} from "./ExploreShyftsController.web";

import {
  TAPIShyftDetails,
  constructURL,
  TRatings,
  formatShyftDetails,
  formatShiftsListResponse,
  TShyftItem,
  checkAuthorization,
} from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isLoading: boolean;
  page: number;
  totalPages: number;
  totalShyfts: number;
  search: string;
  appliedShyftsList: TShyftItem[];
  appliedShyftDetails: TShyftDetails | null;
  selectedShyft: number | null;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  profileCompletion: number;
  isLocationModalOpen: boolean;
  isRegisterationPending: boolean;
}

interface SS {
  id: any;
}

export default class SavedShyftController extends BlockComponent<Props, S, SS> {
  eleRef: RefObject<HTMLDivElement>;
  eleObserver: IntersectionObserver | null = null;
  skipCall = true;

  getUserApiCallId: string = "";
  getAppliedShyftsAPICallId: string = "";
  getNextAppliedShyftsAPICallId: string = "";
  getAppliedShyftDetailsAPICallId: string = "";
  postWithdrawFromShyftAPICallId: string = "";
  deleteRemoveFromShyftList: string = "";

  constructor(props: Props) {
    super(props);
    this.eleRef = createRef<HTMLDivElement>();
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: true,
      page: 1,
      totalPages: 1,
      totalShyfts: 0,
      search: "",
      appliedShyftsList: [],
      selectedShyft: null,
      appliedShyftDetails: null,
      profileCompletion: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
      isLocationModalOpen: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  viewExploreShyfts = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    this.send(message);
  };

  getUserProfile() {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserApiCallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );

    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  setUserProfileCompletion(profileCompletion: number) {
    this.setState({
      profileCompletion,
    });
  }

  getAppliedShyfts({ search, page }: TQueryParams, type: "initial" | "next") {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    if (type === "initial") {
      this.getAppliedShyftsAPICallId = reqMessage.messageId;
    } else {
      this.getNextAppliedShyftsAPICallId = reqMessage.messageId;
    }

    const endpoint = constructURL({
      endpoint: configJSON.getAppliedShyftApiEndPoint,
      search,
      page,
    });

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const header = {
      "Content-Type": configJSON.getAppliedShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppliedShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  listenForScrollChanges() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipCall) {
          this.skipCall = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };

    this.eleObserver = new IntersectionObserver(callback);
    if (this.eleRef.current) this.eleObserver.observe(this.eleRef.current);
  }

  handleAppliedShyftsList(
    shifts: any[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const shyftsList = shifts.map((shyft) => ({
      ...shyft.attributes.shift,
      status: shyft.attributes.status,
    })) as TAPIShyftDetails[];

    const appliedShyftsList = formatShiftsListResponse(shyftsList);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        totalShyfts: pagination.total_records,
        appliedShyftsList,
        selectedShyft: appliedShyftsList[0]?.id,
        isLoading: false,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        appliedShyftsList: [
          ...prevState.appliedShyftsList,
          ...appliedShyftsList,
        ],
      }));
    }
  }

  getAppliedShyftDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAppliedShyftDetailsAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const appliedShyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ appliedShyftDetails, request });
  }

  withdrawFromTheShyft = (shiftId: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawFromShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  removeShyftFromList = () => {
    const { request } = this.state;
    const requestId = request?.id;

    const header = {
      "Content-Type": configJSON.deleteShyftFromListApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteRemoveFromShyftList = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteShyftFromListApiEndPoint}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request_id: requestId,
      })
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteShyftFromListApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setAppliedShyftsList = () => {
    const { appliedShyftsList, selectedShyft } = this.state;
    const currentIndex = appliedShyftsList.findIndex(
      (item) => item.id == selectedShyft
    );

    const updatedList = appliedShyftsList.filter(
      (shyft) => shyft.id !== selectedShyft
    );

    if (!updatedList.length) {
      this.setState({
        appliedShyftsList: [],
        appliedShyftDetails: null,
        selectedShyft: null,
      });
    }

    const prevItem = updatedList[currentIndex - 1];
    const newItem = updatedList[currentIndex];

    this.setState({
      appliedShyftsList: updatedList,
      selectedShyft: newItem?.id ?? prevItem.id,
    });
  };

  onSelectShift = (shyftId: number) =>
    this.setState({ selectedShyft: shyftId });

  onViewBusinessProfile = (businessId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "Applied Shyfts",
        link: "AppliedShyft",
        active: false,
      })
    );
    this.send(msg);
  };

  async componentDidMount() {
    const isAuthorizedShyfterUser = checkAuthorization(false, true);
    if (!isAuthorizedShyfterUser) {
      const appliedShyftsMsg = new Message(getName(MessageEnum.NavigationMessage));
      appliedShyftsMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
      appliedShyftsMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(appliedShyftsMsg);
      return;
    }
    const { search, page } = this.state;
    this.getUserProfile();
    this.getAppliedShyfts({ search, page }, "initial");
    this.listenForScrollChanges();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.search !== prevState.search) {
      const { search, page } = this.state;
      this.getAppliedShyfts({ search, page }, "initial");
    }

    if (this.state.page !== prevState.page) {
      const { search, page } = this.state;
      this.getAppliedShyfts({ search, page }, "next");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getAppliedShyftDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.eleObserver) this.eleObserver.disconnect();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.getAppliedShyftsAPICallId === apiRequestCallId) {
        this.handleAppliedShyftsList(
          responseJson.applied_shifts.data,
          responseJson.pagination_details,
          "initial"
        );
      }

      if (this.getNextAppliedShyftsAPICallId === apiRequestCallId) {
        this.handleAppliedShyftsList(
          responseJson.applied_shifts.data,
          responseJson.pagination_details,
          "next"
        );
      }

      if (this.getAppliedShyftDetailsAPICallId === apiRequestCallId) {
        this.handleShyftDetailsData(
          responseJson.shyft_details.data,
          responseJson.shyft_details.meta.ratings_data,
          responseJson.shyft_details.meta.request
        );
      }

      if (this.getUserApiCallId === apiRequestCallId) {
        this.setUserProfileCompletion(
          responseJson.data.attributes.profile_completion
        );
      }

      if (
        this.postWithdrawFromShyftAPICallId === apiRequestCallId ||
        this.deleteRemoveFromShyftList === apiRequestCallId
      ) {
        this.setAppliedShyftsList();
      }
    }
  }
}
