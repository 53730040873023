import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  notificationList: any[];
  page: number;
  totalPage: number;
}

interface SS {
  id: any;
}

export default class NotificationListController extends BlockComponent<
  Props,
  S,
  SS
> {
  getAllNotificationAPIId: string = "";
  readNotificationAPIId: string = "";
  notificationRef: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.notificationRef = React.createRef();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      notificationList: [],
      page: 1,
      totalPage: 1
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getNotificationList(1);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getAllNotificationAPIId && responseJson?.push_notifications && responseJson?.pagination_details) {
        if (this.state.page === 1) {
          this.setState({...this.state, notificationList: responseJson.push_notifications, totalPage: responseJson.pagination_details?.total_pages});
        } else {
          this.setState({...this.state, notificationList: [...this.state.notificationList, ...responseJson.push_notifications]})
        }
      }
      if (apiRequestCallId === this.readNotificationAPIId && responseJson?.push_notification) {
        this.setReadNotificationResponse(responseJson?.push_notification);
      }
    }
  }

  setReadNotificationResponse = (notification: any) => {
    const notifications = [...this.state.notificationList];
    notifications.forEach((item) => {
      const obj = item.data.find((ob: any) => ob.id === notification?.data?.id);
      if (obj) {
        obj.attributes.is_read = true;
      }
    });
    this.setState({...this.state, notificationList: notifications});
  }

  handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = this.notificationRef.current;

    if (scrollHeight - scrollTop === clientHeight && this.state.page <= this.state.totalPage) {
      this.setState({ ...this.state, page: this.state.page + 1 }, () => {
        this.getNotificationList(this.state.page);
      })
    }
  };

  getNotificationList = (page: number) => {
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const getNotificationsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllNotificationAPIId = getNotificationsAPIMsg.messageId;

    getNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsAPIEndPoint +
        `?page=${page}&per_page=10&sort_column=created_at&sort_order=desc`
    );

    getNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(getNotificationsAPIMsg.id, getNotificationsAPIMsg);
  };

  readNotification = (notification: any) => {
    const id = notification?.id;
    const isRead = notification?.attributes?.is_read;
    if (isRead) {
      return;
    }
    const headers = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.apiContentType,
    };

    const readNotificationsAPIMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.readNotificationAPIId = readNotificationsAPIMsg.messageId;

    readNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNotificationsAPIEndPoint+`/${id}`
    );

    readNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    readNotificationsAPIMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(readNotificationsAPIMsg.id, readNotificationsAPIMsg);
    this.redirectedFromNotification(notification);
  };

  redirectedFromNotification = (notification: any) => {
    const type = notification?.attributes?.notify_type;
    const chatId = notification?.attributes?.chat_id;
    if (type === "chat") {
      if (chatId) {
        localStorage.setItem("activeChatId", chatId);
      }
      this.navigateToScreen("Messages");
    }
    if (type === "requests") {
      this.navigateToScreen("EmployerHomePage");
    }
    if (type === "accept_request") {
      this.navigateToScreen("MyShyftsPage");
    }
    if (type === "subscription") {
      this.navigateToScreen("CurrentSubscription");
    }
    if (type === "end_shift") {
      this.navigateToScreen("PastShyftPage");
    }
  }

  navigateToScreen = (path: string) => {
    const notificationNavigateMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    notificationNavigateMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      path
    );
    notificationNavigateMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(notificationNavigateMsg);
  }
}
