import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  Accordion,
  AccordionSummary,
  AccordionDetails
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import './style.css';
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import { Croupier, Hotel } from "./assets";
import FAQController, { Props } from "./FAQController";
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

const webStyle={
  pageTitle:{
    fontSize:'2.125rem',
    fontWeight:600
  },
  pageSubtitles:{
    color:'white'
  },
  accordion:{ boxShadow: '0px 0px 10px rgb(0, 0, 0,0.06)', padding: '0 16px', borderRadius: '12px' },
  accordionSummary:{ padding: "0" },
  accordionDetails:{ padding: "8px 0 16px" },
  summaryText:{ color: 'black', fontSize: '18px', fontWeight: 600 },
  detailsText:{ color: 'gray', fontWeight: 600, fontSize: '14px' }
}



export default class FAQ extends FAQController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

      <ThemeProvider theme={theme}>
        <GenericHeader navigation={this.props.navigation} onNavigate={this.onNavigateFromFaq} onSignUp={this.toggleSignUpForFaq} />
        {this.state.isOpenSignUpDialog && (
          <AccountTypeSelectionDialog
            isOpen={this.state.isOpenSignUpDialog}
            handleSave={this.redirectToSignUpPage}
            handleClose={this.toggleSignUpForFaq}
          />
        )}
        <Box className="commonheader">
          <Box className="firstsection-heading commonheader-heading">
            <Typography style={webStyle.pageTitle} variant="h5" className="heading-main" data-test-id="test-heading">Snagashyft <span>FAQ's</span></Typography>
            <label className="heading-mainsub">
              <span style={webStyle.pageSubtitles} className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = `/FAQ`} style={webStyle.pageSubtitles} >FAQ</span>
            </label>
          </Box>
        </Box>
        <Box className="for-business borderbottom">
          <div className="faqbody-left firstSection-left">
            <img src={Hotel}></img>
            <label style={{fontSize:'30px',fontWeight:600}}>Frequently Asked Questions for <span>BUSINESSES</span></label>
          </div>
          <div className="faqbody-right">
            <Accordion style={webStyle.accordion} className="bodyright-accordian"
              key={1}
              expanded={this.state.expandAccordian[1]}
              onChange={() => this.handleAccordionToggle(1)}>
              <AccordionSummary
                style={webStyle.accordionSummary}
                expandIcon={this.state.expandAccordian[1] ? <RemoveCircleOutlineIcon style={{ color: 'black' }} /> : <AddCircleOutline style={{ color: 'black' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={webStyle.summaryText} >Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails style={webStyle.accordionDetails}>
                <Typography style={webStyle.detailsText} >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>
        <Box className="for-shyfter">
          <div className="faqbody-right">
            <Accordion style={webStyle.accordion} className="bodyright-accordian"
              key={2}
              expanded={this.state.expandAccordian[2]}
              onChange={() => this.handleAccordionToggle(2)}>
              <AccordionSummary
                style={webStyle.accordionSummary}
                expandIcon={this.state.expandAccordian[2] ? <RemoveCircleOutlineIcon style={{ color: 'black' }} /> : <AddCircleOutline style={{ color: 'black' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={webStyle.summaryText}>Accordion 1</Typography>
              </AccordionSummary>
              <AccordionDetails style={webStyle.accordionDetails}>
                <Typography style={webStyle.detailsText}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                  malesuada lacus ex, sit amet blandit leo lobortis eget.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="faqbody-left secondsection-right">
            <img src={Croupier}></img>
            <label style={{fontSize:'30px',fontWeight:600}}  >Frequently Asked Questions for <span>SHYFTERS</span></label>
          </div>
        </Box>
        <GenericFooter navigation={this.props.navigation} onAboutClick={this.onAboutClick}/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
