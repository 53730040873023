import React from "react";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import { Box, Button, InputBase, Typography } from "@material-ui/core";
import { attachedIcon, emojiIcon, remove, uploadIcon } from "./assets";

interface ChatFooterProps {
  msgText: string;
  attachment: any;
  removeAttachment: () => void;
  onSendMsg: () => void;
  handleChangeMsgText: (e: any) => void;
  toggleEmojiPicker: () => void;
  handleFileUpload: (e: any) => void;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyle = makeStyles({});

export default function ChatFooter(props: ChatFooterProps) {
  const classes = useStyle();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    
    if (event.key === 'Enter' && props.attachment) {
      event.preventDefault();
      props.onSendMsg();
    }
    if (event.key === 'Enter' && !event.shiftKey && props.msgText.trim()) {
      event.preventDefault();
      props.onSendMsg();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        position={"absolute"}
        right={"30px"}
        left={"20px"}
        width={"calc(100% - 10px)"}
        bottom={20}
        minHeight={"65px"}
        zIndex={2}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          width={"calc(100% - 20px)"}
          border={"1px solid #E1E6EC"}
          borderRadius={"14px"}
          bgcolor={"#fff"}
          boxShadow={"rgba(23, 24, 29, 0.1) 0px 3px 6px"}
        >
          <Box margin="auto 15px" display="flex">
            <Box>
              {props.attachment && <Box style={webStyles.attachmentWrapper}>
                <Box style={webStyles.attachmentInnerWrapper}>
                  <Typography style={webStyles.attachmentName}>
                    {props.attachment.name}
                  </Typography>
                  <span data-test-id='remove-attachment' style={{cursor:'pointer'}} onClick={props.removeAttachment}><img src={remove} alt="remove"  /></span>
                </Box>
              </Box>}
              <Button
                style={{
                  background: "transparent",
                  padding: 0,
                  minWidth: "24px",
                  boxShadow: "none",
                }}
                component="label"
                variant="contained"
              >
                <VisuallyHiddenInput
                  type="file"
                  onChange={props.handleFileUpload}
                  data-test-id="file-upload"
                />

                <img
                  src={attachedIcon}
                  style={{ cursor: "pointer" }}
                  alt="attachedIcon"
                  height={"24px"}
                />
              </Button>
            </Box>
            <span onClick={props.toggleEmojiPicker} data-test-id="emoji-icon">
              <img
                src={emojiIcon}
                style={{ cursor: "pointer", paddingLeft: "10px" }}
                alt="emojiIcon"
                height={"24px"}
              />
            </span>
          </Box>
          <InputBase
            multiline
            placeholder={"Send a message…"}
            fullWidth
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: "14px",
              borderLeft: "1px solid #E1E6EC",
              paddingLeft: "10px",
              width: "86%",
              minHeight: '60px',
              maxHeight: '100px',
            }}
            data-test-id="send-msg-input"
            value={props.msgText}
            onChange={props.handleChangeMsgText}
            onKeyDown={handleKeyDown}
          />
          <Box margin="auto 10px" onClick={props.onSendMsg} data-test-id="send-msg-btn">
            <img
              src={uploadIcon}
              style={{ cursor: "pointer" }}
              alt="uploadIcon"
              height={"36px"}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

const webStyles = {
  attachmentWrapper :{
    position: 'absolute' as const,
    top: '-54px',
    boxShadow: 'rgba(23, 24, 29, 0.1) 0px 3px 6px',
    left: 0,
    border: '1px solid rgb(225, 230, 236)',
    borderRadius: '21px',
    display: 'flex',
    justifyContent: 'center',
    height: '70px',
    zIndex: -1,
    alignItems: 'self-start',
    padding: '12px 20px',
    backgroundColor:'white'
  },
  attachmentInnerWrapper:{ display: 'flex', alignItems: 'center',backgroundColor:'rgb(225, 230, 236)',borderRadius:'18px',padding:'4px 13px',maxWidth:'200px' } as React.CSSProperties,
  attachmentName:{ fontSize: '14px', textDecoration: 'underline', textTransform: 'none', marginRight: '5px',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' } as React.CSSProperties
}