// Customizable Area Start
import React from "react";
import { Box, Button, Checkbox, Divider, Typography } from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import UpdateStatusPageController, {
    Props,
} from "./UpdateStatusPageController.web";
import { 
    breadCrumUpdateStatusPath, convertDateIntoDateMonthYearFormat, convertDateIntoDay,
    convertDateIntoTwelveHourFormat, formatHoursWorked 
} from "../../../components/src/Utilities";
import { checkCircleBlue, loopArrow, mealIcon } from "./assets";
import { QueryBuilderSharp } from "@material-ui/icons";
import CustomSelectWithImage from "../../../components/src/CustomSelectFieldWithImage.web";
import CustomTimePickerDropdown from "../../../components/src/CustomTimePickerDropdown.web";
import CustomSelectOptionsWithImage from "../../../components/src/CustomSelectOptionsWithImage.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";

const theme = createTheme({
    palette: {
        primary: {
            main: "#390879",
            light: "#6f7c8e"
        },
        secondary: {
            main: "#0a0412"
        }
    }
});

const styles = {
    updateStatusButton: {
        width: "80%",
        height: "45px",
        borderRadius: "24px",
        backgroundColor: "#390879",
        textTransform: "capitalize" as "capitalize",
        color: "#fff",
        left: "40px",
        fontSize: "14px",
        fontWeight: "bold" as "bold",
        "&:hover": {
            backgroundColor: "#390879",
            color: "#fff",
        },
        "& span": {
            fontSize: "14px",
        }
    },

    titleTextStyle: {
        fontSize: "18px",
        fontWeight: 600,
        textAlign: "center" as "center",
    },

    boxOuterborderStyle: {
        border: "1px solid lightgrey",
        borderRadius: "10px",
        margin: "10px auto",
        minHeight: "40px",
        backgroundColor: "#fff",
        width: "80%",
    },
    card: {
        border: "1px solid #e1e6ec",
        borderRadius: "10px",
        backgroundColor: "#fff",
        position: "relative" as "relative"
    },

    cardbodyText: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "15px",
        color: "#000",
    },

    cardInactiveSubHeading: {
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "15px",
        color: "#6f7c8e",
    },

    updateTimeTextStyl: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#390879",
        textDecorationLine: "underline",
    },

    updateTimeBoxWrapper: {
        display: "flex",
        flexDirection: "row" as "row",
        alignItems: "center",
        justifyContent: "space-around",
        backgroundColor: "#f2f2f2",
        padding: "10px",
        width: "94%",
        margin: "10px auto",
        borderRadius: "10px",
        minHeight: "40px",
    },

    punchInOutTitle: {
        fontSize: "14px",
        fontWeight: 500,
    },

    addMealCheckBoxWrapper: {
        border: "1px solid lightgrey",
        padding: "2px 8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "40px",
        width: "95%",
        margin: "5px auto",
    }

};

const mealBreakOptions = () => {
    return [
        { name: "00 Mins", value: "00 Mins" },
        { name: "15 Mins", value: "15 Mins" },
        { name: "30 Mins", value: "30 Mins" },
    ]
}

const statusOptions = () => {
    return [
        { name: "End Shyft", value: "End Shyft", boxBgColor: "#ff375b", boxInitial: "M"  },
        { name: "Start Shyft", value: "Start Shyft", boxBgColor: "#2da406", boxInitial: "C" },
    ]
}

class UpdateStatusPage extends UpdateStatusPageController {
    constructor(props: Props) {
        super(props);
    }

    coloredBoxWithIntials = (baseColor: string, intial: string) => {
        return (
            <Box
                height={"40px"}
                width={"40px"}
                marginRight={"5px"}
                display={"flex"}
                borderRadius={"10px"}
                alignItems={"center"}
                justifyContent={"center"}
                bgcolor={baseColor}
            >
                <Typography style={{ fontWeight: "bold", fontSize: "22px", color: "#fff" }}>{intial}</Typography>
            </Box>
        )
    }

    renderPunchInOut = (
        title: string, 
        amPmVal: string, amPmName: string, 
        hourVal: string, hourName: string,
        minVal: string, minName: string,
    ) => {
        return (
            
                <Box width={"42%"}>
                    <Typography style={styles.punchInOutTitle}>{title}</Typography>
                    <CustomTimePickerDropdown
                        error={!!this.state.timeErr}
                        selectedValue={amPmVal}
                        options={[
                            { name: "AM", value: "AM" },
                            { name: "PM", value: "PM" },
                        ]}
                        handleDropdownChange={(e) => this.handleTimeValueChang(e, amPmName)}
                        hour={hourVal}
                        handleHourChange={(e) => this.handleTimeValueChang(e, hourName)}
                        minute={minVal}
                        handleMinuteChange={(e) => this.handleTimeValueChang(e, minName)}
                    />
                </Box>
        )
    }

    renderCurrentStatusCard = (item: any) => {
        return (
                <Box
                    style={styles.card}
                    margin={"10px"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                >
                    <Box
                        margin={"10px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Box display={"flex"} alignItems={"center"}>
                            {(item?.attributes?.status === "completed") && this.coloredBoxWithIntials("#2da406", "C")}
                            {(item?.attributes?.status === "missed") && this.coloredBoxWithIntials("#ff375b", "M")}
                            <Box>
                                <Typography style={styles.cardbodyText}>
                                    {convertDateIntoDateMonthYearFormat(item?.attributes?.created_at, false)}
                                </Typography>
                                <Typography style={styles.cardInactiveSubHeading}>
                                    {convertDateIntoDay(item?.attributes?.created_at)}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box display={"flex"} alignItems={"center"} alignSelf={'end'} style={{ float: 'right' }}>
                                <QueryBuilderSharp
                                    style={{ height: "15px", width: "15px", color: "#0091ff" }}
                                />
                                <Typography style={{ color: "#0091ff", fontSize: '14px', marginLeft: '4px' }}>
                                    {formatHoursWorked(item?.attributes?.hours_worked, false)}
                                </Typography>
                            </Box>
                            <Box maxWidth={"100px"} alignSelf={'end'}>
                                <Typography style={styles.cardbodyText}>
                                    Hours Worked
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
    
                    <Divider style={{ margin: '0px 10px' }} />
    
                    {(item?.attributes?.status === "completed") &&
                        <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Box display={"flex"} alignItems={"center"} paddingLeft={"8px"}>
                                <Box padding={"10px"}>
                                    <Typography
                                        style={{
                                            color: "#2da406",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                        }}
                                    >
                                        Punched In
                                    </Typography>
                                    <Typography style={styles.cardbodyText}>
                                        {convertDateIntoTwelveHourFormat(item?.attributes?.punch_in)}
                                    </Typography>
                                </Box>
                                <Divider orientation="vertical" flexItem style={{ margin: '15px 0px' }} />
                                <Box padding={"10px"}>
                                    <Typography
                                        style={{
                                            color: "#ff375b",
                                            fontWeight: 700,
                                            fontSize: "12px",
                                        }}
                                    >
                                        Punched Out
                                    </Typography>
                                    <Typography style={styles.cardbodyText}>
                                        {convertDateIntoTwelveHourFormat(item?.attributes?.punch_out)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box 
                                data-test-id="currentStatsUpdateTimeID" display={"flex"} alignItems={"center"}
                                padding={"10px"} onClick={() => this.handleUpdateTime()}
                            >
                                <Typography style={styles.updateTimeTextStyl}>Update Time</Typography>
                            </Box>
                        </Box>
                    }
    
                    {this.state.isUpdateTime &&
                        this.renderPunchInOutBoxWrapper()
                    }
                </Box>
        )
    }

    renderUpdateMealBreak = () => {
        return (
            <Box width="98%" paddingLeft="8px" marginTop={"20px"}>
                <CustomSelectWithImage
                    value={this.state.mealBreakVal}
                    handleChange={(event: any) => this.handleMealBreakValue(event)}
                    label="Update Meal Break"
                    name="mealBreak"
                    error={!!this.state.mealBreakErr}
                    options={mealBreakOptions()}
                />
            </Box>
        )
    }

    renderChangeStatusCard = () => {
        return (
            <Box width="98%" paddingLeft="8px" marginTop={"20px"}>
                <CustomSelectOptionsWithImage
                    value={this.state.changeStatusToValue}
                    toDisabled={this.state.statusType === "completed" ? "Start Shyft": "End Shyft"}
                    handleChange={(event: any) => this.handleChangeStatusTo(event)}
                    label="Select Status"
                    name="status"
                    error={false}
                    options={statusOptions()}
                />
            </Box>
        )
    }

    renderPunchInOutBoxWrapper = () =>{
        return (
            <Box style={styles.updateTimeBoxWrapper} data-test-id="punchInOutBoxWrapperId">
                {this.renderPunchInOut(
                            "Punched In", this.state.punchInAmPm,"punchInAmPm",
                            this.state.punchInHourTime, "punchInHourTime",
                            this.state.punchInMinTime, "punchInMinTime"
                )}
                {this.renderPunchInOut(
                            "Punched Out", this.state.punchOutAmPm,"punchOutAmPm",
                            this.state.punchOutHourTime, "punchOutHourTime",
                            this.state.punchOutMinTime, "punchOutMinTime"
                )}
            </Box>
        )
    }

    renderAddMealBreakCheckBox = () => {
        return(
            <Box style={styles.addMealCheckBoxWrapper}>
                <Box display={"flex"} flexDirection={"row"} width={"30%"} alignContent={"center"} justifyContent={"space-evenly"}>
                    <img src={mealIcon} alt="add_meal_icon" width={"22px"} height={"20px"} style={{ alignSelf: "center" }}/>
                    <Divider orientation="vertical" flexItem  style={{ height: "30px" }}/>
                    <Typography style={{ alignSelf: "center" }}>Add Meal Break</Typography>
                </Box>

                <Box>
                    <Checkbox
                        data-test-id="addMealCheckboxId"
                        onClick={() => this.handleAddMealCheck()}
                        icon={
                            <div 
                                style={{ 
                                    width: '18px', height: "18px", borderRadius: '50px', 
                                    backgroundColor: 'white', border: '2px gray solid'
                                }}
                            />
                        }
                        checkedIcon={
                            <img 
                                src={checkCircleBlue} 
                                alt="chekced_img" 
                                style={{ width: '24px', height: '24px' }} 
                            />
                        }
                        checked={this.state.isAddMealCheck}
                    />
                </Box>
            </Box>
        )
    }

    renderSelectAddMealBreak = () => {
        return (
            <Box style={styles.updateTimeBoxWrapper}>
                <Box width={"100%"} marginTop={"10px"} marginBottom={"-5px"}>
                    <CustomSelect 
                        value={this.state.mealBreakVal}
                        handleChange={(event) => this.handleMealBreakValue(event)}
                        label={"Meal Break"}
                        name="mealBreak"
                        error={ !!this.state.mealBreakErr }
                        options={mealBreakOptions()}
                    />
                </Box>
            </Box>
        )
    }

    render() {
        const { updateStatusData } = this.state;
        
        return (
            <ThemeProvider theme={theme}>
                <LandingPageHeader {...this.props} isBreadcrumbs={true} breadCrumsData={breadCrumUpdateStatusPath}
                    currentPage={"Update Status"} pageTitle={"Update Status"} isShowDetails={true}
                    isUpdateUser={false}
                />
                <Box width={"50%"} margin={"8% auto"} minHeight={"90vh"} paddingTop={"10px"}>
                    <Box>
                        <Typography style={styles.titleTextStyle}>Current Status</Typography>

                        <Box style={styles.boxOuterborderStyle} width={"90%"} padding={"10px"}>
                            {updateStatusData && this.renderCurrentStatusCard(updateStatusData)}
                            {this.state.statusType === "completed" && this.renderUpdateMealBreak()}
                        </Box>
                    </Box>

                    <Box>
                        <Box 
                            width={"85%"} margin={"10px auto"} display={"flex"} 
                            flexDirection={"row"} justifyContent={"center"} alignItems={"center"}
                        >
                            <Divider style={{ margin: '0px 10px', height: "1px", width: "34%" }} />
                            <img src={loopArrow} alt={""} width={"16px"} height={"16px"} style={{ marginRight: "8px" }} />
                            <Typography style={styles.titleTextStyle}>Change Status</Typography>
                            <Divider style={{ margin: '0px 10px', height: "1px", width: "34%" }} />
                        </Box>

                        <Box style={styles.boxOuterborderStyle} width={"90%"} padding={"10px"}>
                            {this.renderChangeStatusCard()}
                            {this.state.changeStatusToValue === "Start Shyft" && this.renderPunchInOutBoxWrapper()}
                            {this.state.statusType !== "completed" && this.renderAddMealBreakCheckBox()}
                            {this.state.isAddMealCheck && this.renderSelectAddMealBreak()}
                        </Box>
                    </Box>

                    <Box marginTop={"50px"}>
                        <Box width={"50%"} margin={"10px auto"}>
                            <Button
                                style={styles.updateStatusButton}
                                variant="contained"
                                data-test-id="updateNowBtnId"
                                onClick={() => this.handleUpdateStartOrEndShyftApi()}
                            >
                                Update Now
                            </Button>
                        </Box>
                    </Box>

                </Box>
                <Footer />

                <SubmitSuccessPopUp
                    data-test-id="updaetSuccessModalID"
                    statusDate={this.state.currentStatusDate}
                    isUpdateSatus={true}
                    isFromOngoingActivity={true}
                    open={this.state.isStatusUpdated}
                    backToLogin={() =>  this.handleCloseUpdateSuccessModal()}
                />
            </ThemeProvider>
        );
    }
}

export default UpdateStatusPage;
// Customizable Area End