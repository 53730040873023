import React from "react";
import {
  Box,
  Dialog,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
import TimerWithCircularProgressBar from "./TimerWithCircularProgressBar";

interface IShyfterFeedbackModalProps {
  isOpen: boolean;
  imageSrc: string;
  title: string;
  description: string;
  onClose: () => void;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
  isProfileCompletion?: boolean;
  profileCompletionPercent?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 30,
    borderRadius: 20,
    overflowY: "visible",
  },
  closeIcon: {
    position: "absolute",
    border: "1px rgb(255, 255, 255, 0.3) solid",
    padding: "5px",
    top: "-40px",
    right: "0px",
    borderRadius: "50%",
    backgroundColor: "rgb(0 0 0 / 55%)",
    color: "#fff",
  },
  title: {
    textTransform: "capitalize",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: "12px",
    paddingTop: "12px",
    color: "#0a0412",
  },
  description: {
    fontSize: "14px",
    color: "#6f7c8e",
    textAlign: "center",
    width: "65%",
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    marginTop: "12px",
  },
}));

const ShyfterFeedbackModal: React.FC<IShyfterFeedbackModalProps> = ({
  isOpen,
  onClose,
  title,
  description,
  imageSrc,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  isProfileCompletion,
  profileCompletionPercent
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.root }}
    >
      <IconButton className={classes.closeIcon} data-test-id="close-btn-modal" onClick={onClose}>
        <CloseIcon width={20} height={20} />
      </IconButton>

      <Box display="flex" justifyContent="center">
        { isProfileCompletion ? 
            <TimerWithCircularProgressBar
              percent={profileCompletionPercent}
              size={70}
              isShyftPage={false}
            />
            :
            <img src={imageSrc} alt="" width={64} />
          }
      </Box>
      <Box
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        display="flex"
      >
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.description}>{description}</Typography>
      </Box>
      <Box className={classes.buttons}>
        {primaryButtonText && (
          <CustomButton
            isOutlined
            label={primaryButtonText}
            handleClick={primaryButtonOnClick}
          />
        )}
        {secondaryButtonText && (
          <CustomButton
            label={secondaryButtonText}
            handleClick={secondaryButtonOnClick}
          />
        )}
      </Box>
    </Dialog>
  );
};

export default ShyfterFeedbackModal;
