import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  Link,
} from "@material-ui/core";
import { ArrowBack, PhoneIphone } from "@material-ui/icons";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import OptInPopUp from "../../../components/src/OtpPopUp";
import LoginWrapper from "../../../components/src/LoginWrapper";
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";
import "./style.css";
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";
import { LoginAlert } from "../../../components/src/LoginAlert";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      // light: will be calculated from palette.primary.main,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: "#FF375B",
    },
  },
});

const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "uppercase",
    paddingLeft: 0
  },
  "& label": {
    fontSize: 13,
    fontStyle: "normal",
    transform: "translate(14px, 16px) scale(1)",
  },
  "& label[data-shrink='false']": {
    paddingLeft: 32
  },
  "& label.MuiInputLabel-shrink": {
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "uppercase",
    paddingLeft: 0
  },
  "& label.MuiInputLabel-shrink-80": {
    fontStyle: "normal",
    fontWeight: "400",
    textTransform: "uppercase",
    paddingLeft: 0
  },
})

const isVerifyOTPDisabled = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
  }
  setOtp = (otp: string) => this.setState({ otp });

  render() {
    return (
      // Required for all blocks
      <LoginWrapper>
        <div>
          {this.state.alertOpened && this.state.error && (
            <LoginAlert onClose={this.closeAlert} message={this.state.error} />
          )}
          {this.state.open && <OptInPopUp
            isOpen={this.state.open}
            otp={this.state.otp}
            resend={this.handleResend}
            handleClose={() => this.handleOTPPopupClose(false)}
            phone={this.state.phone}
            setOtp={this.setOtp}
            seconds={this.state.seconds}
            handleChange={() => this.handleOTPPopupClose(false)}
            handleSubmit={() => this.handleOTPPopupClose(true)}
            disabled={isVerifyOTPDisabled(this.state.otp, this.state.seconds)}
          />}
          <ThemeProvider theme={theme}>
            <Box
              style={{
                maxWidth: 510,
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                backgroundColor: "white",
                padding: "50px 75px",
              }}
            >
              <div className="back-button">
                <Button
                  id="backBtn"
                  style={{
                    maxWidth: "36px",
                    width: "100%",
                    minWidth: "20px",
                    height: "36px",
                    borderRadius: "100%",
                  }}
                  variant="contained"
                  onClick={() => (window.location.href = "/")}
                  startIcon={<ArrowBack style={{ marginLeft: "10px" }} />}
                />
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    letterSpacing: "-0.31px",
                    marginLeft: "5px",
                  }}
                  variant="body1"
                  color={"primary"}
                >
                  Back to home
                </Typography>
              </div>
              <div className="title" style={{ marginTop: "70%" }}>
                <span>Login</span>
                <span> Now</span>
              </div>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  maxWidth: "310px",
                  letterSpacing: "-0.31px",
                  color: "#6F7C8E",
                }}
              >
                Enter Your mobile number and verify OTP to get access of your
                account.
              </Typography>
              <Box display={"flex"} flexDirection={"row"} width={"100%"} bgcolor={"lighgray"}>

              <PhoneInput
                  country={"in"}
                  data-test-id="country-code-selector"
                  enableSearch={true}
                  value={this.state.countryCodeValue}
                  containerStyle={{
                    width: "29%",
                    height: "45px",
                    marginRight: "-35px",
                    marginTop: "28px",
                  }}
                  inputStyle={{
                    borderRadius: "50px",
                    padding: "19px",
                    paddingLeft: "45px",
                    width: "100%",
                    borderColor: this.state.inputShrink? "#390897" : "",
                    borderWidth: this.state.inputShrink? "2px" : "",
                    display: "none"
                  }}
                  buttonStyle={{
                    backgroundColor:"white",
                    borderRadius: "50px",
                    width: "50%",
                    borderColor: this.state.inputShrink? "#390897" : "",
                    borderWidth: this.state.inputShrink? "2px" : "",
                  }}
                  onFocus={()=> this.handleInputShrink(true)}
                  onChange={(event) => this.handleCountryCodeSelection(event)}
                  placeholder="Country Code"
                />

              <CustomTextField
                id="outlined-helperText"
                name="phone"
                label="Mobile Number"
                color={"primary"}
                variant="outlined"
                onFocus={()=> this.handleInputShrink(true)}
                onBlur={(e) => this.handleInputShrink(!!e.target.value)}
                InputLabelProps={{ shrink: this.state.inputShrink }}
                inputProps={{ maxLength: 10, pattern: '[0-9]*' }}
                error={!!this.state.phoneError}
                value={this.state.phone}
                onChange={this.handleChange}
                data-test-id="mobile-number"
                style={{
                  margin: "28px 0",
                  padding: "0px 0",
                }}
                InputProps={{
                  style: {
                    borderRadius: "50px",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneIphone />
                    </InputAdornment>
                  ),
                }}
              />
       
              </Box>

              <Button
                id="getOtp"
                style={{
                  borderRadius: "50px",
                  padding: "11px 0",
                  fontSize: "14px",
                  fontWeight: 800,
                  textTransform: "none",
                }}
                data-test-id="get-otp"
                variant="contained"
                color={"primary"}
                onClick={this.handlePhoneSubmit}
              >
                Get OTP
              </Button>

              <div className="divider">
                <hr />
                <div className="circle-wrapper">
                  <div className="circle">
                    <div>OR</div>
                  </div>
                </div>
              </div>

              <div className="create-account-block">
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 300,
                    fontStyle: "italic",
                    letterSpacing: "-0.31px",
                  }}
                >
                  If you don’t have an account?
                </Typography>
                <Link
                  id="signUp"
                  component="button"
                  variant="body2"
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    letterSpacing: "-0.31px",
                  }}
                  data-test-id="create-new"
                  onClick={this.handleToggleRegistrationDialog}
                >
                  Create New
                </Link>
              </div>
            </Box>
          </ThemeProvider>
        </div>
        {this.state.isOpenRegistrationDialog && (
          <AccountTypeSelectionDialog
            isOpen={this.state.isOpenRegistrationDialog}
            handleClose={this.handleToggleRegistrationDialog}
            handleSave={this.redirectToSignUp}
          />
        )}
      </LoginWrapper>
    );
  }
}
