import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
} from "@material-ui/core";

import {
  ThemeProvider,
  createTheme,
  makeStyles,
  createStyles,
  StyleRules,
} from "@material-ui/core/styles";
const LuchIcon = require("./lunchIcon.png");
const MinusCircleImg =  require("./assets/images/minus.png");
const filterImg =  require("./assets/images/settings-sliders.png");
const ChatImg = require("./assets/images/mark_unread_chat.png");
const MessageImg = require("./assets/images/comments_white.png");
const ResolveImg = require("./assets/images/rotate-right_purple.png");
const HelpIcon = require("./assets/images/help_outline_24dp.png");
import { Star } from "@material-ui/icons";

import StarIcon from "@material-ui/icons/Star";
import { GreenCheckMark } from "../../blocks/helpcentre/src/assets";
import { DateRangeSharp, QueryBuilderSharp } from "@material-ui/icons";
import SubmitSuccessPopUp from "./SubmitSuccessPopUp";
import RatingPopUp from "./RatingPopUp.web";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomCalender from "./CustomCalender";
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { 
  convertDateIntoDateMonthYearFormat, 
  convertDateIntoDay, 
  convertDateIntoTwelveHourFormat,
  convertMealBreakIntoTimeFormat,
  convertSecondsToHoursMinutesFormat,
  copyDateObjWithTime,
  formatHoursWorked,
  formatHoursWorkedProgress,
  summaryDataIntoStringWithDays, 
} from "./Utilities";
import moment from "moment";
import RecentStatusCard from "./RecentStatusCard.web";
import { toparrow } from "./assets";
import OngoingShyftCustomButton from "./OngoingShyftCustomButton.web";
import RemovePerson from "./RemovePerson.web";
import EndShifyButton from "./EndShifyButton.web";
import UpdateStatusPopUp from "./UpdateStatusPopUp.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  flexContainer: {
    gap: "0px",
  },
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "10px",
    backgroundColor: "#fff",
    position: "relative" as "relative"
  },
  cardContent: {
    overflow: "hidden",
    overflowY: "scroll",
    padding: "10px 0px",
    height: "630px",
  },
  cardFooter: {
    borderTop: "1px solid #e1e6ec",
    // position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "4px 0",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  scrollbarTrack: {
    width: "2px",
  },
  scrollbarThumb: {
    background: "#ffff",
    borderRadius: "5px",
  },
  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "178px",
    height: "87px",
    margin: "10px",
    borderRadius: "10px",
  },
  cardHeading: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#0a0412",
  },
  cardSubHeading: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "15px",
    color: "#000",
  },
  cardActiveHeading: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#390879",
  },
  cardInactiveSubHeading: {
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#6f7c8e",
    margin: "4px 0px",
    textAlign: "center",
  },
  dayTitle: {
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#6f7c8e",
    margin: "4px 0px",
  },
  cardValue: {
    color: "#0a0412",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "14px",
    textAlign: "center",
  },
  cardbodyText: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    color: "#000",
  },
  timeText: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#0a0412",
  },
  cardEmpName: {
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#0a0412",
  },
  cardEmpTimeGreen: { 
    color: "#2da406", 
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "16px",
    marginTop: "5px",
  },
  cardEmpTimeRed: { 
    color: "#ff375b", 
    fontSize: "16px" 
  },
  filterBoxStyle: {
    width: '5%',
    margin: '20px',
    padding: '15px',
    borderRadius: '50px',
    backgroundColor: "#390879",
    position: 'absolute',
    bottom: '110px',
    right: '20px',
  },
  coloredBoxText: { 
    fontWeight: "bold", 
    fontSize: "12px" , 
    color: "#fff" 
  },
  summarySubText: {
    fontSize: "14px",
    color: "#0a0412",
    marginLeft: "4px",
    lineHeight: "14px",
    fontWeight: 400,
  },
  updateContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "8px",
    paddingBottom: "14px"
  },
  updateStatus: {
    fontSize: "12px",
    lineHeight: "14px",
    color: "#390879",
    fontWeight: 600,
    textAlign: "center",
  },
  chatContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
  },
  chatText: {
    color: "#390879",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    textAlign: "center",
    cursor: "pointer",
  },
  toparrowIcon: {
    width: "13px",
    height: "13px",
    marginLeft: "3px",
  },
  footerActivityModalWrapper: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0,0,0,0.3)",
    position: "absolute" as "absolute",
    top: 0,
    left: 0,
    borderRadius: "10px",
    zIndex: 5,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-end",
  },
}));

function getProperties(styles: StyleRules): any {
  const properties: any = {};

  for (const [key, value] of Object.entries(styles)) {
    properties[key] = value;
  }

  return properties;
}

const styles = createStyles({
  "::-webkit-scrollbar": {
    width: "0.4em",
  },
  "::-webkit-scrollbar-track": {
    "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    outline: "1px solid slategrey",
  },
});
const properties = getProperties(styles);

interface TActivityRecords {
  id: string;
  attributes: {
    id: number;
    punch_in: string;
    punch_out: string;
    meal_break: string;
    status: string;
    shift_id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    hours_worked: string;
  },
}

interface SummaryDataProps {
  completed_days: number;
  missed_shifts: number;
  hours_worked: number;
}

interface TActivityRequest {
  id: number;
  status: string;
  removal_reason: string;
  updated_at: string;
}

interface Props {
  isShyftActivity?: boolean;
  backToMyShyfts?: any;
  isPastActivity?: boolean;
  isCompletedShyft?: boolean; //is_from_completed_shyft...
  isShyftEndedSuccess?: boolean; //for_shyft_remove_or_success...
  activityData?: TActivityRecords[]; //apiResponseActivityRecordsData...
  summaryData?: SummaryDataProps | any; //apiResponseSummaryData...
  handleFilterRecords?: any;  //filterFunction...
  handleRepublishShyft?: any; //handle_replublish_shyft_button_click...
  handleMessage?: any; //handle_message_button_click...
  onSubmitRating?: (rating: number, comment: string) => void;
  request?: TActivityRequest;
  shyftEndDate?: string,
  ratingsData?: {
    shiftId: number,
    title: string,
    subtitle: string,
    shyftProfile?: string | null,
    hotelOrRestaurantName?: string | null,
  },
  isRatingSuccess?: boolean;
  isOngoingShyftActivity?: boolean;
  isMyShyftActivity?: boolean;
  handleRemoveShyft?: any;
  handleEndShyft?: any;
  isRemoveModalOpen?: boolean;
  isEndShyftModalOpen?: boolean;
  closeEndShyftModal?:any;
  closeRemoveModal?: any;
  removeText?: string;
  handleRemoveReason?: any;
  handleRemoveSubmit?: any;
  removeTextError?: string;
  handleEndShyftSubmit?: any;
  recentStatusData?: any;
  isReviewAndRate?: boolean;
  closeReviewAndRateModal?: any;
  closeRatingSuccessModal?: any;
  handleUpdateStatusCard?: any;
  isStatusModalOpen?: boolean;
  closeUpdateStatusModal?: any;
  activityUpdateStatusData?: any;
  updateStatusModalSubmitButton?: any;
  isPastShyftRated?: boolean;
  pastShyftRatingScore?: number;
  updateStatusCtaText?: string;
  onNeedUpdate?: () => void;
  goToPage?: any;
}

const PastShyftActivity = (props: Props) => {
  const classes = useStyles();

  const { 
    isShyftActivity, backToMyShyfts, isPastActivity, isCompletedShyft, isShyftEndedSuccess, activityData, 
    summaryData, handleFilterRecords, handleRepublishShyft, handleMessage, ratingsData, onSubmitRating,
    isRatingSuccess, request, shyftEndDate, isOngoingShyftActivity, isMyShyftActivity, handleRemoveShyft, handleEndShyft,
    isRemoveModalOpen, isEndShyftModalOpen, closeEndShyftModal, closeRemoveModal, removeText,
    handleRemoveReason, handleRemoveSubmit, removeTextError, handleEndShyftSubmit, recentStatusData, 
    isReviewAndRate, closeReviewAndRateModal, closeRatingSuccessModal, handleUpdateStatusCard,
    isStatusModalOpen, closeUpdateStatusModal, activityUpdateStatusData, updateStatusModalSubmitButton, onNeedUpdate,
    updateStatusCtaText, isPastShyftRated, pastShyftRatingScore, goToPage
  } = props;

  const [recentStatusState, setRecentStatusState] = React.useState({ currentHoursWorked: 0 });
  const [startTracker, setStartTracker] = React.useState(false);

  const [value, setValue] = React.useState(0);
  
  const [isShowRatingModal, setIsShowRatingModal] = React.useState(false);
  const [isRatingSubmit, setIsRatingSubmit] = React.useState(false);
  const[isCalendarView,setIsCalendarView]=React.useState(false);

  useEffect(() => {
    setIsCalendarView(false);
  }, [isShyftEndedSuccess])

  useEffect(() => {
    let recentStatusActivityTimer: any;

    if(isOngoingShyftActivity &&  startTracker) {
      
      recentStatusActivityTimer = window.setInterval(() => {
        updateShytTracker();
      }, 1000); 

    } 

    return () => {
      window.clearInterval(recentStatusActivityTimer)
    }
  }, [startTracker])

  const handleSubmitReview = (rating: number, comment: string) => {
    onSubmitRating && onSubmitRating(rating, comment);
    setIsShowRatingModal(false);
  }

  const startShyftTracker = (startTime: string, endTime: string) => {

    if(startTime === undefined || startTime === null ) {
      setRecentStatusState({ currentHoursWorked: 0 })
      setStartTracker(false)
      return;
    } 
    else {

      const todayDate = moment().format();

      let hrsWorkedStartTime: any = "";
      let hrsWorkedEndTime: any = "";
      let totalhrsWorkedTime: any = 0;
      let ishrsWrkedTracker = false;

      if(endTime && endTime !== null) {
        hrsWorkedStartTime = copyDateObjWithTime(startTime);
        hrsWorkedEndTime = copyDateObjWithTime(endTime);
        ishrsWrkedTracker = false;
        totalhrsWorkedTime = hrsWorkedEndTime?.diff(hrsWorkedStartTime, "seconds");
      } 
      else {
        hrsWorkedStartTime = copyDateObjWithTime(startTime);
        hrsWorkedEndTime = copyDateObjWithTime(todayDate);
        ishrsWrkedTracker = true;
        totalhrsWorkedTime = hrsWorkedEndTime?.diff(hrsWorkedStartTime, "seconds");
      }

      handleUpdateHrsWrkdTracker(totalhrsWorkedTime, ishrsWrkedTracker)

    }

  };

  const handleUpdateHrsWrkdTracker = (value: number, isEndTime: boolean) => {
    setRecentStatusState({ currentHoursWorked: value });

    if(isEndTime) {
      setStartTracker(true)
    } else {
      setStartTracker(false)
    }
  }

  const updateShytTracker = () => {
      if (!startTracker) {
        setRecentStatusState((prevState) => { 
          return { currentHoursWorked: prevState.currentHoursWorked } 
        }) 
      } 

      else if(startTracker) {
        setRecentStatusState((prevState) => { 
          return { currentHoursWorked: prevState.currentHoursWorked + 1 }
        })
      }
  };

  const getCurrentHoursPercentage = (
    totalShiftHours: number
  ) => {
    if (totalShiftHours === 0) return 0;
    return Math.abs(Math.floor((totalShiftHours/3600)*10));
  };

  useEffect(()=>{
   if(isOngoingShyftActivity){
    setRecentStatusState({ currentHoursWorked: 0 })
    setStartTracker(false)
    recentStatusData && startShyftTracker(recentStatusData?.punch_in,recentStatusData?.punch_out)
   }
  },[])

  useEffect(() => {
    if (isRatingSuccess) {
      setIsRatingSubmit(true);
    }
  }, [isRatingSuccess]);

  useEffect(() => {
    if (isReviewAndRate) {
      setIsShowRatingModal(true);
    }
  }, [isReviewAndRate]);


  const headerCard = (headImg: any, titleText: string, timeText: string, titleClass: any, timeClass: any ) => {
    return (
      <>
        <Box marginRight={"10px"}>
            <img src={headImg} height={"42px"} width={"42px"} />
        </Box>
        <Box>
            <Typography className={titleClass}>
              {titleText}
            </Typography>
            <Typography className={timeClass}>
              {isPastActivity ? shyftEndDate : timeText}
            </Typography>
        </Box>
      </>
    )
  }

  const coloredBoxWithIntials = (baseColor: string, intial: string) => {
    return (
        <Box
          height={"35px"}
          width={"35px"}
          marginRight={"5px"}
          display={"flex"}
          borderRadius={"10px"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={baseColor}
        >
          <Typography className={classes.coloredBoxText}>{intial}</Typography>
        </Box>
    )
  }

  const summaryCardDetails = (boxBgColor: string, title: string, value: string, boxInitial: string) => {
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        {coloredBoxWithIntials(boxBgColor, boxInitial)}
        <Typography className={classes.cardInactiveSubHeading}>
          {title}
        </Typography>
        <Typography className={classes.cardValue} style={{ marginTop: '2px'}}>{value}</Typography>
      </Box>
    )
  }

  const calendarActivityDates = () => {
    let completedDatesArray: string[] = [];
    let missedDatesArray: string[] = [];
    let weeklyOffDatesArray: string[] = [];

    if(activityData && activityData.length > 0) {
      activityData.forEach((item) => {
        if(item.attributes.status === "completed") {
          completedDatesArray.push(convertDateIntoDateMonthYearFormat(item.attributes.created_at, false))
        }
        if(item.attributes.status === "missed") {
          missedDatesArray.push(convertDateIntoDateMonthYearFormat(item.attributes.created_at, false))
        }
        if(item.attributes.status === "weekly_off") {
          weeklyOffDatesArray.push(convertDateIntoDateMonthYearFormat(item.attributes.created_at, false))
        }
      })
    }

    return [completedDatesArray, missedDatesArray, weeklyOffDatesArray];
  }

  const getAllActivityDates = () => {
    let allActivityDatesArray: string[] = [];

    if(activityData && activityData.length > 0) {
      activityData.forEach((item) => {
          allActivityDatesArray.push(convertDateIntoDateMonthYearFormat(item.attributes.created_at, false))
      })
    }

    return allActivityDatesArray;
  }

  const getUpdateStatusDates = () => {
    let updateStatusSelectedDates: string[] = [];

    if(activityData && activityData.length > 0) {
      activityData.forEach((item) => {
        if(item.attributes.status !== "weekly_off") {
          updateStatusSelectedDates.push(convertDateIntoDateMonthYearFormat(item.attributes.created_at, false))
        }
      })
    }

    return updateStatusSelectedDates;
  }


  const handleCalendarClick = (date: string) => {
    activityData?.find((item) => {
      if(item.attributes.status !== "weekly_off" && 
        convertDateIntoDateMonthYearFormat(item.attributes.created_at, false) === date
      ) {
        handleUpdateStatusCard(item)
      }
    })

  }

  return (
    <ThemeProvider theme={theme}>
      <Box className={`${classes.card}`}>
        <Box className={`${classes.cardContent}`} sx={properties} data-test-id="shyftDetailsCardId">
          {isShyftActivity ? 
            (
              <>
                <Box
                  bgcolor={"#e1e6ec"}
                  margin={"10px"}
                  padding={"10px"}
                  borderRadius={"10px"}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Box
                    onClick={backToMyShyfts}
                    marginTop="5px"
                    height={"40px"}
                    width={"40px"}
                    bgcolor={"#fff"}
                    borderRadius={"50px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    data-test-id="pastShyft"
                    marginRight={"12px"}
                  >
                    <ArrowBackIcon />
                  </Box>
                  <Typography className={classes.cardHeading}>
                    Activity Records
                  </Typography>
                </Box>
              </>
            ) 
          :
            ( isOngoingShyftActivity ? 
              <Box paddingX={"2px"} margin={"10px"}>
                { recentStatusData && recentStatusData !== null ?
                  <RecentStatusCard
                    punchInDate={convertDateIntoDateMonthYearFormat(recentStatusData?.punch_in, true)}
                    punchInTime={convertDateIntoTwelveHourFormat(recentStatusData?.punch_in)}
                    punchOutDate={convertDateIntoDateMonthYearFormat(recentStatusData?.punch_out, true)}
                    punchOutTime={convertDateIntoTwelveHourFormat(recentStatusData?.punch_out)}
                    workHours={convertSecondsToHoursMinutesFormat(recentStatusState.currentHoursWorked)}
                    workProgress={getCurrentHoursPercentage(recentStatusState.currentHoursWorked)}
                  />
                  :
                  <RecentStatusCard
                    punchInDate={"--"}
                    punchInTime={"--"}
                    punchOutDate={"--"}
                    punchOutTime={"--"}
                    workHours={"00:00:00"}
                    workProgress={0}
                  />
                }
              </Box>
              :
              <>
                <Box className={classes.cardHeading} margin={"10px"}>
                  Shyft Ended Successfully
                </Box>
                { isShyftEndedSuccess ? 
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    className={classes.card}
                    margin={"0px 10px"}
                    padding={"20px"}
                  >
                    {headerCard(
                        GreenCheckMark,
                        "The Shyft has ended successfully",
                        moment(request?.updated_at).format("D MMM, YYYY [at] hh:mma"), 
                        classes.cardEmpName, 
                        classes.cardEmpTimeGreen 
                      )
                    }
                    
                  </Box>
                :
                  <Box
                    alignItems={"center"}
                    className={classes.card}
                    margin={"0px 10px"}
                    padding={"10px"}
                  >
                    <>
                      <Box display={'flex'} >
                        {headerCard(
                          MinusCircleImg,
                          isPastActivity ? "You're removed from this shyft" : "You removed this shyfter from this shyft",
                          moment(request?.updated_at).format("D MMM, YYYY [at] hh:mma"), 
                          classes.cardEmpName, 
                          classes.cardEmpTimeRed 
                        )}
                      </Box>
                      <Box
                        margin={"0px auto"}
                        padding={"10px 8px"}
                        alignItems={"center"}
                        bgcolor={'rgba(211,211,211,0.4)'}
                        borderRadius={'10px'}
                        marginTop={'10px'}
                      >
                        <Typography className={classes.cardEmpName}> Reason for removing </Typography>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          padding={"10px 0px"}
                        >
                          <Box
                            height={"30px"}
                            width={"30px"}
                            bgcolor={"#fff"}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            borderRadius={"10px"}
                            marginRight={"8px"}
                            border={"1px solid lightgray"}
                            padding={"2px 8px"}
                          >
                            <img src={ChatImg} height={"18px"} width={"18px"} />
                          </Box>
                          <Box>
                            <Typography style={{ color: '#000', fontSize: "12px", fontWeight: 700 }}>
                              {request?.removal_reason}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      {
                        isPastActivity && 
                        <>
                          <Divider flexItem style={{ margin: "15px 0px", height: "1px"}}/>
                          <Box 
                            display={"flex"} 
                            justifyContent={"center"} 
                            alignItems={"center"} 
                            margin={"10px 0px"}
                            style={{ cursor: "pointer" }}
                            onClick={goToPage}
                            data-test-id="need-help-button"
                          >
                            <img src={HelpIcon} alt="help_icon" height={"18px"} width={"18px"} style={{ marginRight: "5px"}}/>
                            <Typography style={{ 
                              fontSize: "14px",
                              textAlign: "center",
                              textDecoration: "underline",
                              textDecorationColor: "lightgray",
                              color: "#390879"
                            }}>Need any help!</Typography>
                          </Box>
                        </>
                      }
                    </>
                  </Box>
                }
              </>
            )
          }

          <Box
            className={classes.cardHeading}
            margin="10px"
            marginTop="15px"
            display={"flex"}
            alignItems={"center"}
          >
            Summary <Typography className={classes.summarySubText}>(Till Today)</Typography>
          </Box>
          <Box
            className={classes.card}
            margin={"10px"}
            padding={"5px 25px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            marginBottom={'15px'}
          >
            {summaryCardDetails("#2da406", "Completed Shyfts",
              summaryDataIntoStringWithDays(summaryData?.completed_days),
              "C"
            )}
            <Divider orientation="vertical" flexItem />
            {summaryCardDetails("#0091ff", "Hours Worked", 
              formatHoursWorked(summaryData?.hours_worked ? `${summaryData?.hours_worked}`: "", true), 
              "W"
            )}
            <Divider orientation="vertical" flexItem />
            {summaryCardDetails("#ff375b", "Missed Shyfts", 
              summaryDataIntoStringWithDays(summaryData?.missed_shifts),
               "M"
            )}
          </Box>
          
          { activityData && activityData?.length > 0 ?
            <>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                margin={"0 10px"}
              >
                <Typography className={classes.cardHeading}>
                  Activity Records
                </Typography>
                {isCalendarView ? (
                  <Box display={"flex"} alignItems={"center"} onClick={()=>setIsCalendarView(false)}>
                      <FormatListBulletedIcon style={{ height: "18px" }} />
                      <Typography className={classes.cardActiveHeading}>
                        List View
                      </Typography>
                    </Box>
                ):(
                  <Box data-test-id="calendar-view" display={"flex"} alignItems={"center"} onClick={()=>setIsCalendarView(true)}>
                    <DateRangeSharp style={{ height: "18px", color: "#390879" }} />
                    <Typography className={classes.cardActiveHeading}>
                      Calender View
                    </Typography>
                  </Box>
                )}
              </Box>

              {isCalendarView?(
                <Box margin={"10px"} borderRadius={"10px"} border={"1px solid lightgray"}>
                  <CustomCalender 
                    isUpdateStatus={isOngoingShyftActivity || isMyShyftActivity || isPastActivity || isCompletedShyft}
                    activityDates={calendarActivityDates()} 
                    allActivityDates={getAllActivityDates()}
                    selectedUpdateStatusDates={getUpdateStatusDates()}
                    handleSelectedDateClick={(date: string) => handleCalendarClick(date)}
                  />
                </Box>
              ):(
                activityData?.map((item: any, index: number) => {
                  return (
                    <Box 
                      key={item.id}
                      className={classes.card}
                      margin={"10px"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box
                        margin={"10px"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Box display={"flex"} alignItems={"center"}>
                          {(item.attributes.status === "weekly_off") && coloredBoxWithIntials("#390879","WO")}
                          {(item.attributes.status === "completed") && coloredBoxWithIntials("#2da406", "C")}
                          {(item.attributes.status === "missed") && coloredBoxWithIntials("#ff375b", "M")}
                          <Box>
                            <Typography className={classes.cardbodyText}>
                              {convertDateIntoDateMonthYearFormat(item.attributes.created_at, false)}
                            </Typography>
                            <Typography className={classes.dayTitle}>
                              {convertDateIntoDay(item.attributes.created_at)}
                            </Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box display={"flex"} alignItems={"center"} alignSelf={'end'} style={{ float:'right' }}>
                            <QueryBuilderSharp
                              style={{ height: "15px", width: "15px", color: "#0091ff" }}
                            />
                            <Typography style={{ color: "#0091ff", fontSize: '14px', marginLeft: '4px' }}>
                              {formatHoursWorked(`${item.attributes.hours_worked}`, false)}
                            </Typography>
                          </Box>
                          <Box maxWidth={"100px"} alignSelf={'end'}>
                            <Typography className={classes.cardbodyText}>
                              Hours Worked
                            </Typography>
                          </Box>
                        </Box>
                      </Box>

                      <Divider style={{ margin: '0px 10px' }}/>

                      {
                        (item.attributes.status === "weekly_off") ? 
                          <Box
                            borderRadius="50px"
                            textAlign="center"
                            margin="5px auto"
                            bgcolor={"#f6edfd"}
                            maxWidth={"45%"}
                            width={'30%'}
                            padding={"4px"}
                            onClick={() => console.log('weekly off- complete shyft')}
                          >
                            <Typography style={{ color: '#390879', fontSize: '14px', fontWeight: 500 }}>Weekly off</Typography>
                          </Box>
                        :
                          <>
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Box display={"flex"} alignItems={"center"} paddingLeft={"8px"}>
                                <Box padding={"10px"}>
                                  <Typography
                                    style={{
                                      color: "#2da406",
                                      fontWeight: 400,
                                      fontSize: "10px",
                                    }}
                                  >
                                    Punched In
                                  </Typography>
                                  <Typography className={classes.timeText}>
                                    {convertDateIntoTwelveHourFormat(item.attributes.punch_in)}
                                  </Typography>
                                </Box>
                                <Divider orientation="vertical" flexItem style={{ margin: '15px 0px'}}/>
                                <Box padding={"10px"}>
                                  <Typography
                                    style={{
                                      color: "#ff375b",
                                      fontWeight: 400,
                                      fontSize: "10px",
                                    }}
                                  >
                                    Punched Out
                                  </Typography>
                                  <Typography className={classes.timeText}>
                                    {convertDateIntoTwelveHourFormat(item.attributes.punch_out)}
                                  </Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} alignItems={"center"} padding={"10px"}>
                              <img src={LuchIcon} alt="meal_break" height={"18px"} />
                              <Typography style={{ color: "#f5c42c", fontSize: '14px', marginLeft: '5px' }}>
                                {convertMealBreakIntoTimeFormat(item?.attributes?.meal_break)}
                              </Typography>
                            </Box>
                          </Box>
                          {isMyShyftActivity && (
                            <>
                              <Divider style={{ margin: '0px 10px' }} />
                              <Box className={classes.chatContainer}>
                                <Typography 
                                  className={classes.chatText}
                                  data-test-id="chat-message-update"
                                  onClick={onNeedUpdate}
                                >
                                  Want an update?
                                </Typography>
                              </Box>
                            </>
                          )}
                          { isOngoingShyftActivity && 
                            <>
                              <Divider style={{ margin: '0px 10px' }}/>
                              <Box className={classes.updateContainer}>
                                <Typography 
                                  className={classes.updateStatus} 
                                  onClick={() => handleUpdateStatusCard(item)}
                                  data-test-id={`actvtyRrdUpdateStatusTextId-${index}`}
                                >
                                  Update Status
                                </Typography>
                                <img src={toparrow} alt="toparrow" className={classes.toparrowIcon}/>
                              </Box>
                            </>
                          }
                          </>
                      }
                    </Box>
                  )
                })
              
              )}
            </>
          :
            <Box width={"100%"} padding={"10px"} textAlign={"center"}>
              <Typography style={{ fontSize: "16px", fontWeight: 600 }}>No Activity Found!</Typography>
            </Box>
          }

        </Box>
  
        {isPastActivity && !isPastShyftRated &&(
          <Box className={classes.cardFooter}>
            <Box
              borderRadius="50px"
              textAlign="center"
              margin="10px auto"
              bgcolor={"#f6edfd"}
              maxWidth={"95%"}
              width={"90%"}
            >
              <Box
                style={{ cursor: "pointer" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                padding={"15px"}
                onClick={() => setIsShowRatingModal(true)}
              >
                <StarIcon style={{ height: "20px", color: "#390879"}} />
                <Typography style={{ color:"#390879", fontSize: "14px"}}>Rate Now</Typography>
              </Box>
            </Box>
          </Box>
        )}

        {isPastActivity && isPastShyftRated && (
          <Box className={classes.cardFooter}>
            <Box
                style={{ cursor: "pointer" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                padding={"20px"}
            >
              <Typography style={{ color:"black", fontSize: "15px"}}>You Rated</Typography>
              <Box
                width="fit-content"
                display={"flex"}
                borderRadius="50px"
                bgcolor="#f7b500"
                alignItems={"center"}
                paddingRight="8px"
                marginLeft="8px"
                paddingY={"2px"}

              >
                <Star style={{ height: "16px", color: "#fff" }} />
                <Typography style={{ color: "#fff", fontSize: "14px", marginLeft: "-4px" }}>{pastShyftRatingScore}</Typography>
              </Box>
            </Box>
          </Box>
        )}

        {isCompletedShyft && (
          <Box className={classes.cardFooter}>
            <Box
              borderRadius="50px"
              textAlign="center"
              margin="10px"
              bgcolor={"#fff"}
              maxWidth={"45%"}
              border={"1px solid #390879"}
              width={'40%'}
              data-test-id="republishShyftBtnId"
              onClick={() => handleRepublishShyft()}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                padding={"10px 15px"}
                justifyContent={"center"}
                style={{ cursor: "pointer" }}
              >
                <img src={ResolveImg} width={"18px"} height={"18px"} />
                <Typography style={{ color: '#390879', fontSize: '14px', marginLeft: "5px" }}>Republish shyft</Typography>
              </Box>
            </Box>

            <Box
              borderRadius="50px"
              textAlign="center"
              margin="10px"
              bgcolor={"#390879"}
              maxWidth={"45%"}
              width={'40%'}
              data-test-id="messageBtnId"
              onClick={() => handleMessage()}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                padding={"10px 15px"}
                justifyContent={"center"}
                style={{ cursor: "pointer" }}
              >
                <img src={MessageImg} width={"18px"} height={"18px"} />
                <Typography style={{ color: '#fff', fontSize: '14px', marginLeft: "5px" }}>Message</Typography>
              </Box>
            </Box>
          </Box>
        )}

        {isOngoingShyftActivity && (
          <Box className={classes.cardFooter}>
            <OngoingShyftCustomButton
              labelName="Remove Person"
              openModal={()=> handleRemoveShyft()}
              endShyft="End Shyft"
              openendShyftModal={() => handleEndShyft()}
              data-test-id="ongoingActivityFooterBtnIdWrapper"
            />
          </Box>
        )}

        <Box data-test-id="filterWrapperId" className={classes.filterBoxStyle} onClick={() => handleFilterRecords()}>
          <img src={filterImg} width={'20px'} height={'20px'} style={{ marginLeft: '2px' }}/>
        </Box>

        {isRemoveModalOpen && (
            <Box className={classes.footerActivityModalWrapper}>
              <RemovePerson
                closeModalButton={()=> closeRemoveModal()}
                data-test-id="closeActivityRemoveModalId"
                inputReasonText={removeText}
                inputReasonError={removeTextError}
                handleReasonText={handleRemoveReason}
                handleRemoveBtn={handleRemoveSubmit}
              />
            </Box>
        )}

        {isEndShyftModalOpen && (
          <Box className={classes.footerActivityModalWrapper}>
            <EndShifyButton
              closeEndShyft={() =>closeEndShyftModal()}
              handleEndShyft={() => handleEndShyftSubmit()}
              data-test-id="closeActivityEndShyftModalId"
            />
          </Box>
        )}

        {isStatusModalOpen && (
          <Box className={classes.footerActivityModalWrapper}>
            <UpdateStatusPopUp
              data-test-id="UpdateStatusPopUpId"
              open={isStatusModalOpen}
              handleCloseModal={() =>  {
                closeUpdateStatusModal()
                setIsCalendarView(false)
              }}
              handleUpdateStatusSubmit={() => {
                updateStatusModalSubmitButton()
                setIsCalendarView(false)
              }}
              ctaText={updateStatusCtaText}
              currentStatusData={activityUpdateStatusData}
            />
          </Box>
        )}

      </Box>

      <RatingPopUp
        data-test-id="rateAndReviewModalId"
        open={isShowRatingModal}
        isFromEmployerEnd={isOngoingShyftActivity}
        title={ratingsData?.title}
        subtitle={ratingsData?.subtitle}
        profile={ratingsData?.shyftProfile}
        hotelOrRestaurName={ratingsData?.hotelOrRestaurantName}
        handleCloseModal={() => {
          setIsShowRatingModal(false)
          closeReviewAndRateModal && closeReviewAndRateModal()
        }}
        handleSubmitReview={(rate:number, review: string) => handleSubmitReview(rate, review)}
      />

      <SubmitSuccessPopUp
        data-test-id="RatingSuccessModalID"
        isRating={true}
        isFromOngoingActivity={isOngoingShyftActivity}
        open={isRatingSubmit}
        backToLogin={() => {
          setIsRatingSubmit(false)
          closeRatingSuccessModal && closeRatingSuccessModal()
        }}
      />
    </ThemeProvider>
  );
};

export default PastShyftActivity;
