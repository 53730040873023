import React from "react";
import Rating from "@material-ui/lab/Rating";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

interface Props {
  ratingCount?: number;
  reviewCount?: number;
  rating?: number;
  handleRatingVal?: any;
}

export default function CustomizedRatings(props: Props) {
  const { rating, handleRatingVal } = props;
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box
          component="fieldset"
          borderColor="transparent"
          padding={0}
          margin="0,0,0,0"
          fontSize={12}
        >
          <Rating
            name="customized-empty"
            defaultValue={rating}
            precision={0.1}
            emptyIcon={<StarBorderIcon style={{ color: "#ffb400" }} fontSize="inherit" />}
            readOnly
          />
        </Box>
        {!!props.reviewCount && (
          <Typography style={{ fontSize: "13px" }}>
            Based on {props?.ratingCount || 0} ratings & {props?.reviewCount || 0} reviews
          </Typography>
        )}
      </Box>
    </ThemeProvider>
  );
}
