import React, { useRef, useEffect, useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  InputBase,
  Box,
  Dialog,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { GetApp, DescriptionOutlined } from "@material-ui/icons";
import EmojiPicker from "emoji-picker-react";
import parser from "html-react-parser";

import ActiveMessagesController, {
  Props,
  configJSON,
} from "./ActiveMessagesController";
import {
  actionIcon,
  actionTakenIcon,
  addIcon,
  closeIcon,
  emptyChatPhoto,
  hotel,
  searchIcon,
} from "./assets";
import ChatCard from "../../../components/src/ChatCard.web";
import ChatHeader from "../../../components/src/ChatHeader.web";
import ChatFooter from "../../../components/src/ChatFooter.web";
import { getTimeAgo } from "../../notifications/src/NotificationList.web";
import {
  capitalizeWords,
  formatRelativeDate,
  sortBasedOnDateString,
  sortBasedOnUpdatedTime,
  truncateFilename,
  getDocUrl,
  sortAllChats,
  convertDateIntoTwelveHourFormat
} from "../../../components/src/Utilities";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import ContactUsPopup from "../../../components/src/ContactUsPopUp";

interface IChatModule {
  user: any;
  chatList: any[];
  chatLoader: boolean;
  isEmployer: boolean;
  handleChatMenuClick: (type: string) => void;
  msgText: string;
  onSendMsg: () => void;
  handleChangeMsgText: (e: any) => void;
  handleChangeEmoji: (e: any) => void;
  handleFileUpload: (e: any) => void;
  navigateToProfile: (bussinessId: number) => void;
  handleOpenReportModal: (userId: number) => void;
  removeAttachment: () => void;
  attachment: any;
}

interface IDeleteMessageProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}

interface IReasonsList {
  name: string; value: string;
}
interface ReportError {
  reasonError: boolean;
  commentError: boolean;
}

interface IReportModal {
  open: boolean;
  handleClose: () => void;
  reason: string;
  comment: string;
  reasonsList: IReasonsList[];
  onChange: (event: React.ChangeEvent<any>) => void;
  submitReport: () => void;
  error: ReportError;
}

export const getChatUsername = (user: any, isEmployer: boolean) => {
  let name = "";
  if (isEmployer) {
    name = user?.receiver?.receiver_name;
  } else {
    name = user?.employer_details?.business_info?.restuarant_name || user?.employer_details?.business_info?.hotel_name;
  }
  return name;
}

const getUnreadCount = (array: any[]) => {
  return array.reduce((sum, element) => {
    return sum + element?.attributes?.unread_messages_count;
  }, 0);
}  

export const EmptyChatModule = (props: any) => {
  return (
    <Box
      padding={"0px 35px 0px 30px"}
      textAlign={"center"}
      height={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box>
        <img src={emptyChatPhoto} width={"85px"} alt="chat placeholder" />
        <Typography
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            color: "#0a0412",
            fontFamily: "Gotham, sans-serif",
            marginTop: "20px",
          }}
          variant="h5"
        >
          Your Snagashyft Messages
        </Typography>
        <Typography
          style={{
            color: "#6f7c8e",
            fontSize: "13px",
            fontWeight: "bold",
            paddingTop: "10px",
            fontFamily: "Gotham, sans-serif",
            width: "250px",
            margin: "auto",
          }}
          variant="subtitle2"
          paragraph={true}
        >
          {props.isEmployer
            ? configJSON.initialChatMsgForEmployer
            : configJSON.initialChatMsgForShyfter}
        </Typography>
      </Box>
    </Box>
  );
};


export const ChatModule = (props: IChatModule) => {
  const divRef = useRef<HTMLDivElement>(null);
  const [emojiPicker, setEmojiPicker] = useState(false);

  useEffect(() => {
    if (props.chatList.length > 0) {
      if (divRef?.current) {
        divRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [props.chatList]);

  const setMsgSide = (chatMsg: any) => {
    return checkForMyMsg(chatMsg?.attributes?.message_user_name)
      ? "flex-end"
      : "flex-start"
  }

  const getUserName = (chatMsg: any) => {
    return checkForMyMsg(chatMsg?.attributes?.message_user_name)
      ? "You"
      : capitalizeWords(props?.user?.user_name);
  };

  const toggleEmojiPicker = () => {
    setEmojiPicker(!emojiPicker);
  };

  const checkForMyMsg = (senderName: string) => {
    return senderName !== props?.user?.user_name;
  };

  const handleSend = () => {
    props.onSendMsg();
    setEmojiPicker(false);
  };

  return (
    <>
      <ChatHeader
        handleOpenReportModal={() => props.handleOpenReportModal(Number(props.user?.user_id))}
        navigateToProfile={() => props.navigateToProfile(Number(props.user?.user_id))}
        businessDetail={props.user?.business_details}
        isEmployer={props.isEmployer}
        image={
          getDocUrl(props.user?.profile_picture) || hotel
        }
        fullName={capitalizeWords(props?.user?.user_name)}
        jobTitle={capitalizeWords(props?.user?.shift_type)}
        handleMenuClick={props.handleChatMenuClick}
      />
      <Box sx={{ maxHeight: 'calc(100% - 160px)', overflow: 'auto' }} marginTop={'70px'} paddingLeft={"20px"} paddingRight={"0px"}>
        {props.chatLoader ? (
          <Box
            height={"370px"}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box paddingRight={'20px'}>
            {sortBasedOnDateString(props.chatList).map((chat: any) => (
              <Box width={"100%"} key={chat.date}>
                <Box style={style.lineWithTextBox}>
                  <span style={style.inlineText}>
                    {formatRelativeDate(chat.date)}
                  </span>
                </Box>
                {sortBasedOnUpdatedTime(chat.data).map((chatMsg: any) => (
                  <Box
                    key={chatMsg.id}
                    display={"flex"}
                    justifyContent={
                      setMsgSide(chatMsg)
                    }
                    width={"100%"}
                  >
                    <Box marginTop={"10px"} maxWidth={"50%"}>
                      <Box
                        style={
                          checkForMyMsg(chatMsg?.attributes?.message_user_name)
                            ? style.senderName
                            : style.receiverName
                        }
                        data-test-id="receiver-name"
                      >
                        {getUserName(chatMsg)}
                      </Box>
                      <Box
                        padding={"16px"}
                        borderRadius={"12px"}
                        style={
                          checkForMyMsg(chatMsg?.attributes?.message_user_name)
                            ? style.receiverBox
                            : style.senderBox
                        }
                      >
                        {chatMsg?.attributes?.message &&
                          parser(chatMsg?.attributes?.message)}{" "}
                        {chatMsg?.attributes?.attachments && (
                          <a
                            href={getDocUrl(
                              chatMsg?.attributes?.attachments[0].url
                            )}
                            download={truncateFilename(
                              chatMsg?.attributes?.attachments[0].file_name,
                              20
                            )}
                            target="_blank"
                            style={style.docFile}
                          >
                            <DescriptionOutlined />
                            <Box style={style.fileName}>
                              {truncateFilename(
                                chatMsg?.attributes?.attachments[0].file_name,
                                20
                              )}
                            </Box>
                            <IconButton
                              style={
                                checkForMyMsg(
                                  chatMsg?.attributes?.message_user_name
                                )
                                  ? style.senderDownloadIcon
                                  : style.downloadIcon
                              }
                            >
                              <GetApp
                                style={{
                                  height: "15px",
                                  width: "15px",
                                }}
                              />
                            </IconButton>
                          </a>
                        )}
                        <span
                          style={{
                            display: "block",
                            fontSize: "11px",
                            marginTop: "10px",
                            textAlign: "right",
                            color: checkForMyMsg(
                              chatMsg?.attributes?.message_user_name
                            )
                              ? "#6F7C8E"
                              : "#fff",
                          }}
                        >
                          {convertDateIntoTwelveHourFormat(chatMsg?.attributes?.updated_at)}
                        </span>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            ))}
            <span id="chat-end" ref={divRef} />
            {emojiPicker && (<>
              <Box onClick={toggleEmojiPicker} sx={{ position: 'fixed', top: '0', right: '0', left: '0', bottom: '0' }}></Box>
              <Box sx={{ position: 'absolute', bottom: '90px', zIndex: 10 }}><EmojiPicker onEmojiClick={props.handleChangeEmoji} /></Box>
            </>
            )}
          </Box>
        )}
        <ChatFooter
          msgText={props.msgText}
          attachment={props.attachment}
          removeAttachment={props.removeAttachment}
          handleChangeMsgText={props.handleChangeMsgText}
          onSendMsg={handleSend}
          toggleEmojiPicker={toggleEmojiPicker}
          handleFileUpload={props.handleFileUpload}
        />
      </Box>
    </>
  );
};


export const ReportModal = (props: IReportModal) => {
  return <Dialog
    open={props.open}
    PaperProps={{
      style: {
        overflowY: "visible",
        borderRadius: "20px",
        width: "530px",
      },
    }}
  >
    <IconButton
      style={webStyles.modalCloseBtn}
      data-test-id="deleteModalClose"
      onClick={props.handleClose}
    >
      <CloseIcon style={{ color: "#fff" }} width={20} height={20} />
    </IconButton>
    <Box sx={webStyles.modalTitle}    >
      Report Shyfter!
    </Box>
    <Box padding={"10px 30px 0px"}>
      <CustomSelect data-test-id='select' value={props.reason} label={"Select Reason"} name={"reason"} error={props.error.reasonError} options={props.reasonsList} handleChange={props.onChange} />
    </Box>
    <Box padding={"5px 30px"}>
      <CustomInput data-test-id='input' multiline value={props.comment} label={''} name={"comment"} error={props.error.commentError} handleChange={props.onChange} minRows={3} placeholder="Enter your comment" />
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '10px 30px 30px 30px' }}>
      <Button
        data-test-id='submit'
        style={webStyles.modalSubmitBtn}
        onClick={props.submitReport}
      >
        Submit
      </Button>
    </Box>
  </Dialog>
}

export const DeleteMessage = (props: IDeleteMessageProps) => {
  return (
    <Dialog
      open={props.open}
      PaperProps={{
        style: {
          overflowY: "visible",
          borderRadius: "20px",
          width: "530px",
        },
      }}
    >
      <IconButton
        style={{
          border: "1px rgb(255, 255, 255, 0.3) solid",
          backgroundColor: "rgb(0 0 0 / 55%)",
          position: "absolute",
          padding: "5px",
          right: 0,
          top: "-40px",
          borderRadius: "50%",
        }}
        data-test-id="deleteModalClose"
        onClick={props.handleClose}
      >
        <CloseIcon style={{ color: "#fff" }} width={20} height={20} />
      </IconButton>
      <Box>
        <Box textAlign={"center"} padding={"35px"}>
          <img src={closeIcon} width={"50px"} alt="delete icon" />
          <Box
            paddingBottom={"10px"}
            fontWeight={600}
            paddingTop={"10px"}
            textAlign="center"
            fontSize="22px"
            color="#0A0412"
          >
            Delete Chat!
          </Box>

          <Typography
            style={{
              color: "#6f7c8e",
              fontSize: "13px",
              textAlign: "center",
              fontWeight: "bold",
              margin: "auto",
              fontFamily: "Gotham, sans-serif",
              paddingBottom: "20px",
              width: "370px",
            }}
            variant="subtitle2"
            paragraph={true}
          >
            Are you sure to want to delete this chat from the list your account,
            please confirm?
          </Typography>

          <Box display={"flex"} justifyContent={"space-around"}>
            <Button
              style={{
                backgroundColor: "#fff",
                borderRadius: "24px",
                color: "#390879",
                textTransform: "capitalize",
                border: "1px solid #390879",
                width: "45%",
                minWidth: "170px",
                height: "48px",
                fontWeight: "bold",
              }}
              onClick={props.handleClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                backgroundColor: "#ff375b",
                borderRadius: "24px",
                color: "#fff",
                textTransform: "capitalize",
                border: "1px solid #ff375b",
                width: "45%",
                minWidth: "170px",
                height: "48px",
                fontWeight: "bold",
              }}
              data-test-id="delete-chats"
              onClick={props.handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const style = {
  lineWithTextBox: {
    textAlign: "center" as any,
    border: "1px solid #E1E6EC",
    lineHeight: "0em",
    margin: "20px 0 20px",
  },
  inlineText: {
    background: "#fff",
    padding: "6px 10px",
    fontSize: "13px",
    color: "#6F7C8E",
    fontWeight: "500" as any,
    backgroundColor: "#E3EAF4",
    borderRadius: "13px",
  },
  receiverName: {
    fontSize: "12px",
    color: "#390879",
  },
  senderName: {
    fontSize: "12px",
    color: "black",
    textAlign: "right",
  },
  senderBox: {
    backgroundColor: "#390879",
    color: "#fff",
    wordBreak: 'break-word',
    whiteSpace: 'pre-line'
  } as any,
  receiverBox: {
    backgroundColor: "#E1E6EC",
    color: "#0A0412",
    wordBreak: 'break-word',
    whiteSpace: 'pre-line'
  },
  docFile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "inherit",
  },
  downloadIcon: {
    backgroundColor: "rgba(112, 124, 142, 1.2)",
    padding: "4px",
    color: "white",
  },
  senderDownloadIcon: {
    backgroundColor: "rgba(112, 124, 142, 0.2)",
    padding: "4px",
    color: "#2a314aa3",
  },
  fileName: {
    margin: "0px 5px",
  },
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
  typography: {
    h6: {
      fontFamily: "Gotham-Bold",
      color: "#0A0412",
    },
  },
});

export default class ActiveMessages extends ActiveMessagesController {
  constructor(props: Props) {
    super(props);
  }

  async componentWillUnmount() {
    this.unsubscribeToMessage();
    this.socket.close()
  }
  render() {
    const {
      deleteModalOpen,
      isActionNeeded,
      selectedItems,
      renderChatId,
      userMessages,
      userChatsData,
      chatLoader,
      msgText,
      allChatSearchText,
      comment,
      openReportModal,
      reason,
      reasonsList,
      reasonNotAdded,
      commentNotAdded,
      attachment,
      activeChatDetails
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        {deleteModalOpen && (
          <DeleteMessage
            open={deleteModalOpen}
            handleClose={this.handleDeleteModalClose}
            handleDelete={this.handleDeleteChat}
          />
        )}
        {openReportModal && <ReportModal error={{ reasonError: reasonNotAdded, commentError: commentNotAdded }} submitReport={this.submitReportUser} reasonsList={reasonsList} onChange={this.handleReportModalFieldChange} comment={comment} reason={reason} open={openReportModal} handleClose={this.handleCloseReportModal} />}
        {<ContactUsPopup open={this.state.openSuccessModal} handleCancel={this.handleCancelSuccessModal} isSuccess={this.state.isSuccess} isApiError={!this.state.isSuccess}/>}
        <Container>
          <Box
            marginTop={"10%"}
            minHeight={"80vh"}
            border={"1px solid #e1e6ec"}
            marginBottom={"3%"}
            bgcolor={"#fff"}
            borderRadius={"14px"}
            position={"relative"}
          >
            <Grid
              container
              spacing={2}
              style={{
                position: "absolute",
                height: "calc(100% - 20px)",
                padding: '0 20px',
                top: "20px",
              }}
            >
              <Grid
                item
                xs={6}
                sm={5}
                md={4}
                lg={4}
                xl={4}
                style={{
                  borderRight: "1px solid #e1e6ec",
                  marginTop: "10px",
                  height: '100%',
                  marginBottom: "10px",
                  position: "relative",
                }}
              >
                <Box style={{ overflowY: "hidden" }} height={'100%'} padding={"0px 3px 0px 0px"}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        color: "#0a0412",
                        fontWeight: "bold",
                        fontSize: "16px",
                        marginBottom: "0",
                        fontFamily: "Gotham, sans-serif",
                      }}
                      variant="subtitle2"
                      paragraph={true}
                    >
                      Messages ({getUnreadCount(userMessages)})
                    </Typography>
                    <Box display={"flex"} alignItems={"center"}>
                      <Box display={"flex"} alignItems={"center"}>
                        <img
                          src={isActionNeeded ? actionTakenIcon : actionIcon}
                          width={"22px"}
                          height={"22px"}
                          alt="action icon"
                          onClick={() => this.handleAction()}
                          data-test-id="takeActionBtn"
                          style={{
                            cursor: "pointer",
                            marginRight: isActionNeeded ? "5px" : "15px",
                          }}
                        />
                        {isActionNeeded && (
                          <Typography
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              fontSize: "20px",
                              color: "#390879",
                              fontFamily: "Gotham, sans-serif",
                            }}
                          >
                            {selectedItems.length}
                          </Typography>
                        )}
                      </Box>
                      <Button
                        data-test-id="btnChatAdd"
                        disabled={isActionNeeded}
                        variant="contained"
                        style={{
                          backgroundColor: isActionNeeded
                            ? "rgba(57, 8, 121, 0.5)"
                            : "#390879",
                          borderRadius: "18px",
                          color: "#fff",
                          width: "90px",
                          textTransform: "capitalize",
                        }}
                        onClick={this.props.openNewMessageDialog}
                      >
                        {" "}
                        Add
                        <img
                          src={addIcon}
                          height={"22px"}
                          width={"22px"}
                          alt="action button"
                          style={{ marginLeft: "7px" }}
                        />
                      </Button>
                    </Box>
                  </Box>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    bgcolor={"rgb(225, 230, 236, 0.6)"}
                    borderRadius={"23px"}
                    marginTop={"15px"}
                    marginBottom={"15px"}
                    height={"40px"}
                  >
                    <Box margin="0 10px">
                      <img src={searchIcon} alt="search icon" height={"18px"} />
                    </Box>
                    <InputBase
                      placeholder={"Search for chat..."}
                      data-test-id="search-input"
                      fullWidth
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        fontSize: "14px",
                      }}
                      value={allChatSearchText}
                      onChange={this.handleAllChatSearchUpdate}
                    />
                  </Box>
                  <Box height={"calc(100% - 100px)"} style={{ overflowY: "auto" }}>
                    <Box>
                      {sortAllChats(userMessages).map((user) => {
                        return (
                          <ChatCard
                            jobTitle={capitalizeWords(
                              user?.attributes?.shift_type
                            )}
                            imgUrl={getDocUrl(
                              user?.attributes?.receiver?.profile_picture
                            )}
                            senderName={getChatUsername(
                              user?.attributes, this.props.isEmployer
                            )}
                            time={getTimeAgo(
                              user?.attributes?.last_message?.attributes
                                ?.updated_at
                            )}
                            lastChat={
                              user?.attributes?.last_message?.attributes
                            }
                            chatId={user?.id}
                            isActionNeeded={isActionNeeded}
                            selectedItem={this.selectedItem}
                            handleRenderChat={this.handleRenderChat}
                            activeId={renderChatId}
                            key={user?.id}
                            unreadCount={
                              user?.attributes?.unread_messages_count
                            }
                          />
                        );
                      })}
                    </Box>
                  </Box>
                  {isActionNeeded && (
                    <Box
                      position={"absolute"}
                      left={"0px"}
                      bottom={"20px"}
                      display={"flex"}
                      justifyContent={"space-evenly"}
                      right={"0px"}
                    >
                      <Button
                        onClick={() => this.handleCloseAction()}
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "24px",
                          color: "#390879",
                          border: "1px solid #390879",
                          textTransform: "capitalize",
                          minWidth: "130px",
                          fontWeight: "bold",
                          height: "48px",
                        }}
                        data-test-id="actionClose"
                      >
                        Cancel
                      </Button>
                      <Button
                        data-test-id="actionDelete"
                        style={{
                          borderRadius: "24px",
                          backgroundColor: "#ff375b",
                          color: "#fff",
                          textTransform: "capitalize",
                          border: "1px solid #ff375b",
                          height: "48px",
                          minWidth: "130px",
                          fontWeight: "bold",
                        }}
                        disabled={!selectedItems.length}
                        onClick={() => this.handleDeleteModal()}
                      >
                        Delete {`(${selectedItems.length})`}
                      </Button>
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                sm={7}
                md={8}
                lg={8}
                xl={8}
                style={{
                  padding: "0",
                  marginBottom: "10px",
                  position: "relative",
                  marginTop: "10px",
                  height: '100%'
                }}
              >
                {renderChatId === -1 ? (
                  <EmptyChatModule isEmployer={this.props.isEmployer} />
                ) : (
                  <ChatModule
                    handleOpenReportModal={this.handleOpenReportModal}
                    navigateToProfile={this.viewBusinessProfile}
                    user={activeChatDetails}
                    chatList={userChatsData}
                    chatLoader={chatLoader}
                    isEmployer={this.props.isEmployer}
                    handleChatMenuClick={this.handleChatMenuClick}
                    msgText={msgText}
                    attachment={attachment}
                    handleChangeMsgText={this.handleMsgTextInput}
                    onSendMsg={this.sendNewMessage}
                    handleChangeEmoji={this.handleEmojiUpdate}
                    handleFileUpload={this.handleFileUpload}
                    removeAttachment={this.removeAttachment}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
}


const webStyles = {
  modalCloseBtn: {
    border: "1px rgb(255, 255, 255, 0.3) solid",
    backgroundColor: "rgb(0 0 0 / 55%)",
    position: "absolute" as const,
    padding: "5px",
    right: '0',
    top: "-40px",
    borderRadius: "50%",
  },
  modalTitle: {
    fontWeight: 600,
    textAlign: "center",
    fontSize: "24px",
    color: "#0A0412",
    padding: "20px 30px 10px 30px"
  },
  modalSubmitBtn: {
    backgroundColor: "#390879",
    borderRadius: "24px",
    color: "white",
    textTransform: "capitalize" as const,
    width: "45%",
    minWidth: "270px",
    height: "48px",
    fontWeight: 'bold' as any,
  }
}