import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";

import { Grid, Container, Box, Typography } from "@material-ui/core";
import ProfileCard from "../../../components/src/ProfileCard";
import ShyfterLandingPageController, {
  Props, configJSON,
} from "./ShyfterLandingPageController.web";
import ProgressBar from "../../../components/src/ProgressBar";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import SearchShyftsModal from "../../../components/src/ShyftsSearchModal";
import SideNav from "../../../components/src/SideNav";
import Carousel from "react-material-ui-carousel";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";
import FeedbackModal from "./FeedbackModal.web";
import { successTickIcon, placeIcon } from "./assets";
import { checkIfUserAppliedeForShyft } from "../../../components/src/Utilities";
import FormDialogBox from "../../../components/src/FormDialogBox";
import OtpPopUp from "../../../components/src/OtpPopUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0a0412",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#390879",
    },
  },
});
const styles = {
  viewAll: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    color: "#390879",
    textDecoration: "underline",
    cursor: "pointer",
  },
  shyftText: {
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  sideText: {
    color: "#0a0412",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "19px",
  },
  locationBox: { marginTop: "15px", gap: "5px" },
  location: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#0a0412",
    cursor: "pointer",
    textDecoration: "underline",
  },
} as const;

const isOTPDisabled = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};

export default class ShyfterLandingPage extends ShyfterLandingPageController {
  constructor(props: Props) {
    super(props);
  }

  renderNoDataFound = (heightStyle: string) => {
    return (
      <Box
        width={"100%"}
        height={heightStyle}
        display={"flex"}
        padding={"10px"}
        alignItems={"center"}
        justifyContent={"center"}
        borderRadius={"10px"}
        margin={"10px 0px"}
      >
        <Typography style={{ fontSize: "16px", textAlign: 'center' }}>No Shyfts Found!</Typography>
      </Box>
    )
  }

  render() {
    const {
      shyftDetails,
      shyftsList,
      selectedShyft,
      recommendedShyftsList,
      shyftRequest,
      profileComplition,
      userData,
      isRegisterationPendingModalOpen,
      isAppliedModalOpen,
      recentSearches,
      isShowLocationModal,
      isShowSearchModal,
      locationData,
      isCountrySelected,
      isStateSelected,
      isCitySelected,
      locationBreadCrumData,
      isSearchingLocation,
      searchedLocationData,
      ongoingShyft,
      remainingTime,
      activeShyft,
      shyftEndTime,
      timePercent,
      topHotelAndRestaurantsData,
      filteredDataForSearchModal,
      searchedText,
      updatedPhoneNumber,
      countryCode,
      isEmailOtpPopUpOpen,
      isPhoneOtpPopUpOpen,
      phoneNumberOTP,
      seconds,
      emailOTP,
      updatedEmail
    } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          gotToProfile={() => this.navigateToShyfterProfile()}
          isShowDetails={false}
          isUpdateUser={false}
        />
        <Container>
          <Box marginTop={"8%"}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={3}>
                <ProfileCard
                  userName={"Test User"}
                  place={"Berin"}
                  city={"New Yark"}
                  contactMail={"example@gmail.com"}
                  contactNumber={"5241578523"}
                  goToProfile={() => this.navigateToShyfterProfile()}
                  userData={this.state.userData}
                  showEditEmailPopUp={this.showUpdateEmailPopUpShyfter}
                  showEditPhonePopUp={this.showUpdatePhonePopUpShyfter}
                />
                <Typography>
                  <Box style={styles.sideText} padding={"10px 0px"}>
                    {configJSON.MoreOptions}
                  </Box>
                </Typography>
                <SideNav
                  pastShyft={() => this.navigateToPastShyft()}
                  myShyft={() => this.navigateToMyShyft()}
                  otherPageNavigation={(path: string) =>
                    this.handleMoreOptionsNavigation(path)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Box padding={"0 10px"}>
                  <ProgressBar
                    timePercent={timePercent}
                    shyftEndTime={shyftEndTime}
                    navigateToMyShyft={() => this.navigateToMyShyft()}
                    navigateToManageShyft={() => this.navigateToManageShyft()}
                    progress={profileComplition}
                    activeShyft={activeShyft}
                    ongoingShyft={ongoingShyft}
                    remainingTime={remainingTime} />
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={this.showLocationModal}
                    style={styles.locationBox}
                    data-test-id="locationModalOpener"
                  >
                    <img src={placeIcon} alt="" height={"18px"} />
                    <Typography style={styles.location}>{this.state.userData?.shyfter_city}</Typography>
                  </Box>
                  <Box>
                    <SearchWithFilterandLocation
                      isDisabled={true}
                      handleSerchModal={() => this.showSearchModal()}
                      handleSearchFilter={this.navigateToExploreShift}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                    marginTop={"30px"}
                  >
                    <Typography style={styles.shyftText}>
                      {configJSON.ExploreShyfts}
                    </Typography>
                    <Typography
                      style={styles.viewAll}
                      onClick={() => this.handleExploreShyft()}
                      data-test-id="exploreShyfts"
                    >
                      {configJSON.ViewAll}
                    </Typography>
                  </Box>
                  <Box>
                    {shyftsList.length > 0 ?
                      <Carousel
                        animation={configJSON.Slide}
                        indicators={false}
                        navButtonsAlwaysVisible
                        swipe={true}
                        autoPlay={false}
                        navButtonsProps={{
                          style: {
                            color: "black",
                            padding: "14px",
                            backgroundColor: "#fff",
                            border: "1px solid #e1e6ec",
                            height: "18px",
                            width: "18px",
                            margin: "0px",
                            marginRight: "-9px",
                            marginLeft: "-9px"
                          },
                        }}
                        next={(next: number) => this.setSelectedShyft(next, configJSON.Explore)}
                        prev={(prev: number) => this.setSelectedShyft(prev, configJSON.Explore)}
                      >
                        {
                          shyftsList.map((shift, index) => (
                            <ShyftCard
                              key={shift.id}
                              {...shift}
                              isActiveShyft={index === selectedShyft}
                              onSaveShyft={() =>
                                this.handleSaveShyft(shift.id, shift.saved)
                              }
                              showBookMark={true}
                            />
                          ))
                        }
                      </Carousel>
                      : this.renderNoDataFound("20%")
                    }
                  </Box>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    maxWidth={"100%"}
                  >
                    <Typography style={styles.shyftText} variant="subtitle1">
                      {configJSON.RecommendedShyfts}
                    </Typography>
                    <Typography
                      style={styles.viewAll}
                      onClick={() => this.handleViewAllRecomendedShyfts()}
                      data-test-id="recomendedShyfts"
                    >
                      {configJSON.ViewAll}
                    </Typography>
                  </Box>
                  <Box>
                    {recommendedShyftsList.length > 0 ?
                      recommendedShyftsList.map((shift) => (
                        <ShyftCard
                          data-test-id='recommended'
                          {...shift}
                          key={shift.id}
                          onCardClick={() => this.setSelectedShyft(shift.id, configJSON.Recommended)}
                          isActiveShyft={shift.id === selectedShyft}
                          showBookMark={true}
                          onSaveShyft={() =>
                            this.handleSaveShyft(shift.id, shift.saved)
                          }
                        />
                      ))
                      : this.renderNoDataFound("20%")
                    }
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <Box
                  padding={"0 10px"}
                  style={{ position: "sticky" }}
                >
                  {shyftDetails ?
                    (
                      <ShyfterDetailsCard
                        hideEmployer
                        onApplyShift={() =>
                          this.applyForTheShyft(shyftDetails.id)
                        }
                        onWithdrawShift={() =>
                          this.withdrawFromTheShyft(shyftDetails.id)
                        }
                        onSaveShyft={(id, saved) => this.handleSaveShyft(id, saved)}
                        key={shyftDetails.id}
                        {...shyftDetails}
                        onViewProfile={() =>
                          this.navigateToBusinessProfile(shyftDetails.businessId)
                        }
                        isAppliedShyft={checkIfUserAppliedeForShyft(shyftRequest)}
                        shyftStatus={
                          shyftRequest ? shyftRequest.status : undefined
                        }
                      />
                    )
                    : this.renderNoDataFound("70vh")
                  }
                </Box>
              </Grid>
            </Grid>
          </Box>
          <GetLocationModal
            data-test-id="getLocationModalId"
            open={isShowLocationModal}
            closeModal={() => this.closeLocationModal()}
            locationData={!isSearchingLocation ? locationData : searchedLocationData}
            handleCurrentLocation={this.handleCurrentLocation}
            fetchCountries={this.fetchCountries}
            fetchStateByCountry={this.fetchStateByCountry}
            fetchCitiesByCountryAndState={this.fetchCitiesByCountryAndState}
            handleSearchForLocation={this.handleSearchForLocation}
            isCountrySelected={isCountrySelected}
            isStateSelected={isStateSelected}
            isCitySelected={isCitySelected}
            handleCitySelection={this.handleLocationModalCitySelection}
            locationBreadCrumData={locationBreadCrumData}
            isSearchingLocation={isSearchingLocation}
            handleAllStatesBreadCrum={this.handleLocationBreadCrumAllStates}
            handleUpdateSearchedLocation={this.handleUpdateSearchedLocation}
          />
          <SearchShyftsModal
            open={isShowSearchModal}
            recentSearches={recentSearches}
            removeQueryFromSearch={this.removeQueryFromRecentSearches}
            closeModal={() => this.closeSearchModal()}
            onSearch={this.onSearchShifts}
            topHotelAndRestaData={topHotelAndRestaurantsData}
            onChangeSearch={this.handleSearchForTopShyfts}
            isSearching={this.state.isBusinessSearch}
            filteredData={filteredDataForSearchModal}
            searchedText={searchedText}
          />
          <SubmitSuccessPopUp
            open={!!this.state.signUpMessage}
            isShyfter={true}
            isDoubleAction={true}
            completeRegistration={() => this.navigateToShyfterProfile()}
            exploreShyfts={() => this.handleExploreShyft()}
            backToLogin={() => this.setState({ signUpMessage: "" })}
            isShyfterRefg={true}
          />
          <FeedbackModal
            isOpen={isAppliedModalOpen}
            modalTitle={configJSON.AppliedSuccessfully}
            imageSrc={successTickIcon}
            primaryButtonText={configJSON.ExploreMore}
            primaryButtonOnClick={this.closeAppliedShiftModal}
            modalDescription={configJSON.FeedbackModalDesShyfterLand}
            secondaryButtonText={configJSON.ViewStatus}
            secondaryButtonOnClick={this.navigateToAppliedShyts}
            onClose={this.closeAppliedShiftModal}
          />
          <FeedbackModal
            data-test-id="completeRegristrationFeedbackModalId"
            isOpen={isRegisterationPendingModalOpen}
            onClose={()=>this.closeRegistrationModal()}
            modalTitle={configJSON.RegistrationPending}
            modalDescription="Your registeration is pending. Please complete your registration to apply for a shyft in any hotel and restaurant."
            imageSrc={successTickIcon}
            secondaryButtonText="Complete Now"
            secondaryButtonOnClick={this.handleCompleteNowRedirection}
            isProfileCompletion={true}
            profileCompletionPercent={this.state.profileComplition}
          />
          <FormDialogBox
            title={configJSON.updateEmail}
            type={"email"}
            value={updatedEmail}
            name={"email"}
            isOpen={this.state.updateEmailPopUp}
            handleClose={this.showUpdateEmailPopUpShyfter}
            handleChange={this.onChangeEmail}
            handleSubmit={this.getEmailOtp}
            errorMessage={this.state.errorMessageModal}
            errorPopUp={this.state.errorPopUp}
          />

          <FormDialogBox
            title={configJSON.updatePhone}
            type={"phone"}
            value={updatedPhoneNumber}
            name={"phone"}
            isOpen={this.state.updatePhonePopUp}
            handleClose={this.showUpdatePhonePopUpShyfter}
            countryCode={countryCode}
            handleChange={this.onChangePhoneNumber}
            handleSubmit={this.getPhoneNumberOtpShyfter}
            handleCountryCodeSelect={this.handleCountryCodeSelectionShyfter}
            disabled={false}
            errorMessage={this.state.errorMessageModal}
            errorPopUp={this.state.errorPopUp}
          />

          <OtpPopUp
            isOpen={isEmailOtpPopUpOpen}
            otp={emailOTP}
            phone={updatedEmail}
            setOtp={this.onChangeEmailOTP}
            seconds={seconds}
            resend={this.getEmailOtp}
            handleSubmit={this.updateUserEmail}
            handleChange={this.closeEmailOTPModal}
            handleClose={this.closeEmailOTPModal}
            disabled={isOTPDisabled(emailOTP, seconds)}
          />

          <OtpPopUp
            isOpen={isPhoneOtpPopUpOpen}
            phone={updatedPhoneNumber}
            otp={phoneNumberOTP}
            setOtp={this.onChangePhoneNumberOTP}
            seconds={seconds}
            handleClose={this.closePhoneOTPModal}
            resend={this.getPhoneNumberOtpShyfter}
            handleChange={this.closePhoneOTPModal}
            handleSubmit={this.updateUserPhoneNumber}
            disabled={isOTPDisabled(phoneNumberOTP, seconds)}
           />
        </Container>
        <Footer />
      </ThemeProvider>
    );
  }
}
