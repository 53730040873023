import React from "react";

import { Box, Container, Grid } from "@material-ui/core";
import SavedShyftController, { Props } from "./SavedShyftController.web";

import {
  breadCrumSubsPath,
  checkIfUserAppliedeForShyft,
} from "../../../components/src/Utilities";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";
import { SuccessIcon, FailedIcon } from "./assets";

import Spinner from "../../../components/src/Spinner";
import NoDataFound from "../../../components/src/NoDataFound.web";
import Footer from "../../../components/src/Footer.web";

export default class SavedShyftPage extends SavedShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      savedShyftsList,
      selectedShyft,
      savedShyftDetails,
      request,
      isShyftApplied,
      isRegisterationPending,
      isLoading,
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Saved Shyfts"
          breadCrumsData={breadCrumSubsPath}
          isShowDetails={false}
          isUpdateUser={false}
        />
        {isLoading && <Spinner />}

        {savedShyftsList.length === 0 && !isLoading && (
          <Container>
            <Box marginTop="12%">
              <NoDataFound
                heading="No Saved Shyfts!"
                paragraph={
                  "You’ve not saved any of the shyfts yet, Explore and save to view the shyfts."
                }
                imgUrl={FailedIcon}
                primaryBtnText="Explore Shyfts"
                primaryBtnClick={this.viewExploreShyfts}
              />
            </Box>
          </Container>
        )}

        <Box marginTop={"10%"} width={"100%"}>
          <Container maxWidth="md">
            {savedShyftsList.length !== 0 && (
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <Box>
                    {savedShyftsList.map((item) => {
                      const shouldShowStatus = item.appliedStatus === 'expired' || item.appliedStatus === 'accepted'||item.appliedStatus==='ended'||item.appliedStatus==='rejected'
                      return (
                        <ShyftCard
                          key={item.id}
                          {...item}
                          onCardClick={() => this.onSelectShift(item.id)}
                          isActiveShyft={item.id === selectedShyft}
                          onSaveShyft={() => this.handleSaveShyft(item.id, item.saved)}
                          onRemove={() => this.handleSaveShyft(item.id, item.saved)}
                          showBookMark={!shouldShowStatus}
                          showRemoveIcon={shouldShowStatus}
                          statusText={shouldShowStatus ? item.appliedStatus : ""}
                        />
                      );
                    })}
                    <div ref={this.elementRef} style={{ height: "20px" }}></div>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    margin={"15px 0px"}
                    style={{ position: "sticky", top: "24%" }}
                  >
                    {savedShyftDetails && (
                      <ShyfterDetailsCard
                        hideEmployer
                        onSaveShyft={(id, saved) => this.handleSaveShyft(id, saved)}
                        {...savedShyftDetails}
                        key={savedShyftDetails.id}
                        shyftStatus={request ? request.status : undefined}
                        isAppliedShyft={checkIfUserAppliedeForShyft(request)}
                        onApplyShift={() =>
                          this.applyForTheShyft(savedShyftDetails.id)
                        }
                        onWithdrawShift={() =>
                          this.withdrawFromTheShyft(savedShyftDetails.id)
                        }
                        onViewProfile={() => {
                          this.onViewBusinessProfile(
                            savedShyftDetails.businessId
                          );
                        }}
                        showOverviewSubtext={false}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}

            {isShyftApplied && (
              <ShyfterFeedbackModal
                isOpen={isShyftApplied}
                title="Applied Successfully"
                description="This shyft has been applied successfully. Explore more shyfts or you can view status of this shyft"
                imageSrc={SuccessIcon}
                primaryButtonText="Explore More"
                primaryButtonOnClick={this.viewExploreShyfts}
                onClose={this.closeApplyShiftModal}
                secondaryButtonText="View Status"
                secondaryButtonOnClick={() => this.navigationToPathInSavedShyft("AppliedShyft")}
              />
            )}

            {isRegisterationPending && (
              <ShyfterFeedbackModal
                data-test-id="savedShyft-registration-pending-modal"
                isOpen={isRegisterationPending}
                onClose={this.closeRegistrationModal}
                imageSrc={SuccessIcon}
                title="Registration Pending"
                description="Your registeration is pending. Please complete your registration to apply for a shyft in any hotel and restaurant."
                secondaryButtonText="Complete Now"
                secondaryButtonOnClick={this.handleCompleteNowButtonRedirection}
                isProfileCompletion={true}
                profileCompletionPercent={this.state.profileCompletion}
              />
            )}
          </Container>
        </Box>

        <Footer />
      </>
    );
  }
}
