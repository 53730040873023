import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import moment from "moment";
import { setStorageData } from "../../../framework/src/Utilities";
import { clearUserLoginData, checkAuthorization } from "../../../components/src/Utilities";
export const configJSON = require("./config");
const sideNavTab = [
  {
    id: 1,
    tab: "Edit & Update Profile",
    isActive: true,
  },
  {
    id: 2,
    tab: "Notification",
    isActive: false,
  },
];

const sideNavTabLink = [
  {
    id: 1,
    tab: "Help Center",
    link: "HelpCentre",
    isActive: false,
  },
  {
    id: 2,
    tab: "Terms & Conditions",
    link: "TermsAndConditions",
    isActive: false,
  },
  {
    id: 3,
    tab: "Privacy Policy",
    link: "PrivacyPolicy",
    isActive: false,
  },
];
export interface Props {
  navigation: any;
  id: string;
}

interface UserFormErrors {
  full_name: boolean;
  full_phone_number: boolean;
  email: boolean;
  address: boolean;
  date_of_birth: boolean;
  ssn: boolean;
  dl_number: boolean;
  dl_state: boolean;
  experience: boolean;
  languageSkills: boolean;
  skills: boolean;
  industry_computer_application_pos: boolean;
  industry_computer_application_pms: boolean;
  industry_computer_application_other_pos: boolean;
  work_authorization_knockout_question1: boolean;
  work_authorization_knockout_question2: boolean;
}

interface S {
  isPastShyft?: boolean;
  sideNavTab?: any;
  token: any;
  userData: any;
  sideNavTabLink: any;
  progress: number;
  user: {
    id: number;
    full_name: string;
    phone_number: string;
    full_phone_number: string;
    email: string;
    address: string;
    date_of_birth: string;
    ssn: string;
    dl_state: string;
    dl_number: string;
    industry_computer_application_pos: string;
    industry_computer_application_pms: string;
    industry_computer_application_other_pos: string;
    experience: string;
    language_skill: any[];
    restaurant_skills: any[];
    hotel_skills: any[];
    work_authorization_knockout_question: any[];
    role_id: 2;
    ein: null;
    created_at: string;
    updated_at: string;
    recent_login: null;
    recent_logout: null;
    age: string;
    profile_picture: null;
  };
  userFormError: UserFormErrors;
  isShyfterFirstStepSaved: boolean;
  shyfterStep: number;
  profileComplition: number;
  openSkillSetDialog: string;
  isCompleteReg: boolean;
  isNumberVerified: boolean;
  verifiedNumber: string;
  snackbarMessage: string;
  resendDisabled: boolean;
  vafiyOpen: boolean;
  otp: string;
  seconds: number;
  otpSentCount: number;
  showAlert: boolean;
  error: string;
  isDeleteAccount: boolean;
  isChangeNumber: boolean;
  activeTab: number
  userStorageData: any;
  isUpdateUser: boolean;
  countryCode: string;
  updatedPhoneNumber: string;
  userName: string;
  errorMessageModal: string;
  errorPopUp: boolean;
  isDeleteAccountError: boolean;
  deleteAccountErrorMessage: string;
}

interface SS {
  id: any;
}

export default class EditShyfterProfilePageContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getShyfterProfileApiCallId: string = "";
  patchShyfterProfileApiCallId: string = "";
  verifyOTPApiCallId: string = "";
  phoneOtpVarifyApiCallId: string = "";
  deleteAccountApiCallId: string = "";
  updateProfilePicApiCallId: string = "";
  countInterval: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.state = {
      isShyfterFirstStepSaved: false,
      isPastShyft: false,
      sideNavTab: sideNavTab,
      token: "",
      userData: "",
      sideNavTabLink: sideNavTabLink,
      progress: 10,
      shyfterStep: 1,
      profileComplition: 0,
      user: {
        id: 20,
        full_name: "",
        phone_number: "",
        full_phone_number: "",
        email: "",
        address: "",
        date_of_birth: "",
        ssn: "",
        dl_state: "",
        dl_number: "",
        industry_computer_application_pos: "",
        industry_computer_application_pms: "",
        industry_computer_application_other_pos: "",
        experience: "",
        language_skill: [],
        restaurant_skills: [],
        hotel_skills: [],
        work_authorization_knockout_question: [],
        role_id: 2,
        ein: null,
        created_at: "",
        updated_at: "",
        recent_login: null,
        recent_logout: null,
        age: "",
        profile_picture: null,
      },
      userFormError: {
        full_name: false,
        full_phone_number: false,
        email: false,
        address: false,
        date_of_birth: false,
        ssn: false,
        dl_number: false,
        dl_state: false,
        experience: false,
        languageSkills: false,
        skills: false,
        industry_computer_application_pos:false,
        industry_computer_application_pms: false,
        industry_computer_application_other_pos: false,
        work_authorization_knockout_question1: false,
        work_authorization_knockout_question2: false,
      },
      openSkillSetDialog: "",
      isCompleteReg: false,
      isNumberVerified: false,

      resendDisabled: false,
      verifiedNumber: "",
      vafiyOpen: false,
      otp: "",
      seconds: 120,
      snackbarMessage: "",
      otpSentCount: 0,
      showAlert: false,
      isDeleteAccount: false,
      error: "",
      isChangeNumber: false,
      activeTab: 1,
      userStorageData: {},
      isUpdateUser: false,
      countryCode: "91",
      updatedPhoneNumber: "",
      userName: "",
      errorMessageModal: "",
      errorPopUp: false,
      isDeleteAccountError: false,
      deleteAccountErrorMessage: "",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const isWorker = checkAuthorization(false, true);
    if (!isWorker) {
      this.navigateToPage("Login");
      return;
    }
    const shyfterData: any = localStorage.getItem("userData");
    this.setState({ userStorageData: JSON.parse(shyfterData) })
    if (!!shyfterData) {
      const user1 = JSON.parse(shyfterData);
      this.getShyfterProfile(user1.user.id);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.navigateToPage("Login");
        return;
      } else {
        this.handleApiResponse(message, responseJson);
      }
    }
  }

  handleDeleteAccountApiResponse = (response: any) => {
    const isDeleteApiErr = Array.isArray(response) && response.length > 0

    if (response?.message === "User account deleted successfully") {
      this.handleLogoutAfterUpdateNumber()
    }
    if (isDeleteApiErr) {
      this.setState({ isDeleteAccountError: true, deleteAccountErrorMessage: response[0].error })
    }
  }

  handleApiResponse(message: Message, responseJson: any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.phoneOtpVarifyApiCallId) {
      this.handleOtpVarifyResponse(responseJson);
    } else if (apiRequestCallId === this.deleteAccountApiCallId) {
      this.handleDeleteAccountApiResponse(responseJson);
    } else if (apiRequestCallId === this.updateProfilePicApiCallId) {
      if (responseJson.user.profile_picture_url) {
        const updtaeStorageData = { ...this.state.userStorageData, profile_picture: responseJson.user.profile_picture_url }
        setStorageData('userData', JSON.stringify(updtaeStorageData))
        this.setState({
          ...this.state,
          isUpdateUser: true,
          user: { ...this.state.user, profile_picture: responseJson.user.profile_picture_url }
        })
      }
    } else if (apiRequestCallId === this.verifyOTPApiCallId) {
      this.handleVerifyOTPResponse(responseJson);
    }
    if (apiRequestCallId === this.getShyfterProfileApiCallId) {
      this.setState({
        ...this.state,
        profileComplition: responseJson.profile_completion,
        verifiedNumber: responseJson.user.data.attributes.full_phone_number,
        user: responseJson.user.data.attributes,
        updatedPhoneNumber: responseJson.user.data.attributes.full_phone_number,
        userName: responseJson.user.data.attributes.full_name
      });
    }

    if (apiRequestCallId === this.patchShyfterProfileApiCallId) {
      let userShyfterId = responseJson.user.id;
      this.getShyfterProfile(userShyfterId);
      const updtaeStorageData = { 
        ...this.state.userStorageData, 
        user: {
          ...this.state.userStorageData.user,
          full_name: responseJson.user.full_name
        } 
      }
      setStorageData('userData', JSON.stringify(updtaeStorageData))
      this.setState({
        ...this.state,
        isUpdateUser: true,
      })
    }
  }

  moveToLoginPage = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), "");
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  };

  getShyfterProfile(userId: any) {
    const headerContent = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getShyfterProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShyfteUserProfileEndPoint + `/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerContent)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getIsNumberVerified = () => {
    const enteredNumber = this.state.user.phone_number;
    return (
      this.state.isNumberVerified && this.state.verifiedNumber === enteredNumber
    );
  };

  handleVerifyOTPResponse = (responseJson: any) => {
    let isVerified = false;
    if (
      responseJson &&
      responseJson.message === "phone number updated successfully"
    ) {
      isVerified = true;
      this.handleLogoutAfterUpdateNumber()
    }
    let userNumber = this.state.user.phone_number;
    this.setState({
      isNumberVerified: isVerified,
      resendDisabled: false,
      vafiyOpen: false,
      verifiedNumber: isVerified ? userNumber : "",
      otp: "",
      seconds: 120,
      snackbarMessage: isVerified
        ? ""
        : "Verification failed. Please enter the correct pin!",
    });
    clearInterval(this.countInterval);
  };

  handlePhoneVerification = () => {
    const enteredNumber = this.state.updatedPhoneNumber;
    if (this.state.otpSentCount < 5) {
      if (!this.state.resendDisabled) {
        this.setState({ resendDisabled: true });
        this.sendPhoneSignUpNumberOtpVarify(enteredNumber);
      }
    } else {
      this.setState({
        ...this.state,
        snackbarMessage:
          "Maximum OTP generation attempts reached. For a fresh start, consider refreshing the page.",
      });
    }
  };

  setOTP = (otp: string) => {
    this.setState({ ...this.state, otp: otp });
  };

  handleOTPDialogClose = () => {
    this.setState({
      vafiyOpen: false,
      otp: "",
      resendDisabled: false,
      seconds: 120,
    });
    clearInterval(this.countInterval);
  };

  handleOtpVarifyResponse(responseJson: any) {
    if (
      responseJson.message &&
      responseJson.message === "OTP sent successfully"
    ) {
      this.setOTPTimer();
      this.setState({ errorPopUp: false })
    } else {
      this.setState({
        errorMessageModal: responseJson?.message || responseJson?.error || responseJson?.errors[0],
        errorPopUp: true,
        resendDisabled: false,
      });
    }
  }
  setOTPTimer = () => {
    this.setState(
      {
        seconds: 120,
        otpSentCount: this.state.otpSentCount + 1,
        otp: "",
        vafiyOpen: true,
      },
      () => {
        this.countInterval = setInterval(() => {
          const { seconds } = this.state;
          if (seconds > 0) {
            this.setState((prevState) => ({
              seconds: prevState.seconds - 1,
            }));
          } else {
            clearInterval(this.countInterval);
            this.setState({ resendDisabled: false });
          }
        }, 1000);
      }
    );
  };

  sendPhoneSignUpNumberOtpVarify(phone: string) {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getPhoneOtpMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneOtpVarifyApiCallId = getPhoneOtpMsg.messageId;

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberOptVarifyUrl + `%2B${this.state.countryCode}${phone}`
    );

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getPhoneOtpMsg.id, getPhoneOtpMsg);
  }

  verifyOTPRequest = (phone: string, pin: string) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const validateOTPRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verifyOTPApiCallId = validateOTPRequest.messageId;

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putUpdatePhoneEndpoint
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    validateOTPRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({ phone_number: `+${this.state.countryCode}${phone}`, pin: pin })
    );
    runEngine.sendMessage(validateOTPRequest.id, validateOTPRequest);
  };

  updateUserProfile() {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.patchShyfterProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );
    const formData = new FormData();
    formData.append("role_id", "2");
    formData.append("shyfter[full_name]", this.state.user.full_name);
    formData.append("shyfter[email]", this.state.user.email);
    formData.append(
      "shyfter[phone_number]",
      `${this.state.countryCode}${this.state.user.full_phone_number}`
    );
    formData.append("shyfter[address]", this.state.user.address);
    formData.append("shyfter[ssn]", this.state.user.ssn);
    formData.append("shyfter[dl_state]", this.state.user.dl_state);
    formData.append("shyfter[dl_number]", this.state.user.dl_number);
    formData.append("shyfter[experience]", this.state.user.experience);
    formData.append(
      "shyfter[language_skill][]",
      this.state.user.language_skill.join(",")
    );
    formData.append(
      "shyfter[restaurant_skills][]",
      this.state.user.restaurant_skills.join(",")
    );
    formData.append(
      "shyfter[hotel_skills][]",
      this.state.user.hotel_skills.join(",")
    );
    formData.append(
      "shyfter[date_of_birth]",
      moment(this.state.user.date_of_birth).format("DD/MM/YYYY")
    );
    formData.append(
      "shyfter[work_authorization_knockout_question][]",
      this.state.user.work_authorization_knockout_question.join(","),
    );
    formData.append(
      "shyfter[industry_computer_application_pos]",
      this.state.user.industry_computer_application_pos === "Other" ? this.state.user.industry_computer_application_other_pos : this.state.user.industry_computer_application_pos 
    );
    formData.append(
      "shyfter[industry_computer_application_pms]",
      this.state.user.industry_computer_application_pms
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  arePersonalDetailsValid = () => {
    const { user, userFormError } = this.state;
    const { address, full_name, email, date_of_birth, dl_state, full_phone_number, ssn, dl_number } =
      user;

    const newErrors: UserFormErrors = {
      ...userFormError,
    };

    newErrors.full_name = full_name.length < 3;
    newErrors.email = !configJSON.emailRegex.test(email) || email.length < 1;
    newErrors.full_phone_number = full_phone_number.length < 10;
    newErrors.address = address.length < 1;
    newErrors.ssn = ssn.length < 1;
    newErrors.dl_state = dl_state.length < 1;
    newErrors.dl_number = dl_number.length < 1;
    newErrors.date_of_birth = date_of_birth.length < 1;

    this.setState({ userFormError: newErrors });
    const excludedKeys = [
    "experience",
    "languageSkills","skills",
    "work_authorization_knockout_question1",
    "work_authorization_knockout_question2",
    "industry_computer_application_pms",
    "industry_computer_application_pos",
    "industry_computer_application_other_pos"]
    const areValid = Object.keys(newErrors)
    .filter(key => !excludedKeys.includes(key as any)) 
    .map((key) => (newErrors as any)[key]) 
    .every(value => value === false); 
    return areValid;
  };

  areProfessionalDetailsValid = () => {
    const { user, userFormError } = this.state;
    const { experience,language_skill,hotel_skills,restaurant_skills } = user;
    const newErrors: UserFormErrors = {
      ...userFormError,
    };
    
    newErrors.experience = experience.length < 1;
    newErrors.languageSkills = language_skill.length < 1;
    newErrors.skills = hotel_skills.length < 1 || restaurant_skills.length < 1;
    this.setState({ userFormError: newErrors });
    const excludedKeys = [
    "work_authorization_knockout_question1",
    "work_authorization_knockout_question2",
    "industry_computer_application_pms",
    "industry_computer_application_pos",
    "industry_computer_application_other_pos"
  ]
    const areValid = Object.keys(newErrors)
    .filter(key => !excludedKeys.includes(key as any)) 
    .map((key) => (newErrors as any)[key]) 
    .every(value => value === false); 
    return areValid;
  }

  areOtherDetails = () => {
    const { user, userFormError } = this.state;
    const {
      industry_computer_application_pos,
      industry_computer_application_other_pos,
      industry_computer_application_pms,
      work_authorization_knockout_question
    } = user;

    const newErrors:UserFormErrors = {
      ...userFormError
    }
    const isKnockoutQuestionExist = !work_authorization_knockout_question.length;
    const isOtherSelected = industry_computer_application_pos === 'Other';
    const otherSelectedCase = industry_computer_application_other_pos ? industry_computer_application_other_pos.length < 1 : true
    newErrors.work_authorization_knockout_question1 = isKnockoutQuestionExist || work_authorization_knockout_question.length > 0 ? !work_authorization_knockout_question[0] : true;
    newErrors.work_authorization_knockout_question2 = isKnockoutQuestionExist || work_authorization_knockout_question.length > 1 ? !work_authorization_knockout_question[1] : true;
    newErrors.industry_computer_application_pms = industry_computer_application_pms === null;
    newErrors.industry_computer_application_pos = industry_computer_application_pos === null;
    newErrors.industry_computer_application_other_pos = isOtherSelected ? otherSelectedCase : false;
    this.setState({ userFormError: newErrors });
    const areValid = Object.values(newErrors).every((value) => value === false);
    return areValid;
  }

  moveShyfterNextStep = () => {
    switch (this.state.shyfterStep) {
      case 1:
        if (this.arePersonalDetailsValid())
          this.setState({ ...this.state, shyfterStep: this.state.shyfterStep + 1 });
        break;
      case 2:
        if (this.areProfessionalDetailsValid())
          this.setState({ ...this.state, shyfterStep: this.state.shyfterStep + 1 });
        break;
      case 3:
        if (this.areOtherDetails()) {
          this.updateUserProfile();
          this.setState({ ...this.state, isCompleteReg: true, shyfterStep: 1 });
        }
        break;
      default:
        this.setState({ ...this.state });
    }
  };

  moveShyfterBackStep = () => {
    this.setState({ ...this.state, shyfterStep: this.state.shyfterStep - 1 });
  };

  handleExploreShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  handleShyfterInputChange = (event: any, name: any) => {
    const { value } = event.target;
    let newValue = value;
    let work = this.state.user.work_authorization_knockout_question
    switch (name) {
      case "full_name":
        newValue = newValue.replace(/[^a-zA-Z\s]/g, "");
        break;
      case "phone_number":
        const numberValue = newValue.toString();
        const sanitizedValue = numberValue
          .replace(/[+e-]/g, "")
          .replace(/\D/g, "");
        newValue = sanitizedValue.slice(0, 10);
        break;
      case "email":
        newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, "");
        break;
      case "work_authorization":
        work[0]= value;
        break;
      case "work_age":
        work[1]= value;
        break;
    }

    let error: any = { [name]: !value };
    if (name === 'work_authorization') {
      error = { work_authorization_knockout_question1: Boolean(!work[0]) }
    } else if (name === 'work_age') {
      error = { work_authorization_knockout_question2: Boolean(!work[1]) }
    }

    this.setState(
      (prevState) => ({
        user: {
          ...prevState.user,
          [name]: newValue,
          ["work_authorization_knockout_question"]: work
        },
      }),
      () => {
        this.setState({ userFormError: { ...this.state.userFormError, ...error } })
      }
    );
  };


  handlePhoneNumberUpdate = (event: any, name: any) => {
    const { value } = event.target;
    let newValue = value;
    
    if(name === "full_phone_number") {
        let changePhoneNumber = newValue.toString();
        const sanitizedValue = changePhoneNumber
          .replace(/[+e-]/g, "")
          .replace(/\D/g, "");
        newValue = sanitizedValue.slice(0, 10);
    }

    this.setState({
      ...this.state,
      updatedPhoneNumber: newValue,
      errorPopUp: false
    })
  }

  deleteSkillItem = (type: string, skillItem: string) => {
    if (type === "hotel") {
      this.setState({
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          hotel_skills: this.state.user.hotel_skills.filter(
            (i) => i !== skillItem
          ),
        },
      });
    } else {
      this.setState({
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          restaurant_skills: this.state.user.restaurant_skills.filter(
            (i) => i !== skillItem
          ),
        },
      });
    }
  };

  toggleSkillSetDialog = (type: string) => {
    this.setState({ ...this.state, openSkillSetDialog: type });
  };

  setSelectedSkills = () => {
    return this.state.openSkillSetDialog === "hotel"
      ? this.state.user.hotel_skills
      : this.state.user.restaurant_skills;
  };

  saveSkillData = (type: string, list: string[]) => {
    this.setState(
      {
        ...this.state,
        openSkillSetDialog: "",
        user: {
          ...this.state.user,
          [`${type}_skills`]: list,
        },
      },
      () => {}
    );
  };

  handleLanguageChange = (data: any) => {
    this.setState(
      {
        ...this.state,
        user: {
          ...this.state.user,
          language_skill: data,
        },
      },
      () => this.areProfessionalDetailsValid()
    );
  };

  openDeleteAccontPopup = () => {
    this.setState({ isDeleteAccount: !this.state.isDeleteAccount, isDeleteAccountError: false, deleteAccountErrorMessage: "" });
  };

  confirmDeleteAccount = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const deletedMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteAccountApiCallId = deletedMsg.messageId;

    deletedMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteAccountApiEndPoint
    );
    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deletedMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );
    runEngine.sendMessage(deletedMsg.id, deletedMsg);
    return true;
  };

  handleFileChange = (event: any) => {
    this.setState({
      ...this.state,
      isUpdateUser: false
    })

    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfilePicApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("user[profile_picture]", selectedFile, "[PROXY]");

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToPage = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  openChangeNumberModal = () => {
    this.setState({
      ...this.state,
      isChangeNumber: true,
      updatedPhoneNumber: "",
      errorPopUp: false
    });
  }

  closeChangeNumberModal = () => {
    this.setState({
      ...this.state,
      isChangeNumber: false,
      vafiyOpen: false,
    });
  }

  changeTab = (tabId: number) => {
    const updatedTabs = this.state.sideNavTab.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      }

      return { ...tab, isActive: false };
    });

    this.setState({ sideNavTab: updatedTabs, activeTab: tabId });
  };

  handleCountryCodeSelectionForEditShyfter = (event: string) => {
    this.setState({ countryCode: event})
  }

  handleLogoutAfterUpdateNumber = () => {
    clearUserLoginData()
    this.moveToLoginPage()
  }

  closeShyfterDeleteErrorPopup = () => {
    this.setState({ isDeleteAccountError: false, deleteAccountErrorMessage: "" });
  }
}
