// Customizable Area Start
import React from "react";
import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { radioCheckedDarkBlueIcon, radioUnCheckedIcon } from "./assets";

import FilterRecordsController, { Props } from "./FilterRecordsController";
import { CustomDrawer } from "../../../components/src/RequestsDialog";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomDateInput from "../../../components/src/CustomDateInput.web";
import moment from "moment";

const styles = {
  filterTitle: {
    fontSize: 22,
    color: "#0a0412",
    fontWeight: "bold" as any,
    fontFamily: '"Whyte Inktrap", sans-serif',
    textAlign: "center" as any,
    padding: "20px 0px 10px"
  },
  closeIcon: {
    color: "#fff" as any,
    right: "390px",
    top: "15px",
    width: "35px",
    cursor: "pointer",
    height: "35px",
    display: "flex",
    zIndex: 9999,
    position: "fixed" as any,
    alignItems: "center" as any,
    borderColor: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    backgroundColor: "#26202e"
  },
  tabsContainer: {
    border: "1px solid #e1e6ec",
    borderRadius: "14px",
    height: "fit-content",
    backgroundColor: "#FFF",
    padding: "0px 10px"
  },
  inActiveTab: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#6f7c8e",
    fontFamily: '"Gotham", sans-serif',
    fontWeight: 500,
    textAlign: "center" as any,
    borderBottom: "3px solid transparent",
    padding: "10px",
    cursor: "pointer",
    width: "100%"
  },
  activeTab: {
    lineHeight: "16px",
    fontSize: "14px",
    width: "100%",
    color: "#390879",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    borderBottom: "3px solid #390879",
    textAlign: "center" as any,
    padding: "10px",
    cursor: "pointer"
  },
  filterItem: {
    borderRadius: "23px",
    backgroundColor: "#ffffff",
    border: "1px solid #e1e6ec",
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "18px",
    color: "#0a0412",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    cursor: "pointer"
  },
  filterItemActive: {
    borderRadius: "23px",
    backgroundColor: "#ffffff",
    border: "1px solid #390879",
    fontSize: "14px",
    letterSpacing: "0px",
    lineHeight: "18px",
    color: "#0a0412",
    fontWeight: 500,
    fontFamily: '"Gotham", sans-serif',
    cursor: "pointer"
  },
  actionWrapper: {
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between"
  },
  completedBox: {
    width: "30px",
    height: "30px",
    borderRadius: "9px",
    backgroundColor: "#2da406",
    marginRight: "5px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px"
  },
  missedBox: {
    width: "30px",
    height: "30px",
    borderRadius: "9px",
    backgroundColor: "#ff375b",
    marginRight: "5px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px"
  },
  weeklyOffsBox: {
    width: "30px",
    height: "30px",
    borderRadius: "9px",
    backgroundColor: "#390879",
    marginRight: "5px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px"
  },
  dateLabel: {
    fontSize: "13px",
    letterSpacing: "0px",
    lineHeight: "18px",
    color: "#0a0412",
    fontWeight: 500,
    fontFamily: "'Gotham', sans-serif"
  },
  recordsOf: {
    width: "100%",
    textAlign: "center" as any,
    borderBottom: "1px dotted #e1e6ec",
    lineHeight: "0.1em",
    margin: "10px 0 20px"
  },
  recordsOfText: {
    padding: "0px 10px",
    backgroundColor: "#fff",
    color: "#0a0412",
    fontSize: "14px",
    fontWeight: 500,
    fontFamily: "'Gotham', sans-serif"
  },
  totalDays: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "#390879",
    fontWeight: "bold" as any,
    fontFamily: "'Gotham', sans-serif",
    textAlign: "center" as any
  }
};

const setActiveFilterTabStyle = (tab: number, selectedTab: number) => {
  return tab === selectedTab ? styles.activeTab : styles.inActiveTab;
};

const setActiveStatusFilterImage = (tab: string, selectedTab: string) => {
  return tab === selectedTab ? radioCheckedDarkBlueIcon : radioUnCheckedIcon;
};

const setActiveFilterStyle = (tab: string, selectedTab: string) => {
  return tab === selectedTab ? styles.filterItemActive : styles.filterItem;
};

export const daysCount = (startDate: any, endDate: any) => {
  let days = 0;
  if (startDate && endDate) {
    let start = moment(startDate, "YYYY-MM-DD");
    let end = moment(endDate, "YYYY-MM-DD");

    days = end.diff(start, "days") + 1;
  }
  return days > 0 ? `${days} Days` : "0 Day";
};

export const getStartAndEndDateValidation = (startDate: any, endDate: any) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return startMoment.isSameOrBefore(endMoment);
};

export default class FilterRecords extends FilterRecordsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      activeFilterTab,
      byStatusFilter,
      dateFrom,
      dateTo,
      dateToError,
      dateFromError
    } = this.state;
    return (
      <CustomDrawer open={this.props.isOpenFilterDrawer} anchor="right">
        <Box height="100%" width="375px" position="relative">
          <IconButton
            data-test-id="close-btn"
            style={styles.closeIcon}
            onClick={this.closeFilterRecords}
          >
            <CloseIcon height={20} width={20} />
          </IconButton>
          <Box style={styles.filterTitle}>Filter Records</Box>
          <Box
            display="flex"
            alignItems="center"
            borderBottom="1px solid #e1e6ec"
            justifyContent="space-between"
          >
            <Box
              style={setActiveFilterTabStyle(0, activeFilterTab)}
              onClick={() => this.onChangeFilterTab(0)}
            >
              By Status
            </Box>
            <Box
              style={setActiveFilterTabStyle(1, activeFilterTab)}
              onClick={() => this.onChangeFilterTab(1)}
              data-test-id="date-filter"
            >
              By Date
            </Box>
          </Box>
          {activeFilterTab === 0 ? (
            <Box padding="20px">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={setActiveFilterStyle("default", byStatusFilter)}
                marginBottom="12px"
                padding="13px"
                onClick={() => this.onChangeStatusFilter("default")}
                data-test-id="default-status"
              >
                <Box>Default: All</Box>
                <Box>
                  <img
                    src={setActiveStatusFilterImage("default", byStatusFilter)}
                    alt="checkbox"
                    height={18}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="12px"
                style={setActiveFilterStyle("completed", byStatusFilter)}
                onClick={() => this.onChangeStatusFilter("completed")}
                padding="7px 13px"
                data-test-id="completed-status"
              >
                <Box display="flex" alignItems="center">
                  <Box style={styles.completedBox}>C</Box>
                  <Box>Completed Shyfts</Box>
                </Box>
                <Box>
                  <img
                    src={setActiveStatusFilterImage(
                      "completed",
                      byStatusFilter
                    )}
                    alt="checkbox"
                    height={18}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="12px"
                style={setActiveFilterStyle("missed", byStatusFilter)}
                onClick={() => this.onChangeStatusFilter("missed")}
                padding="7px 13px"
                data-test-id="missed-status"
              >
                <Box display="flex" alignItems="center">
                  <Box style={styles.missedBox}>M</Box>
                  <Box>Missed Shyfts</Box>
                </Box>
                <Box>
                  <img
                    src={setActiveStatusFilterImage("missed", byStatusFilter)}
                    alt="checkbox"
                    height={18}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                marginBottom="8px"
                style={setActiveFilterStyle("weekly_off", byStatusFilter)}
                onClick={() => this.onChangeStatusFilter("weekly_off")}
                padding="7px 13px"
                data-test-id="weekly-off-status"
              >
                <Box display="flex" alignItems="center">
                  <Box style={styles.weeklyOffsBox}>WO</Box>
                  <Box>Weekly Offs</Box>
                </Box>
                <Box>
                  <img
                    src={setActiveStatusFilterImage("weekly_off", byStatusFilter)}
                    alt="checkbox"
                    height={18}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box padding="20px">
              <Box style={styles.dateLabel}>Date From</Box>
              <CustomDateInput
                handleChange={e => this.onChangeDate(e, "dateFrom")}
                value={dateFrom}
                label={""}
                name="dateFrom"
                error={!!dateFromError}
              />
              <Box style={styles.dateLabel}>Date To</Box>
              <CustomDateInput
                handleChange={e => this.onChangeDate(e, "dateTo")}
                value={dateTo}
                label={""}
                name="dateTo"
                error={!!dateToError}
              />
              <Box style={styles.recordsOf}>
                <span style={styles.recordsOfText}>Record Of</span>
              </Box>
              <Box style={styles.totalDays}>
                Total: {daysCount(dateFrom, dateTo)}
              </Box>
            </Box>
          )}
          <Box style={styles.actionWrapper}>
            <Box paddingRight="5px" width="100%">
              <CustomButton
                isOutlined={true}
                label="Clear All"
                handleClick={this.clearFilter}
              />
            </Box>
            <Box data-test-id="apply-filter-button" paddingLeft="5px" width="100%">
              <CustomButton label="Apply" handleClick={this.applyFilter} />
            </Box>
          </Box>
        </Box>
      </CustomDrawer>
    );
  }
}

// Customizable Area End
