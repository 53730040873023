import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setUserRole } from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;
  placeHolderEmail: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  open: boolean,
  phone: string,
  seconds: number,
  otp: string,
  resendDisabled: boolean,
  error: string,
  alertOpened: boolean,
  phoneError: string,
  isOpenRegistrationDialog: boolean;
  checkNumberValidation: boolean;
  resendCount: number;
  inputShrink: boolean;
  countryCodeValue: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiEmailLoginCallId: string = "";
  validationApiCallId: string = "";
  phoneOtpVarifyApiCallId: string = "";
  phoneOtpLoginApiCallId: string = "";
  timer: NodeJS.Timeout | undefined;
  emailReg: RegExp;
  labelTitle: string = "";
  otpTimer: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      email: "",
      password: "",
      enablePasswordField: true,
      checkedRememberMe: false,
      placeHolderEmail: configJSON.placeHolderEmail,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: configJSON.imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      open: false,
      phone: "",
      seconds: 120,
      otp: "",
      resendDisabled: false,
      error: "",
      alertOpened: false,
      phoneError: "",
      isOpenRegistrationDialog: false,
      checkNumberValidation: false,
      resendCount: 0,
      inputShrink: false,
      countryCodeValue: "91",
    };
    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.callGetValidationApi();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    localStorage.clear();
    // Customizable Area End
  }

  // Customizable Area Start
  saveOtpLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.authentication_token ) {
      setStorageData('userData',JSON.stringify(responseJson))
      setStorageData("manageAccountTabId", "1")
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.authentication_token
      );

      this.send(msg);
      setUserRole(responseJson.user.role_id);
      this.redirectFromLogin(
        responseJson.user.role_id,
        responseJson.user.admin_verification
      );
    }
  }
  
  navigateToRoute = (route: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  redirectFromLogin = (roleId: number, isVerfied: boolean) => {
    if (roleId === 2) return this.navigateToRoute("ShyfterLandingPage");

    if (isVerfied) {
      this.navigateToRoute("EmployerHomePage");
    } else {
      this.setState({ alertOpened: true, error: "Admin Verification Pending" });
    }
  }

  sendPhoneNumberOtpVarify(phone: string) {
    const headers = {
      "Content-Type": configJSON.phoneNumberOptVarifyContentType,
    };

    const getPhoneOtpMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneOtpVarifyApiCallId = getPhoneOtpMsg.messageId;

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberOptVarifyUrl + `%2B${this.state.countryCodeValue}${phone}`
    );

    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPhoneOtpMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.phoneNumberOptVarifyMethodType
    );
    runEngine.sendMessage(getPhoneOtpMsg.id, getPhoneOtpMsg);
  }

  sendPhoneNumberOtpLogin(phone: string, otp: string) {
    const headers = {
      "Content-Type": configJSON.phoneNumberOptLoginContentType,
    };

    const getPhoneOtpLoginMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.phoneOtpLoginApiCallId = getPhoneOtpLoginMsg.messageId;

    getPhoneOtpLoginMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.phoneNumberOptLoginUrl
    );

    getPhoneOtpLoginMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getPhoneOtpLoginMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.phoneNumberOptLOginMethodType
    );

    getPhoneOtpLoginMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        phone_number: `+${this.state.countryCodeValue}${phone}`,
        pin: otp,
        device_id: localStorage.getItem("fcmToken") || "",
      })
    );
    runEngine.sendMessage(getPhoneOtpLoginMsg.id, getPhoneOtpLoginMsg);
  }

  btnSocialLoginProps = {
    onPress: () => this.goToSocialLogin(),
  };

  btnEmailLogInProps = {
    onPress: () => this.doEmailLogIn(),
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry =
        !this.state.enablePasswordField;
      this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  // Web Event Handling
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  setEmail = (text: string) => {
    this.setState({
      email: text,
    });
  };

  setPassword = (text: string) => {
    this.setState({
      password: text,
    });
  };

  setRememberMe = (value: boolean) => {
    this.setState({ checkedRememberMe: value });
  };

  CustomCheckBoxProps = {
    onChangeValue: (value: boolean) => {
      this.setState({ checkedRememberMe: value });
      this.CustomCheckBoxProps.isChecked = value;
    },
    isChecked: false,
  };

  btnForgotPasswordProps = {
    onPress: () => this.goToForgotPassword(),
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  btnPasswordShowHideImageProps = {
    source: imgPasswordVisible,
  };

  btnRememberMeProps = {
    onPress: () => {
      this.setState({ checkedRememberMe: !this.CustomCheckBoxProps.isChecked });
      this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
    },
  };

  txtInputEmailWebProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtInputEmailProps.value = text;
    },
  };

  txtInputEmailMobileProps = {
    ...this.txtInputEmailWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputEmailProps = this.isPlatformWeb()
    ? this.txtInputEmailWebProps
    : this.txtInputEmailMobileProps;

  handleToggleRegistrationDialog = () => {
    this.setState({
      isOpenRegistrationDialog: !this.state.isOpenRegistrationDialog
    });
  };

  redirectToSignUp = (url: string) => {
    this.setState({ isOpenRegistrationDialog: false });
    this.props.navigation.navigate(url);
  };

  handleMobileNumberValidation = () => {
    const mobileNumber = this.state.phone;
    if (!mobileNumber || !configJSON.mobileNumberRegex.test(mobileNumber)) {
      return false;
    }
    return true;
  };

  handleResend = () => {
    if (this.state.resendCount < 5 && !this.state.resendDisabled) {
      this.setState({ resendDisabled: true });
      this.sendPhoneNumberOtpVarify(this.state.phone);
    }
  };

  handleInputShrink = (value: boolean) => {
    this.setState({ inputShrink: value });
  }

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toString();
    const sanitizedValue = value.replace(/[+e-]/g, "").replace(/\D/g, "");
    const limitedValue = sanitizedValue.slice(0, 10);
    if (limitedValue && limitedValue.toString().length > 0) {
      this.handleInputShrink(true);
    }
    this.setState({ phone: limitedValue }, () => {
      if (this.state.checkNumberValidation) {
        const isValid = this.handleMobileNumberValidation();
        this.setState({ phoneError: isValid ? "" : "Invalid Number"})
      }
    });
  };

  handlePhoneSubmit = () => {
    this.setState({ checkNumberValidation: true, alertOpened: false }, () => {
      if (this.handleMobileNumberValidation()) {
        this.setState({ phoneError: "" });
        if (this.state.resendCount < 5 && !this.state.resendDisabled) {
          this.setState({resendDisabled: true});
          this.sendPhoneNumberOtpVarify(this.state.phone);
        } else {
          this.setState({ alertOpened: true, error: "Maximum limit exceeded"});
        }
      } else {
        this.setState({ phoneError: "Invalid Number" });
      }
    });
  };

  closeAlert = () => {
    this.setState({ alertOpened: false, error: "" });
  };

  handleOTPPopupClose = (isSubmit: boolean) => {
    this.setState({
      open: false,
      otp: "",
      resendDisabled: false,
      seconds: 120,
      phoneError: "",
    });
    clearInterval(this.otpTimer);
    if (isSubmit) {
      this.sendPhoneNumberOtpLogin(this.state.phone, this.state.otp);
    }
  };

  handleErrorResponse = (responseJson: any) => {
    let errorMessage = "";

    if (responseJson) {
      if (responseJson.message) {
        errorMessage = responseJson.message;
      } else if (responseJson.errors) {
        if (typeof(responseJson.errors[0]) === "string") {
          errorMessage = responseJson.errors[0];
        } else if (responseJson.errors[0].token) {
          errorMessage = responseJson.errors[0].token;
        }
      }
    }
    this.setState({
      error: errorMessage,
      alertOpened: true,
      resendDisabled: false,
    });
  };

  otpTimerFunction = (otp: any) => {
    this.setState(
      {
        seconds: 120,
        otp: "",
        resendCount: this.state.resendCount + 1,
        open: true,
      },
      () => {
        this.otpTimer = setInterval(() => {
          const { seconds } = this.state;
          if (seconds > 0) {
            this.setState((prevState) => ({
              seconds: prevState.seconds - 1,
            }));
          } else {
            clearInterval(this.otpTimer);
            this.setState({ resendDisabled: false });
          }
        }, 1000);
      }
    );
  };

  handleCountryCodeSelection = (event: string) => {
    this.setState({ countryCodeValue: event})
  }


  componentDidUpdate() {
    if(this.state.alertOpened){
      setTimeout(() => this.setState({ alertOpened: false}), 4000)
    }
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const userName = message.getData(getName(MessageEnum.LoginUserName));
      const password = message.getData(getName(MessageEnum.LoginPassword));
      const countryCode = message.getData(getName(MessageEnum.LoginCountryCode));

      if (!countryCode && userName && password) {
        this.setState({
          email: userName,
          password: password,
          checkedRememberMe: true,
        });

        //@ts-ignore
        this.txtInputEmailProps.value = userName;

        //@ts-ignore
        this.txtInputPasswordProps.value = password;

        this.CustomCheckBoxProps.isChecked = true;
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      if (apiRequestCallId != null && responseJson) {
        if (apiRequestCallId === this.phoneOtpVarifyApiCallId && responseJson.message === "OTP sent successfully") {
          this.otpTimerFunction(responseJson.otp);
        } else if (apiRequestCallId === this.phoneOtpLoginApiCallId && responseJson.message === "Login successful") {
          this.saveOtpLoggedInUserData(responseJson);
        } else {
          this.handleErrorResponse(responseJson);
        }
      }
    }
    // Customizable Area End
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }

  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
    msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doEmailLogIn(): boolean {
    if (
      this.state.email === null ||
      this.state.email.length === 0 ||
      !this.emailReg.test(this.state.email)
    ) {
      this.showAlert("Error", configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password === null || this.state.password.length === 0) {
      this.showAlert("Error", configJSON.errorPasswordNotValid);
      return false;
    }

    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };

    const attrs = {
      email: this.state.email,
      password: this.state.password,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiEmailLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  callGetValidationApi() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
}
