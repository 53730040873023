import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import { ProfileQuickLink, ProfileQuickLinkPage } from "../../../components/src/ProfileQuickLink";
import EditShyfterProfilePageContoller, {
  Props,
  configJSON,
} from "./EditShyfterProfileController.web";
import { hourglass } from "./assets";
import { UpdateProfileCard } from "../../../components/src/UpdateProfileCard";
import CustomButton from "../../../components/src/CustomButton.web";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";
import WarningPopup from "../../../components/src/WarningPopUp";
import { breadCrumManageAccountPath} from "../../../components/src/Utilities";
import { UpdatePersonalDeatils } from "./UpdatePersonalDetils.web";
import { UpdateProfessionalDeatils } from "./UpdateProfessionalDetails.web";
import { UpdateOtherDeatils } from "./UpdateOtherDetails.web";
import PushNotificationsWeb from "../../pushnotifications/src/PushNotifications.web";
import ContactUsPopup from "../../../components/src/ContactUsPopUp";

export const setDisabledVerifyOTP = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};
const setMaxDate = () => {
  const maxSelectionDate = new Date();
  maxSelectionDate.setFullYear(new Date().getFullYear() - 18);
  return maxSelectionDate;
};

const setSkillSetList = (type: string) => {
  let list: string[] = [];
  list =
    type === "hotel" ? configJSON.hotelSkills : configJSON.restaurantSkills;
  return list;
};
export default class EditShyfterProfilePage extends EditShyfterProfilePageContoller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader isUpdateUser={this.state.isUpdateUser} {...this.props} isBreadcrumbs={true} breadCrumsData={breadCrumManageAccountPath} currentPage="Manage Account" isShowDetails={false}/>
        <Box marginTop={"10%"}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography>{configJSON.genralSettig}</Typography>
                </Box>
                <ProfileQuickLink linkData={this.state.sideNavTab}  getActiveTab={this.changeTab}/>
                <Box width={"100%"} marginY={"10px"}>
                  <Typography>Other</Typography>
                </Box>
                <ProfileQuickLinkPage
                  linkData={this.state.sideNavTabLink}
                  isEdit={true}
                  navigateToPage={this.navigateToPage}
                />

                <Box width={"100%"} marginY={"15px"}>
                  <Divider />
                </Box>
                <CustomButton
                  isDeleteBtn={true}
                  isDanger={true}
                  label={"Delete Account"}
                  isOutlined={true}
                  handleClick={this.openDeleteAccontPopup}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                {this.state.activeTab === 1 &&  <Box>
                  {this.state.profileComplition < 100 && 
                   <Box
                   display={"flex"}
                   justifyContent={"center"}
                   alignItems={"center"}
                   bgcolor={"rgba(252, 226, 152, 0.3)"}
                   borderRadius={"10px"}
                   padding={"17px"}
                 >
                   <Box paddingRight={"5px"}>
                     <img src={hourglass} height={"18px"} />
                   </Box>
                   
                   <Typography
                   style={{
                     color: "#f7b500",
                     fontSize: "14px",
                     fontWeight: "bold",
                   }}
                 >
                   Complete your Registration...
                 </Typography>
                 </Box>
                  }
                 
                  <Box marginY={"13px"}>
                    <Typography>Edit & Update Profile</Typography>
                  </Box>
                  <UpdateProfileCard
                    progress={this.state.profileComplition}
                    profilePic={this.state.user.profile_picture}
                    handleFileChange={this.handleFileChange}
                    userFullName={this.state.userName}
                  />
                 
                  {this.state.shyfterStep == 1 && (
                    <>
                     <Typography
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    Profile Details
                  </Typography>

                  <UpdatePersonalDeatils
                      data-test-id="updatepersonalDetails"
                      user={this.state.user}
                      userFormError={this.state.userFormError}
                      configJSON={configJSON}
                      handleShyfterInputChange={this.handleShyfterInputChange}
                      setMaxDate={setMaxDate}
                      moveToNext={this.moveShyfterNextStep}
                      getIsNumberVerified={this.getIsNumberVerified}
                      handlePhoneVerification={this.handlePhoneVerification}
                      vafiyOpen={this.state.vafiyOpen}
                      otp={this.state.otp}
                      setOtp={this.setOTP}
                      seconds={this.state.seconds}
                      handleOTPDialogClose={this.handleOTPDialogClose}
                      verifyOTPRequest={this.verifyOTPRequest}
                      setDisabledVerifyOTP={setDisabledVerifyOTP}
                      openChangeNumberModal={this.openChangeNumberModal}
                      closeChangeNumberModal={this.closeChangeNumberModal}
                      isChangeNumber={this.state.isChangeNumber}
                      handleCountryCodeChange={this.handleCountryCodeSelectionForEditShyfter}
                      countryCodeUpdate={this.state.countryCode}
                      handlePhoneNumberUpdate={this.handlePhoneNumberUpdate}
                      updatedPhoneNumber={this.state.updatedPhoneNumber}
                      errorMessage={this.state.errorMessageModal}
                      errorPopUp={this.state.errorPopUp}
                    />
                    </>
                 
                  )}
                  {this.state.shyfterStep == 2 && (
                    <>
                  <Typography
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    Professional Details
                  </Typography>  
                    <UpdateProfessionalDeatils
                      user={this.state.user}
                      userFormError={this.state.userFormError}
                      configJSON={configJSON}
                      handleShyfterInputChange={this.handleShyfterInputChange}
                      setMaxDate={setMaxDate}
                      deleteSkillItem={this.deleteSkillItem}
                      handleLanguageChange={this.handleLanguageChange}
                      toggleSkillSetDialog={this.toggleSkillSetDialog}
                      moveToNext={this.moveShyfterNextStep}
                      moveBack={this.moveShyfterBackStep}
                      setSkillSetList={setSkillSetList}
                      setSelectedSkills={this.setSelectedSkills}
                      openSkillSetDialog={this.state.openSkillSetDialog}
                      saveSkillData={this.saveSkillData}
                    />
                    </>
                    
                  )}
                  {this.state.shyfterStep == 3 && (
                    <>
                    <Typography
                    style={{ marginTop: "10px", marginBottom: "5px" }}
                  >
                    Other Details
                  </Typography>
                  <UpdateOtherDeatils
                      user={this.state.user}
                      userFormError={this.state.userFormError}
                      configJSON={configJSON}
                      handleShyfterInputChange={this.handleShyfterInputChange}
                      submitBtn={this.moveShyfterNextStep}
                      moveBack={this.moveShyfterBackStep}
                    />
                    </>
                   
                  )}
                </Box>}
               
                {this.state.activeTab === 2 && <PushNotificationsWeb {...this.props} />}
              </Grid>
            </Grid>
              <SubmitSuccessPopUp
                open={this.state.isCompleteReg}
                isRegProgress={this.state.profileComplition}
                isDoubleAction={true}
                completeRegistration={() => this.handleExploreShyft()}
                exploreShyfts={() => this.setState({ isCompleteReg: false })}
                backToLogin={() => this.setState({ isCompleteReg: false })}
              />

                <WarningPopup
                open={this.state.isDeleteAccount}
                isAccDelete={true}
                handleAction={() => this.confirmDeleteAccount()}
                handleCancle={() => this.setState({ isDeleteAccount: false })}
              />

              <ContactUsPopup 
                isSuccess={false}
                open={this.state.isDeleteAccountError}
                handleCancel={this.closeShyfterDeleteErrorPopup}
                isApiError={true}
                isDeleteAccountError={this.state.isDeleteAccountError}
                deleteErrMsg={this.state.deleteAccountErrorMessage} 
              />
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  boxContainer: {
    marginY: "10px",
    border: "1px solid #e1e6ec",
    bgcolor: "#fff",
    borderRadius: "20px",
  },

  fadeText: {
    fontSize: "16px",
    lineHeight: "18px",
    color: "#6f7c8e",
    fontWieght: 500,
  },
  lable: {
    color: "#000",
    fontSize: "16px",
    fontWieght: 700,
    lineHeight: "18px",
  },
  contentText: {
    fontWieght: 500,
    fontSize: "16px",
    color: "#000",
    lineHeight: "18px",
  },
  mainWrapper: {
    display: "flex",
    paddingBottom: "30px",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    background: "#fff",
  },
  activeText: {
    fontSize: "16px",
    color: "#390879",
    fontWieght: 500,
    lineHeight: "18px",
  },
 
  inputStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
  },
 
};
