import React from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import { warningInfoIcon } from "./assets";

const useStyles = makeStyles((theme) => ({
  NoShyftDataCardContainer: () => ({
    borderRadius: 14,
    border: "1px solid #e1e6ec",
    padding: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 640,
    " & .imgContainer": {
      paddingBottom: 14,
    },
    " & .noDataTitle": {
      fontSize: 20,
      fontWeight: "bold",
      color: "#0a0412",
      paddingBottom: 14,
    },
    " & .noDataDescription": {
      fontSize: 14,
      fontWeight: 500,
      color: "#6f7c8e",
    },
    " & .noDataAction": {
      paddingTop: 20,
    },
    " & .addShyftBtn": {
      borderRadius: "50px",
      padding: "11px 0",
      fontSize: "14px",
      fontWeight: 800,
      textTransform: "none",
      width: 205,
    },
  }),
}));

interface INoShyftDataCardProps {
  title: string;
  description: string;
  handleClick: (e: any) => void;
}

const NoShyftDataCard: React.FC<INoShyftDataCardProps> = ({
  title,
  description,
  handleClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.NoShyftDataCardContainer}>
      <Box textAlign="center" width={392}>
        <div className="imgContainer">
          <img src={warningInfoIcon} height={64} width={64} alt="warning" />
        </div>
        <div data-test-id="no-data-title" className="noDataTitle">{title}</div>
        <div className="noDataDescription">{description}</div>
        <div className="noDataAction">
          <Button
            className="addShyftBtn"
            color="primary"
            variant="contained"
            onClick={handleClick}
          >
            + Add Shyfts
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default NoShyftDataCard;
