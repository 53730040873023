import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
import {
  constructURL,
  TRatings,
  formatShiftsListResponse,
  formatShyftDetails,
  TAPIShyftDetails,
  constructSearchFilter,
  checkAuthorization,
} from "../../../components/src/Utilities";
import { createRef, RefObject } from "react";

import {
  TShyftItem,
  TPagination,
  TShyftDetails,
  TQueryParams,
  TShyftRequest,
} from "./ExploreShyftsController.web";
import { setStorageData } from "../../../framework/src/Utilities";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  recommendedList: TShyftItem[];
  selectedShyft: number | null;
  shyftDetails: TShyftDetails | null;
  totalPages: number;
  totalShyfts: number;
  perPage: number;
  page: number;
  profileComplition: number;
  isLocationModalOpen: boolean;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  isRegisterationPending: boolean;
  search: string;
  saveShyftId?: number;
  isLocationSearching: boolean;
  currentLatitude: number;
  currentLongitude: number;
  locationModalDataBreadCrum: string[],
  locationCountries: [],
  isCountrySelectedInModal: boolean;
  locationRecommendsData: [],
  locationStateDataForModal: any[];
  locationCityDataForModal: any[];
  isStateSelectedInModal: boolean;
  isCitySelectedInModal: boolean;
  countryCodeForLocation: string;
  searchedLocationData: [];
  searchTextForLocationDataPrediction: string;
  countryName: string;
  stateName: string;
  cityName: string;
  userCity: string;
}

interface SS {
  id: any;
}

export default class RecommendedShyftsController extends BlockComponent<
  Props,
  S,
  SS
> {
  elementObserver: IntersectionObserver | null = null;
  skipRender = true;
  elementRef: RefObject<HTMLDivElement>;
  getRecommendedShyftsAPICallId: string = "";
  getRecommendedShyftDetailsAPICallId: string = "";
  getNextRecommendedShyfts: string = "";
  getCurrentUserApiCallId: string = "";
  postWithdrawFromShyftCallId: string = "";
  postApplyForShyftAPICallId: string = "";
  postRecommShyftSaveAPICallId: string = "";
  deleteShyftSaveAPICallId: string = "";
  getUsersCurrentLocationUsingLatLongAPICallId: string = "";
  getCountriesDataForLocationAPICallId: string = "";
  getStatesDataByCountryDataAPICallId: string = "";
  getCitiesDataByStateDataAPICallId: string = "";
  getLocationPredictionsForSearchingAPICallId: string = "";
  timer: any = null;
  patchLocationUpdateInRecommendAPICallId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [
      getName(MessageEnum.NavigationSearchShyftsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      search: "",
      recommendedList: [],
      page: 1,
      perPage: 15,
      totalPages: 1,
      totalShyfts: 0,
      shyftDetails: null,
      selectedShyft: null,
      isLocationModalOpen: false,
      profileComplition: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
      isLocationSearching: false,
      currentLatitude: 0,
      currentLongitude: 0,
      locationModalDataBreadCrum: [],
      locationCountries: [],
      isCountrySelectedInModal: false,
      locationRecommendsData: [],
      locationStateDataForModal: [],
      locationCityDataForModal: [],
      isStateSelectedInModal: false,
      isCitySelectedInModal: false,
      countryCodeForLocation: "",
      searchedLocationData: [],
      searchTextForLocationDataPrediction: "",
      countryName: "",
      stateName: "",
      cityName: "",
      userCity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (response?.errors && response?.errors?.length > 0 && response?.errors[0]?.token) {
        this.backToSignIn();
        return;
      } else {
        this.handleRecommendedRestAPIResponses(response, apiRequestCallId);
      }
    }

    if (getName(MessageEnum.NavigationSearchShyftsMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationSearchShyftsMessage)
      );
      this.setState({ search: data.search });
    }
  }

  handleUpdateLocationData = (data: any) => {
    if (data && data.length > 0) {
      this.setState({
        locationRecommendsData: data,
      });
    }
  };

  updatedLocationRefresh = () => {
    const { search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getRecommendedShifts({ search, page, perPage }, "initial");
    this.getCountriesForRecommends();
    this.listenForScrollIntersection();
  }

  handleLocationApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getCountriesDataForLocationAPICallId) {
      this.setState({
        locationCountries: responseJson,
        isCountrySelectedInModal: true,
        isStateSelectedInModal: false,
        isCitySelectedInModal: false
      });
      this.handleUpdateLocationData(responseJson);
    }

    if (apiRequestCallId === this.getStatesDataByCountryDataAPICallId) {
      this.setState({
        locationStateDataForModal: responseJson,
        isCountrySelectedInModal: false,
        isStateSelectedInModal: true,
        isCitySelectedInModal: false
      });
      this.handleUpdateLocationData(responseJson);
    }
    if (apiRequestCallId === this.getCitiesDataByStateDataAPICallId) {
      this.setState({
        locationCityDataForModal: responseJson,
        isCountrySelectedInModal: false,
        isStateSelectedInModal: false,
        isCitySelectedInModal: true,
      });
      this.handleUpdateLocationData(responseJson);
    }

    if (apiRequestCallId === this.getUsersCurrentLocationUsingLatLongAPICallId) {
      const addressComponents = responseJson.results[0].address_components;
      const city = addressComponents.find((component: any) => component.types.includes('locality'));
      const state = addressComponents.find((component: any) => component.types.includes('administrative_area_level_1'));
      const country = addressComponents.find((component: any) => component.types.includes('country'));

      this.setState({
        cityName: city.long_name,
        stateName: state.long_name,
        countryName: country.long_name
      });

      this.updateLocationInRecommends(city.long_name, state.long_name, country.long_name)
    }

    if (apiRequestCallId === this.getLocationPredictionsForSearchingAPICallId) {
      if (responseJson?.predictions && responseJson?.predictions.length > 0) {
        this.setState({
          searchedLocationData: responseJson.predictions
        });
      }
    }

    if (apiRequestCallId === this.patchLocationUpdateInRecommendAPICallId) {
      this.updatedLocationRefresh();
    }
  };

  handleRecommendedRestAPIResponses = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (
      [
        this.getRecommendedShyftsAPICallId,
        this.getNextRecommendedShyfts,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedShyftsListingResponse(
        responseJson,
        apiRequestCallId
      );
    }

    if (
      [
        this.postApplyForShyftAPICallId,
        this.postWithdrawFromShyftCallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedShyftsApplyResponse(responseJson, apiRequestCallId);
    }

    if (
      [
        this.postRecommShyftSaveAPICallId,
        this.deleteShyftSaveAPICallId,
      ].includes(apiRequestCallId)
    ) {
      this.handleRecommendedSaveShyftsResponse(apiRequestCallId);
    }

    if (this.getRecommendedShyftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetailsData(
        responseJson.shyft_details.data,
        responseJson.shyft_details.meta.ratings_data,
        responseJson.shyft_details.meta.request
      );
    }

    if (apiRequestCallId === this.getCurrentUserApiCallId) {
      this.setCurrentProfileCompletion(
        responseJson.data.attributes.profile_completion,
        responseJson.data.attributes.shyfter_city
      );
    }

    this.handleLocationApiResponse(apiRequestCallId, responseJson);
  };

  handleRecommendedShyftsListingResponse = (
    responseJson: any,
    apiRequestCallId: string
  ) => {
    if (this.getRecommendedShyftsAPICallId === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.recommended_shifts.data,
        responseJson.pagination_details,
        "initial"
      );
    }

    if (this.getNextRecommendedShyfts === apiRequestCallId) {
      this.handleShiftsData(
        responseJson.recommended_shifts.data,
        responseJson.pagination_details,
        "next"
      );
    }
  };

  handleRecommendedShyftsApplyResponse = (
    response: any,
    apiRequestCallId: string
  ) => {
    if (this.postApplyForShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData(response);
    }

    if (this.postWithdrawFromShyftCallId === apiRequestCallId) {
      this.setState({
        request: undefined,
      });
    }
  };

  handleRecommendedSaveShyftsResponse = (apiRequestCallId: string) => {
    if (this.postRecommShyftSaveAPICallId === apiRequestCallId) {
      this.setRecommendedSaveShyftsData("save");
    }

    if (this.deleteShyftSaveAPICallId === apiRequestCallId) {
      this.setRecommendedSaveShyftsData("unsave");
    }
  };

  getCurrentUserProfile() {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCurrentUserApiCallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  setCurrentProfileCompletion(profileComplition: number, userCity: string) {
    this.setState({
      profileComplition,
      userCity
    });
  }

  getRecommendedShifts(
    { search, page, filter }: TQueryParams,
    type: "initial" | "next"
  ) {
    const header = {
      "Content-Type": configJSON.getRecommendedShiftsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getRecommendedShiftsApiEndPoint,
      search,
      page,
      sortBy: "created_at",
      orderBy: "desc",
    });

    const requestBody = { filter: constructSearchFilter({ filter }) };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRecommendedShiftsApiMethodType
    );

    if (type === "initial") {
      this.getRecommendedShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextRecommendedShyfts = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShiftsData(
    shifts: TAPIShyftDetails[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const recommendedList = formatShiftsListResponse(shifts);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        recommendedList,
        selectedShyft: recommendedList[0]?.id,
        totalShyfts: pagination.total_records,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        recommendedList: [...prevState.recommendedList, ...recommendedList],
      }));
    }
  }

  handleRecomendedFilterShifts = (filter: string[], reset?: string) => {
    const { search, perPage } = this.state;
    this.getRecommendedShifts({ search, page: 1, perPage, filter }, "initial");

    if (reset) {
      this.getRecommendedShifts({ search, page: 1, perPage }, "initial");
    }
  };

  onSearchShifts = (value: string) => {
    this.setState({ search: value });
  };

  onSelectShift = (shyftId: number) => {
    this.setState({ selectedShyft: shyftId });
  };

  listenForScrollIntersection() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipRender) {
          this.skipRender = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.elementObserver = new IntersectionObserver(callback);
    if (this.elementRef.current)
      this.elementObserver.observe(this.elementRef.current);
  }

  async componentDidMount() {
    const isValidShyfterUser = checkAuthorization(false, true);
    if (!isValidShyfterUser) {
      this.backToSignIn();
      return;
    }
    const { search, page, perPage } = this.state;
    this.getCurrentUserProfile();
    this.getRecommendedShifts({ search, page, perPage }, "initial");
    this.getCountriesForRecommends();
    this.listenForScrollIntersection();
  }

  backToSignIn = () => {
    const recommendedShyftMsg = new Message(getName(MessageEnum.NavigationMessage));
    recommendedShyftMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    recommendedShyftMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(recommendedShyftMsg);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.page !== prevState.page) {
      const { search, page, perPage } = this.state;
      this.getRecommendedShifts({ search, page, perPage }, "next");
    }

    if (this.state.search !== prevState.search) {
      const { search, page, perPage } = this.state;
      this.getRecommendedShifts({ search, page, perPage }, "initial");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getRecommendShyftDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.elementObserver) this.elementObserver.disconnect();
  }

  getRecommendShyftDetails(shyftId: number) {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getRecommendedShyftDetailsAPICallId = reqMessage.messageId;

    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const shyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ shyftDetails, request });
  }

  applyForRecommendedShyft = (shiftId: number) => {
    if (this.state.profileComplition < 100) {
      this.setState({ isRegisterationPending: true });
      return;
    }

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postApplyForShyftAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    const header = {
      "Content-Type": configJSON.postApplyShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setShyftRequestData = (responseJson: any) => {
    this.setState({
      request: {
        updated_at: responseJson.data.attributes.updated_at,
        created_at: responseJson.data.attributes.created_at,
        status: responseJson.data.attributes.status,
        removal_reason: responseJson.data.attributes.removal_reason,
        shift_id: responseJson.data.attributes.id,
        worker_id: responseJson.data.attributes.worker_id,
        id: responseJson.data.attributes.id,
      },
      isShyftApplied: true,
    });
  };

  withdrawFromRecommendedShyft = (shiftId: number) => {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawFromShyftCallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unsaveRecommendedShyft(shiftId);
    } else {
      this.saveRecommendedShyft(shiftId);
    }
  };

  saveRecommendedShyft = (id: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        saved_shift: {
          shift_id: id,
        },
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.postRecommShyftSaveAPICallId = requestMessage.messageId;
    this.setState({ saveShyftId: id });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  unsaveRecommendedShyft = (id: number) => {
    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteShyftSaveAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  setRecommendedSaveShyftsData = (type: "save" | "unsave") => {
    const { recommendedList, saveShyftId, shyftDetails } = this.state;
    const updatedList = [...recommendedList];
    const updatedShyftDetails = { ...shyftDetails! };

    const shyftIndex = recommendedList.findIndex(
      (shyft) => shyft.id === saveShyftId
    );
    const detailsShiftIndex = updatedShyftDetails.businessShifts.findIndex(shift => shift.id === saveShyftId);

    if (shyftIndex !== -1) {
      updatedList[shyftIndex].saved = type === "save";
    }

    if (detailsShiftIndex !== -1) {      
      updatedShyftDetails.businessShifts[detailsShiftIndex].saved = type === "save";
    }

    this.setState({
      recommendedList: updatedList,
      shyftDetails:updatedShyftDetails
    });
  };

  openLocationModal = () => {
    this.setState({ isLocationModalOpen: true });
  };

  closeLocationModal = () => {
    this.setState({
      isLocationModalOpen: false,
      locationModalDataBreadCrum: [],
      locationRecommendsData: this.state.locationCountries,
      isCountrySelectedInModal: true,
      isStateSelectedInModal: false,
      isCitySelectedInModal: false,
      isLocationSearching: false,
      searchedLocationData: [],
    });
  };

  closeApplyShiftModal = () => {
    this.setState({ isShyftApplied: false });
  };

  closePendingRegistrationModal = () => {
    this.setState({ isRegisterationPending: false });
  };

  handleCompleteNowButnRedirection = () => {
    this.closePendingRegistrationModal();
    this.navigateRecommendToPath("EditShyfterProfilePage");
  }

  viewBusinessProfile = (businessId: number) => {
    const navMsg = new Message(getName(MessageEnum.NavigationMessage));
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "Recomended Shyfts",
        link: "RecomendedShyfts",
        active: false,
      })
    );

    navMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    this.send(navMsg);
  };

  getCurrentLocationForModal = (latitude: number, longitude: number) => {
    let url = `${configJSON.getGoogleMapsApiEndPoint}/geocode/json?latlng=${latitude},${longitude}&key=${configJSON.getGoogleMapsApiKey}`;

    this.setState({
      isLocationSearching: false,
    });

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUsersCurrentLocationUsingLatLongAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);

    this.setState({
      ...this.state,
      currentLatitude: latitude,
      currentLongitude: longitude,
    });

    this.closeLocationModal();
  };

  getBreadCrumDataForRecommends = (data: string[]) => {
    this.setState({
      locationModalDataBreadCrum: [...this.state.locationModalDataBreadCrum, ...data],
    });
  };

  getCountriesForRecommends = () => {
    this.setState({
      locationModalDataBreadCrum: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCountriesDataForLocationAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryStateCityApiEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleLocationBreadCrumAllStatesInRecommend = () => {
    this.setState({ locationModalDataBreadCrum: [] }, () => {
      this.getStatesData(this.state.countryCodeForLocation, this.state.countryName);
    });
  }

  getStatesData = (locationCode: any, selectedCountryName: string) => {
    this.getBreadCrumDataForRecommends([selectedCountryName, "All States"]);

    this.setState({
      countryCodeForLocation: locationCode,
      countryName: selectedCountryName,
      locationRecommendsData: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStatesDataByCountryDataAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${locationCode}/states`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  getCitiesData = (
    locationCode: any,
    selectedStateName: string
  ) => {
    this.getBreadCrumDataForRecommends([selectedStateName, "All Cities"]);

    this.setState({
      stateName: selectedStateName,
      locationRecommendsData: []
    });

    const header = {
      "X-CSCAPI-KEY":
        configJSON.getCountryStateCityApiKey,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCitiesDataByStateDataAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCountryStateCityApiEndPoint}/${this.state.countryCodeForLocation}/states/${locationCode}/cities`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleGoogleSearchingPlacesDataApiCall = (value: string) => {
    this.setState({ searchedLocationData: [] })

    let url = `${configJSON.getGoogleMapsApiEndPoint}/place/autocomplete/json?input=${value}&types=(cities)&key=${configJSON.getGoogleMapsApiKey}`;

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getLocationPredictionsForSearchingAPICallId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleSearchingForLocationData = (value: any) => {
    const searchText = value;
    this.setState({
      isLocationSearching: true,
      searchTextForLocationDataPrediction: searchText,
    });

    clearTimeout(this.timer);

    if (value !== "") {
      this.timer = setTimeout(() => {
        this.handleGoogleSearchingPlacesDataApiCall(value)
      }, 500)
    } else {
      this.setState({
        isLocationSearching: false
      })
    }
  };

  handleLocationWhenCitySelected = (cityName: string) => {
    this.setState({
      cityName: cityName
    });

    this.closeLocationModal();
    this.updateLocationInRecommends(cityName, this.state.stateName, this.state.countryName);
  };

  updateLocationInRecommends = (cityName: string, stateName: string, countryName: string) => {
    const header = {
      "Content-Type": configJSON.postSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      "shyfter": {
        "shyfter_city": cityName,
        "shyfter_state": stateName,
        "shyfter_country": countryName
      }
    }

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.patchLocationUpdateInRecommendAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchLocationUpdateEventsBlockApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  handleUpdateSearchedLocationInRecommend = (searchedCityName: string, searchedStateName: string, searchedCountryName: string) => {

    this.setState({
      cityName: searchedCityName,
      stateName: searchedStateName,
      countryName: searchedCountryName
    }); 

    this.closeLocationModal();
    this.updateLocationInRecommends(searchedCityName, searchedStateName, searchedCountryName)
  }

  navigateRecommendToPath = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }
}
