import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { SubscriptionCard,useStyles } from "./SubscriptionCard.web";
import { crownBlue } from "./assets";

interface IPlan {
  title: string;
  month: string;
  price: string;
  benefits: string[];
  type: string;
  name:string;
  duration:string;
  id:string;
}

interface ISubscribeNowProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSave: (selection: any) => void;
  allSubscriptionsList?:any
  selectedplanId?:any;
  upgradedPlanId?:any;
  gridColumnWidth?:number;
  title?:string;
  lable?:string;
}




const SubscribeNowModal: React.FC<ISubscribeNowProps> = ({
  isOpen,
  handleClose,
  handleSave,
  allSubscriptionsList,
  selectedplanId,
  upgradedPlanId,
  gridColumnWidth,
  title,
}) => {
  const classes = useStyles();
  let subsList:any=!!allSubscriptionsList&&allSubscriptionsList
  const [selectedPlan, setSelectedPlan] = useState<any>(!!selectedplanId?selectedplanId: "Basic Plan");
  const [selectedSubsPlan,setSelectedSubsPlan]=useState<any>();

  useEffect(() => {
    handleSeletedPlan()
  }, [subsList])

  const handleSeletedPlan = () => {
    !!subsList && subsList.forEach((item:any) => {
      if(item.name.split(" ")[0] === selectedPlan.split(" ")[0]) {
        setSelectedSubsPlan(item)
      }
  })
  }

  if(!!upgradedPlanId){
    subsList=!!allSubscriptionsList&&allSubscriptionsList.filter((item:any) => item.name !=upgradedPlanId)
  }

  const handlePlanChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    planType: string
  ) => {
    allSubscriptionsList?.filter((item:any)=>{
      if(item.name === planType){
        setSelectedSubsPlan(item)
      }
    })
    setSelectedPlan(planType);
  };

  const getSubsType = (planName:any) => {
    if(planName.includes("Basic")){
      return 'basic'
    }
    if(planName.includes("Standard")){
      return "standard"
    }else{
      return "gold"
    }
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      scroll="body"
      fullWidth
      className={classes.subscriptionDialogWrapper}
    >
      <IconButton 
        className="close-icon" 
        onClick={() => {
          handleClose()
          handleSeletedPlan()
        }}
      >
        <CloseIcon height={20} width={20} />
      </IconButton>
      <div className="subscribe-now-dialog-content">
        <div className="subscribe-image">
          <span className="crown-icon">
            <img src={crownBlue} alt="crown" height={18} width={22} />
          </span>
        </div>
        <div className="subscribe-now-title">{!!title?title:"Subscribe Now"}</div>
        <Grid container spacing={3}>
          {!!subsList&&subsList?.map((item: IPlan) =>(
                <Grid
                item
                xs={12}
                sm={12}
                md={!!gridColumnWidth?6:4}
                className="subscription-tile-grid"
                key={item.name + "subscription-tile"}
              >
                <SubscriptionCard
                  id={item.id}
                  minHeight={"240px"}
                  isModaluse={true}
                  isChecked={item.name.split(" ")[0] === selectedPlan.split(" ")[0]}
                  title={item.name}
                  month={item.duration}
                  price={item.price}
                  benefits={item.benefits}
                  type={getSubsType(item.name)}
                  handleChecked={(e: any) => handlePlanChecked(e, item.name)}
                />
              </Grid>
              )
          )}
        </Grid>
        <div className="subscribe-now-action-container">
          <Button className="subscribe-btn" data-test-id="continue" onClick={()=>handleSave(selectedSubsPlan)}>
            Continue
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
export default SubscribeNowModal;
