import React from "react";
import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import { noDocuments } from "./assets";

const useStyles = makeStyles({
    heroImg: {
        marginBottom: '14px'
    },
    pageTitle: {
        fontSize: '20px',
        fontWeight: 600,
        marginBottom: '8px'
    },
    pageWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '20px',
        width: '100%',
        border: '1px solid #e3e3e3',
        padding: '50px 0'
    },
    subTitle: {
        color: 'grey',
        fontWeight: 600,
        fontSize: '14px'
    },
    allDocs: {
        marginBottom: '8px'
    },
    requestBtn: {
        fontWeight: 600,
        padding: '0 32px',
        boxShadow: 'none',
        textTransform: 'initial',
        color: 'white',
        height: '50px',
        borderRadius: '25px',
        backgroundColor: "#390879",
        '&:hover': {
            backgroundColor: "#390879",
        }
    }
})

interface Props {
    shyfter: boolean;
}

const NoDocuments = (props: Props) => {
    const {shyfter}=props;
    const classes = useStyles();
    return <Box sx={{ mt: '10px' }}>
        <Typography className={classes.allDocs}> All Official Docs</Typography>
        <Box className={classes.pageWrapper}>
            <img className={classes.heroImg} src={noDocuments} alt="" />
            <Typography className={classes.pageTitle}>No Documents Found!</Typography>
            {shyfter && <>
                <Typography className={classes.subTitle} >Update your documents</Typography>
                <Typography style={{ marginBottom: '28px' }} className={classes.subTitle}>Please get in touch with admin for more info.</Typography>
                <Button variant="contained" className={classes.requestBtn} >Request to Upload</Button>
            </>}
        </Box>
    </Box>
}
export default NoDocuments;