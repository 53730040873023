import React from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Grid,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";

const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .title": {
      textTransform: "capitalize",
      fontSize: "22px",
      fontWeight: 400,
      textAlign: "center",
      marginBottom: "12px",
      marginTop: "24px",
      color: "#0a0412",
      lineHeight: "26px",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
      width: "60%",
      margin: "0 auto",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
  deleteButton: {
    color: "#fff",
    borderRadius: "24px",
    backgroundColor: "#ff375b",
    fontSize: "14px",
    fontWeight: 800,
    padding: "11px 0",
    border: "1px solid #ff375b",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f5d9de",
      color: "#ff375b",
      border: "1px solid #ff375b",
    },
  },
}));

interface IDeleteAccountPopup {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteAccountPopUp: React.FC<IDeleteAccountPopup> = ({
  open,
  onConfirm,
  onCancel,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={onCancel}>
        <CloseIcon height={20} width={20} />
      </IconButton>

      <Box justifyContent="center" display="flex">
        <img src={require("./assets/images/close.png")} alt="" width={64} />
      </Box>
      <Box className="title">Delete Account!</Box>
      <Box className="description">
        Are you sure to want to delete your account, please confirm?
      </Box>

      <Grid container className="actionWrapper" spacing={2}>
        <Grid item xs={6}>
          <CustomButton
            label="Cancel"
            isOutlined={true}
            handleClick={onCancel}
          />
        </Grid>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={onConfirm}
            data-test-id="delete-btn"
            className={classes.deleteButton}
          >
            Delete Now
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default DeleteAccountPopUp;
