import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  LinearProgress,
} from "@material-ui/core";

import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
const Briefcase = require("./assets/images/briefcase-blank.png");
const PlaceIcon = require("./assets/images/placeIcon.png");
const billingIcon = require("./assets/images/money-bills-simple.png");
const checkmark = require("./assets/verified.png");
const clockIcon = require("./assets/images/clock.png");
const experienceIcon = require("./assets/images/experience.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyles = makeStyles((theme) => ({
  pr3: {
    paddingRight: "2px",
  },
  cardContent: {
    border: "1px solid #e1e6ec",
    borderRadius: "14px",
    backgroundColor: "#fff",
  },
  boxContainer:{
   border:"1px solid #e1e6ec",
   borderRadius:"14px",
   display:"flex",
   justifyContent:"space-around",
   alignItems:"center"
  },
  boxes: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "178px",
    height: "85px",
    margin: "10px",
    borderRadius: "10px",
  },
  punchedInLabel: {
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#2da406",
    paddingBottom: "3px",
    textAlign: "center",
  },
  punchedOutLabel: {
    fontSize: "11px",
    fontWeight: 500,
    lineHeight: "16px",
    color: "#ff375b",
    paddingBottom: "3px",
    textAlign: "center",
  },
  punchedInDate: {
    fontSize: "11px",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#0a0412",
    paddingBottom: "3px",
    textAlign: "center",
  },
  punchedInTime: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    color: "#0a0412",
    paddingBottom: "3px",
    textAlign: "center",
  },
  recentStatusHeader: {
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "14px",
    color: "#0a0412",
    paddingBottom: 10,
  },
  workHourLabel: {
    color: "#6f7c8e",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "16px",
  },
  progressBar:{
    height:"10px",
    borderRadius:"5px",
    width:"114px"
  },
  workHourValue: {
    color: "#390879",
    fontSize: 13,
    lineHeight: "16px",
    fontWeight: "bold",
  },
}));

interface IRecentStatusCardProps {
  punchInDate?: string;
  punchInTime?: string;
  punchOutDate?: string;
  punchOutTime?: string;
  workHours?: string;
  workProgress?: number;
}

const RecentStatusCard = ({
  punchInDate,
  punchInTime,
  punchOutDate,
  punchOutTime,
  workHours,
  workProgress,
}: IRecentStatusCardProps) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Box className={classes.recentStatusHeader}>Recent Status</Box>
      <Box className={`${classes.cardContent}`}>
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <Box className={classes.boxes} bgcolor={"#2da4060d"}>
            <Typography className={classes.punchedInLabel}>
              Punched In
            </Typography>
            <Typography className={classes.punchedInDate}>
              {punchInDate}
            </Typography>
            <Typography className={classes.punchedInTime}>{punchInTime}</Typography>
          </Box>
          <Box className={classes.boxes}>
            <Typography className={classes.workHourLabel}>
              <img
                className={classes.pr3}
                src={clockIcon}
                alt=""
                height="10px"
              />
              Hours Worked
            </Typography>
            <Box width={"100%"}>
              <LinearProgress
                value={workProgress}
                color="primary"
                variant="determinate"
                className={classes.progressBar}
              />
            </Box>
            <Typography className={classes.workHourValue}>{workHours}</Typography>
          </Box>
          <Box className={classes.boxes} bgcolor={"#ff375b0d"}>
            <Typography className={classes.punchedOutLabel}>
              Punched Out
            </Typography>
            <Typography className={classes.punchedInDate}>
             {punchOutDate}
            </Typography>
            <Typography className={classes.punchedInTime}>{punchOutTime}</Typography>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default RecentStatusCard;