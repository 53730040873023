import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { TProfile } from "./EmployerManageAccountController.web";
import { getFilenameFromURL } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export type TBusinessForm = {
  type: string;
  companyName: string;
  dbaName: string;
  phoneNumber: string;
  address: string;
  website: string;
};

export type TOtherForm = {
  contactName: string;
  email: string;
  contactNumber: string;
  einNumber: string;
};

export type TDocumentsForm = {
  certificate: File | null;
  form: File | null;
};

export type TDropdown = {
  name: string;
  value: string;
};

export type TFormData = {
  businessDetails: TBusinessForm;
  otherDetails: TOtherForm;
  uploadDetails: TDocumentsForm;
};

export type TFormErrors = {
  type: boolean;
  companyName: boolean;
  dbaName: boolean;
  phoneNumber: boolean;
  address: boolean;
  website: boolean;
  contactName: boolean;
  email: boolean;
  contactNumber: boolean;
  einNumber: boolean;
  certificate: boolean;
  form: boolean;
};

export interface Props {
  profile: TProfile;
  activeTab: number;
  onSubmit: (data: TFormData) => void;
  onUploadDocs: (form: File, certificate: File) => void;
  onToggleNotifications: (isAllowed: boolean) => void;
}

interface S {
  formStep: number;
  businessDetails: TBusinessForm;
  otherDetails: TOtherForm;
  uploadDetails: TDocumentsForm;
  categoryList: TDropdown[];
  errors: TFormErrors;
  isBusinessFormSubmitted: boolean;
  isUpdateFormSubmitted: boolean;
}

interface SS {
  id: any;
}

export default class EmployerFormsContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [];

    this.state = {
      formStep: 1,
      businessDetails: {
        type: "",
        companyName: "",
        dbaName: "",
        phoneNumber: "",
        address: "",
        website: "",
      },
      otherDetails: {
        contactName: "",
        email: "",
        contactNumber: "",
        einNumber: "",
      },
      uploadDetails: {
        certificate: null,
        form: null,
      },
      categoryList: [],
      errors: {
        type: false,
        companyName: false,
        dbaName: false,
        phoneNumber: false,
        address: false,
        website: false,
        contactName: false,
        email: false,
        contactNumber: false,
        einNumber: false,
        certificate: false,
        form: false,
      },
      isBusinessFormSubmitted: false,
      isUpdateFormSubmitted: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  createFileFromURL = (url: string) => {
    if (!url) return null;
    const filename = getFilenameFromURL(url);
    const file = new File([""], `${filename}`);
    return file;
  };

  populateInitialValues = (profile: TProfile) => {
    const defaultType = profile.isHotel
      ? configJSON.hotelList[0].value
      : configJSON.restaurantList[0].value;

    this.setState({
      businessDetails: {
        type: profile.type || defaultType,
        companyName: profile.companyName || "",
        dbaName: profile.dbaName || "",
        phoneNumber: profile.telephone || "",
        address: profile.address || "",
        website: profile.websiteURL || "",
      },
      otherDetails: {
        contactName: profile.contactName || "",
        email: profile.email || "",
        contactNumber: profile.phoneNumber || "",
        einNumber: profile.einNumber || "",
      },
      uploadDetails: {
        certificate: this.createFileFromURL(profile.certificate),
        form: this.createFileFromURL(profile.form),
      },
      categoryList: profile.isHotel
        ? configJSON.hotelList
        : configJSON.restaurantList,
    });
  };

  changeFormStep = (formStep: number) => {
    let isValid = true;

    if (formStep === 2) {
      isValid = this.areBusinessFieldsValid();
      this.setState({ isBusinessFormSubmitted: true });
    }

    if (isValid) this.setState({ formStep });
  };

  onChangeBusinessFormInput = <T extends keyof TBusinessForm>(
    field: T,
    value: TBusinessForm[T]
  ) => {
    this.setState(
      (prevState) => {
        return {
          businessDetails: { ...prevState.businessDetails, [field]: value },
        };
      },
      () => {
        this.areBusinessFieldsValid();
      }
    );
  };

  onChangeOtherFormInput = <T extends keyof TOtherForm>(
    field: T,
    value: TOtherForm[T]
  ) => {
    this.setState(
      (prevState) => {
        return {
          otherDetails: { ...prevState.otherDetails, [field]: value },
        };
      },
      () => {
        this.areOtherFieldsValid();
      }
    );
  };

  onUploadDocument = <T extends keyof TDocumentsForm>(
    field: T,
    file: File | null
  ) => {
    this.setState(
      (prevState) => ({
        uploadDetails: {
          ...prevState.uploadDetails,
          [field]: file,
        },
      }),
      () => {
        this.areDocumentsUploaded();
      }
    );
  };

  areBusinessFieldsValid = () => {
    const { businessDetails, errors } = this.state;
    const { website } =
      businessDetails;

    const newErrors: TFormErrors = {
      ...errors,
    };

    newErrors.website = website.length < 0;

    this.setState({ errors: newErrors });
    const areValid = Object.values(newErrors).every((value) => value === false);
    return areValid;
  };

  areOtherFieldsValid = () => {
    const { otherDetails, errors } = this.state;
    const { contactName } = otherDetails;

    const newErrors: TFormErrors = {
      ...errors,
    };

    newErrors.contactName = contactName.length < 3;

    this.setState({ errors: newErrors });
    const areValid = Object.values(newErrors).every((value) => value === false);
    return areValid;
  };

  areDocumentsUploaded = () => {
    const { errors } = this.state;

    const newErrors: TFormErrors = {
      ...errors,
    };

    this.setState({ errors: newErrors });
    const areValid = Object.values(newErrors).every((value) => value === false);
    return areValid;
  };

  onSubmitForm = () => {
    const isBusinessFormValid = this.areBusinessFieldsValid();
    const isOtherFormValid = this.areOtherFieldsValid();
    const isUploadFormValid = this.areDocumentsUploaded();

    const isValid =
      isBusinessFormValid && isOtherFormValid && isUploadFormValid;

    this.setState({ isUpdateFormSubmitted: true });

    if (isValid) {
      const { businessDetails, otherDetails, uploadDetails } = this.state;

      const formData = {
        businessDetails,
        otherDetails,
        uploadDetails,
      };
      this.props.onSubmit(formData);
    }
  };

  onSubmitDocuments = () => {
    const { uploadDetails } = this.state;
    const { form, certificate } = uploadDetails;

    const isValid = this.areDocumentsUploaded();
    if (isValid) this.props.onUploadDocs(form as File, certificate as File);
  };

  async componentDidMount() {
    this.populateInitialValues(this.props.profile);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.props.profile !== prevProps.profile) {
      this.populateInitialValues(this.props.profile);
    }
  }
}
