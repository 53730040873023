import React from "react";
import { Container, Box, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const profileTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ProfileCard from "../../../components/src/ProfileCard";
import ApplicantProfileController, {
  Props,
} from "./ApplicantProfileController.web";
import { AllReview } from "../../../components/src/AllReview";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import Footer from "../../../components/src/Footer.web";
import { UserProfileBasicBlock } from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import { UserOfficialDocs } from "../../user-profile-basic/src/UserOfficialDocs.web";
import { breadCrumSubsPath } from "../../../components/src/Utilities";
import NoDocuments from "../../../components/src/NoDocuments.web";

export default class ApplicantProfilePage extends ApplicantProfileController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const anyDocumentedUploaded = this.state.user?.official_docs && Object.values(this.state.user?.official_docs).some(value => value !== null);
    return (
      <ThemeProvider theme={profileTheme}>
        <LandingPageHeader
          {...this.props} isBreadcrumbs={true} currentPage={this.state.isAccepted ? "Shyfter's Profile" : "Applicant Profile"}
          breadCrumsData={breadCrumSubsPath} isShowDetails={this.state.isAccepted ? false : true}
          isShyfter={this.state.isAccepted ? true : false} shyfterTitle={this.state.shiftType}
          isUpdateUser={false}
        />
        <Box marginTop={"10%"}>
          <Container maxWidth={"lg"} style={{ minHeight: "75vh" }}>
            <Box width={"100%"}>
              <ProfileCard
                userData={this.state.user}
                isProfilePage={true}
                isApplicantProfile
                handleIgnoreBtn={this.ignoreRequestShyft}
                handleAcceptBtn={this.acceptRequestShyft}
                isAccepted={this.state.isAccepted}
                isIgnored={this.state.isIgnored}
                isFromEmployeer={this.state.isAccepted ? false : true}
              />
            </Box>
            <Box marginY={"15px"}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} style={{ marginTop: "20px" }}>
                  <Box marginBottom={"20px"}>
                    <Typography style={profileStyle.lableProfile}>Quick Links</Typography>
                  </Box>
                  <Box bgcolor={"#fff"} borderRadius={"20px"}>
                    <ProfileQuickLink
                      linkData={this.state.sideNavTabs}
                      getActiveTab={this.handleActiveTabs}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={9} style={{ marginTop: "20px" }}>
                  <Box>
                    {this.state.sideNavTabs.map((profileTab: any) => {
                      if (profileTab.id == 1 && profileTab.isActive) {
                        return <UserProfileBasicBlock userData={this.state.user} key={profileTab.id} />;
                      } else if (profileTab.id == 2 && profileTab.isActive) {
                        return anyDocumentedUploaded ? <UserOfficialDocs key={profileTab.id} officialDocs={this.state.user?.official_docs} /> : <NoDocuments shyfter={false} />;
                      } else if (profileTab.id == 3 && profileTab.isActive) {
                        return <AllReview ratingsData={this.state.ratingsData} key={profileTab.id} />;
                      }
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    );
  }
}

const profileStyle = {
  profileBoxContainer: {
    marginY: "10px",
    borderRadius: "20px",
    border: "1px solid #e1e6ec",
    bgcolor: "#fff",
  },
  lableProfile: {
    color: "#000",
    fontWieght: 700,
    fontSize: "16px",
    lineHeight: "18px",
  },
  fadeTextProfile: {
    fontSize: "16px",
    fontWieght: 500,
    color: "#6f7c8e",
    lineHeight: "18px",
  },
  contentTextProfile: {
    fontSize: "16px",
    fontWieght: 500,
    color: "#000",
    lineHeight: "18px",
  },
  activeTextProfile: {
    fontSize: "16px",
    fontWieght: 500,
    color: "#390879",
    lineHeight: "18px",
  },
  mainWrapperProfile: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    background: "#fff",
    paddingBottom: "30px",
  },
  inputStyleProfile: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    height: "100px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  buttonProfileStyle: {
    width: "100%",
    backgroundColor: "rgb(98, 0, 238)",
    marginTop: "40px",
    height: "45px",
    border: "none",
  },
};
