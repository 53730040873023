import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { createRef, RefObject } from "react";
export const configJSON = require("./config");
import moment from "moment";
import { ProfileImg } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";
import { checkAuthorization, convertDateIntoTwelveHourFormat, getUserId } from "../../../components/src/Utilities";

interface TPastShyft {
  id: number;
  shyft: string;
  shyftPlace: string;
  syftTimimg: string;
  shyftEndDate: string;
  county: string;
  userImg: string;
  billing: string;
  day: string;
  isSavedShyft: boolean;
  status: string;
  businessId: number;
  businessType: string;
  hotelOrRestaurantName?: string;
}

interface TPastShyftResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    shift_type: string;
    requirements: string[];
    description: string;
    expirence: string;
    amount_per: string;
    amount: number;
    shift_from: string;
    shift_to: string;
    shift_end: string;
    employer_id: number;
    activated: boolean;
    isAvailable: boolean;
    created_at: string;
    saved: boolean;
    applied_status: string;
    business_type: string;
    business_details: {
      business: {
        id: number;
        hotel_type?: string;
        hotel_name?: string;
        restaurant_type?: string;
        restuarant_name?: string;
        dba_name: string;
        address: string;
        telephone: string;
        website: string;
        user_id: number;
        created_at: string;
        updated_at: string;
      };
      employer_profile_picture: string | null;
    };
  };
}

interface TSearchParams {
  search?: string;
  page: number;
  perPage: number;
  filter?: any;
}

interface TPagination {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_records: number;
}

interface TSummary {
  hours_worked: string;
  missed_shifts: string;
  completed_days: string;
}

interface TActivityRecords {
  id: string;
  attributes: {
    id: number;
    punch_in: string;
    punch_out: string;
    meal_break: string;
    status: string;
    shift_id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    hours_worked: string;
  };
}

interface TActivityRequest {
  id: number;
  status: string;
  removal_reason: string;
  updated_at: string;
}

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isLoading: boolean;
  search: string;
  page: number;
  perPage: number;
  totalPages: number;
  receiverId:number|null;
  pastShyftsList: TPastShyft[];
  selectedPastShyft: number | null;
  activityRecords: TActivityRecords[];
  summary?: TSummary | any;
  request?: TActivityRequest | any;
  ratingSucess: boolean;
  isShowPastActivityFilter: boolean;
  pastShyftRating: any;
  isPastWorkSummaryVisible: boolean;
  pastWorkSummary: any;
  isOpenSortByFilter: boolean;
}

interface SS {
  id: any;
}

export default class PastShyftController extends BlockComponent<Props, S, SS> {
  getAllPastShyftsAPICallId: string = "";
  getNextPastShyftsAPICallId: string = "";
  getActivityRecordsAPICallId: string = "";
  postShyftRatingsAPICallId: string = "";
  observer: IntersectionObserver | null = null;
  elementRef: RefObject<HTMLDivElement>;
  skipCall = true;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: true,
      search: "",
      page: 1,
      perPage: 15,
      totalPages: 1,
      pastShyftsList: [],
      activityRecords: [],
      selectedPastShyft: null,
      ratingSucess: false,
      isShowPastActivityFilter: false,
      pastShyftRating: null,
      receiverId:null,
      isPastWorkSummaryVisible: false,
      pastWorkSummary: {},
      isOpenSortByFilter: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.loginFromPastShyft();
        return;
      } else {
        this.handleOtherAPIResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleOtherAPIResponse = (apiRequestCallId: string, responseJson: any) => {
    if (this.getAllPastShyftsAPICallId === apiRequestCallId) {
      this.handlePastShyfts(
        responseJson.data,
        responseJson.meta.pagination_details,
        "initial"
      );
    }

    if (this.getNextPastShyftsAPICallId === apiRequestCallId) {
      this.handlePastShyfts(
        responseJson.data,
        responseJson.meta.pagination_details,
        "next"
      );
    }

    if (this.getActivityRecordsAPICallId === apiRequestCallId) {
      this.setActivityRecordsResponse(responseJson);
    }

    if (this.postShyftRatingsAPICallId === apiRequestCallId) {
      this.handleRatingSuccess();
    }
  }

  setActivityRecordsResponse = (responseJson: any) => {
    if(responseJson?.activities?.data && responseJson?.summary && responseJson?.request){
      this.handleActivityRecords(
        responseJson.activities.data,
        responseJson.summary,
        responseJson.request,
        responseJson?.rating
      );
    } else {
      this.setState({
        ...this.state,
        activityRecords: [],
        summary: {},
        request: responseJson?.request,
        pastShyftRating: responseJson?.rating,
        isPastWorkSummaryVisible: false,
      })
    }
  }

  async componentDidMount() {
    const isValidWorkerUser = checkAuthorization(false, true);
    if (!isValidWorkerUser) {
      this.loginFromPastShyft();
      return;
    }
    const { search, page, perPage } = this.state;
    this.getAllPastShyfts({ search, page, perPage }, "initial");
    this.listenForScrollChanges();
  }

  loginFromPastShyft = () => {
    const pastShyftMsg = new Message(getName(MessageEnum.NavigationMessage));
    pastShyftMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    pastShyftMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(pastShyftMsg);
  }

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.search !== prevState.search) {
      const { search, page, perPage } = this.state;
      this.getAllPastShyfts({ search, page, perPage }, "initial");
    }

    if (this.state.page !== prevState.page) {
      const { search, page, perPage } = this.state;
      this.getAllPastShyfts({ search, page, perPage }, "next");
    }

    if (
      this.state.selectedPastShyft &&
      this.state.selectedPastShyft !== prevState.selectedPastShyft
    ) {
      const { selectedPastShyft } = this.state;
      this.getActivityRecords(selectedPastShyft, false);
    }
  }

  async componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  constructURL({ search, page, perPage, filter }: TSearchParams) {
    const params = new URLSearchParams();

    params.append("order", `${filter || "desc"}`);
    params.append("page", `${page}`);
    params.append("per_page", `${perPage}`);
    params.append("sort_column", "created_at");
    if (search) params.append("search", search);

    const query = `?${params.toString()}`;
    const url = `${configJSON.getPastShyftsApiEndPoint}${query}`;
    return url;
  }

  getAllPastShyfts(
    { search, page, perPage, filter }: TSearchParams,
    type: "initial" | "next"
  ) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = this.constructURL({ search, page, perPage, filter });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const header = {
      "Content-Type": configJSON.getPastShyftsApiContentType,
      token: localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getPastShyftsApiMethodType
    );

    if (type === "initial") {
      this.getAllPastShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextPastShyftsAPICallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onSearchPastShyfts = (value: string) => {
    this.setState({ search: value });
  };
  
  resetPastShyftActivityData = () => {
    this.setState({
      ...this.state,
      activityRecords: [],
      summary: {},
      request: {},
      pastShyftRating: null,
      isPastWorkSummaryVisible: false,
    })
  }

  getActivityRecords(shiftId: number | null, isFilter: boolean, filterPastData?: any) {
    this.resetPastShyftActivityData()
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const userId = getUserId();
    let pastActivityEndpoint = "";

    if (isFilter) {
      pastActivityEndpoint = `${configJSON.getActivityRecordsApiEndPoint}&filter=${filterPastData.filterType}&from=${filterPastData.filterDateFrom}&to=${filterPastData.filterDateTo}`;
    }else {
      pastActivityEndpoint = configJSON.getActivityRecordsApiEndPoint;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${pastActivityEndpoint}&shift_id=${shiftId}&user_id=${userId}`
    );

    const header = {
      "Content-Type": configJSON.getActivityRecordsApiContentType,
      token: localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getActivityRecordsApiMethodType
    );

    this.getActivityRecordsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  listenForScrollChanges() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipCall) {
          this.skipCall = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.observer = new IntersectionObserver(callback);
    if (this.elementRef.current) this.observer.observe(this.elementRef.current);
  }

  onSelectPastShift(shiftId: number,businessId:number) {
    this.setState({ selectedPastShyft: shiftId, receiverId:businessId });
  }

  formatPastShyftList(pastShyfts: TPastShyftResponse[]) {
    const pastShyftsList: TPastShyft[] = pastShyfts.map((shift) => {
      return {
        id: shift.attributes.id,
        day: `${moment(shift.attributes.created_at).calendar()}`,
        shyft: shift.attributes.shift_type,
        shyftPlace: shift.attributes.business_type,
        county: shift.attributes.business_details.business.address,
        isSavedShyft: shift.attributes.saved,
        userImg:
          shift.attributes.business_details.employer_profile_picture ??
          ProfileImg,
        syftTimimg: `${convertDateIntoTwelveHourFormat(shift.attributes.shift_from)} - ${convertDateIntoTwelveHourFormat(shift.attributes.shift_to)}`,
        billing: `$${shift.attributes.amount} Per Hr`,
        shyftEndDate: `${moment(shift.attributes.shift_end).format(
          "D MMM, YYYY [at] hh:mmA"
        )}`,
        status: shift.attributes.applied_status,
        businessId: shift.attributes.business_details.business.user_id,
        businessType: shift.attributes.business_type,
        hotelOrRestaurantName: shift.attributes.business_type === "Hotel" ?
          shift.attributes.business_details.business.hotel_name
          : shift.attributes.business_details.business.restuarant_name
      };
    });
    return pastShyftsList;
  }

  handlePastShyfts(
    pastShyfts: TPastShyftResponse[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );
    const pastShyftsList = this.formatPastShyftList(pastShyfts);
    if (type === "initial") {
      this.setState({
        totalPages,
        pastShyftsList,
        selectedPastShyft: pastShyftsList[0]?.id,
        receiverId:pastShyftsList[0]?.businessId,
        isLoading: false,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        pastShyftsList: [...prevState.pastShyftsList, ...pastShyftsList],
      }));
    }
  }

  handleActivityRecords(
    activityRecords: TActivityRecords[],
    summary: TSummary,
    request: TActivityRequest,
    shyftRating: any
  ) {
    this.setState({
      activityRecords,
      summary,
      request,
      pastShyftRating: shyftRating
    });
  }

  onSubmitRatings = (rating: number, comment: string) => {
    const { pastShyftsList, selectedPastShyft } = this.state;
    const currentShift = pastShyftsList.find(
      (shift) => shift.id === selectedPastShyft
    ) as TPastShyft;

    const header = {
      "Content-Type": configJSON.postShyftsRatingApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const body = {
      score: rating,
      comment,
      shift_id: currentShift.id,
      worker_id: currentShift.businessId,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = `${configJSON.postShyftsRatingApiEndPoint}`;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postShyftsRatingApiMethodType
    );

    this.postShyftRatingsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleRatingSuccess = () => {
    this.setState({ ratingSucess: true });
  };

  viewExploreShyfts = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };

  onViewBusinessProfile = (businessId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "Past Shyfts",
        link: "PastShyft",
        active: false,
      })
    );
    this.send(msg);
  };

  handleOpenFilterForPastShyft = () => {
    this.setState({
      isShowPastActivityFilter: true,
    });
  }

  closeFilterForPastShyft = () => {
    this.setState({...this.state, isShowPastActivityFilter: false });
  }

  applyFilterForPastShyft = (filterType: string, filterDateFrom: string, filterDateTo: string) => {
    const filterPastData = { filterType, filterDateFrom, filterDateTo };
    this.getActivityRecords(this.state.selectedPastShyft, true, filterPastData);
    this.closeFilterForPastShyft();
  }

  closePastWorkSummaryPanel = () => {
    this.setState({ isPastWorkSummaryVisible: false });
  };

  setPastWorkSummaryData = (pastWorkSummary: any) => {
    this.setState({ pastWorkSummary, isPastWorkSummaryVisible: true });
  };

  handleSortByFilterModal = () => {
    this.setState({
      ...this.state,
      isOpenSortByFilter: !this.state.isOpenSortByFilter
    })
  }

  handleApplySortByFilter = (filter: any) => {
    const { search, page, perPage } = this.state;

    if (filter === "earliest"){
      this.getAllPastShyfts({ search, page, perPage, filter: "asc"  }, "initial");
    } else {
      this.getAllPastShyfts({ search, page, perPage, filter: "desc"  }, "initial");
    }

    this.handleSortByFilterModal()
  }

  goToPagePastShyft = (receiverId: number|null) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "Messages");
    msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props);
    localStorage.setItem("receiverId", JSON.stringify(receiverId));
    this.send(msg);
  }
}
