Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPatchMethodType = "PATCH";
exports.validationApiPutMethodType = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.validationPostMethod='POST';
exports.exampleAPiMethod = "POST";
exports.exampleDeleteAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.phoneNumberOptVarifyUrl = "account_block/verify_now?phone_number=";
exports.verifyOTP = "account_block/verify_otp";
exports.btnExampleTitle = "CLICK ME";
exports.getShyfteUserProfileEndPoint="account_block/users";
exports.patchUserEndPoint="account_block/users"
exports.updateprofilepic="account_block/users"
exports.postEmailOTPEndpoint = "account_block/accounts/send_otps/email_otp";
exports.getPhoneOTPEndpoint = "account_block/verify_now";
exports.putUpdatePhoneEndpoint = "account_block/update_phone";
exports.deleteAccountApiEndPoint="account_block/delete";
exports.genralSettig="General Settings";
exports.dlStateList = [
  { name: "Alabama", value: "Alabama" },
  { name: "Alaska", value: "Alaska" },
  { name: "Arizona", value: "Arizona" },
  { name: "Arkansas", value: "Arkansas" },
  { name: "California", value: "California" },
  { name: "Colorado", value: "Colorado" },
  { name: "Connecticut", value: "Connecticut" },
  { name: "Delaware", value: "Delaware" },
  { name: "Florida", value: "Florida" },
  { name: "Georgia", value: "Georgia" },
  { name: "Hawaii", value: "Hawaii" },
  { name: "Idaho", value: "Idaho" },
  { name: "Illinois", value: "Illinois" },
  { name: "Indiana", value: "Indiana" },
  { name: "Iowa", value: "Iowa" },
  { name: "Kansas", value: "Kansas" },
  { name: "Kentucky", value: "Kentucky" },
  { name: "Louisiana", value: "Louisiana" },
  { name: "Maine", value: "Maine" },
  { name: "Maryland", value: "Maryland" },
  { name: "Massachusetts", value: "Massachusetts" },
  { name: "Michigan", value: "Michigan" },
  { name: "Minnesota", value: "Minnesota" },
  { name: "Mississippi", value: "Mississippi" },
  { name: "Missouri", value: "Missouri" },
  { name: "Montana", value: "Montana" },
  { name: "Nebraska", value: "Nebraska" },
  { name: "Nevada", value: "Nevada" },
  { name: "New Hampshire", value: "New Hampshire" },
  { name: "New Jersey", value: "New Jersey" },
  { name: "New Mexico", value: "New Mexico" },
  { name: "New York", value: "New York" },
  { name: "North Carolina", value: "North Carolina" },
  { name: "North Dakota", value: "North Dakota" },
  { name: "Ohio", value: "Ohio" },
  { name: "Oklahoma", value: "Oklahoma" },
  { name: "Oregon", value: "Oregon" },
  { name: "Pennsylvania", value: "Pennsylvania" },
  { name: "Rhode Island", value: "Rhode Island" },
  { name: "South Carolina", value: "South Carolina" },
  { name: "South Dakota", value: "South Dakota" },
  { name: "Tennessee", value: "Tennessee" },
  { name: "Texas", value: "Texas" },
  { name: "Utah", value: "Utah" },
  { name: "Vermont", value: "Vermont" },
  { name: "Virginia", value: "Virginia" },
  { name: "Washington", value: "Washington" },
  { name: "West Virginia", value: "West Virginia" },
  { name: "Wisconsin", value: "Wisconsin" },
  { name: "Wyoming", value: "Wyoming" }
];

exports.restaurantSkills = [
  "server",
  "cook",
  "host",
  "busser/runner",
  "dishwasher",
  "bartender",
  "N/A"
];
exports.experienceList = [
  { name: "1 Year", value: "1"},
  { name: "2 Years", value: "2"},
  { name: "3 Years", value: "3"},
  { name: "4 Years", value: "4"},
  { name: "5 Years", value: "5"},
];
exports.hotelSkills = [
  "front desk",
  "room attendant",
  "concierge",
  "doorman",
  "bellperson",
  "laundry attendant",
  "shuttle driver",
  "house person",
  "engineer (maintenance)",
  "N/A"
]
exports.posList=[
  { name: "Aloha", value: "Aloha"},
  { name: "Micros", value: "Micros"},
  { name: "Harbour Touch", value: "Harbour Touch"},
  { name: "Toast", value: "Toast"},
  { name: "Other", value: "Other"},
  { name: "N/A", value: "N/A"},
]
exports.pmsList=[
  { name: "Opera", value: "AloOperaha"},
  { name: "FSPMS", value: "FSPMS"},
  { name: "FOSSE", value: "FOSSE"},
  { name: "Lightspeed", value: "Lightspeed"},
  { name: "OnQ", value: "OnQ"},
  { name: "Visual Matrix", value: "Visual Matrix"},
  { name: "Guestline", value: "Guestline"},
  { name: "Auto Clerk", value: "AutoClerk"},
  { name: "choiceADVANTAGE", value: "choiceADVANTAGE"},
  { name: "N/A", value: "n/a"},
]
exports.ansList=[
  { name: "Yes", value: "yes"},
  { name: "No", value: "no"},
]

exports.hotelList = [
  { name: "Luxury", value: "luxury" },
  { name: "Economy", value: "economy" },
  { name: "Mid Scale", value: "midscale" },
  { name: "Resort", value: "resort" },
];

exports.restaurantList = [
  { name: "Quick Serve", value: "quick_serve" },
  { name: "Casual", value: "casual" },
  { name: "Up Scale", value: "upscale" },
  { name: "Fine Dining", value: "fine_dining" },
];

exports.emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
exports.pms="PMS";
exports.pos="POS"
exports.minMaxCharLimitRegex = /^.{4,30}$/;
exports.minMaxCharLimitForAddressRegex = /^.{4,100}$/;
exports.einNumberRegex = /^\d{9,11}$/;
exports.backToLogin = "Back to Login";
exports.phone_numberRegex = /^\d{10}$/;
exports.enterPersonalDetails = "Enter Your Personal Details";
exports.enterProfessionalDetails = "Enter Your Professional Details";
exports.fullName = "Contact Name";
exports.address = "Address";
exports.dateOfBirth = "Date Of Birth";
exports.mobileNumber = "Mobile Number";
exports.yourExperience = "Your Experience";
exports.prev = "Prev";
exports.signUpNow = "Sign Up Now";
exports.languageSkills = "Language Skills";
exports.full_nameRegex = /^[A-Za-z ]+$/;
exports.verifyNow = "Verify Now";
exports.emailAddress = "Email Address";
exports.enterSSN = "Enter SSN";
exports.selectDLState = "Select DL State";
exports.dlNumber = "DL-Number";
exports.next = "Next";
exports.auth="Are you authorized to work in the USA?";
exports.age="Are you 18 years of age or over?";
exports.updateEmail = "Update Your Email";
exports.updatePhone = "Update Your Phone Number";
exports.patchPushNotificationsEndpoint = "account_block/users/set_push_notification";
exports.userLogoutApiEndPoint = "account_block/logout"
// Customizable Area End