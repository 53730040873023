import React from "react";

import ExploreShyftsController, { Props } from "./ExploreShyftsController.web";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import { FailedIcon, SuccessIcon } from "./assets";
import { breadCrumSubsPath, checkIfUserAppliedeForShyft } from "../../../components/src/Utilities";
import NoDataFound from "../../../components/src/NoDataFound.web";

export default class ExploreShyftsPage extends ExploreShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      shyftsList,
      selectedShyft,
      shyftDetails,
      request,
      search,
      isShyftApplied,
      isRegisterationPending,
      isLocationModalOpen,
      totalRecords,
      isLocationSearch,
      locationDataBreadCrum,
      isCountrySelectedForModal,
      isStateSelectedForModal,
      isCitySelectedForModal,
      locationDataExplore,
      searchedLocationData,
      userCity
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Explore Shyfts"
          breadCrumsData={breadCrumSubsPath}
          isShowDetails={false}
          isUpdateUser={false}
        />
        <Box width="100%" marginTop={"8%"}>
          <Container maxWidth="md">
            <SearchWithFilterandLocation
              showFilterText={true}
              handleSerchModal={this.onSearchShifts}
              islocation={true}
              defaultValue={search}
              handleLoctionModal={this.openLocationModal}
              handleSearchFilter={this.handleExploreFilterShifts}
              cityLocation={userCity}
            />
            {shyftsList && shyftsList.length === 0 &&
             <Container>
              <Box marginTop="4%">
                <NoDataFound
                  heading="No Record Found!"
                  paragraph={
                    "No Record Found for the Searched Shyft, Hotel or Restaurant."
                  }
                  imgUrl={FailedIcon}
                />
              </Box>
            </Container>
            }
            {totalRecords !== 0 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >{`Total ${totalRecords} Shyfts`}</Typography>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Box>
                  {shyftsList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        onSaveShyft={() =>
                          this.handleExploreSaveShyft(item.id, item.saved)
                        }
                        isActiveShyft={item.id === selectedShyft}
                        showBookMark={true}
                      />
                    );
                  })}
                  <div ref={this.elementRef}></div>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  margin={"15px 0px"}
                  style={{ position: "sticky", top: "20%" }}
                >
                  {shyftDetails && totalRecords !== 0 && (
                    <ShyfterDetailsCard
                      {...shyftDetails}
                      hideEmployer
                      onSaveShyft={(id,saved) => this.handleExploreSaveShyft(id,saved)}
                      key={shyftDetails.id}
                      shyftStatus={request ? request.status : undefined}
                      isAppliedShyft={checkIfUserAppliedeForShyft(request)}
                      onApplyShift={() => this.applyForShyft(shyftDetails.id)}
                      onWithdrawShift={() =>
                        this.withdrawFromShyft(shyftDetails.id)
                      }
                      onViewProfile={() => {
                        this.onViewBusinessProfile(shyftDetails.businessId);
                      }}
                      showOverviewSubtext={false}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <ShyfterFeedbackModal
              isOpen={isShyftApplied}
              title="Applied Successfully"
              description="This shyft has been applied successfully, Explore more shyfts or you can check the status of this shyft."
              imageSrc={SuccessIcon}
              primaryButtonText="Explore More"
              onClose={this.closeApplyShiftModal}
              secondaryButtonText="View Status"
              primaryButtonOnClick={this.closeApplyShiftModal}
              secondaryButtonOnClick={() => this.navigateExploreToPath("AppliedShyft")}
            />
            <ShyfterFeedbackModal
              data-test-id="explore-registration-pending-modal"
              title="Registration Pending"
              isOpen={isRegisterationPending}
              description="Your registeration is pending. Please complete your registration to apply for a shyft in any hotel and restaurant."
              imageSrc={SuccessIcon}
              onClose={this.closePendingRegistrationModal}
              secondaryButtonText="Complete Now"
              secondaryButtonOnClick={this.handleCompleteNowBtnRedirection}
              isProfileCompletion={true}
              profileCompletionPercent={this.state.profileComplition}
            />
            <GetLocationModal
              data-test-id="getLocationModalIdInExplore"
              open={isLocationModalOpen}
              closeModal={this.closeLocationModal}
              handleCurrentLocation={this.getCurrentLocation}
              locationData={!isLocationSearch ? locationDataExplore : searchedLocationData}
              isSearchingLocation={isLocationSearch}
              locationBreadCrumData={locationDataBreadCrum}
              fetchCountries={this.getCountriesForModal}
              fetchStateByCountry={this.fetchStatesDataByCountry}
              fetchCitiesByCountryAndState={this.fetchCitiesDataByCountryAndState}
              isCountrySelected={isCountrySelectedForModal}
              isStateSelected={isStateSelectedForModal}
              isCitySelected={isCitySelectedForModal}
              handleCitySelection={this.handleLocationModalCitySelected}
              handleSearchForLocation={this.handleSearchingForLocation}
              handleAllStatesBreadCrum={this.handleLocationBreadCrumAllStatesInExplore}
              handleUpdateSearchedLocation={this.handleUpdateSearchedLocationInExplore}
            />
          </Container>
        </Box>
        {/* <Footer /> */}
      </>
    );
  }
}
