import React, { Component } from 'react';
// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import './style.css';
// Customizable Area Start
// Customizable Area End

interface Props {
  navigation: any
  // Customizable Area Start
  dataTestId?: string;
  onAboutClick?: () => void;
  // Customizable Area End

}

const webStyle = {
  icon: {
    borderRadius: "8px",
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    padding: "6px"
  },
  footerArrowLink: {
    display: 'flex',
    alignItems: 'center'
  },
  hrLineLarge: {
    borderTop: '1px solid rgba(190, 190, 190, 0.33)'
  },
  hrLineLinks: {
    borderBottom: '1px solid rgba(190, 190, 190, 0.33)'
  },
  firstItem: {
    width: '30%'
  },
  fullWidth: {
    width: '100%'
  },
  activeLink: {
    color: '#B9DF12',
    display: 'flex',
    alignItems: 'center'
  },
  footerLogo:{
    width:'45px'
  },
  footerCardTitlePaddingBottom:{
    paddingBottom:'11px'
  }
}
export default class GenericFooter extends Component<Props, any>{
  constructor(props: Props) {
    super(props);
  }
  render() {
    const pathname = window.location.pathname;
    return (
      <Box className='generic-footer imageSection' >
        <div className='footersecsion-first'>
          <div style={webStyle.firstItem} className='footerfirst-item'>
            <div style={{...webStyle.hrLineLinks,paddingBottom:0}} className='footerfirst-header'>
              <img style={webStyle.footerLogo} className='footer-logo' src={require('./logo2.png')}></img>
              <Typography variant='h6'>
                About Snagashyft
              </Typography>
            </div>
            <div style={webStyle.fullWidth} className='footerfirst-body'>
              <p style={{ lineHeight: '25px' }}>
                Consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure.
              </p>
              <span data-test-id="read-more" style={webStyle.activeLink} className='readmore' onClick={this.props.onAboutClick}>Read More</span>
            </div>
          </div>
          <div style={webStyle.firstItem} className='footerfirst-item'>
            <div style={{...webStyle.hrLineLinks,...webStyle.footerCardTitlePaddingBottom}} className='footerfirst-header'>
              <Typography variant='h6'>
                More Links
              </Typography>
            </div>
            <div style={webStyle.fullWidth} className='footerfirst-body'>
              <p className='footerfirst-links'>
                <span style={pathname === '/BusinessBenifits' ? webStyle.activeLink : webStyle.footerArrowLink} onClick={() => window.location.href = "/BusinessBenifits"}> <ChevronRightRoundedIcon /> Business </span>
                <span style={pathname === '/ShyfterBenifits' ? webStyle.activeLink : webStyle.footerArrowLink} onClick={() => window.location.href = "/ShyfterBenifits"} data-test-id="Business-testing"> <ChevronRightRoundedIcon /> Shyfters </span>
                <span style={pathname === '/FAQ' ? webStyle.activeLink : webStyle.footerArrowLink} onClick={() => window.location.href = "/FAQ"} data-test-id="helpcentre-testing"> <ChevronRightRoundedIcon /> Faq </span>
                <span style={pathname === '/ContactUS' ? webStyle.activeLink : webStyle.footerArrowLink} className={window.location.pathname === '/ContactUS' ? 'selected' : ''} onClick={() => window.location.href = "/ContactUS"} data-test-id="ContactUs-testing"> <ChevronRightRoundedIcon /> Contact Us </span>
              </p>

            </div>
          </div>
          <div style={webStyle.firstItem} className='footerfirst-item'>
            <div style={{...webStyle.hrLineLinks,...webStyle.footerCardTitlePaddingBottom}} className='footerfirst-header'>
              <Typography variant='h6'>
                Connect With us
              </Typography>
            </div>
            <div style={webStyle.fullWidth} className='footerfirst-body'>
              <p className='footer-contact'>
                <PhoneOutlinedIcon style={webStyle.icon} />
                <span>
                  <label>+1 - 010 4412 325</label>
                  <label>+1 - 010 2232 005</label>
                </span>
              </p>
              <p className='footer-contact'>
                <MailOutlinedIcon style={webStyle.icon} />
                <span>
                  <label>info@snagashyft.com</label>
                  <label>support@snagashyft.com</label>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div style={webStyle.hrLineLarge} className='footersection-second'>
          <label>Copyright © 2023 Snagashyft. All rights reserved.</label>
          <label><label onClick={() => window.location.href = "/TermsAndConditions3"} data-test-id="terms-testing">Terms & Conditions </label>| <label onClick={() => window.location.href = "/PrivacyAndPolicy"} data-test-id="Privacy-testing">Privacy Policy</label></label>
        </div>
      </Box>
    )
  }
}
// Customizable Area End
