import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
const userImage = require("./assets/images/ProfileImg.png");
const placeIcon = require("./assets/images/placeIcon.png");
const Phone = require("./phone.png");
const phoneColored = require("./assets/images/phoneColored.png");
const emailColored = require("./assets/images/emailColored.png");
const OutgoingMail = require("./assets/images/OutgoingMail.png");
const ManageIcon = require("./assets/images/settings.png");
const briefcase = require("./assets/images/briefcase.png");
const marker = require("./assets/images/place_black_24dp.png");
const editIcon = require("./assets/images/editIcon.png");
const verifyIcon = require("./assets/images/verified.png");
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import CustomButton from "../../components/src/CustomButton.web";
import { Star } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import BlueButton from "./BlueButton";
import { capitalizeWords, truncateFilename } from "./Utilities";

const gemIcon = require("./assets/images/gem.png");

interface Props {
  userName?: String;
  place?: String;
  city?: String;
  contactMail?: String;
  contactNumber?: String;
  isProfilePage?: boolean;
  goToProfile?: any;
  navigateManageProfile?: any;
  userData?: any;
  employerType?: any;
  showEditEmailPopUp?: any;
  showEditPhonePopUp?: any;
  isApplicantProfile?: boolean;
  handleIgnoreBtn?: any;
  handleAcceptBtn?: any;
  isIgnored?: boolean;
  isAccepted?: boolean;
  isFromEmployeer?: boolean;
}
const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyle = makeStyles({
  card: {
    border: "1px solid #e1e6ec",
    borderRadius: "20px",
    backgroundColor: "#ffffff",
    padding: "10px",
  },
  welcomeText: {
    color: "#0a0412",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    textAlign: "center",
  },
  title: {
    color: "#0a0412",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "26px",
    textAlign: "center",
    padding: "5px 0",
    textTransform: "capitalize",
  },
  address: {
    color: "#6f7c8e",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    textTransform: "capitalize",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  userTypeText: {
    color: "#6236ff",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  detail: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
  },
  editText: {
    color: "#390879",
    fontSize: "12px",
    fontWeight: 600,
    lineHeight: "14px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  icon: {
    marginRight: "8px",
  },
  divider: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  IconMargin: {
    marginRight: "5px",
  },
  ManageIconMargin: {
    marginLeft: "10px",
  },
  margin: {
    margin: theme.spacing(2),
    borderRadius: "50px",
    padding: " 5px 25px",
  },
  cardTitleText: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "26px",
    color: "#0a0412",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "75%"

  },
  cardTitleSpacing: {
    padding: "5px 0",
  },
  textCapitalize: {
    textTransform: "capitalize"
  },
  cardSubtitleText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  cardbodyText: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#000",
  },
  manageAccountText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#390879",
    margin: "10px",
    textDecoration: "underline",
    textDecorationColor: "lightgray",
    marginTop: "8px",
  },
  link: {
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#390879",
    cursor: "pointer"
  },
  primaryText: {
    color: "#390879",
  },
  actionIcon: {
    height: "18px",
    cursor: "pointer",
    marginLeft: "8px"
  },
  usertype: {
    color: "#390879",
    backgroundColor: "rgba(225, 195, 247, 0.3)",
    padding: "5px 10px",
    borderRadius: "20px",
    marginBottom: "10px"
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiGrid-item': {
      padding: '0px 7px',
    },
    "& button": {
      width: '100%',

    }
  },
  ignoreBtn: {
    '& button': {
      backgroundColor: '#fff !important',
      boxShadow: 'initial',
      fontSize: '13px',
      color: '#390879',
      textTransform: "capitalize",
      border: "1px solid #390879",
    }
  },
  acceptBtn: {
    '& button': {
      boxShadow: 'initial',
      fontSize: '13px',
      textTransform: "capitalize"
    }
  }
});

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 20,
      height: 22,
    },
  })
)(Avatar);

export const hotelList = [
  { name: "Luxury", value: "luxury" },
  { name: "Economy", value: "economy" },
  { name: "Mid Scale", value: "midscale" },
  { name: "Resort", value: "resort" },
];
export const restaurantList = [
  { name: "Quick Serve", value: "quick_serve" },
  { name: "Casual", value: "casual" },
  { name: "Up Scale", value: "upscale" },
  { name: "Fine Dining", value: "fine_dining" },
];

export const renderEmpTypeTitle = (type: string, dataType: string) => {
  let arr = [];
  if (dataType === 'hotel') {
    arr = hotelList;
  } else {
    arr = restaurantList;
  }
  const filtteredObj = arr.filter((e) => e.value === type);
  return filtteredObj.length && filtteredObj[0].name;
}

export default function ProfileCard(props: Props) {
  const classes = useStyle();

  const {
    isProfilePage,
    goToProfile,
    navigateManageProfile,
    userData,
    employerType,
    showEditEmailPopUp,
    showEditPhonePopUp,
    isApplicantProfile,
    handleIgnoreBtn,
    handleAcceptBtn,
    isIgnored,
    isAccepted,
    isFromEmployeer,
  } = props;

  const ProfileImg = userData?.profile_picture
  const isActionTaken = isIgnored || isAccepted

  const renderCityAndState = () => {
    return `${userData?.city ? userData?.city : "--"}, ${userData?.state ? userData?.state : "--"}`
  }

  
  return (
    <ThemeProvider theme={theme}>
      {!!isProfilePage ? (
        <Box className={classes.card}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Box
                padding={"10px"}
                display={"flex"}
                alignItems={"center"}
                width={"100%"}
              >
                <Box marginRight={"20px"}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <SmallAvatar alt="Remy Sharp" src={verifyIcon} />
                    }
                  >
                    <Avatar
                      alt="Travis Howard"
                      src={!!userData.profile_picture ? userData.profile_picture : userImage}
                      style={{ height: "100px", width: "100px" }}
                    />
                  </Badge>
                </Box>
                <Box width={"70%"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography className={classes.cardTitleText}>
                      {isFromEmployeer &&
                        userData?.business_details?.business_info?.dba_name

                      }
                      {isProfilePage && capitalizeWords(userData?.full_name || "")}
                    </Typography>
                    <Box
                      width="fit-content"
                      display={"flex"}
                      borderRadius="50px"
                      bgcolor="#f7b500"
                      alignItems={"center"}
                      paddingRight="8px"
                      marginLeft="8px"
                    >
                      <Star style={{ height: "16px", color: "#fff" }} />
                      <Typography style={{ color: "#fff", fontSize: "15px", marginLeft: "-4px" }}>{userData?.ratings?.overall_rating}</Typography>
                    </Box>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} paddingTop={"10px"} paddingBottom={"4px"}>
                    <img src={marker} alt="" height={"16px"} style={{ marginRight: "5px" }} />
                    <Typography className={classes.cardSubtitleText}>
                      {capitalizeWords(renderCityAndState())}
                    </Typography>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} paddingY={"5px"}>
                    <img src={briefcase} alt="" height={"16px"} style={{ marginRight: "5px" }} />
                    <Typography className={classes.cardSubtitleText}>
                      Exp. {userData?.experience ? userData.experience[0] : 0} Yrs
                    </Typography>
                  </Box>
                </Box>
                <Divider orientation="vertical" flexItem />
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
                height={"100%"}
                padding={"0 20px 0 20px"}
              >
                <Box width={"30%"}>
                  <Box display={"flex"} alignItems={"center"}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                      marginBottom={"10px"}
                    >
                      <img
                        src={phoneColored}
                        alt=""
                        className={classes.IconMargin}
                        height={30}
                      />
                      <Typography data-test-id="phone-number" className={classes.cardbodyText}>
                        {userData?.country_code}-{userData?.full_phone_number}
                      </Typography>
                    </Box>
                    {!isApplicantProfile &&
                      <img
                        src={editIcon}
                        alt=""
                        className={classes.actionIcon}
                        style={{ marginTop: '-12px' }}
                        onClick={() => showEditPhonePopUp()}
                        data-test-id="editPhonePopup"
                      />
                    }

                  </Box>
                  <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"}>
                    <Box
                      display={"flex"}
                      justifyContent={"flex-start"}
                      alignItems={"center"}
                    >
                      <img
                        src={emailColored}
                        alt=""
                        className={classes.IconMargin}
                        height={30}
                      />
                      <Typography data-test-id="email" className={classes.cardbodyText}>
                        {userData?.email}
                      </Typography>
                    </Box>
                    {!isApplicantProfile &&
                      <img
                        src={editIcon}
                        alt=""
                        className={classes.actionIcon}
                        onClick={() => showEditEmailPopUp()}
                        data-test-id="emailPopUp"
                      />}

                  </Box>
                </Box>
                {!isApplicantProfile &&
                  <Box
                    style={{ cursor: "pointer" }}
                    borderRadius={"50px"}
                    bgcolor={"#f6edfd"}
                    padding={"8px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    data-test-id="viewProfile"
                    onClick={navigateManageProfile}
                  >
                    <img
                      src={ManageIcon}
                      alt=""
                      height={"16px"}
                      className={classes.ManageIconMargin}
                    />
                    <Typography className={classes.manageAccountText}>
                      Manage Account
                    </Typography>
                  </Box>}
                {isApplicantProfile && !isActionTaken &&
                  <Box className={classes.btnWrapper}>
                    <Grid container direction="column-reverse" spacing={2}>
                      <Grid item style={{ width: "170px" }} >
                        <Box className={classes.ignoreBtn}>
                          <BlueButton btnText='Ignore' data-test-id="ignoreApplicant" handleClick={() => handleIgnoreBtn()} />
                        </Box>
                      </Grid>
                      <Grid item style={{ width: "170px" }}>
                        <Box className={classes.acceptBtn}>
                          <BlueButton btnText='Accept' data-test-id="acceptApplicant" handleClick={() => handleAcceptBtn()} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className={classes.card}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}

          >
            <Badge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              badgeContent={<SmallAvatar alt="Remy Sharp" src={verifyIcon} />}
            >
              <Avatar
                alt="Travis Howard"
                src={!!ProfileImg ? ProfileImg : userImage}
                className={classes.large}
              />
            </Badge>
            <Box padding={"10px 0px 5px 0px"} textAlign={"center"}>
              <Typography className={classes.welcomeText}>Welcome!</Typography>
              <Typography className={classes.title}>
                {isFromEmployeer ?
                  userData?.business_details?.business_type === "Hotel" ?
                    userData?.business_details?.business_info?.hotel_name :
                    userData?.business_details?.business_info?.restuarant_name
                  : userData?.full_name
                }
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              padding={"0px 0px 10px 0px"}
              className={!!employerType ? classes.usertype : ""}
              width={!isFromEmployeer ? "70%" : "auto"}
            >
              <img
                src={!!employerType ? gemIcon : placeIcon}
                className={classes.IconMargin}
                alt=""
                height={"14px"}
              />{" "}
              <Typography className={!!employerType ? classes.userTypeText : classes.address}>
                {isFromEmployeer ?
                  userData?.business_details?.business_type === "Hotel" ?
                    renderEmpTypeTitle(userData?.business_details?.business_info?.hotel_type, 'hotel')
                    : renderEmpTypeTitle(userData?.business_details?.business_info?.restaurant_type, 'resta')
                  :
                  renderCityAndState()
                }
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} variant="middle" />
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"5px 10px"}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <img
                src={Phone}
                alt=""
                className={classes.icon}
                height={24}
              />
              <Typography className={classes.detail}>
                {userData.full_phone_number}
              </Typography>
            </Box>
            <Box onClick={() => showEditPhonePopUp()} data-test-id="editPhonePopUp">
              <Typography className={classes.editText} >
                Edit
              </Typography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            padding={"5px 10px"}
          >
            <Box
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <img
                src={OutgoingMail}
                alt=""
                className={classes.icon}
                height={24}
              />
              <Typography className={classes.detail}>
                {truncateFilename(userData?.email || "", 25)}
              </Typography>
            </Box>
            <Box onClick={() => showEditEmailPopUp()} data-test-id="emailPopUp">
              <Typography className={classes.editText}>
                Edit
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} variant="middle" />
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <CustomButton
              isOutlined={true}
              label={"Your Account"}
              handleClick={goToProfile}
            />
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}
