import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { clearUserLoginData, eventEmitter, getUserRole } from "../../../components/src/Utilities";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  userAddress?:string;
  userName?:string;
  isBreadcrumbs?:boolean;
  gotToProfile?:any;
  breadCrumsData?: any;
  currentPage?: string;
  pageTitle?:string
  userImage?:string
  isShowDetails?: boolean;
  isShyfter?:boolean;
  shyfterTitle?: string;
  isUpdateUser?: boolean;
}

interface S {
  isOpenTabsMenu: boolean;
  isProfileMenu: boolean;
  isShowNotification: any;
  activeTab: string;
  activePage: string;
  isEmployer: boolean;
  parenRoute: string;
  loggedUserData: any;
  isUpdateProfileUser: boolean;
  profilePicture: any;
  isNewNotification: boolean;
}

interface SS {
  id: any;
}

export default class LandingPageHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {

  userLogoutApiCallId: string = "";
  getProfileApiCallId: string = "";
  updateNotificationIndicatorApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationShyfterDashboardMessage),
      getName(MessageEnum.NavigatoShyfterProfileMessage),
      getName(MessageEnum.NavigationPastShyftMessage),
      getName(MessageEnum.NavigationToDashboardStatsMessage),
      getName(MessageEnum.NavigationToEmployerLandingPageMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      isOpenTabsMenu: false,
      isProfileMenu: false,
      isShowNotification: null,
      activeTab: window.location.pathname,
      activePage: "",
      parenRoute: "",
      isEmployer: false,
      loggedUserData: {},
      isUpdateProfileUser: false,
      profilePicture: null,
      isNewNotification: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    eventEmitter.subscribe("new_notification", () => this.handleNotificationIndicator())
  }

  handleNotificationIndicator = () => {
    this.setState({
      ...this.state, 
      isNewNotification: this.state.isShowNotification ? false : true
    })
  }

  async receive(from: string, message: Message) {
    this.getActiveTab(message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiResponse(message);
    }
  }

  handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.userLogoutApiCallId) {
      if (Array.isArray(responseJson?.messages)) {
        this.handleUserLogout();
      }
    }

    if (apiRequestCallId === this.getProfileApiCallId) {
      this.setState({
        profilePicture: responseJson?.data?.attributes?.profile_picture,
        isNewNotification: responseJson?.data?.attributes?.new_notification,
      })
    }

    if (apiRequestCallId === this.updateNotificationIndicatorApiCallId) {
      if (responseJson?.message ===	"New_Notification set to false successfully"){
        this.setState({
          isNewNotification: false,
        })
      }
    }
  }

  getUserData = async(value: boolean | undefined) => {
    const Data  = await getStorageData("userData", true);
    this.setState({ loggedUserData : Data, isUpdateProfileUser: value ? true : false, profilePicture: Data.profile_picture });
  }

  async componentDidMount() {
    this.getUserData(this.props.isUpdateUser);
    const userRoleId = getUserRole();
    this.setState({ ...this.state, isEmployer: userRoleId === "1" });
    if (userRoleId) {
      this.getUserProfilePicture();
    }
  }

  async componentDidUpdate() {
    if(this.state.isUpdateProfileUser !== this.props.isUpdateUser) {
      this.getUserData(this.props.isUpdateUser)
    }
  }

  getActiveTab(message: any) {
    if (getName(MessageEnum.NavigationShyfterDashboardMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationShyfterDashboardMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigationPastShyftMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationPastShyftMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigationSavedShyftMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigationSavedShyftMessage)
      );
      this.setState({ activeTab: currentTab });
    }
    if (getName(MessageEnum.NavigatoShyfterProfileMessage) === message.id) {
      const currentTab = message.getData(
        getName(MessageEnum.NavigatoShyfterProfileMessage)
      );
      this.setState({ activeTab: currentTab });
      if (
        getName(MessageEnum.NavigationToDashboardStatsMessage) === message.id
      ) {
        const currentTab = message.getData(
          getName(MessageEnum.NavigationToDashboardStatsMessage)
        );
        this.setState({ activeTab: currentTab });
      }
      if (
        getName(MessageEnum.NavigationToEmployerLandingPageMessage) ===
        message.id
      ) {
        const currentTab = message.getData(
          getName(MessageEnum.NavigationToEmployerLandingPageMessage)
        );
        this.setState({ activeTab: currentTab });
      }
    }
  }
  goToHome() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.state.isEmployer ? "EmployerHomePage" : "ShyfterLandingPage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    if (this.state.isEmployer) {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationToEmployerLandingPageMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationToEmployerLandingPageMessage),
        "/LandingPage"
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    } else {
      const raiseMessage: Message = new Message(
        getName(MessageEnum.NavigationShyfterDashboardMessage)
      );
      raiseMessage.addData(
        getName(MessageEnum.NavigationShyfterDashboardMessage),
        "/ShyfterDashboard"
      );
      msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
      this.send(msg);
    }
  }

  navigateToYourSubscription() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "CurrentSubscription"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationYourSubsCriptionMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationYourSubsCriptionMessage),
      "Your Subscription"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  openProfileMenu() {
    this.setState({
      isShowNotification: null,
      isProfileMenu: !this.state.isProfileMenu,
    });
  }
  goToPastShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "PastShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/PastShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }
  goToSavedShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SavedShyftPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/SavedShyft"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  goToMyShyft() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "MyShyftsPage");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/MyShyfts"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  goToDashboardStats() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "DashboardStats");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationToDashboardStatsMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationToDashboardStatsMessage),
      "/DashboardStats"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  openTabsMenu() {
    this.setState({
      isOpenTabsMenu: !this.state.isOpenTabsMenu,
    });
  }
  showNotification(e: any) {
    this.setState({
      isProfileMenu: false,
      isShowNotification: e?.currentTarget
    });
   this.state.isNewNotification && this.updateNotificationIndicatorApi();
  }

  closeNotification = () => {
    this.setState({...this.state, isShowNotification: null});
  }

  goToPage = (path: string) => {
    this.setState({
      ...this.state,
      isProfileMenu: false
    })
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);
    msg.addData( getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  handleBreadCrumNavigation = (path: string) => {
    if(path === "Home") {
      this.goToHome();
    } else {
      this.goToPage(path)
    }
  }
  
  handleUserLogout = () => {
    clearUserLoginData()
    this.goToPage("Login")
  }

  handlGeneralSettingNavigation = (path: string, tabId: string) => {
    setStorageData("manageAccountTabId", tabId)
    this.goToPage(path)
  }

  userLogout = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userLogoutApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userLogoutApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      device_id: localStorage.getItem("fcmToken") || "",
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfilePicture = () => {
    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestProfileMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileApiCallId = requestProfileMessage.messageId;

    requestProfileMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserProfileEndPoint
    );
    requestProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestProfileMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(requestProfileMessage.id, requestProfileMessage);
  }

  updateNotificationIndicatorApi = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateNotificationIndicatorApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchNewNotificationIndicatorEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
