import React from "react";
import { DownloadDocsCard } from "../../../components/src/DownloadDocCard";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
interface Props {
  officialDocs: any;
  formLabel?: string;
  certificateLabel?: string;
  isBusinessProfile?: boolean;
  isShyfterProfile?: boolean;
}

export class UserOfficialDocs extends React.Component<Props> {
  render() {
    const form_i_9_url = this.props.officialDocs?.form_i_9_url;
    const form_w_4_url = this.props.officialDocs?.form_w_4_url;
    const form_it_2104_url = this.props.officialDocs?.form_it_2104_url;
    const motor_vehicle_check_url = this.props.officialDocs?.motor_vehicle_check_url;
    const servsafe_tips_url = this.props.officialDocs?.servsafe_tips_url;
    const background_check_authorization_url = this.props.officialDocs?.background_check_authorization_url;
    const first_aid_cpr_url = this.props.officialDocs?.first_aid_cpr_url;
    const sexual_harassment_training_url = this.props.officialDocs?.sexual_harassment_training_url;
    return (
      <Box id="userOfficialDocsWrapper" marginTop={this.props.isShyfterProfile ? "10px" : "0px"}>
        <Grid container spacing={2}>
          {this.props.isBusinessProfile ? (
            <>
              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Certificate of Organization"}
                  docs={this.props?.officialDocs?.certificate}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"W9-Form"}
                  docs={this.props?.officialDocs?.form}
                />
              </Grid>
            </>
          ) :
            <>
              {form_i_9_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | I-9"}
                  docs={form_i_9_url}
                />
              </Grid>}
              {form_w_4_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | W-4"}
                  docs={form_w_4_url}
                />
              </Grid>}
              {form_it_2104_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Form | IT-2104"}
                  docs={form_it_2104_url}
                />
              </Grid>}
              {motor_vehicle_check_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Motor Vehicle Check (based on position)"}
                  docs={motor_vehicle_check_url}
                />
              </Grid>}
              {servsafe_tips_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Servsafe Tips"}
                  docs={servsafe_tips_url}
                />
              </Grid>}
              {background_check_authorization_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable="Background Check Authorization"
                  docs={
                    background_check_authorization_url
                  }
                />
              </Grid>}
              {first_aid_cpr_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"First Aid/CPR"}
                  docs={first_aid_cpr_url}
                />
              </Grid>}
              {sexual_harassment_training_url && <Grid item xs={12} md={6}>
                <DownloadDocsCard
                  lable={"Sexual Harassment Training"}
                  docs={sexual_harassment_training_url}
                />
              </Grid>}
            </>}
        </Grid>
      </Box>
    );
  }
}
