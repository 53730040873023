import React from "react";
import { MenuItem, Menu } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const TrashIcon = require("./assets/images/trashIcon.png");
const MessageIcon = require("./assets/images/message.png");
const UserBlackIcon = require("./assets/images/userBlack.png");
const EditMenuIcon = require("./assets/images/editMenuIcon.png");
const RotateRight = require("./assets/images/rotate-right.png");

const useStyles = makeStyles((theme) => ({
  menuWrapper: {
    " & .MuiPopover-paper": {
      borderRadius: 10,
      border: "1px solid #e1e6ec",
    },
    " & .MuiMenuItem-root": {
      color: "#0a0412",
      fontSize: 14,
      fontWeight: 500,
      fontFamily: "'Gotham', sans-serif",
    },
    " & .menuImage": {
      paddingRight: 5,
    },
  },
}));

interface IShyftMenuProps {
  anchorEl: any;
  handleMenuItemClick: (item: string) => void;
  handleClose: () => void;
  selectedTab: number;
}

const ShyftMenu = ({
  anchorEl,
  handleClose,
  handleMenuItemClick,
  selectedTab,
}: IShyftMenuProps) => {
  const classes = useStyles();
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.menuWrapper}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {selectedTab === 0 && (
        <>
          <MenuItem onClick={() => handleMenuItemClick("Edit Shyft")}>
            <img
              className="menuImage"
              src={EditMenuIcon}
              height={20}
              alt="edit"
            />
            Edit Shyft
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Delete Shyft")}>
            <img className="menuImage" src={TrashIcon} height={20} alt="edit" />
            Delete Shyft
          </MenuItem>
        </>
      )}
      {selectedTab === 1 && (
        <>
          <MenuItem onClick={() => handleMenuItemClick("View Profile")}>
            <img
              className="menuImage"
              src={UserBlackIcon}
              height={20}
              alt="view"
            />
            View Profile
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Edit Shyft")}>
            <img
              className="menuImage"
              src={EditMenuIcon}
              alt="edit"
              height={20}
            />
            Edit Shyft
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Message")}>
            <img
              className="menuImage"
              src={MessageIcon}
              alt="message"
              height={20}
            />
            Message
          </MenuItem>
        </>
      )}
      {selectedTab === 2 && (
        <>
          <MenuItem onClick={() => handleMenuItemClick("View Profile")}>
            <img
              className="menuImage"
              src={UserBlackIcon}
              height={20}
              alt="view"
            />
            View Profile
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Republish Shyft")}>
            <img
              className="menuImage"
              src={RotateRight}
              height={20}
              alt="republish"
            />
            Republish Shyft
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Message")}>
            <img
              className="menuImage"
              src={MessageIcon}
              height={20}
              alt="message"
            />
            Message
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default ShyftMenu;
