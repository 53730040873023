import React from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { createFileFromURL, truncateFilename } from "./Utilities";

interface Props {
  lable: string;
  docs: any;
}
const useStyle = makeStyles({
  card: {
    padding: "14px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
    backgroundColor: "#fff",
    border: "1px solid #e1e6ec",
  },
  lable: {
    fontSize: "15px",
  },
  cardBody: {
    fontSize: "16px",
    marginLeft: "10px",
    color: "#390879",
  },
  download: {
    background: "#e1e6ec",
    padding: "6px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
export const DownloadDocsCard = (props: Props) => {
  const classes = useStyle();
  const { lable, docs } = props;

  const getFileName = () => {
    let fileName = lable;
    if (docs && createFileFromURL(docs)) {
      const newFile = createFileFromURL(docs)!.name;
      fileName = truncateFilename(newFile, 20);
    }
    return fileName;
  }

  return (
    <Box>
      <Box>
        <Typography className={classes.lable}>{lable}</Typography>
      </Box>
      <a href={docs} download={lable} target="_blank">
        <Box className={classes.card} marginTop={"10px"}>
          <Box display={"flex"} alignItems={"center"}>
            <img
              src={require("./assets/images/document.png")}
              alt=""
              height={"24px"}
            />
            <Typography className={classes.cardBody}>{getFileName()}</Typography>
          </Box>
          <Box className={classes.download}>
            <GetApp
              style={{ color: "#2a314aa3", height: "20px", width: "20px" }}
            />
          </Box>
        </Box>
      </a>
    </Box>
  );
};
