import React from "react";
import {
  Box,
  Typography,
  Divider,
  MenuItem,
  IconButton,
  Menu,
} from "@material-ui/core";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  styled,
} from "@material-ui/core/styles";
import moment from "moment";

const Briefcase = require("./assets/images/briefcase-blank.png");
const MoneyIcon = require("./assets/images/billingIcon.png");
const BookmarkBlank = require("./assets/images/bookmarkBlank.png");
const BookmarkFilled = require(".//assets/images/bookmarkfilled.png");
import { close } from "./assets";
const Clock = require("./assets/images/clock.png");
const placeIcon = require("./assets/images/placeIcon.png");
const BlocIcon = require("./assets/images/blockIcon.png");
const ProfileImg = require("./assets/images/ProfileImg.png");

import { MoreHoriz } from "@material-ui/icons";
import ArrowRightAltSharpIcon from "@material-ui/icons/ArrowRightAltSharp";
import CustomButton from "./CustomButton.web";
import { capitalizeWords } from "./Utilities";

interface ShyftCardProps {
  id: Number;
  employername?: String;
  county?: String;
  userImg?: any;
  isPasstShyft?: boolean;
  isShyfExpired?: boolean;
  statusText?: string;
  syftTimimg: String;
  billing: String;
  day: String;
  shyft: String;
  shyftPlace?: String;
  shyftEndDate?: string;
  isMyShyft?: Boolean;
  isMenu?: boolean;
  isMyShyftList?: boolean;
  showBookMark?: boolean;
  showRemoveIcon?: boolean;
  onRemove?: () => void;
  isSavedShyft?: boolean;
  saved?: boolean;
  isActiveShyft?: boolean;
  status?: string;
  isOngoing?: boolean;
  nextShyftTime?: string;
  aboutToStart?: boolean;
  onCardClick?: () => void;
  options?: TMenuOption[];
  onSaveShyft?: () => void;
  btnText?: string;
  btnClick?: () => void;
  hideEmployer?: boolean;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
    secondary: {
      main: "#6f7c8e",
    },
  },
  typography: {
    fontFamily: `"Gotham",sans-serif`,
    fontSize: 18,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const useStyle = makeStyles({
  card: {
    height: "fit-content",
    borderRadius: "14px",
    backgroundColor: "#ffffff",
    border: "1px solid #e1e6ec",
    margin: "15px 0px",
  },
  active: {
    border: "1px solid rgb(57, 8, 121)",
  },
  activeStart: {
    border: "1px solid #f7b500",
  },
  iconWithBg: {
    backgroundColor: "rgba(225, 195, 247, 0.3)",
    height: "40px",
    width: "40px",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  IconMargin: {
    marginRight: "8px",
  },
  margin: {
    margin: theme.spacing(2),
    borderRadius: "50px",
    padding: " 5px 25px",
  },
  expired: {
    backgroundColor: "rgba(255, 55, 91, 0.07)",
  },
  expiredText: {
    color: "#ff375b",
  },
  accepted: {
    backgroundColor: '#f4faf2',
  },
  acceptedText: {
    color: "#259300",
  },
  ended: {
    backgroundColor: '#e1e6ec',
  },
  endedText: {
    color: '#6f7c8e',
  },
  statusWrapper:{
    margin: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    borderRadius: "50px",
    padding: "8px 10px",
  },
  statusText:{
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    textTransform: "capitalize",
  },
  cardtitleText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "26px",
    color: "#35c206",
  },
  timming: {
    color: "#6f7c8e",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  cardSubtitleText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#6f7c8e",
  },
  heading: {
    color: "#0a0412",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    textTransform: "capitalize",
  },
  viewDetail: {
    color: "#390879",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
  },
  cardbodyText: {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "15px",
    color: "#000",
  },
  gutterSpace: {
    paddingLeft: 0,
  },
  placeText: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "19px",
  },
  locationText: {
    color: "#6f7c8e",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16px",
    marginTop: "3px",
  },
  hotelText: {
    color: "#390879",
  },
  restaurantText: {
    color: "#5e9908",
  },
  removeButton: {
    backgroundColor: "#e1e6ec",
    padding: "6px",
    borderRadius: "13px",
    cursor: "pointer",
  },
  timer: {
    fontSize: "18px",
    color: "rgb(57, 8, 121)",
  },
  aboutToStartTimer: {
    fontSize: "18px",
    color: "#f7b500",
  },
  activeTimer: {
    fontSize: "18px",
    color: "#b9df12",
  },
});

type TMenuOption = {
  menuIcon: string;
  menuText: string;
  onClick: () => void;
};

const getActiveClass = (
  hotelClass: string,
  restoClass: string,
  place?: String,
) => {
  switch (place?.toLocaleLowerCase()) {
    case 'restaurant': return restoClass;
    case 'hotel': return hotelClass;
    default: return ""
  }
};


const CustomMenu = styled(Menu)({
  width: "100%",
  "& .MuiPaper-root.MuiMenu-paper": {
    borderRadius: "10px",
    marginLeft: '-130px',
    marginTop: '45px',
    width: "auto",
    minWidth: "160px",
  }
})

export default function ShyftCard(props: ShyftCardProps) {
  const classes = useStyle();
  const {
    employername,
    county,
    userImg,
    shyft,
    shyftPlace,
    syftTimimg,
    day,
    billing,
    statusText,
    isPasstShyft,
    isMyShyft,
    isMenu,
    isMyShyftList,
    showBookMark,
    showRemoveIcon,
    onRemove,
    shyftEndDate,
    isActiveShyft,
    nextShyftTime,
    aboutToStart,
    isOngoing = false,
    status,
    saved,
    onCardClick,
    onSaveShyft,
    options = [],
    btnText = "View Activity",
    btnClick,
    hideEmployer = false
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const formattedDay = hideEmployer ? null : day.includes("day") ? day : moment(day as string, 'DD/MM/YYYY').format('MMM D, YYYY')

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isExpiredOrRejected = statusText === "expired" || statusText === 'rejected';
  const styleStatus = (status: any) => {
    switch (status) {
      case 'expired':
      case "rejected": return [classes.expired, classes.expiredText]
      case 'accepted': return [classes.accepted, classes.acceptedText]
      case 'ended': return [classes.ended, classes.endedText]
    }
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        onClick={onCardClick ? () => onCardClick() : undefined}
        className={[classes.card, isActiveShyft ? classes.active : "", aboutToStart && isActiveShyft ? classes.activeStart : ""].join(
          " "
        )}
        data-test-id="shyft-card"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="10px 15px"
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.iconWithBg}>
              <img src={Briefcase} alt="" height="20px" />
            </Box>
            <Box>
              <Typography className={classes.heading}>{shyft}</Typography>
              <Typography
                className={`${classes.placeText} ${getActiveClass(
                  classes.hotelText,
                  classes.restaurantText,
                  shyftPlace,
                )}`}
              >
                {shyftPlace} <span className={`${classes.placeText} ${classes.hotelText}`}> {isMyShyft && `| ${capitalizeWords(employername)}`} </span>
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            {isOngoing && isMyShyftList && (
              <Box style={{ border: "1px solid #390879", borderRadius: "50%", width: "10px", height: "10px", padding: "2px" }}>
                <Box
                  style={{
                    backgroundColor: "#390879", borderRadius: "50%", width: "10px", height: "10px"
                  }}
                />
              </Box>
            )}
            {!!isMenu && (
              <Box>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                  data-test-id="more-options"
                >
                  <MoreHoriz />
                </IconButton>
                <CustomMenu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                    },
                  }}
                >
                  {options.map((option) => (
                    <Box key={option.menuText} display={"flex"} alignItems={"center"} borderRadius={"15px"}>
                      <Box padding={"10px"}>
                        <img
                          src={option.menuIcon}
                          height={"18px"}
                          width={"18px"}
                        />
                      </Box>
                      <MenuItem
                        key={option.menuText}
                        selected={option.menuText === "Pyxis"}
                        data-test-id="menu-option"
                        onClick={() => {
                          option.onClick();
                          handleClose()
                        }}
                        classes={{
                          root: classes.cardbodyText,
                          gutters: classes.gutterSpace,
                        }}
                      >
                        {option.menuText}
                      </MenuItem>
                    </Box>
                  ))}
                </CustomMenu>
              </Box>
            )}
            {showBookMark && (
              <img
                src={saved ? BookmarkFilled : BookmarkBlank}
                alt=""
                style={{ zIndex: 200, position: "relative" }}
                height="20px"
                onClick={onSaveShyft}
                data-test-id="bookmark-icon"
              />
            )}

            {showRemoveIcon && (
              <Box
                className={classes.removeButton}
                data-test-id="bookmark-icon"
                onClick={onRemove}
              >
                <img src={close} alt="cross" />
              </Box>
            )}
          </Box>
        </Box>

        {!!isMyShyft ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              display="flex"
              alignItems={employername ? "flex-start" : "center"}
              padding="0px 15px 10px 15px"
              flexDirection={"row"}
              width={"100%"}
              justifyContent={"space-between"}
            >
              <Box
                padding={"10px"}
                width={"43%"}
                bgcolor={"#f7faff"}
                borderRadius={"10px"}
              >
                <Box display="flex" padding="5px 0px" marginRight={"10px"}>
                  <img
                    src={Clock}
                    alt=""
                    height="14px"
                    className={classes.IconMargin}
                  />{" "}
                  <Typography className={classes.cardSubtitleText}>
                    Shyft Timing
                  </Typography>
                </Box>
                <Typography className={classes.cardbodyText}>
                  {syftTimimg}
                </Typography>
              </Box>
              <Box
                padding={"10px"}
                width={"43%"}
                bgcolor={"#f7faff"}
                borderRadius={"10px"}
              >
                <Box display="flex" alignItems="center" padding={"5px 0"}>
                  <img
                    src={MoneyIcon}
                    alt=""
                    height="14px"
                    className={classes.IconMargin}
                  />{" "}
                  <Typography className={classes.cardSubtitleText}>
                    Amount of Shyft
                  </Typography>
                </Box>
                <Typography className={classes.cardbodyText}>
                  {billing}
                </Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              display="flex"
              alignItems={hideEmployer ? "center" : "flex-start"}
              padding="0px 15px 10px 15px"
              flexDirection={hideEmployer ? "row" : "column"}
            >
              <Box
                display="flex"
                alignItems="center"
                padding="5px 0px"
                marginRight={"10px"}
              >
                <img
                  src={Clock}
                  alt=""
                  height="14px"
                  className={classes.IconMargin}
                />{" "}
                <Typography className={classes.timming}>
                  {syftTimimg}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src={MoneyIcon}
                  alt=""
                  height="14px"
                  className={classes.IconMargin}
                />{" "}
                <Typography className={classes.timming}>
                  {billing}
                </Typography>
              </Box>
            </Box>
            {statusText && (
              <Box className={[styleStatus(statusText)![0],classes.statusWrapper].join(" ")}>
                {isExpiredOrRejected && <img src={BlocIcon} alt="" height={"14px"} />}
                <Typography
                  className={[styleStatus(statusText)![1],classes.statusText].join(" ")}
                >
                  {statusText}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        {!hideEmployer && (!isMyShyft && !isPasstShyft) && (
          <>
            <Divider variant="middle" />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding="10px 15px"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box className={classes.iconWithBg} borderRadius="10px">
                  <img
                    src={userImg || ProfileImg}
                    style={{ borderRadius: "10px", objectFit: "cover" }}
                    alt=""
                    width="42px"
                    height="42px"
                  />
                </Box>
                <Box>
                  <Typography className={classes.cardbodyText}>
                    {employername}
                  </Typography>{" "}
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <img
                      src={placeIcon}
                      alt=""
                      height="16px"
                      className={classes.IconMargin}
                    />
                    <Typography className={classes.locationText}>
                      {county}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography className={classes.cardSubtitleText}>
                  {formattedDay}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {isPasstShyft && (
          <>
            <Divider variant="middle" />
            <Box
              display={"flex"}
              alignItems={"center"}
              padding={"10px"}
              width={"95%"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box>
                  <Typography className={classes.cardSubtitleText}>
                    {status === "ended"
                      ? "Shyft Ended Successfuly"
                      : "Shyft will end on"}
                  </Typography>
                </Box>
                <Box>
                  <Typography className={classes.cardtitleText}>
                    {shyftEndDate}
                  </Typography>
                </Box>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Typography className={classes.viewDetail}>
                  View Details{" "}
                </Typography>
                <ArrowRightAltSharpIcon width="10px" />
              </Box>
            </Box>
          </>
        )}
        {isMyShyftList && (
          <>
            <Divider style={{ width: "95%", margin: "auto" }} />
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              padding={"10px 15px"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={require("./assets/images/timerDis.png")}
                  height={"24px"}
                />
                <Box marginLeft={"10px"}>
                  <Typography className={classes.cardSubtitleText}>
                    {isOngoing ? "Hours Worked" : "Next shyft starts in:"}
                  </Typography>
                  <Typography className={[classes.timer, aboutToStart ? classes.aboutToStartTimer : "", isOngoing ? classes.activeTimer : ""].join(" ")}>
                    {nextShyftTime}
                  </Typography>
                </Box>
              </Box>
              <Box width={"43%"}>
                <CustomButton
                  label={btnText}
                  handleClick={btnClick}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}
