import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getNotificationStatus, setNotificationStatus } from "../../../components/src/Utilities";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notifications: any[];
  isChecked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PushNotificationsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiNotificationsCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      notifications: [],
      isChecked: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  } 
  
  async componentDidMount() {
    this.setState({
      isChecked: getNotificationStatus()
    })
  }

  onToggle = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const setPushNotificationApiMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiNotificationsCallId = setPushNotificationApiMsg.messageId;

    const requestBody = { push_notification: !this.state.isChecked, device_id: localStorage.getItem("fcmToken") || ""};

    setPushNotificationApiMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setNotificationsEndPoint 
    );

    setPushNotificationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    setPushNotificationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.setNotificationsAPIMethod
    );

    setPushNotificationApiMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(setPushNotificationApiMsg.id, setPushNotificationApiMsg);

    this.setState({
      isChecked: !this.state.isChecked
    })
    setNotificationStatus(!this.state.isChecked)
  }
}
