import React, { Component } from 'react';
// Customizable Area Start 
import './style.css';
import { Button } from '@material-ui/core';
// Customizable Area End
// Customizable Area Start
interface Props {
  dataTestId?: string;
  navigation: any;
  onSignUp?: () => void;
  onNavigate?: (path: string) => void;
  // Customizable Area End

  // Customizable Area Start
}

const webStyle = {
  loginBtn: {
    border: "1px solid #390879",
    width: "120px",
    height: "40px",
    borderRadius: "20px",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#390879",
    textTransform: "none" as any
  },
  signUpButton: {
    border: "1px solid gray",
    width: "120px",
    height: "40px",
    borderRadius: "20px",
    fontSize: "0.8rem",
    fontWeight: 600,
    backgroundColor: "#390879",
    color: "white",
    textTransform: "none" as any
  },
  logo: {
    cursor: "pointer"
  }
};
export default class GenericHeader extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="generic-header">
        <ul className="header-listitems">
          <li className={
              window.location.pathname === "/BusinessBenifits" ? "selected" : ""
            } onClick={() => this.props.onNavigate && this.props.onNavigate("BusinessBenifits")}>
            Business
          </li>
          <li className={
              window.location.pathname === "/ShyfterBenifits" ? "selected" : ""
            } onClick={() => this.props.onNavigate && this.props.onNavigate("ShyfterBenifits")}>
            Shyfters
          </li>
          <li className={
              window.location.pathname === "/FAQ" ? "selected" : ""
            } onClick={() => this.props.onNavigate && this.props.onNavigate("FAQ")}>Faq</li>
          <li
            className={
              window.location.pathname === "/ContactUS" ? "selected" : ""
            }
            onClick={() => this.props.onNavigate && this.props.onNavigate("ContactUS")}
          >
            Contact Us
          </li>
        </ul>
        <span
          className="header-logo"
          style={webStyle.logo}
          onClick={() => this.props.onNavigate && this.props.onNavigate("Home")}
        >
          <img
            style={{width:'44px'}}
            src={require("./logo.png")}
            onClick={() => this.props.onNavigate && this.props.onNavigate("Home")}
            alt=""
          />
          <img style={{width:'150px'}} src={require("./logotitle.png")} alt="" />
        </span>
        <span className="header-buttongrp">
          <Button
            style={webStyle.loginBtn}
            onClick={() => this.props.onNavigate && this.props.onNavigate("Login")}
          >
            Login Now
          </Button>
          <Button style={webStyle.signUpButton} onClick={this.props.onSignUp}>
            Sign Up
          </Button>
        </span>
      </div>
    )
  }
}
  // Customizable Area End