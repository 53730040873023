import React, { ChangeEvent } from "react";
import { Avatar, Box, Typography } from "@material-ui/core";
import { ProfileImg } from "../../blocks/landingpage/src/assets";
import UploadPicButtons from "./UploadPicButton";
import { business } from "./assets";

interface Props {
  imageSrc: string;
  userName: string;
  place: string;
  onUploadFile: (e: ChangeEvent<HTMLInputElement>) => void;
}

const styles = {
  title: {
    fontSize: "24px",
    lineHeight: "26px",
    color: "#0a0412",
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginTop: "5px",
  },
  subtitle: {
    color: "#6f7c8e",
    fontSize: "16px",
  },
};

export const UpdateProfileImage = (props: Props) => {
  const { imageSrc, userName, onUploadFile, place } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      border="1px solid #e1e6ec"
      bgcolor="#fff"
      padding="20px"
      borderRadius="10px"
      gridGap="25px"
    >
      <Box position="relative">
        <Avatar
          alt={userName}
          src={imageSrc ? imageSrc : ProfileImg}
          style={{
            height: "82px",
            width: "82px",
            boxShadow: "0px 4px 12px rgba(10,4,18,0.07)",
          }}
        />

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="30px"
          width="30px"
          borderRadius="50px"
          bgcolor="#390879"
          padding="2px"
          color="#fff"
          position="absolute"
          bottom="-10%"
          right="-10%"
        >
          <UploadPicButtons handleFileChange={onUploadFile} />
        </Box>
      </Box>
      <Box>
        <Box>
          <Typography style={styles.title}>{userName}</Typography>
        </Box>
        <Box style={styles.container}>
          <img src={business} alt="business" />
          <Typography style={styles.subtitle}>{place}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
