import React from "react";
import {
  Box,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import ShyfterContactUsFormController, {
  Props,
  configJSON,
} from "./ShyfterContactUsFormController.web";
import CustomInput from "../../../components/src/CustomInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import CustomMultiSelect from "../../../components/src/CustomSelectMultipleField.web";
import CustomInputTextBox from "../../../components/src/CustomInputTextBoxField.web";
import ContactUsPopup from "../../../components/src/ContactUsPopUp";
import PhoneInput from "react-phone-input-2";
import { configJSON as config } from "../../email-account-registration/src/EmailAccountRegistrationController";

export interface MyFormValues {
    businessName?: string ,
    firstName: string, 
    lastName: string,
    email: string,
    phoneNumber: number | null,
    state: string,
    city: string, 
    zipCode: number | null,
    requirement: string[],
    comments: string,
}

const theme = createTheme({
    palette: {
      primary: {
        main: "#390879",
        contrastText: "#fff",
      },
    },
});

const CustomFormWrapper = styled(Box)({
    width: "100%",
    "&.box_wrapper > .MuiInputBase-root-108": {
        borderRadius: '18px !important'
    }
})

const webStyle = {
  mobileInputWrapper: {
    position: "relative" as any,
    width: "85%"
  },
  actionWrapper: {
    width: "30%",
    marginTop: "24px",
    float: 'right' as 'right',
    paddingBottom: '30px',
  },
  multipleFieldsWrapper: {
    display: "flex", 
    justifyContent: "space-between",
    width: "100%" 
  },
  commonNameFieldWrapper:{ 
    width: "48%" 
  },
  commonAddressFieldWrapper: {
    width: "30%" 
  }

};

export class ShyfterContactUsForm extends ShyfterContactUsFormController {
  constructor(props: Props) {
    super(props);
  }

  renderForm = ( formData: any, errors: any) => {
    return(
        <>
            <div style={webStyle.multipleFieldsWrapper}>
                <div style={webStyle.commonNameFieldWrapper}>
                    <CustomInput
                        value={formData.firstName}
                        handleChange={(event) => this.handleShyfterInputChange(event, "firstName")}
                        label={configJSON.firstNameTitle}
                        name="firstName"
                        maxLength={30}
                        error={!!errors.firstName}
                    />
                </div>

                <div style={webStyle.commonNameFieldWrapper}>
                    <CustomInput
                        value={formData.lastName}
                        handleChange={(event) =>
                            this.handleShyfterInputChange(event, "lastName")
                        }
                        label={configJSON.lastNameTitle}
                        name="lastName"
                        maxLength={100}
                        error={ !!errors.lastName }
                    />
                </div>
            </div>
                        
            <CustomInput
                value={formData.email}
                handleChange={(event) => this.handleShyfterInputChange(event, "email")}
                label={configJSON.emailTitle}
                name="email"
                maxLength={30}
                error={ !!errors.email }
            />

            <div style={{ display: "flex", justifyContent:"space-between"}}>
                <div style={{ width: "12%"}}>
                    <PhoneInput
                        country={"in"}
                        data-test-id="country-code-selector-contactUs"
                        enableSearch={true}
                        value={""}
                        containerStyle={{
                            width: "100%",
                            height: "47px",
                            marginRight: "-40px"
                        }}
                        inputStyle={{
                            borderRadius: "50px",
                            padding: "19px",
                            paddingLeft: "45px",
                            width: "100%",
                            display: "none"
                        }}
                        buttonStyle={{
                            backgroundColor:"white",
                            borderRadius: "50px",
                            width: "100%",
                        }}
                        onChange={(event) => this.handleCountryCodeSelectionContactUs(event)}
                        placeholder="Country Code"
                    />
                </div>
                <div style={webStyle.mobileInputWrapper}>
                    <CustomInput
                        value={formData.mobileNumber}
                        handleChange={(event) => this.handleShyfterInputChange(event, "mobileNumber")}
                        label={configJSON.busiessPhoneTitle}
                        name="mobileNumber"
                        maxLength={10}
                        error={ !!errors.mobileNumber }
                    />
                </div>
            </div>

            <div style={webStyle.multipleFieldsWrapper}>
                <div style={webStyle.commonAddressFieldWrapper}>
                    <CustomSelect
                        value={formData.state}
                        handleChange={(event) => this.handleShyfterInputChange(event, "state")}
                        label={configJSON.stateTitle}
                        name="state"
                        error={ !!errors.state }
                        options={config.dlStateList}
                    />
                </div>

                <div style={webStyle.commonAddressFieldWrapper}>
                    <CustomSelect
                        value={formData.city}
                        handleChange={(event) => this.handleShyfterInputChange(event, "city")}
                        label={configJSON.cityTitle}
                        name="city"
                        error={ !!errors.city }
                        options={configJSON.cityList}
                    />
                </div>

                <div style={webStyle.commonAddressFieldWrapper}>
                    <CustomInput
                        value={formData.zipCode}
                        handleChange={(event) =>  this.handleShyfterInputChange(event, "zipCode")}
                        label={configJSON.zipCodeTitle}
                        name="zipCode"
                        maxLength={11}
                        error={ !!errors.zipCode }
                    />  
                </div>

            </div>

            <CustomMultiSelect
                data-test-id="multiSelectTestId"
                selectedValues={formData.skills}
                handleChange={(event:any) => this.handleShyfterInputChange(event, "skills")}
                label={this.props.tabValue === 0 ? configJSON.interestInHiringTitle :  configJSON.previousExperienceTitle}
                name="skills"
                error={ !!errors.skills }
                options={this.props.tabValue === 0 ? configJSON.hiringInterestList: configJSON.prevExperienceList}
                deleteSelected={(event: any , value?: string)=> this.handleShyfterDelete(event, value)}
            />

            <CustomInputTextBox
                value={formData.comments}
                handleChange={(event:any) => this.handleShyfterInputChange(event, "comments")}
                label={configJSON.commentsTitle}
                name="comments"
                maxLength={200}
                error={ !!errors.comments }
            />
        </>
    )
  }

  render() {

    const { formData, shyfterFormDataErrors, businessFormDataErrors } = this.state;

    return (
        <ThemeProvider theme={theme}>
            <CustomFormWrapper data-test-id="boxWrapperId" className="box_wrapper" >
                    <form className="form_wrapper">
                        { this.props.tabValue === 0 ? 
                             <>
                                <CustomInput
                                    value={formData.businessName}
                                    handleChange={(event) => this.handleShyfterInputChange(event, "businessName")}
                                    label={configJSON.businessTitle}
                                    name="businessName"
                                    maxLength={30}
                                    error={!!businessFormDataErrors.businessName}
                                />
                                {this.renderForm(formData, businessFormDataErrors)}
                            </>
                            : 
                                this.renderForm(formData, shyfterFormDataErrors)
                        }
                        
                        <div style={webStyle.actionWrapper}>
                            <CustomButton
                                label={'Submit Request'}
                                handleClick={() => this.onSubmitHandler()}
                            />
                        </div>
                    </form>
            </CustomFormWrapper>
            <ContactUsPopup
                data-test-id="popUpId"
                open={this.state.openPopup} 
                handleCancel={() => this.handlePopUpModalClose()} 
                isSuccess={this.state.isSuccess}
                isApiError={this.state.contactApiError}
            />
        </ThemeProvider>
    );
  }
}

export default ShyfterContactUsForm;
