import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setAboutLoading } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  openSignUpDialogFromBusiness: boolean;
}

interface SS {
  id: any;
}

export default class BenefitsController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      openSignUpDialogFromBusiness: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", JSON.stringify(message));
  }

  getRedirectUrl = () => {
    let url = window.location.pathname;
    return url;
  };

  onNavigateFromBusiness = (pathString: string) => {
    const navigateFromBusinessMsg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigateFromBusinessMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pathString
    );
    navigateFromBusinessMsg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateFromBusinessMsg);
  };

  toggleSignUpForBusiness = () => {
    this.setState({
      openSignUpDialogFromBusiness: !this.state.openSignUpDialogFromBusiness,
    });
  };

  redirectToSignUpPage = (navigationPath: string) => {
    this.setState({ openSignUpDialogFromBusiness: false });
    this.onNavigateFromBusiness(navigationPath);
  };

  onRedirectToAbout = () => {
    setAboutLoading(true);
    this.onNavigateFromBusiness("Home");
  };

  joinNow = () => {
    const path =
      this.getRedirectUrl() === "/ShyfterBenifits"
        ? "ShyfterSignUp"
        : "EmployerSignUp";
    this.onNavigateFromBusiness(path);
  };
}
