import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
  globeIcon,
  phoneIcon,
  marker,
  envelopeIcon,
  userIcon,
  buildingIcon,
} from "./assets";
import { checkAuthorization, clearUserLoginData, formatRating, getCityAndState, getUserId } from "../../../components/src/Utilities";

const tabs = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Official Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];

import {
  TProfileBlock,
  TSideTab,
  TBusinessProfile,
  TRatingsData,
  TOfficialDocs,
} from "./ShyfterBusinessProfileController";
import { ChangeEvent } from "react";

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  employerProfile: TBusinessProfile | null;
  sideTabs: TSideTab[];
  employerBlock: TProfileBlock[];
  managementBlock: TProfileBlock[];
  ratingsData: TRatingsData | null;
  officialDocuments: TOfficialDocs | null;
  isEmailEditPopUpOpen: boolean;
  isEmailOtpPopUpOpen: boolean;
  isPhoneEditPopUpOpen: boolean;
  isPhoneOtpPopUpOpen: boolean;
  phoneNumber: string;
  email: string;
  phoneNumberOTP: string;
  emailOTP: string;
  seconds: number;
  countryCode: string;
  errorMessageModal: string;
  errorPopUp: boolean;
}

interface SS {
  id: any;
}

export default class EmployerBusinessProfileContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getEmployerProfileId: string = "";
  getEmailOTPId: string = "";
  getPhoneOTPId: string = "";
  patchUserProfleId: string = "";
  otpTimer?: number;
  userLogoutEmployerApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationViewBusinessProfileMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      employerProfile: null,
      sideTabs: tabs,
      employerBlock: [],
      managementBlock: [],
      ratingsData: null,
      officialDocuments: null,
      isEmailEditPopUpOpen: false,
      isEmailOtpPopUpOpen: false,
      isPhoneEditPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      phoneNumber: "",
      email: "",
      phoneNumberOTP: "",
      emailOTP: "",
      seconds: 120,
      countryCode: "91",
      errorMessageModal: "",
      errorPopUp: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  getBusinessProfileHandler(userId: number) {
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmployerProfileId = reqMessage.messageId;

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShyfteUserProfileEndPoint}/${userId}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(reqMessage.id, reqMessage);
  }

  handleBusinessProfileData = (responseJson: any) => {
    const response = responseJson.user.data.attributes;
    const isHotel = (response.type as string).toLowerCase() === "hotel";

    const employerProfile: TBusinessProfile = {
      id: response.id,
      name: response.full_name,
      address: getCityAndState(response.city, response.state),
      imageURL: response.profile_picture,
      tag: response.business_details[
        isHotel ? "hotel_type" : "restaurant_type"
      ],
      phoneNumber: response.phone_number,
      websiteURL: response.email,
      hotelOrRestaurantName:
        response.business_details[isHotel ? "hotel_name" : "restuarant_name"],
      rating: response.ratings.overall_rating,
      phoneNumWithCode: response.country_code + "-" + response.full_phone_number
    };

    const employerBlock = [
      {
        title: "DBA Name",
        icon: buildingIcon,
        value: response.business_details.dba_name,
      },
      {
        title: `${isHotel ? "Hotel" : "Restaurant"} Address`,
        icon: marker,
        value: response.business_details.address,
      },
      {
        title: "Business Website ",
        icon: globeIcon,
        value: response.business_details.website,
      },
    ];

    const managementBlock = [
      {
        title: "Contact Name",
        icon: userIcon,
        value: `${response.full_name} | EIN-${response.ein}`,
      },
      {
        title: "Business Telephone",
        icon: phoneIcon,
        value: response.business_details.telephone,
      },
      {
        title: "Contact Email ID",
        icon: envelopeIcon,
        value: response.email,
      },
    ];

    const ratingsData = formatRating(responseJson);

    const officialDocuments = {
      certificate: response.official_docs.certificate,
      form: response.official_docs.form,
    };

    this.setState({
      employerProfile,
      employerBlock,
      ratingsData,
      managementBlock,
      officialDocuments,
    });
  };

  changeTabHandler = (tabId: any) => {
    const updatedTabs = this.state.sideTabs.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      }

      return { ...tab, isActive: false };
    });

    this.setState({ sideTabs: updatedTabs });
  };

  openEditPhoneNumberModal = () => {
    this.setState({ isPhoneEditPopUpOpen: true, phoneNumber: "", errorPopUp: false });
  };

  closeEditPhoneNumberModal = () => {
    this.setState({ isPhoneEditPopUpOpen: false });
  };

  openEditEmailModal = () => {
    this.setState({ isEmailEditPopUpOpen: true, email: "", errorPopUp: false });
  };

  closeEditEmailModal = () => {
    this.setState({ isEmailEditPopUpOpen: false });
  };

  startBusinessProfileOtpTimer = () => {
    this.clearBusinessProfileOtpTimer();
    this.otpTimer = window.setInterval(() => {
      this.setState((prevState) => {
        if (prevState.seconds > 0) {
          return { seconds: prevState.seconds - 1 };
        }

        this.clearBusinessProfileOtpTimer();
        return { seconds: prevState.seconds };
      });
    }, 1000);
  };

  clearBusinessProfileOtpTimer = () => {
    if (this.otpTimer) {
      clearInterval(this.otpTimer);
    }
  };

  openPhoneOTPModal = (response: any) => {
    if (response?.message?.toLowerCase() === "otp sent successfully") {
      this.setState({ isPhoneOtpPopUpOpen: true, seconds: 120, phoneNumberOTP: "", errorPopUp: false });
      this.startBusinessProfileOtpTimer();
    } else {
      this.setState({ errorMessageModal: response?.message  || response?.error || response?.errors[0], errorPopUp: true });
    }
  };

  closePhoneOTPModal = () => {
    this.setState({ isPhoneOtpPopUpOpen: false });
    this.clearBusinessProfileOtpTimer();
  };

  openEmailOTPModal = (response: any) => {
    if (response?.message?.toLowerCase().includes("otp sent")) {
      this.setState({ isEmailOtpPopUpOpen: true, seconds: 120, emailOTP: "", errorPopUp: false });
      this.startBusinessProfileOtpTimer();
    } else {
      this.setState({ errorMessageModal: response?.message || response?.error || response?.errors?.email[0], errorPopUp: true });
    }
  };

  closeEmailOTPModal = () => {
    this.setState({ isEmailOtpPopUpOpen: false });
    this.clearBusinessProfileOtpTimer();
  };

  onChangePhoneNumber = (event: any) => {
    const { value } = event.target;
    let newValue = value;
      
      let changePhoneNumber = newValue.toString();
      const sanitizedValue = changePhoneNumber
        .replace(/[+e-]/g, "")
        .replace(/\D/g, "");
      newValue = sanitizedValue.slice(0, 10);
  
    this.setState({
      ...this.state,
      phoneNumber: newValue,
      errorPopUp: false
    })
  };

  onChangePhoneNumberOTP = (phoneNumberOTP: string) => {
    this.setState({ phoneNumberOTP });
  };

  onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newValue = value;

    newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, "");

    this.setState({ email: newValue, errorPopUp: false });
  };

  onChangeEmailOTP = (emailOTP: string) => {
    this.setState({ emailOTP });
  };

  getPhoneNumberOtp = () => {
    const { phoneNumber } = this.state;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPhoneOTPEndpoint}?phone_number=${this.state.countryCode}${phoneNumber}`
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    this.getPhoneOTPId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  getEmailOtp = () => {
    const { email } = this.state;
    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postEmailOTPEndpoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationPostMethod
    );

    this.getEmailOTPId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  updateUserPhoneNumber = () => {
    const { phoneNumber, phoneNumberOTP } = this.state;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putUpdatePhoneEndpoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      phone_number: `${this.state.countryCode}${phoneNumber}`,
      pin: phoneNumberOTP,
    };

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    this.patchUserProfleId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  updateUserEmail = () => {
    const { email, emailOTP } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email,
      pin: emailOTP,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );

    this.patchUserProfleId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleUpdateSuccess = () => {
    this.setState({
      email: "",
      phoneNumber: "",
      isEmailEditPopUpOpen: false,
      isPhoneEditPopUpOpen: false,
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      emailOTP: "",
      phoneNumberOTP: "",
    });

    this.clearBusinessProfileOtpTimer();

    this.handleLogout();
  };

  goToManageAccount = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmployerManageAccount"
    );

    this.send(message);
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.loginRedirection();
        return;
      } else {
        this.handleApiResponse(apiRequestCallId, responseJson);
      }
    }
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.getEmployerProfileId) {
      this.handleBusinessProfileData(responseJson);
    }

    if (apiRequestCallId === this.getEmailOTPId) {
      this.openEmailOTPModal(responseJson);
    }

    if (apiRequestCallId === this.getPhoneOTPId) {
      this.openPhoneOTPModal(responseJson);
    }

    if (apiRequestCallId === this.patchUserProfleId) {
      this.handleUpdateSuccess();
    }

    if (apiRequestCallId === this.userLogoutEmployerApiCallId) {
      if (Array.isArray(responseJson?.messages)) {
        this.handleLogout();
      }
    }
  }

  async componentDidMount() {
    const isAuthorizedBusinessUser = checkAuthorization(true, false);
    if (!isAuthorizedBusinessUser) {
      this.loginRedirection();
      return;
    }
    const userId = getUserId();
    if (userId) {
      this.getBusinessProfileHandler(userId);
    }
  }

  async componentWillUnmount() {
    this.clearBusinessProfileOtpTimer();
  }

  loginRedirection = () => {
    const loginMsg = new Message(getName(MessageEnum.NavigationMessage));
    loginMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    loginMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(loginMsg);
  }

  handleLogout = () => {
    clearUserLoginData();
    this.loginRedirection();
  };

  handleCountryCodeSelectionEmployer = (event: string) => {
    this.setState({ countryCode: event})
  }

  userLogoutEmployer = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const body = {
      device_id: localStorage.getItem("fcmToken") || "",
    };
    
    const userLogoutRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userLogoutEmployerApiCallId = userLogoutRequestMessage.messageId;

    userLogoutRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    userLogoutRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userLogoutApiEndPoint
    );

    userLogoutRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    userLogoutRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );

    runEngine.sendMessage(userLogoutRequestMessage.id, userLogoutRequestMessage);
  }
}
