import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

import { ChangeEvent } from "react";
import {
  docsIcon,
  fadeDocsIcon,
  fileCircle,
  fileCircleIconFade,
  startComment,
  startCommentfade,
} from "./assets";
import { clearUserLoginData, formatRating, getUserId, checkAuthorization } from "../../../components/src/Utilities";
import { TRatingsData } from "./ShyfterBusinessProfileController";
export const configJSON = require("./config");
const sideNavTab = [
  {
    id: 1,
    tab: "Overview",
    tabIcon: fileCircleIconFade,
    activeTabIcon: fileCircle,
    isActive: true,
  },
  {
    id: 2,
    tab: "Official Docs",
    tabIcon: fadeDocsIcon,
    activeTabIcon: docsIcon,
    isActive: false,
  },
  {
    id: 3,
    tab: "Ratings",
    tabIcon: startComment,
    activeTabIcon: startCommentfade,
    isActive: false,
  },
];
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isPastShyft?: boolean;
  sideNavTab?: any;
  user: any;
  updateShyfterEmailPopUp: boolean;
  updateShyfterPhonePopUp: boolean;
  newEmailShyfter: string;
  newPhoneShyfter: string;
  ratingsData: TRatingsData | undefined;
  isEmailOtpPopUpOpen: boolean;
  isPhoneOtpPopUpOpen: boolean;
  phoneNumberOTP: string;
  emailOTP: string;
  seconds: number;
  countryCode: string;
  errorMessageModal: string;
  errorPopUp: boolean;
}

interface SS {
  id: any;
}

export default class ShyfterProfilePageContoller extends BlockComponent<
  Props,
  S,
  SS
> {
  getUserApiCallId: string = "";
  otpTimer?: number;
  getPhoneOTPId: string = "";
  getEmailOTPId: string = "";
  patchUserProfleId: string = "";
  userLogoutShyfterApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionResponseToken),
    ];

    this.state = {
      isPastShyft: false,
      sideNavTab: sideNavTab,
      user: "",
      updateShyfterEmailPopUp: false,
      updateShyfterPhonePopUp: false,
      newEmailShyfter: "",
      newPhoneShyfter: "",
      ratingsData: {
        reviews: [],
        ratingsCount: 0,
        reviewsCount: 0,
        overallRating: 0,
      },
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      phoneNumberOTP: "",
      emailOTP: "",
      seconds: 120,
      countryCode: "91",
      errorMessageModal: "",
      errorPopUp: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  componentDidMount = async () => {
    const isValidEditorShyfterUser = checkAuthorization(false, true);
    if (!isValidEditorShyfterUser) {
      this.handleShyfterLogout();
      return;
    }
    const profileData = localStorage.getItem("userData");
    if (!!profileData) {
      const userdata = JSON.parse(profileData);
      this.getShyfterProfile(userdata.user.id);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.handleLoginRedirection();
        return;
      } else {
        this.handleApiResponseData(message);
      }
    }
  }

  handleApiResponseData(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getUserApiCallId) {
      this.getShyfterUserProfileRes(responseJson);
    }

    if (apiRequestCallId === this.getEmailOTPId) {
      this.openEmailOTPModal(responseJson);
    }

    if (apiRequestCallId === this.getPhoneOTPId) {
      this.openPhoneOTPModal(responseJson);
    }

    if (apiRequestCallId === this.patchUserProfleId) {
      this.handleUpdateSuccess();
    }

    if (apiRequestCallId === this.userLogoutShyfterApiCallId) {
      if (Array.isArray(responseJson?.messages)) {
        this.handleShyfterLogout();
      }
    }
  }

  getShyfterProfile(userId: any) {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getShyfteUserProfileEndPoint + `/${userId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getShyfterUserProfileRes(responseJson: any) {
    this.setState({
      user: responseJson?.user?.data?.attributes,
      ratingsData: formatRating(responseJson)
    });
  }

  handleActiveTab = (tabId: any) => {
    const updatedTabs = this.state.sideNavTab.map((tab: any) => {
      if (tab.id === tabId) {
        return { ...tab, isActive: true };
      } else {
        return { ...tab, isActive: false };
      }
    });

    this.setState({ sideNavTab: updatedTabs });
  };

  navigateManageProfile() {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EditShyfterProfilePage"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPastShyftMessage)
    );
    raiseMessage.addData(
      getName(MessageEnum.NavigationPastShyftMessage),
      "/Mange Your Account"
    );
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
  }

  showShyfterUpdateEmailPopUp = () => {
    this.setState({
      updateShyfterEmailPopUp: !this.state.updateShyfterEmailPopUp,
      newEmailShyfter: "",
      errorPopUp: false
    });
  };

  showShyfterUpdatePhonePopUp = () => {
    this.setState({
      updateShyfterPhonePopUp: !this.state.updateShyfterPhonePopUp,
      newPhoneShyfter: "",
      errorPopUp: false
    });
  };

  onChangePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    let newValue = value;

    let changePhoneNumber = newValue.toString();
    const sanitizedValue = changePhoneNumber
      .replace(/[+e-]/g, "")
      .replace(/\D/g, "");
    newValue = sanitizedValue.slice(0, 10);

    this.setState({
      ...this.state,
      newPhoneShyfter: newValue,
      errorPopUp: false
    })
  };

  onChangePhoneNumberOTP = (phoneNumberOTP: string) => {
    this.setState({ phoneNumberOTP });
  };

  onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let newValue = value;

    newValue = newValue.replace(/[^a-zA-Z0-9@.]/g, "");

    this.setState({ newEmailShyfter: newValue, errorPopUp: false });
  };

  onChangeEmailOTP = (emailOTP: string) => {
    this.setState({ emailOTP });
  };

  startTimerForOtp = () => {
    this.clearOTPTimer();
    this.otpTimer = window.setInterval(() => {
      this.setState((prev) => {
        if (prev.seconds > 0) {
          return { seconds: prev.seconds - 1 };
        }

        this.clearOTPTimer();
        return { seconds: prev.seconds };
      });
    }, 1000);
  };

  clearOTPTimer = () => {
    if (this.otpTimer) {
      clearInterval(this.otpTimer);
    }
  };

  openPhoneOTPModal = (response: any) => {
    if (response?.message?.toLowerCase() === "otp sent successfully") {
      this.setState({ seconds: 120, isPhoneOtpPopUpOpen: true, phoneNumberOTP: "", errorPopUp: false });
      this.startTimerForOtp();
    } else {
      this.setState({ errorMessageModal: response?.message || response?.error || response?.errors[0], errorPopUp: true });
    }
  };

  closePhoneOTPModal = () => {
    this.setState({ isPhoneOtpPopUpOpen: false });
    this.clearOTPTimer();
  };

  openEmailOTPModal = (res: any) => {
    if (res?.message?.toLowerCase().includes("otp sent")) {
      this.setState({ seconds: 120, isEmailOtpPopUpOpen: true, emailOTP: "", errorPopUp: false });
      this.startTimerForOtp();
    } else {
      this.setState({ errorMessageModal: res?.message || res?.error || res?.errors?.email[0], errorPopUp: true });
    }
  };

  closeEmailOTPModal = () => {
    this.setState({ isEmailOtpPopUpOpen: false });
    this.clearOTPTimer();
  };

  getPhoneNumberOtp = () => {
    const { newPhoneShyfter } = this.state;

    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getPhoneOTPId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getPhoneOTPEndpoint}?phone_number=${this.state.countryCode}${newPhoneShyfter}`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  getEmailOtp = () => {
    const { newEmailShyfter } = this.state;
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getEmailOTPId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postEmailOTPEndpoint
    );

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email: newEmailShyfter,
    };
    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationPostMethod
    );
    runEngine.sendMessage(request.id, request);
  };

  updateUserPhoneNumber = () => {
    const { newPhoneShyfter, phoneNumberOTP } = this.state;

    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.validationApiContentType,
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.putUpdatePhoneEndpoint
    );
    this.patchUserProfleId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      phone_number: `${this.state.countryCode}${newPhoneShyfter}`,
      pin: phoneNumberOTP,
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPutMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  updateUserEmail = () => {
    const { newEmailShyfter, emailOTP } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.patchUserEndPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      email: newEmailShyfter,
      pin: emailOTP,
    };
    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiPatchMethodType
    );

    this.patchUserProfleId = reqMessage.messageId;
    runEngine.sendMessage(reqMessage.id, reqMessage);
  };

  handleUpdateSuccess = () => {
    this.setState({
      newEmailShyfter: "",
      newPhoneShyfter: "",
      isEmailOtpPopUpOpen: false,
      isPhoneOtpPopUpOpen: false,
      emailOTP: "",
      phoneNumberOTP: "",
      updateShyfterEmailPopUp: false,
      updateShyfterPhonePopUp: false,
    });

    this.clearOTPTimer();

    this.handleShyfterLogout()
  };

  handleCountryCodeSelectionForUpdate = (event: string) => {
    this.setState({ countryCode: event})
  }

  handleShyfterLogout = () => {
    clearUserLoginData();
    this.handleLoginRedirection();
  };

  handleLoginRedirection = () => {
    const loginMsg = new Message(getName(MessageEnum.NavigationMessage));
    loginMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    loginMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(loginMsg);
  }

  userLogoutShyfter = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userLogoutShyfterApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userLogoutApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      device_id: localStorage.getItem("fcmToken") || "",
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleDeleteAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
