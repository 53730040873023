import React from "react";
import { Box, makeStyles, IconButton, Dialog, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";
const useStyles = makeStyles((theme) => ({
  successDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISuccessDialogProps {
  open: boolean;
  title: string;
  description: string;
  positiveActionText: string;
  negativeActionText: string;
  positiveAction: (e: any) => void;
  negativeAction: (e: any) => void;
  closeDialog: (e: any) => void;
}

const CustomSuccessDialog: React.FC<ISuccessDialogProps> = ({
  open,
  title,
  description,
  positiveAction,
  positiveActionText,
  negativeActionText,
  negativeAction,
  closeDialog
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.successDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={closeDialog} data-test-id="close-icon">
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box justifyContent="center" display="flex">
        <img src={require("./assets/image_Accept.svg")} alt="" width={64} />
      </Box>
      <Box className="successDialogTitle">
        {title}
      </Box>
      <Box className="description">
        {description}
      </Box>
        <Grid container className="actionWrapper" spacing={2}>
          <Grid item xs={6}>
            <CustomButton
              label={negativeActionText}
              isOutlined={true}
              handleClick={negativeAction}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomButton
              label={positiveActionText}
              handleClick={positiveAction}
            />
          </Grid>
        </Grid>
    </Dialog>
  );
};

export default CustomSuccessDialog;
