import React from "react";
import {
  Button,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
const signouticon = require("./assets/images/logout.svg");
const deleteIcon = require("./assets/images/trash-red.png");

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
      light: "#6f7c8e",
    },
    secondary: {
      main: "#0a0412",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
  },
  dangerBtn: {
    borderRadius: "50px",
    padding: "11px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
    backgroundColor: "#f5d9de",
    color: "#ff375b",
    border: "none",
    "&:hover": {
      backgroundColor: "#f5d9de",
      color: "#ff375b",
      borderRadius: "50px",
      border: "none",
    },
  },
  logOutBtn: {
    borderRadius: "50px",
    padding: "15px 0",
    fontSize: "14px",
    fontWeight: 800,
    textTransform: "none",
    backgroundColor: "rgba(255, 55, 91, 0.1)",
    color: "#ff375b",
    border: "none",
    "&:hover": {
      backgroundColor: "#f5d9de",
      color: "#ff375b",
      borderRadius: "50px",
      border: "none",
    },
  },
  btnIcon: {
    height: "18px",
    margin: "0px 10px",
  },
}));

interface IButtonProps {
  label?: string;
  disabled?: boolean;
  isOutlined?: boolean;
  handleClick?: (e: any) => void;
  isDanger?: boolean;
  islogOutBtn?: boolean;
  isDeleteBtn?: boolean;
  notFullWidth?: boolean;
}

const CustomButton: React.FC<IButtonProps> = ({
  label,
  disabled,
  isOutlined,
  handleClick,
  isDanger,
  isDeleteBtn,
  islogOutBtn,
  notFullWidth,
}) => {
  const classes = useStyles();

  const getBtnClasses = () => {
    if(islogOutBtn){
      return classes.logOutBtn
    } 

    if(isDanger) {
      return classes.dangerBtn
    }
    else {
      return classes.button
    } 
  
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        fullWidth={notFullWidth ? false : true}
        variant={isOutlined ? "outlined" : "contained"}
        color="primary"
        className={getBtnClasses()}
        disabled={disabled}
        onClick={handleClick}
        data-test-id="customBtn"
      >
        {isDeleteBtn && (
          <img src={deleteIcon} alt="" className={classes.btnIcon} />
        )}
        {label}
        {islogOutBtn && (
          <img src={signouticon} alt="" className={classes.btnIcon} />
        )}
      </Button>
    </ThemeProvider>
  );
};

export default CustomButton;
