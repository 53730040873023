import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { createRef, RefObject } from "react";
export const configJSON = require("./config");
import moment from "moment";
import { ProfileImg } from "./assets";
import {
  checkAuthorization,
  constructURL,
  convertDateIntoTwelveHourFormat,
  convertSecondsToHrsMinsSecWithHMSFormat,
  convertTimeStringIntoDuration,
  copyDateObjWithTime,
  getKeyFromLocalStorage,
} from "../../../components/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";

export interface TMyShyft {
  id: number;
  shyft: string;
  syftTimimg: string;
  shyftPlace: string;
  shyftEndDate: string;
  isSavedShyft: boolean;
  employername: string;
  startTime: string;
  endTime: string;
  userImg: string;
  billing: string;
  day: string;
  status: string;
  county: string;
  businessType: string;
  businessId: number;
  nextShyftTime: string;
  isOngoing: boolean;
}

interface TMyShyftResponse {
  id: string;
  type: string;
  attributes: {
    id: number;
    status: string;
    worker_id: number;
    removal_reason: string;
    created_at: string;
    updated_at: string;
    applied_on: string;
    hours_worked: {};
    activity: string;
    shift: {
      id: string;
      type: string;
      attributes: {
        id: number;
        shift_type: string;
        requirements: string[];
        description: string;
        expirence: string;
        amount_per: string;
        shift_from: string;
        shift_to: string;
        employer_id: number;
        activated: true;
        isAvailable: false;
        created_at: string;
        amount: number;
        saved: boolean;
        business_type: string;
        activity_details: string;
        next_shift: string;
        shift_end: string;
        applied_status: string;
        ongoing_status: boolean;
        business_details: {
          business: {
            id: number;
            restaurant_type: string;
            restuarant_name: string;
            dba_name: string;
            hotel_name:string;
            address: string;
            telephone: string;
            website: string;
            user_id: number;
            created_at: string;
            updated_at: string;
          };
          employer_profile_picture: string;
        };
      };
    };
  };
}

interface TSearchParams {
  page: number;
  perPage?: number;
}

interface TPagination {
  page_number: number;
  records_in_this_page: number;
  records_per_page: number;
  total_records: number;
}

interface TSummary {
  hours_worked: string;
  missed_shifts: string;
  completed_days: string;
}

export interface TActivityRecords {
  id: string;
  attributes: {
    id: number;
    punch_in: string;
    punch_out: string;
    meal_break: string;
    status: string;
    shift_id: number;
    user_id: number;
    created_at: string;
    updated_at: string;
    hours_worked: string;
  };
}

export interface TCurrentActivity {
  id: number;
  shiftId: number;
  punchIn: string;
}

export interface TActivityRequest {
  id: number;
  status: string;
  removal_reason: string;
  updated_at: string;
}

export interface TShyftTimers {
  [property: string]: number;
}

export interface Props {
  navigation: any;
  classes: Record<string, string>;
  id: string;
}

interface S {
  isLoading: boolean;
  isShyftActivity?: boolean;
  page: number;
  totalPages: number;
  selectedMyShyft: number | null;
  punchedShyftId: number | null;
  myShyftsList: TMyShyft[];
  appliedShyftsCount: number;
  activityRecords: TActivityRecords[];
  summary?: TSummary | any;
  request?: TActivityRequest | any;
  totalShiftTime: number;
  currentHoursWorked: number;
  activityId: number | null;
  shyftPunchInTime: string;
  shyftPunchOutTime: string;
  shyftStartTime: string;
  shyftEndTime: string;
  nextShyftHoursLeft: TShyftTimers;
  mealTime: string;
  isShowActivityFilter: boolean;
  showEditMealBreak: boolean;
  isMessageOverlayVisible: boolean;
  isWorkSummaryVisible: boolean;
  isAlreadyRunning: boolean;
  workSummary: any;
}

interface SS {
  id: any;
}

export default class MyShyftsController extends BlockComponent<Props, S, SS> {
  getMyShyftsAPICallId: string = "";
  getAppliedShyftsAPICallId: string = "";
  getNextMyShyftsAPICallId: string = "";
  getActivityRecordsAPICallId: string = "";
  postPunchInShyftAPICallId: string = "";
  postPunchOutShyftAPICallId: string = "";
  patchMealBreakAPICallId: string = "";
  observer: IntersectionObserver | null = null;
  elementRef: RefObject<HTMLDivElement>;
  getViewActivityAPICallId: string = "";
  activityTimer?: number;
  shyftsTimer?: number;
  skipCall = true;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isLoading: true,
      isShyftActivity: false,
      myShyftsList: [],
      appliedShyftsCount: 0,
      page: 1,
      totalPages: 1,
      selectedMyShyft: null,
      punchedShyftId: null,
      activityRecords: [],
      currentHoursWorked: 0,
      totalShiftTime: 0,
      activityId: null,
      shyftPunchInTime: "",
      shyftPunchOutTime: "",
      shyftStartTime: "",
      shyftEndTime: "",
      nextShyftHoursLeft: {},
      mealTime: "15 mins",
      isShowActivityFilter: false,
      showEditMealBreak: true,
      isMessageOverlayVisible: false,
      isWorkSummaryVisible: false,
      workSummary: {},
      isAlreadyRunning: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  handleAPIResponses = (responseJson: any, apiRequestCallId: string) => {
    if (this.getMyShyftsAPICallId === apiRequestCallId) {
      this.handleMyShyfts(
        responseJson.data,
        responseJson.meta.pagination_details,
        "initial"
      );
    }

    if (this.getNextMyShyftsAPICallId === apiRequestCallId) {
      this.handleMyShyfts(
        responseJson.data,
        responseJson.meta.pagination_details,
        "next"
      );
    }

    if (this.getActivityRecordsAPICallId === apiRequestCallId) {
      this.handleActivityRecordResponse(responseJson);
    }

    if (this.getViewActivityAPICallId === apiRequestCallId) {
      this.handleCurrentActivityData(responseJson.activity.data);
    }

    if (this.postPunchInShyftAPICallId === apiRequestCallId) {
      this.handlePunchInShyft(responseJson);
    }

    if (this.postPunchOutShyftAPICallId === apiRequestCallId) {
      this.handlePunchOutShyft(responseJson.data);
    }

    if (this.getAppliedShyftsAPICallId === apiRequestCallId) {
      this.handleAppliedShyftsCount(responseJson.pagination_details);
    }

    if (this.patchMealBreakAPICallId === apiRequestCallId) {
      this.handleMealBreak(responseJson?.meta?.message);
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.errors && responseJson?.errors?.length > 0 && responseJson?.errors[0]?.token) {
        this.navigateToPage("Login");
        return;
      } else {
        this.handleAPIResponses(responseJson, apiRequestCallId);
      }

    }
  }

  toggleEditMeal = (value: boolean) => {
    this.setState({ showEditMealBreak: value });
  };

  handleMealBreak = (message?: string) => {
    if (!message) return;
    if (message.toLowerCase() === "activity updated successfully") {
      this.toggleEditMeal(false);
    }
  };

  handleActivityRecordResponse = (responseJson: any) => {
    if (responseJson.activities && responseJson.summary) {
      this.handleActivityRecords(
        responseJson.activities.data,
        responseJson.summary,
        responseJson.request
      );
    } else {
      this.setState({
        activityRecords: [],
        summary: {},
        request: {},
      });
    }
  };

  handleCurrentActivityData = (response: any) => {
    const mealTime = response.attributes.meal_break;

    this.setState({
      activityId: response.attributes.id,
      shyftPunchInTime: response.attributes.punch_in,
      mealTime: mealTime ? `${mealTime} mins` : "15 mins",
      showEditMealBreak: mealTime ? false : true,
    });
  };

  navigateToPage(target: string) {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), target);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  viewBusinessProfile = (businessId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "My Shyfts",
        link: "MyShyftsPage",
        active: false,
      })
    );
    this.send(message);
  };

  chatWithUser = (receiverId: number) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Messages");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("receiverId", receiverId);
    this.send(message);
  };

  viewRecordActivity() {
    const { selectedMyShyft } = this.state;

    this.setState({
      isShyftActivity: true,
    });

    this.getActivityRecords(selectedMyShyft, false);
  }

  bactToMyshyfts() {
    this.setState({
      isShyftActivity: false,
    });
  }

  getUserId = () => {
    let userId = "";

    const userData = getKeyFromLocalStorage("userData");
    if (!Array.isArray(userData)) {
      userId = userData.user.id;
    }

    return userId;
  };

  getAllAppliedShyfts() {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const header = {
      token: localStorage.getItem("authToken"),
      "Content-Type": configJSON.getAppliedShyftApiContentType,
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getAppliedShyftApiEndPoint,
    });

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppliedShyftApiMethodType
    );

    this.getAppliedShyftsAPICallId = request.messageId;
    runEngine.sendMessage(request.id, request);
  }

  getAllMyShyfts({ page }: TSearchParams, type: "initial" | "next") {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const endpoint = constructURL({
      endpoint: configJSON.getMyShyftsApiEndPoint,
      page,
      sortBy: "created_at",
      orderBy: "desc",
    });
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const header = {
      "Content-Type": configJSON.getMyShyftsApiContentType,
      token: localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMyShyftsApiMethodType
    );

    if (type === "initial") {
      this.getMyShyftsAPICallId = requestMessage.messageId;
    } else {
      this.getNextMyShyftsAPICallId = requestMessage.messageId;
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  listenForScrollChanges() {
    const callback: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        if (this.skipCall) {
          this.skipCall = false;
          return;
        }

        if (this.state.page < this.state.totalPages) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };
    this.observer = new IntersectionObserver(callback);
    if (this.elementRef.current) this.observer.observe(this.elementRef.current);
  }

  formatMyShyftList(myShyfts: TMyShyftResponse[]) {
    const myShyftsList: TMyShyft[] = myShyfts.map((shift, index) => {
      return {
        id: shift.attributes.shift.attributes.id,
        day: `${moment(
          shift.attributes.shift.attributes.created_at
        ).calendar()}`,
        shyft: shift.attributes.shift.attributes.shift_type,
        shyftPlace: shift.attributes.shift.attributes.business_type,
        county:
          shift.attributes.shift.attributes.business_details.business.address,
        isSavedShyft: shift.attributes.shift.attributes.saved,
        employername:
          shift.attributes.shift.attributes.business_details.business.hotel_name|| shift.attributes.shift.attributes.business_details.business.restuarant_name,
        userImg:
          shift.attributes.shift.attributes.business_details
            .employer_profile_picture ?? ProfileImg,
        syftTimimg: `${convertDateIntoTwelveHourFormat(
          shift.attributes.shift.attributes.shift_from
        )} - ${convertDateIntoTwelveHourFormat(
          shift.attributes.shift.attributes.shift_to
        )}`,
        billing: `$${shift.attributes.shift.attributes.amount} Per Hr`,
        startTime: shift.attributes.shift.attributes.shift_from,
        endTime: shift.attributes.shift.attributes.shift_to,
        shyftEndDate: `${moment(
          shift.attributes.shift.attributes.shift_end
        ).format("Do MMM, YYYY [at] hh:mma")}`,
        status: shift.attributes.shift.attributes.applied_status,
        isOngoing: shift.attributes.shift.attributes.ongoing_status,
        businessId:
          shift.attributes.shift.attributes.business_details.business.user_id,
        businessType: shift.attributes.shift.attributes.business_type,
        // nextShyftTime: shift.attributes.shift.attributes.next_shift,
        nextShyftTime:
          index % 2 !== 0
            ? "00:10:12"
            : shift.attributes.shift.attributes.next_shift,
      };
    });
    return myShyftsList;
  }

  handleMyShyfts(
    myShyfts: TMyShyftResponse[],
    pagination: TPagination,
    type: "next" | "initial"
  ) {
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );
    const myShyftsList = this.formatMyShyftList(myShyfts);
    if (type === "initial") {
      this.setState({
        totalPages,
        myShyftsList,
        selectedMyShyft: myShyftsList[0]?.id,
        isLoading: false,
      });

      this.addTimersForNextShyft("initial");
    } else {
      this.setState((prevState) => ({
        totalPages,
        myShyftsList: [...prevState.myShyftsList, ...myShyftsList],
      }));
      this.addTimersForNextShyft("update");
    }
  }

  handleAppliedShyftsCount = (pagination: TPagination) => {
    if (pagination) {
      this.setState({ appliedShyftsCount: pagination.total_records });
    }
  };

  onSelectMyShift(shiftId: number) {
    this.setState({
      selectedMyShyft: shiftId,
      isShyftActivity: false,
      isWorkSummaryVisible: false,
    });
  }

  getActivityRecords(
    shiftId: number | null,
    isFilter: boolean,
    filterData?: any
  ) {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const userId = this.getUserId();
    let activityEndpoint = "";

    if (isFilter) {
      activityEndpoint = `${configJSON.getActivityRecordsApiEndPoint}&filter=${filterData.filterType}&from=${filterData.filterDateFrom}&to=${filterData.filterDateTo}`;
    } else {
      activityEndpoint = configJSON.getActivityRecordsApiEndPoint;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${activityEndpoint}&shift_id=${shiftId}&user_id=${userId}`
    );

    const header = {
      "Content-Type": configJSON.getActivityRecordsApiContentType,
      token: localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getActivityRecordsApiMethodType
    );

    this.getActivityRecordsAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleActivityRecords(
    activityRecords: TActivityRecords[],
    summaryRes: TSummary,
    request: TActivityRequest
  ) {
    this.setState({
      activityRecords,
      summary: summaryRes,
      request,
    });
  }

  getCurrentActivity = (shyftId: number) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getViewActivityApiEndPoint}?shift_id=${shyftId}`
    );

    const header = {
      "Content-Type": configJSON.getViewActivityApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getViewActivityApiMethodType
    );

    this.getViewActivityAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  punchInToTheShyft = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postPunchInShyftApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.postPunchInShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      shift_id: this.state.selectedMyShyft,
      punch_in: moment().toISOString(),
      status: "ongoing",
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postPunchInShyftApiMethodType
    );

    this.postPunchInShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePunchInShyft = (responeJson: any) => {
    if (responeJson.error) {
      this.setState({ isAlreadyRunning: true });
      return;
    }

    const respone = responeJson.data;
    const shiftId = respone.attributes.shift_id;
    const activityId = respone.attributes.id;
    const shyftPunchInTime = respone.attributes.punch_in;

    let shyftStartTime = "";
    let shyftEndTime = "";

    const myShyftsList = this.state.myShyftsList.map((shift) => {
      if (shift.id === shiftId) {
        shyftStartTime = shift.startTime;
        shyftEndTime = shift.endTime;
        return { ...shift, isOngoing: true };
      }

      return shift;
    });

    this.setState({
      myShyftsList,
      activityId,
      shyftStartTime,
      shyftEndTime,
      shyftPunchInTime,
      punchedShyftId: shiftId,
    });
  };

  checkIfOngoingShyftOnLoad = () => {
    const { myShyftsList } = this.state;

    const ongoingShift = myShyftsList.find(
      (shyft) => shyft.isOngoing
    ) as TMyShyft;

    if (ongoingShift) {
      this.setState({
        shyftEndTime: ongoingShift.endTime,
        shyftStartTime: ongoingShift.startTime,
        punchedShyftId: ongoingShift.id,
      });

      this.getCurrentActivity(ongoingShift.id);
    }
  };

  checkIfOngoingShyft = () => {
    const { myShyftsList, selectedMyShyft } = this.state;
    const currentShift = myShyftsList.find(
      (shyft) => shyft.id === selectedMyShyft
    ) as TMyShyft;

    if (currentShift.isOngoing) {
      this.setState({
        shyftEndTime: currentShift.endTime,
        shyftStartTime: currentShift.startTime,
      });

      this.getCurrentActivity(currentShift.id);
    }
  };

  resetTrackingData = () => {
    this.setState({
      activityId: null,
      shyftStartTime: "",
      shyftPunchInTime: "",
      shyftPunchOutTime: "",
      shyftEndTime: "",
      punchedShyftId: null,
    });
  };

  startShyftTracker = (startTime: string, endTime: string, punchIn: string) => {
    const shiftStartTime = copyDateObjWithTime(startTime);
    const shiftEndTime = copyDateObjWithTime(endTime);
    const totalShiftTime = shiftEndTime.diff(shiftStartTime, "seconds");

    const punchInTime = moment(punchIn);
    const currentTime = moment();

    const currentHoursWorked = currentTime.diff(punchInTime, "seconds");
    this.setState({ currentHoursWorked, totalShiftTime });

    if (this.activityTimer) clearInterval(this.activityTimer);
    this.activityTimer = window.setInterval(() => {
      this.updateShytTracker();
    }, 1000);
  };

  updateShytTracker = () => {
    this.setState((prevState) => {
      if (prevState.currentHoursWorked === prevState.totalShiftTime) {
        clearInterval(this.activityTimer);
        return { currentHoursWorked: prevState.currentHoursWorked };
      }

      return {
        currentHoursWorked: prevState.currentHoursWorked + 1,
      };
    });
  };

  getCurrentHoursPercentage = (
    currentHoursWorked: number,
    totalShiftHours: number
  ) => {
    if (totalShiftHours === 0) return 0;
    return Math.floor((currentHoursWorked / totalShiftHours) * 100);
  };

  punchOutFromTheShyft = () => {
    const { activityId } = this.state;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postPunchOutShyftApiEndPoint}/${activityId}`
    );

    const header = {
      "Content-Type": configJSON.postPunchOutShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      status: "completed",
      punch_out: moment().toISOString(),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postPunchOutShyftApiMethodType
    );

    this.postPunchOutShyftAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handlePunchOutShyft = (response: any) => {
    this.setState((prevState) => {
      const shiftId = response.attributes.shift_id;
      const shyftPunchOutTime = response.attributes.punch_out;

      let shyftStartTime = "";
      let shyftEndTime = "";

      const myShyftsList = prevState.myShyftsList.map((shift) => {
        if (shift.id === shiftId) {
          shyftStartTime = shift.startTime;
          shyftEndTime = shift.endTime;

          return {
            ...shift,
            isOngoing: false,
          };
        }

        return shift;
      });

      clearInterval(this.activityTimer);

      const updatedHoursLeft = { ...prevState.nextShyftHoursLeft };

      const nextDayStartTime = copyDateObjWithTime(shyftStartTime)
        .add(1, "days")
        .format("HH:mm:ss");

      updatedHoursLeft[shiftId] =
        convertTimeStringIntoDuration(nextDayStartTime);

      if (this.shyftsTimer) clearInterval(this.shyftsTimer);

      return {
        myShyftsList,
        activityId: null,
        shyftStartTime,
        shyftEndTime,
        shyftPunchOutTime,
        nextShyftHoursLeft: updatedHoursLeft,
      };
    });

    this.shyftsTimer = window.setInterval(() => {
      this.updateTimersForNextShyt();
    }, 1000);
  };

  addTimersForNextShyft = (type: "initial" | "update") => {
    this.setState((prevState) => {
      const { myShyftsList, nextShyftHoursLeft } = prevState;
      const updatedHoursLeft = { ...nextShyftHoursLeft };

      myShyftsList.forEach((shift) => {
        if (type === "initial") {
          updatedHoursLeft[shift.id] = convertTimeStringIntoDuration(
            shift.nextShyftTime
          );
        } else {
          if (!updatedHoursLeft[shift.id]) {
            updatedHoursLeft[shift.id] = convertTimeStringIntoDuration(
              shift.nextShyftTime
            );
          }
        }
      });

      if (this.shyftsTimer) clearInterval(this.shyftsTimer);
      return { nextShyftHoursLeft: updatedHoursLeft };
    });

    this.shyftsTimer = window.setInterval(() => {
      this.updateTimersForNextShyt();
    }, 1000);
  };

  checkIfTimerShouldStop = (hoursLeftMap: TShyftTimers) => {
    for (let key in hoursLeftMap) {
      if (hoursLeftMap.hasOwnProperty(key)) {
        if (hoursLeftMap[key] !== 0) {
          return false;
        }
      }
    }

    return true;
  };

  updateTimersForNextShyt = () => {
    this.setState((prevState) => {
      const { nextShyftHoursLeft } = prevState;
      const updatedHoursLeft = { ...nextShyftHoursLeft };

      for (let key in updatedHoursLeft) {
        if (updatedHoursLeft.hasOwnProperty(key)) {
          const value = updatedHoursLeft[key];
          updatedHoursLeft[key] = value > 0 ? value - 1 : 0;
        }
      }

      const shouldStop = this.checkIfTimerShouldStop(updatedHoursLeft);
      if (shouldStop) clearInterval(this.shyftsTimer);
      return { nextShyftHoursLeft: updatedHoursLeft };
    });
  };

  onChangeMealTime = (mealTime: string) => {
    this.setState({ mealTime });
  };

  addMealBreak = () => {
    const { activityId, mealTime } = this.state;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postPunchOutShyftApiEndPoint}/${activityId}`
    );

    const header = {
      "Content-Type": configJSON.postPunchOutShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    const body = {
      activity: {
        status: "ongoing",
        meal_break: mealTime,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postPunchOutShyftApiMethodType
    );

    this.patchMealBreakAPICallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getNextShyftTime = (isOngoingShyft: boolean, shiftId: number) => {
    const { currentHoursWorked, nextShyftHoursLeft } = this.state;

    if (isOngoingShyft) {
      return convertSecondsToHrsMinsSecWithHMSFormat(currentHoursWorked);
    }

    return convertSecondsToHrsMinsSecWithHMSFormat(nextShyftHoursLeft[shiftId]);
  };

  formatPunchTime = (time: string) => {
    return time ? convertDateIntoTwelveHourFormat(time) : "-- --";
  };

  async componentDidMount() {
    const isValidShyfterUser = checkAuthorization(false, true);
    if (!isValidShyfterUser) {
      this.navigateToPage("Login");
      return;
    }
    const { page } = this.state;
    this.getAllAppliedShyfts();
    this.getAllMyShyfts({ page }, "initial");
    this.listenForScrollChanges();
  }

  getShyftCardBtnText = (shiftId: number, isOngoingShyft: boolean) => {
    const { nextShyftHoursLeft } = this.state;
    if (nextShyftHoursLeft[shiftId] < 600 && !isOngoingShyft)
      return "Proceed to Punch";
  };

  getTimerPercentage = (isOngoingShyft: boolean) => {
    const { currentHoursWorked, totalShiftTime } = this.state;

    if (isOngoingShyft) {
      return this.getCurrentHoursPercentage(currentHoursWorked, totalShiftTime);
    }

    return 0;
  };

  getPunchInTime = () => {
    const { punchedShyftId, shyftPunchInTime, selectedMyShyft } = this.state;

    return this.formatPunchTime(
      punchedShyftId === selectedMyShyft ? shyftPunchInTime : ""
    );
  };

  getPunchOutTime = () => {
    const { punchedShyftId, shyftPunchOutTime, selectedMyShyft } = this.state;

    return this.formatPunchTime(
      punchedShyftId === selectedMyShyft ? shyftPunchOutTime : ""
    );
  };

  toggleMessagePanel = (isMessageOverlayVisible: boolean) => {
    this.setState({ isMessageOverlayVisible, isWorkSummaryVisible: false });
  };

  closeWorkSummaryPanel = (isWorkSummaryVisible: boolean) => {
    this.setState({ isWorkSummaryVisible });
  };

  setWorkSummaryData = (workSummary: any) => {
    this.setState({ workSummary, isWorkSummaryVisible: true });
  };

  closeAlreadyRunningModal = () => {
    this.setState({ isAlreadyRunning: false });
  };

  componentDidUpdate(_: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.page !== prevState.page) {
      const { page } = this.state;
      this.getAllMyShyfts({ page }, "next");
    }

    if (this.state.myShyftsList.length !== prevState.myShyftsList.length) {
      this.checkIfOngoingShyftOnLoad();
    }

    if (
      this.state.selectedMyShyft &&
      this.state.selectedMyShyft !== prevState.selectedMyShyft
    ) {
      const { selectedMyShyft, shyftPunchOutTime } = this.state;
      this.getActivityRecords(selectedMyShyft, false);
      this.checkIfOngoingShyft();

      if (shyftPunchOutTime) {
        this.resetTrackingData();
      }
    }

    if (
      this.state.activityId &&
      this.state.shyftStartTime &&
      this.state.shyftEndTime &&
      this.state.shyftPunchInTime &&
      this.state.activityId !== prevState.activityId
    ) {
      const { shyftStartTime, shyftEndTime, shyftPunchInTime } = this.state;
      this.startShyftTracker(shyftStartTime, shyftEndTime, shyftPunchInTime);
    }
  }

  async componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
    if (this.activityTimer) clearInterval(this.activityTimer);
  }

  handleOpenFilterForCurrentShyft = () => {
    this.setState({
      isShowActivityFilter: true,
    });
  };

  closeFilter = () => {
    this.setState({ ...this.state, isShowActivityFilter: false });
  };

  applyFilter = (
    filterType: string,
    filterDateFrom: string,
    filterDateTo: string
  ) => {
    const filterData = { filterType, filterDateFrom, filterDateTo };
    this.getActivityRecords(this.state.selectedMyShyft, true, filterData);
    this.closeFilter();
  };
}
