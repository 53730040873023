import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { RefObject, createRef } from "react";
import {
  TAPIShyftDetails,
  constructURL,
  formatShiftsListResponse,
  TRatings,
  formatShyftDetails,
  checkAuthorization,
} from "../../../components/src/Utilities";

import {
  TShyftItem,
  TPagination,
  TShyftDetails,
  TQueryParams,
  TShyftRequest,
} from "./ExploreShyftsController.web";
import { setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  page: number;
  search: string;
  totalPages: number;
  totalShyfts: number;
  isLoading: boolean;
  savedShyftsList: TShyftItem[];
  savedShyftDetails: TShyftDetails | null;
  selectedShyft: number | null;
  request?: TShyftRequest;
  isShyftApplied: boolean;
  profileCompletion: number;
  saveShyftId?: number;
  isRegisterationPending: boolean;
}

interface SS {
  id: any;
}

export default class SavedShyftController extends BlockComponent<Props, S, SS> {
  observer: IntersectionObserver | null = null;
  elementRef: RefObject<HTMLDivElement>;
  skipInitialCall = true;

  getUserApiCallId: string = "";
  getSavedShyftsAPICallId: string = "";
  getNextSavedShyftsAPICallId: string = "";
  getShyftDetailsAPICallId: string = "";
  postApplyForShyftAPICallId: string = "";
  postWithdrawFromShyftAPICallId: string = "";
  deleteSaveShyftAPICallId: string = "";
  postSaveShyftAPICallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.elementRef = createRef<HTMLDivElement>();

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      page: 1,
      totalPages: 1,
      totalShyfts: 0,
      search: "",
      isLoading: true,
      savedShyftsList: [],
      selectedShyft: null,
      savedShyftDetails: null,
      profileCompletion: 0,
      isShyftApplied: false,
      isRegisterationPending: false,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  viewExploreShyfts = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ExploreShyftsPage"
    );
    this.send(msg);
  };

  getUserProfile() {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserApiCallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCurrentUserProfileEndPoint
    );

    const headerData = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  }

  setUserProfileCompletion(profileCompletion: number) {
    this.setState({
      profileCompletion,
    });
  }

  getSavedShifts({ search, page }: TQueryParams, type: "initial" | "next") {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));

    if (type === "initial") {
      this.getSavedShyftsAPICallId = request.messageId;
    } else {
      this.getNextSavedShyftsAPICallId = request.messageId;
    }

    const endpoint = constructURL({
      endpoint: configJSON.getSavedShyftApiEndPoint,
      search,
      page,
    });

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    const header = {
      "Content-Type": configJSON.getSavedShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSavedShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  }

  listenForScrollEnd() {
    const callback: IntersectionObserverCallback = (elements) => {
      if (elements[0].isIntersecting) {
        if (this.skipInitialCall) {
          this.skipInitialCall = false;
          return;
        }

        if (this.state.totalPages > this.state.page) {
          this.setState((prevState) => ({ page: prevState.page + 1 }));
        }
      }
    };

    this.observer = new IntersectionObserver(callback);
    if (this.elementRef.current) this.observer.observe(this.elementRef.current);
  }

  handleSavedShiftsList(
    shifts: TAPIShyftDetails[],
    pagination: TPagination,
    type: "initial" | "next"
  ) {
    const savedShyftsList = formatShiftsListResponse(shifts);
    const totalPages = Math.ceil(
      pagination.total_records / pagination.records_per_page
    );

    if (type === "initial") {
      this.setState({
        totalPages,
        savedShyftsList,
        selectedShyft: this.state.selectedShyft?this.state.selectedShyft:savedShyftsList[0]?.id,
        totalShyfts: pagination.total_records,
        isLoading: false,
      });
    } else {
      this.setState((prevState) => ({
        totalPages,
        savedShyftsList: [...prevState.savedShyftsList, ...savedShyftsList],
      }));
    }
  }

  getShiftsDetails(shyftId: number) {
    const header = {
      "Content-Type": configJSON.getShiftDetailsApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getShyftDetailsAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getShiftDetailsApiEndPoint}/${shyftId}`
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getShiftDetailsApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  }

  handleShyftDetailsData(
    shift: TAPIShyftDetails,
    ratings: TRatings,
    request?: TShyftRequest
  ) {
    const savedShyftDetails = formatShyftDetails(shift, ratings);
    this.setState({ savedShyftDetails, request });
  }

  applyForTheShyft = (shiftId: number) => {
    if (this.state.profileCompletion < 100) {
      this.setState({ isRegisterationPending: true });
      return;
    }

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postApplyForShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postApplyShyftApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
          status: "pending",
        },
      })
    );

    const header = {
      "Content-Type": configJSON.postApplyShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApplyShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  withdrawFromTheShyft = (shiftId: number) => {
    const header = {
      "Content-Type": configJSON.postWithdrawShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postWithdrawFromShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWithdrawShyftApiEndPoint
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        request: {
          shift_id: shiftId,
        },
      })
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postWithdrawShyftApiMethodType
    );

    runEngine.sendMessage(request.id, request);
  };

  setShyftRequestData = (response?: any) => {
    if (response) {
      this.setState({
        isShyftApplied: true,
        request: {
          id: response.attributes.id,
          status: response.attributes.status,
          removal_reason: response.attributes.removal_reason,
          updated_at: response.attributes.updated_at,
          created_at: response.attributes.created_at,
          shift_id: response.attributes.id,
          worker_id: response.attributes.worker_id,
        },
      });
    } else {
      this.setState({ request: undefined });
    }
  };

  unSaveShyft = (id: number) => {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteSaveShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteSaveShyftApiEndPoint}/${id}`
    );

    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteSaveShyftApiMethodType
    );

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(request.id, request);
  };

  saveShyft = (id: number) => {
    const request = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.postSaveShyftAPICallId = request.messageId;

    request.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postSaveShyftApiEndPoint
    );

    const header = {
      "Content-Type": configJSON.deleteSaveShyftApiContentType,
      token: localStorage.getItem("authToken"),
    };

    request.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    request.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postSaveShyftApiMethodType
    );

    request.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify({
      saved_shift: {
        shift_id: id,
      },
    }))

    this.setState({ saveShyftId: id });
    runEngine.sendMessage(request.id, request);
  };

  handleSaveShyft = (shiftId: number, isSaved: boolean) => {
    if (isSaved) {
      this.unSaveShyft(shiftId);
    } else {
      this.saveShyft(shiftId);
    }
  };

  setSavedShiftList = (isSave:boolean) => {
    const {search,page, savedShyftsList, saveShyftId, selectedShyft, savedShyftDetails } = this.state;
    this.getSavedShifts({ search, page }, "initial");
    const currentIndex = savedShyftsList.findIndex(
      (item) => item.id == saveShyftId
    );

    const updatedList = savedShyftsList.filter(
      (shyft) => shyft.id !== saveShyftId
    );

    const updatedShyftDetails = {...savedShyftDetails!};
    const detailsShiftIndex = updatedShyftDetails.businessShifts.findIndex(shift => shift.id === saveShyftId);

    if (detailsShiftIndex !== -1) {      
      updatedShyftDetails.businessShifts[detailsShiftIndex].saved = isSave;
    }

    if (!updatedList.length) {
      this.setState({
        savedShyftsList: [],
        savedShyftDetails: null,
        selectedShyft: null,
      });
    }

    const newItem = updatedList[currentIndex];
    const prevItem = updatedList[currentIndex - 1];

    this.setState({
      savedShyftsList: updatedList,
      selectedShyft: saveShyftId === selectedShyft? newItem?.id ?? prevItem.id:selectedShyft,
    });
  };

  onSelectShift = (shyftId: number) =>
    this.setState({ selectedShyft: shyftId });

  closeApplyShiftModal = () => {
    this.setState({ isShyftApplied: false });
  };

  closeRegistrationModal = () => {
    this.setState({ isRegisterationPending: false });
  };

  handleCompleteNowButtonRedirection = () => {
    this.closeRegistrationModal();
    this.navigationToPathInSavedShyft("EditShyfterProfilePage");
  }

  onViewBusinessProfile = (businessId: number) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BusinessProfile"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    setStorageData("businessId", businessId);
    setStorageData(
      "visitedFrom",
      JSON.stringify({
        title: "Saved Shyfts",
        link: "SavedShyft'",
        active: false,
      })
    );
    this.send(msg);
  };

  navigationToPathInSavedShyft = (path: string) => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), path);

    this.send(msg);
  };

  async componentDidMount() {
    const isWorkerUser = checkAuthorization(false, true);
    if (!isWorkerUser) {
      this.navigationToPathInSavedShyft("Login");
      return;
    }
    const { search, page } = this.state;
    this.getUserProfile();
    this.getSavedShifts({ search, page }, "initial");
    this.listenForScrollEnd();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>): void {
    if (this.state.search !== prevState.search) {
      const { search, page } = this.state;
      this.getSavedShifts({ search, page }, "initial");
    }

    if (this.state.page !== prevState.page) {
      const { search, page } = this.state;
      this.getSavedShifts({ search, page }, "next");
    }

    if (
      this.state.selectedShyft &&
      this.state.selectedShyft !== prevState.selectedShyft
    ) {
      this.getShiftsDetails(this.state.selectedShyft);
    }
  }

  async componentWillUnmount() {
    if (this.observer) this.observer.disconnect();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const res = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (res?.errors && res?.errors?.length > 0 && res?.errors[0]?.token) {
        this.navigationToPathInSavedShyft("Login");
        return;
      }
      this.handleShyftResponse(apiRequestCallId, res);
    }
  }

  handleShyftResponse = (apiRequestCallId:any, res: any)=>{
    if (this.postWithdrawFromShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData();
    }

    if (this.deleteSaveShyftAPICallId === apiRequestCallId) {
      this.setSavedShiftList(false);
    }

    if(this.postSaveShyftAPICallId === apiRequestCallId){
      this.setSavedShiftList(true);
    }

    if (this.getUserApiCallId === apiRequestCallId) {
      this.setUserProfileCompletion(res.data.attributes.profile_completion);
    }

    if (this.postApplyForShyftAPICallId === apiRequestCallId) {
      this.setShyftRequestData(res.data);
    }

    if (this.getNextSavedShyftsAPICallId === apiRequestCallId) {
      this.handleSavedShiftsList(
        res.saved_shifts.data,
        res.pagination_details,
        "next"
      );
    }

    if (this.getShyftDetailsAPICallId === apiRequestCallId) {
      this.handleShyftDetailsData(
        res.shyft_details.data,
        res.shyft_details.meta.ratings_data,
        res.shyft_details.meta.request
      );
    }

    if (this.getSavedShyftsAPICallId === apiRequestCallId) {
      this.handleSavedShiftsList(
        res.saved_shifts.data,
        res.pagination_details,
        "initial"
      );
    }
  }
}
