import React, { useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  makeStyles,
} from "@material-ui/core";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    paddingBottom: "16px",
    position: "relative",
    "& input": {
      padding: "4px 18px",
      margin: "10px 0px",
      borderLeft: "1px solid #E1E6EC",
      width: "80px",
      textOverflow: "ellipsis",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
      paddingLeft: 42
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
      paddingLeft: 0
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
      paddingLeft: 0
    },
    "& .dropdownWrapper": {
      position: "absolute",
      right: 20,
      top: 16,
      fontSize: 12,
      color: "#6f7c8e"
    },
    " & .MuiSelect-select.MuiSelect-select": {
      paddingRight: 20,
      backgroundColor: "transparent",
      fontSize: 13,
      color: "#6f7c8e",
      fontWeight: 500
    },
    " & .MuiInputBase-input": {
      height: "100%"
    },
  },
}));

interface ITextPropWithDropdownProps {
  value: string | number;
  label: string;
  name: string;
  error: boolean;
  maxLength?: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomInputWithDropdown: React.FC<ITextPropWithDropdownProps> = ({
  value,
  label,
  error,
  name,
  maxLength,
  handleChange,
}) => {
  const classes = useStyles();
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    if (value && value.toString().length > 0) {
      setShrink(true);
    }
  }, [value]);

  return (
    <div className={classes.roundedTextField}>
      <TextField
        fullWidth
        label={label}
        name={name}
        onFocus={() => setShrink(true)}
        onBlur={(e) => setShrink(!!e.target.value)}
        InputLabelProps={{ shrink }}
        inputProps={{ maxLength: maxLength }}
        variant="outlined"
        value={value}
        error={error}
        onChange={handleChange}
        data-test-id={name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AttachMoneyIcon className="" />
            </InputAdornment>
          ),
        }}
      />
      <span className="dropdownWrapper">
        Per Hr
      </span>
    </div>
  );
};

export default CustomInputWithDropdown;
