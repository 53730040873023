import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setAboutLoading } from "../../../components/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  expandAccordian: any;
  isOpenSignUpDialog: boolean;
}

interface SS {
  id: any;
}

export default class FAQController extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      expandAccordian: { 1: true, 2: false },
      isOpenSignUpDialog: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", JSON.stringify(message));
  }

  handleAccordionToggle = (id: number) => {
    this.setState((prevState) => ({
      expandAccordian: {
        ...prevState.expandAccordian,
        [id]: !prevState.expandAccordian[id],
      },
    }));
  };

  onNavigateFromFaq = (pathString: string) => {
    const navigateFromFaqMsg: Message = new Message(getName(MessageEnum.NavigationMessage));
    navigateFromFaqMsg.addData(getName(MessageEnum.NavigationTargetMessage), pathString);
    navigateFromFaqMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateFromFaqMsg);
  }

  toggleSignUpForFaq = () => {
    this.setState({
      isOpenSignUpDialog: !this.state.isOpenSignUpDialog
    });
  };

  redirectToSignUpPage = (pathVal: string) => {
    this.setState({ isOpenSignUpDialog: false });
    this.onNavigateFromFaq(pathVal);
  };

  onAboutClick = () => {
    setAboutLoading(true);
    this.onNavigateFromFaq("Home");
  }
}
