import React from "react";
import {
  InputAdornment,
  TextField,
  makeStyles,
  Select,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    paddingBottom: "16px",
    position: "relative",
    "& input": {
      padding: "4px 18px",
      margin: "10px 0px",
      borderLeft: "1px solid #E1E6EC",
      width: "120px",
      textOverflow: "ellipsis",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& .dropdownWrapper": {
      position: "absolute",
      right: 20,
      top: 8,
    },
    "& .hoursDropdown": {
      position: "absolute",
      right: 160,
      top: 8,
    },
    " & .minutesDropdown": {
      position: "absolute",
      right: 136,
      top: 8,
    },
    " & .colon": {
      position: "absolute",
      right: 169,
      top: 10,
      fontSize: 20,
    },
    " & .MuiSelect-select.MuiSelect-select": {
      paddingRight: 10,
      paddingLeft: 10,
      backgroundColor: "transparent",
      fontSize: 13,
      fontWeight: 500
    },
    " & .timeSelect .MuiSelect-select.MuiSelect-select": {
      paddingRight: 16,
      backgroundColor: "transparent",
      fontSize: 13,
      fontWeight: 500,
      color: "#0a0412"
    },
    " & .timeFormatSelect .MuiSelect-select.MuiSelect-select": {
      paddingRight: 20,
      backgroundColor: "transparent",
      fontSize: 13,
      fontWeight: 500,
      color: "#6f7c8e"
    },
    " & .MuiInputBase-input": {
      height: "100%"
    },
    " & .watchIcon": {
      color: "black",
    },
  },
}));

const minuteOptions = () => {
  const minutesArray = [];
  for (let i = 0; i < 60; i++) {
    const minute = i < 10 ? `0${i}` : `${i}`;
    minutesArray.push({ name: minute, value: minute });
  }
  return minutesArray;
};

const hoursOptions = () => {
  const hoursArray = [];
  for (let i = 0; i <= 12; i++) {
    const hour = i < 10 ? `0${i}` : `${i}`;
    hoursArray.push({ name: hour, value: hour });
  }
  return hoursArray;
};

interface ITimeSelectionProps {
  error: boolean;
  selectedValue?: number | string;
  options: any[];
  minute: string;
  hour: string;
  handleMinuteChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleHourChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  handleDropdownChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const CustomTimePickerDropdown: React.FC<ITimeSelectionProps> = ({
  error,
  selectedValue,
  options,
  minute,
  hour,
  handleHourChange,
  handleMinuteChange,
  handleDropdownChange,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.roundedTextField}>
      <TextField
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccessTimeIcon className="watchIcon" />
            </InputAdornment>
          ),
          style: {
            borderColor: "inherit",
            pointerEvents: "none",
            color: "inherit",
            backgroundColor: "inherit"
          }
        }}
        error={error}
      />
      <span className="hoursDropdown">
        <Select
          native
          value={hour}
          disableUnderline
          inputProps={{ IconComponent: () => null }}
          className="timeSelect"
          onChange={handleHourChange}
          data-test-id="hourSelect"
        >
          {hoursOptions().map((item) => (
            <option key={item.value + "-hour-option"} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </span>
      <span className="colon">:</span>
      <span className="minutesDropdown">
        <Select
          native
          value={minute}
          className="timeSelect"
          disableUnderline
          onChange={handleMinuteChange}
          inputProps={{ IconComponent: () => null }}
          data-test-id="minSelect"
        >
          {minuteOptions().map((item) => (
            <option key={item.value + "-minute-option"} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </span>
      <span className="dropdownWrapper">
        <Select
          native
          value={selectedValue}
          disableUnderline
          className="timeFormatSelect"
          IconComponent={ExpandMoreIcon}
          onChange={handleDropdownChange}
          data-test-id="drpdwnSelect"
        >
          {options.map((item) => (
            <option key={item.value + "-time-option"} value={item.value}>
              {item.name}
            </option>
          ))}
        </Select>
      </span>
    </div>
  );
};

export default CustomTimePickerDropdown;
