import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import Footer from "../../../components/src/Footer.web";
import PastShyftController, { Props } from "./PastShyftController.web";
import PastShyftActivity from "../../../components/src/PastShyftActivity.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
import Spinner from "../../../components/src/Spinner";
import { FailedIcon, comment, user } from "./assets";
import FilterRecords from "../../filteritems/src/FilterRecords.web";
import SortFilter from "../../filteritems/src/SortFilter.web";

export default class PastShyftPage extends PastShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      pastShyftsList,
      selectedPastShyft,
      activityRecords,
      summary,
      ratingSucess,
      request,
      isLoading,
      isShowPastActivityFilter,
      pastShyftRating,
      isPastWorkSummaryVisible,
      pastWorkSummary
    } = this.state;

    const currentShift = pastShyftsList.find(
      (shift) => shift.id === selectedPastShyft
    );
    let ratingsData;

    if (currentShift) {
      ratingsData = {
        shiftId: currentShift.id,
        title: currentShift.shyft,
        subtitle: currentShift.shyftPlace,
        hotelOrRestaurantName: currentShift.hotelOrRestaurantName,
      };
    }


    const options = pastShyftsList.map((shift) => [
      {
        menuIcon: user,
        menuText: "View Profile",
        onClick: () => this.onViewBusinessProfile(shift.businessId),
      },
      {
        menuIcon: comment,
        menuText: "Message",
        onClick: () => this.goToPagePastShyft(shift.businessId),
      },
    ]);

    return (
      <>
        <LandingPageHeader {...this.props} isShowDetails={false} isUpdateUser={false}/>
        {isLoading && <Spinner />}
        {pastShyftsList.length === 0 && !isLoading && (
          <Container>
            <Box marginTop={"10%"}>
              <NoDataFound
                heading="No Shyfters Found!"
                paragraph={
                  "No Past Shyfts found, get jobs and complete the shyfts first"
                }
                imgUrl={FailedIcon}
                primaryBtnText="Explore Jobs"
                primaryBtnClick={this.viewExploreShyfts}
                data-test-id="no-shyft-data-card"
              />
            </Box>
          </Container>
        )}
        <Box marginTop={"5%"} width={"100%"}>
          <Container maxWidth="md">
            {pastShyftsList.length !== 0 && (
              <>
                <SearchWithFilterandLocation
                  isSortIcon={true}
                  handleSerchModal={this.onSearchPastShyfts}
                  placeholder="Search for shyfts..."
                  handleSortByFilterModal={this.handleSortByFilterModal}
                />
                <Grid container spacing={2}>
                  <>
                    <Grid item xs={6}>
                      <Box data-testid="shyfts-srcoll-area">
                        {pastShyftsList.map((shift, index) => {
                          return (
                            <ShyftCard
                              {...shift}
                              key={shift.id}
                              onCardClick={() =>
                                this.onSelectPastShift(shift.id,shift.businessId)
                              }
                              isMenu
                              isActiveShyft={shift.id === selectedPastShyft}
                              isPasstShyft={true}
                              options={options[index]}
                            />
                          );
                        })}
                        <div ref={this.elementRef}></div>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box margin={"15px 0px"}>
                        <PastShyftActivity
                          shyftEndDate={currentShift?.shyftEndDate}
                          ratingsData={ratingsData}
                          summaryData={summary}
                          activityData={activityRecords}
                          isPastActivity={true}
                          isRatingSuccess={ratingSucess}
                          onSubmitRating={this.onSubmitRatings}
                          request={request}
                          isShyftEndedSuccess={
                            request?.removal_reason ? false : true
                          }
                          handleFilterRecords={() => this.handleOpenFilterForPastShyft()}
                          isPastShyftRated={
                            (pastShyftRating === null || pastShyftRating === undefined) ?
                             false : true
                          }
                          pastShyftRatingScore={
                            pastShyftRating && pastShyftRating?.score ?
                             pastShyftRating.score : 0
                          }
                          isStatusModalOpen={isPastWorkSummaryVisible}
                          activityUpdateStatusData={pastWorkSummary}
                          handleUpdateStatusCard={this.setPastWorkSummaryData}
                          updateStatusModalSubmitButton={this.closePastWorkSummaryPanel}
                          closeUpdateStatusModal={this.closePastWorkSummaryPanel}
                          updateStatusCtaText="Okay"
                          goToPage={()=>this.goToPagePastShyft(this.state.receiverId)}
                        />
                      </Box>
                    </Grid>
                  </>
                </Grid>
              </>
            )}
          </Container>
          <FilterRecords
            data-test-id="myShyftFilterRecordsId"
            id=""
            navigation={this.props.navigation}
            isOpenFilterDrawer={isShowPastActivityFilter}
            onCloseFilter={this.closeFilterForPastShyft}
            applyFilter={this.applyFilterForPastShyft}
          />
        </Box>
        <Footer />

        <SortFilter
            data-test-id="myShyftSortByFilterId"
            isOpen={this.state.isOpenSortByFilter}
            handleClose={this.handleSortByFilterModal}
            activeFilter={"default"}
            applyFilter={this.handleApplySortByFilter}
            isFromPastShyft={true}
          />
      </>
    );
  }
}
