import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: "24px",
    padding: "12px 24px",
    fontSize: "14px",
    color: "#ff375b",
    lineHeight: "18px",
    textAlign: "center",
    backgroundColor: "rgba(255, 55, 91, 0.05)",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#f5d9de",
    },
  },
  label: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
  },
  icon: {},
}));

interface IButtonProps {
  children: string;
  isOutlined?: boolean;
  disabled?: boolean;
  onClick: (e: any) => void;
  isFullWidth?: boolean;
  iconSrc?: string;
}

const DangerButton: React.FC<IButtonProps> = ({
  children,
  disabled = false,
  isOutlined = false,
  onClick,
  isFullWidth = false,
  iconSrc,
}) => {
  const classes = useStyles();

  return (
    <Button
      fullWidth={isFullWidth}
      variant={isOutlined ? "outlined" : "contained"}
      classes={{
        root: classes.button,
        label: classes.label,
      }}
      onClick={onClick}
      disabled={disabled}
      data-test-id="danger-button"
    >
      {iconSrc && <img src={iconSrc} alt="icon" className={classes.icon} />}
      {children}
    </Button>
  );
};

export default DangerButton;
