import React from "react";
import { Container, Box, Typography, Grid, Divider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import EmployerManageAccountController, {
  Props,
} from "./EmployerManageAccountController.web";

import DangerButton from "../../../components/src/DangerButton";
import DeleteAccountPopUp from "../../../components/src/DeleteAccountPopUp.web";
import EmployerForms from "./EmployerForms.web";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import SubmitSuccessPopUp from "../../../components/src/SubmitSuccessPopUp";
import { UpdateProfileImage } from "../../../components/src/UpdateProfileImage";

import { breadCrumEmployerManageAccountPath } from "../../../components/src/Utilities";
import { trash } from "./assets";
import ContactUsPopup from "../../../components/src/ContactUsPopUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export default class EmployerManageAccount extends EmployerManageAccountController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      generalTabs,
      otherTabs,
      profile,
      activeTab,
      isSuccessPopUpVisible,
      isDeletePopUpVisible,
      isUpdatingUser,
      isDeleteAccountError,
      deleteAccountErrorMessage
    } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          breadCrumsData={breadCrumEmployerManageAccountPath}
          currentPage="Manage Account"
          isShowDetails={true}
          isUpdateUser={isUpdatingUser}
        />
        <Box marginTop={"10%"}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Box marginY={"10px"}>
                  <Typography>General Settings</Typography>
                </Box>
                <ProfileQuickLink
                  linkData={generalTabs}
                  getActiveTab={this.changeTab}
                />

                <Box width={"100%"} marginY={"10px"}>
                  <Typography>Other</Typography>
                </Box>

                <ProfileQuickLink
                  linkData={otherTabs}
                  getActiveTab={this.changePage}
                  isEdit={true}
                />

                <Box width={"100%"} marginY={"15px"}>
                  <Divider />
                </Box>

                <DangerButton
                  isFullWidth
                  iconSrc={trash}
                  onClick={this.openDeletePopUp}
                >
                  Delete Account
                </DangerButton>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box>
                  {profile && activeTab === 1 && (
                    <>
                      <Box marginY={"10px"}>
                        <Typography>Edit & Update Profile</Typography>
                      </Box>
                      <UpdateProfileImage
                        userName={profile.name}
                        imageSrc={profile.imageURL}
                        place={profile.tag}
                        onUploadFile={this.uploadProfileImage}
                      />
                    </>
                  )}
                  {profile && (
                    <EmployerForms
                      profile={profile}
                      activeTab={activeTab}
                      onSubmit={this.onSubmitForm}
                      onUploadDocs={this.uploadOfficialDocs}
                      onToggleNotifications={this.onTogglePushNotifications}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
          {isSuccessPopUpVisible && (
            <SubmitSuccessPopUp
              isUpdateProfile={activeTab === 1}
              isUpdateDocs={activeTab === 2}
              open={isSuccessPopUpVisible}
              isFromOngoingActivity
              backToLogin={this.closeSuccessPopUp}
            />
          )}
          {isDeletePopUpVisible && (
            <DeleteAccountPopUp
              open={isDeletePopUpVisible}
              onConfirm={this.onDeleteAccount}
              onCancel={this.closeDeletePopUp}
            />
          )}

          {isDeleteAccountError && (
            <ContactUsPopup 
              open={isDeleteAccountError}
              handleCancel={this.closeDeleteErrorPopup}
              isSuccess={false}
              isApiError={true}
              isDeleteAccountError={isDeleteAccountError}
              deleteErrMsg={deleteAccountErrorMessage} 
            />
          )}
          
        </Box>
      </ThemeProvider>
    );
  }
}
