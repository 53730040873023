import React from "react";

import RecommendedShyftsController, {
  Props,
} from "./RecommendedShyftsController.web";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import SearchWithFilterandLocation from "../../../components/src/SearchWithFilterandLocation";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ShyftCard from "../../../components/src/ShyftCard";
import { FailedIcon, SuccessIcon } from "./assets";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import { breadCrumSubsPath, checkIfUserAppliedeForShyft } from "../../../components/src/Utilities";
import GetLocationModal from "../../../components/src/GetLocationModal.web";
import ShyfterFeedbackModal from "../../../components/src/ShyfterFeedbackModal.web";
import NoDataFound from "../../../components/src/NoDataFound.web";

export default class RecomendedShyftsPage extends RecommendedShyftsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      recommendedList,
      selectedShyft,
      shyftDetails,
      request,
      isShyftApplied,
      isRegisterationPending,
      isLocationModalOpen,
      totalShyfts,
      isLocationSearching,
      locationModalDataBreadCrum,
      isCountrySelectedInModal,
      isStateSelectedInModal,
      isCitySelectedInModal,
      locationRecommendsData,
      searchedLocationData,
      userCity
    } = this.state;

    return (
      <>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Recommended Shyfts"
          breadCrumsData={breadCrumSubsPath}
          isShowDetails={false}
          isUpdateUser={false}
        />
        <Box width={"100%"} marginTop="8%">
          <Container maxWidth="md">
            <SearchWithFilterandLocation
              showFilterText={true}
              handleSerchModal={this.onSearchShifts}
              islocation={true}
              handleLoctionModal={this.openLocationModal}
              handleSearchFilter={this.handleRecomendedFilterShifts}
              cityLocation={userCity}
            />

            {recommendedList && recommendedList.length === 0 &&
              <Container>
                <Box marginTop="4%">
                  <NoDataFound
                    heading="No Record Found!"
                    paragraph={
                      "No Record Found for the Searched Shyft, Hotel or Restaurant."
                    }
                    imgUrl={FailedIcon}
                  />
                </Box>
              </Container>
            } 

            {totalShyfts !== 0 && (
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                  marginTop: "10px",
                }}
              >{`Total ${totalShyfts} Shyfts`}</Typography>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6}>
                <Box>
                  {recommendedList.map((item) => {
                    return (
                      <ShyftCard
                        key={item.id}
                        {...item}
                        onCardClick={() => this.onSelectShift(item.id)}
                        onSaveShyft={() =>
                          this.handleSaveShyft(item.id, item.saved)
                        }
                        isActiveShyft={item.id === selectedShyft}
                        showBookMark={true}
                      />
                    );
                  })}
                  <div ref={this.elementRef}></div>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Box
                  margin={"15px 0px"}
                  style={{ position: "sticky", top: "20%" }}
                >
                  {shyftDetails && totalShyfts !== 0 && (
                    <ShyfterDetailsCard
                      hideEmployer
                      onSaveShyft={(id, saved) => this.handleSaveShyft(id, saved)}
                      key={shyftDetails.id}
                      {...shyftDetails}
                      shyftStatus={request ? request.status : undefined}
                      isAppliedShyft={checkIfUserAppliedeForShyft(request)}
                      onWithdrawShift={() =>
                        this.withdrawFromRecommendedShyft(shyftDetails.id)
                      }
                      onApplyShift={() =>
                        this.applyForRecommendedShyft(shyftDetails.id)
                      }
                      showOverviewSubtext={false}
                      onViewProfile={() => {
                        this.viewBusinessProfile(shyftDetails.businessId);
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>

            <GetLocationModal
              data-test-id="getLocationModalIdInRecommends"
              open={isLocationModalOpen}
              closeModal={this.closeLocationModal}
              handleCurrentLocation={this.getCurrentLocationForModal}
              locationData={!isLocationSearching ? locationRecommendsData : searchedLocationData}
              isSearchingLocation={isLocationSearching}
              locationBreadCrumData={locationModalDataBreadCrum}
              fetchCountries={this.getCountriesForRecommends}
              fetchStateByCountry={this.getStatesData}
              fetchCitiesByCountryAndState={this.getCitiesData}
              isCountrySelected={isCountrySelectedInModal}
              isStateSelected={isStateSelectedInModal}
              isCitySelected={isCitySelectedInModal}
              handleCitySelection={this.handleLocationWhenCitySelected}
              handleSearchForLocation={this.handleSearchingForLocationData}
              handleAllStatesBreadCrum={this.handleLocationBreadCrumAllStatesInRecommend}
              handleUpdateSearchedLocation={this.handleUpdateSearchedLocationInRecommend}
            />

            <ShyfterFeedbackModal
              data-test-id="recommend-registration-pending-modal"
              title="Registration Pending"
              isOpen={isRegisterationPending}
              onClose={this.closePendingRegistrationModal}
              secondaryButtonText="Complete Now"
              secondaryButtonOnClick={this.handleCompleteNowButnRedirection}
              description="Your registeration is pending. Please complete your registration to apply for a shyft in any hotel and restaurant."
              imageSrc={SuccessIcon}
              isProfileCompletion={true}
              profileCompletionPercent={this.state.profileComplition}
            />

            <ShyfterFeedbackModal
              isOpen={isShyftApplied}
              title="Applied Successfully"
              imageSrc={SuccessIcon}
              description="This shyft has been applied successfully, Explore more shyfts or you can check the status of this shyft."
              primaryButtonText="Explore More"
              secondaryButtonText="View Status"
              primaryButtonOnClick={this.closeApplyShiftModal}
              secondaryButtonOnClick={this.closeApplyShiftModal}
              onClose={this.closeApplyShiftModal}
            />
          </Container>
        </Box>
      </>
    );
  }
}
