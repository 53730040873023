// Customizable Area Start
import React from "react";
import { createTheme,  ThemeProvider } from "@material-ui/core/styles";
import TermsAndConditionWebController, { Props } from "./TermsAndConditionWebController.web";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";
import CommonTermsAndPolicy from "../../../components/src/CommonTermsAndPolicy.web";


const theme = createTheme({
  palette: {
    primary: {
      main: "#f7faff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});



export default class TermsAndConditionWeb extends TermsAndConditionWebController {
  constructor(props: Props) {
    super(props);
    
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader 
          {...this.props} isBreadcrumbs={true} breadCrumsData={this.state.tAndCBreadCrumPath} 
          currentPage={this.state.currentPage} isShowDetails={this.state.userRoleType==="business" ? true: false}
          isUpdateUser={false}
        />
          <CommonTermsAndPolicy 
            title1={"Terms of Use for"} 
            title2={this.state.subTitle} 
            renderData={this.state.termsAndConditionApiResponseData}
            isLoading={this.state.loading}
            apiError={this.state.tAndCApiError}
          />
        <Footer />
      </ThemeProvider>
    );
  }
}

// Customizable Area End
