Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.phoneNumberOptVarifyUrl = "account_block/verify_now?phone_number=";
exports.verifyOTP = "account_block/verify_otp";
exports.phoneNumberOptVarifyContentType = "application/json";
exports.phoneNumberOptVarifyMethodType = "GET";

exports.signUpUrl = "account_block/users";
exports.signUpContentType = "multipart/form-data";
exports.signUpMethodType = "POST";
exports.hotelList = [
  { name: "Luxury", value: "luxury"},
  { name: "Economy", value: "economy"},
  { name: "Mid Scale", value: "midscale"},
  { name: "Resort", value: "resort"},
];
exports.restaurantList = [
  { name: "Quick Serve", value: "quick_serve"},
  { name: "Casual", value: "casual"},
  { name: "Up Scale", value: "upscale"},
  { name: "Fine Dining", value: "fine_dining"},
];
exports.dlStateList = [
  { name: "Alabama", value: "Alabama" },
  { name: "Alaska", value: "Alaska" },
  { name: "Arizona", value: "Arizona" },
  { name: "Arkansas", value: "Arkansas" },
  { name: "California", value: "California" },
  { name: "Colorado", value: "Colorado" },
  { name: "Connecticut", value: "Connecticut" },
  { name: "Delaware", value: "Delaware" },
  { name: "Florida", value: "Florida" },
  { name: "Georgia", value: "Georgia" },
  { name: "Hawaii", value: "Hawaii" },
  { name: "Idaho", value: "Idaho" },
  { name: "Illinois", value: "Illinois" },
  { name: "Indiana", value: "Indiana" },
  { name: "Iowa", value: "Iowa" },
  { name: "Kansas", value: "Kansas" },
  { name: "Kentucky", value: "Kentucky" },
  { name: "Louisiana", value: "Louisiana" },
  { name: "Maine", value: "Maine" },
  { name: "Maryland", value: "Maryland" },
  { name: "Massachusetts", value: "Massachusetts" },
  { name: "Michigan", value: "Michigan" },
  { name: "Minnesota", value: "Minnesota" },
  { name: "Mississippi", value: "Mississippi" },
  { name: "Missouri", value: "Missouri" },
  { name: "Montana", value: "Montana" },
  { name: "Nebraska", value: "Nebraska" },
  { name: "Nevada", value: "Nevada" },
  { name: "New Hampshire", value: "New Hampshire" },
  { name: "New Jersey", value: "New Jersey" },
  { name: "New Mexico", value: "New Mexico" },
  { name: "New York", value: "New York" },
  { name: "North Carolina", value: "North Carolina" },
  { name: "North Dakota", value: "North Dakota" },
  { name: "Ohio", value: "Ohio" },
  { name: "Oklahoma", value: "Oklahoma" },
  { name: "Oregon", value: "Oregon" },
  { name: "Pennsylvania", value: "Pennsylvania" },
  { name: "Rhode Island", value: "Rhode Island" },
  { name: "South Carolina", value: "South Carolina" },
  { name: "South Dakota", value: "South Dakota" },
  { name: "Tennessee", value: "Tennessee" },
  { name: "Texas", value: "Texas" },
  { name: "Utah", value: "Utah" },
  { name: "Vermont", value: "Vermont" },
  { name: "Virginia", value: "Virginia" },
  { name: "Washington", value: "Washington" },
  { name: "West Virginia", value: "West Virginia" },
  { name: "Wisconsin", value: "Wisconsin" },
  { name: "Wyoming", value: "Wyoming" }
];
exports.experienceList = [
  { name: "1 Year", value: "1"},
  { name: "2 Years", value: "2"},
  { name: "3 Years", value: "3"},
  { name: "4 Years", value: "4"},
  { name: "5 Years", value: "5"},
];
exports.restaurantSkills = [
  "Server",
  "Host",
  "Bartender",
  "Busser/Runner",
  "Cook",
  "Dishwasher",
  "N/A"
];
exports.hotelSkills = [
  "Front Desk",
  "Concierge",
  "Bellperson",
  "Doorman",
  "Room Attendant",
  "House Person",
  "Laundry Attendant",
  "Shuttle Driver",
  "Engineer (Maintenance)",
  "N/A"
];
exports.minMaxCharLimitRegex = /^.{4,30}$/;
exports.minMaxCharLimitForAddressRegex = /^.{4,100}$/;
exports.phoneNumberRegex = /^\d{10}$/;
exports.emailRegex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/;
exports.website =  /^(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9]+\.[a-z]{2,6}(?:\/\S*)?$/;
exports.einNumberRegex = /^\d{9,11}$/;
exports.fullNameRegex = /^[A-Za-z ]+$/;
exports.backToLogin = "Back to Login";
exports.enterPersonalDetails = "Enter Your Personal Details";
exports.enterProfessionalDetails = "Enter Your Professional Details";
exports.fullName = "Full Name";
exports.mobileNumber = "Mobile Number";
exports.verifyNow = "Verify Now";
exports.emailAddress = "Email Address";
exports.address = "Address";
exports.dateOfBirth = "Date Of Birth";
exports.enterSSN = "Enter SSN";
exports.selectDLState = "Select DL State";
exports.dlNumber = "DL-Number";
exports.next = "Next";
exports.yourExperience = "Your Experience";
exports.prev = "Prev";
exports.signUpNow = "Sign Up Now";
exports.languageSkills = "Language Skills";
exports.getCountryStateCityApiKey = "TFB4Tzgwb2U5aW53WEkyMDlRT2wyRlBzd0VJUTZiZzVqdzM0S3FpOQ==";
exports.getCountryStateCityApiEndPoint = "https://api.countrystatecity.in/v1/countries";

// Customizable Area End
