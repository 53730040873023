import React from "react";

// Customizable Area Start
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./style.css";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

export interface Props {
  // Customizable Area Start
  isOpen: boolean;
  handleToLogin: (e: any) => void;
  handleChangeNumber: (e: any) => void;
  phone: string;
  // Customizable Area End
}

const popUptheme = createTheme({
  palette: {
    primary: {
      main: "#390879",
    },
  },

  typography: {
    subtitle1: {
      lineHeight: "1.6rem",
      fontWeight: "bold",
      fontSize: "1.4rem",
    },
    subtitle2: {
      lineHeight: "20px",
      fontSize: "14px",
      maxWidth: "60%",
      color: "#6F7C8E",
    },
    body1: {
      lineHeight: "20px",
      fontSize: "14px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
});

export default class SubmittedFailedPopUp extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      // Required for all blocks

      <ThemeProvider theme={popUptheme}>
        <Dialog
          open={this.props.isOpen}
          onClose={this.props.handleChangeNumber}
          PaperProps={{
            style: {
              width: "530px",
              height: "350px",
              borderRadius: "20px",
              overflowY: "visible"
            },
          }}
        >
          <IconButton onClick={this.props.handleChangeNumber} style={{border: "1px rgb(255, 255, 255, 0.3) solid", padding: "5px", position: "absolute", top: "-40px", right: 0, borderRadius: "50%", backgroundColor: "rgb(0 0 0 / 55%)"}}>
            <CloseIcon style={{ color: "#fff"}} height={20} width={20}/>
          </IconButton>
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              lineHeight: "26px",
              fontWeight: 800,
              fontSize: "22px",
            }}
          >
            <img
              src={require("./assets/image_Failed.svg")}
              alt=""
              style={{ marginLeft: "60px" }}
            />
            <p>Already Registered</p>
          </DialogTitle>
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <DialogContentText variant="subtitle2" style={{color: "rgba(0,0,0,0.7)"}}>
              <ins style={{color: "#390879"}}>{this.props.phone}</ins> number has already been registered
              with us. Please login with otp verificatiion or change this number
              to sign up.
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{
                borderRadius: "20px",
                borderColor: "#39087 !important",
                borderStyle: "solid !important",
                marginTop: "0px",
                width: "228px",
                height: "50px",
                marginBottom: "15px",
                textTransform: "none",
              }}
              onClick={this.props.handleToLogin}
            >
              <strong
                style={{
                  fontSize: "14px",
                  fontWeight: 800,
                  lineHeight: "18px",
                  color: "#390879",
                }}
              >
                Go to Login{" "}
              </strong>
            </Button>
            <Button
              style={{
                marginTop: "0px",
                width: "228px",
                height: "50px",
                borderRadius: "20px",
                backgroundColor: "#390879",
                color: "white",
                marginBottom: "15px",
                textTransform: "none",
              }}
              onClick={this.props.handleChangeNumber}
            >
              <strong
                style={{
                  fontSize: "14px",
                  fontWeight: 800,
                  lineHeight: "18px",
                }}
              >
                Change number
              </strong>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}
