import { Box, Typography,Divider } from "@material-ui/core";
import { DateRangeSharp, QueryBuilderSharp } from "@material-ui/icons";
import React from "react";

import {
    ThemeProvider,
    createTheme,
    makeStyles,
    createStyles,
    StyleRules,
  } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    flexContainer: {
      gap: "0px",
    },
    card: {
      border: "1px solid #e1e6ec",
      borderRadius: "10px",
      backgroundColor: "#fff",
    },
    cardContent: {
      overflow: "hidden",
      overflowY: "scroll",
      height: "100vh",
      padding: "10px",
    },
    cardFooter: {
      borderTop: "1px solid #e1e6ec",
      position: "sticky",
      bottom: 0,
      width: "100%",
      padding: "10px 0",
      backgroundColor: "#fff",
    },
    scrollbarTrack: {
      width: "2px",
    },
    scrollbarThumb: {
      background: "#ffff",
      borderRadius: "5px",
    },
    boxes: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "178px",
      height: "87px",
      margin: "10px",
      borderRadius: "10px",
    },
    cardHeading: {
      fontSize: "16px",
      fontWeight: 700,
      lineHeight: "26px",
      color: "#0a0412",
    },
    cardSubHeading: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#000",
    },
    cardActiveHeading: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "15px",
      color: "#390879",
      marginBottom: "10px",
    },
    cardInactiveSubHeading: {
      fontSize: "11px",
      fontWeight: 400,
      lineHeight: "14px",
      color: "#6f7c8e",
      margin: "4px 0px",
      textAlign: "center",
    },
    cardbodyText: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "15px",
      color: "#000",
    },
    cardEmpName: {
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "15px",
      color: "#000",
    },
    summaryTypeInitial: {
      fontSize: "14px",
      color: "white",
    },
    summaryDaysText: {
      color: "#0a0412",
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "14px",
      textAlign: "center",
    }
  }));
  interface Props{
    viewRecordActivity?:any
    hoursWorked?: string;
    missedShifts?: string;
    completedDays?: string;
  }

export default function ShyftSummary(props:Props) {
    const classes=useStyles()
    const { missedShifts, hoursWorked, completedDays } = props;

  return (
    <Box>
      <Box
        className={classes.cardHeading}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <div
          style={{display: "flex", justifyContent: "center", alignItems: "center"}}
        >
         <Typography style={{fontSize:"15px"}}>Summary</Typography>
         <Typography style={{fontSize:"14px", marginLeft:"5px"}}>(Till Today)</Typography>
         </div>
         <Typography data-test-id="recordActivity" className={classes.cardActiveHeading} style={{cursor:"pointer",textDecoration:"underline"}} onClick={props.viewRecordActivity}>Activity Records</Typography>
      </Box>
      <Box
        className={classes.card}
        padding={"10px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Box
            height={"40px"}
            width={"40px"}
            bgcolor={"#2da406"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"10px"}
            margin={"5px"}
          >
            <Typography className={classes.summaryTypeInitial}>C</Typography>
          </Box>
          <Typography className={classes.cardInactiveSubHeading}>
            Completed Shyfts
          </Typography>
          <Typography className={classes.summaryDaysText}>{completedDays}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Box
            height={"40px"}
            width={"40px"}
            bgcolor={"#0091ff"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"10px"}
            margin={"5px"}
          >
            <Typography className={classes.summaryTypeInitial}>W</Typography>
          </Box>
          <Typography className={classes.cardInactiveSubHeading}>
            Hours Worked
          </Typography>
          <Typography className={classes.summaryDaysText}>{hoursWorked}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
          <Box
            height={"40px"}
            width={"40px"}
            bgcolor={"#ff375b"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            borderRadius={"10px"}
            margin={"5px"}
          >
            <Typography className={classes.summaryTypeInitial}>M</Typography>
          </Box>
          <Typography className={classes.cardInactiveSubHeading}>
            Missed Shyfts
          </Typography>
          <Typography className={classes.summaryDaysText}>{missedShifts}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
