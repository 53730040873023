import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  makeStyles,
} from "@material-ui/core/styles";
import moment from "moment";
import { convertDateIntoTwelveHourFormat, convertSecondsToHoursMinutesFormat, convertSecondsToHoursMinutesTimeFormat, convertTimeStringIntoDuration } from "./Utilities";
const KeyArrowRight = require("./assets/images/keyboard_arrow_down_black.png");
const timer = require("./assets/images/timer.png");
const clock = require("./assets/images/clock.png");

interface Props {
  progress: number;
  ongoingShyft: any[];
  remainingTime: number;
  activeShyft: number;
  shyftEndTime: number;
  timePercent: number;
  navigateToMyShyft: ()=> void;
  navigateToManageShyft: ()=> void;
}

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#b9df12",
    },
  })
)(LinearProgress);

const BorderProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#390879",
    },
  })
)(LinearProgress);


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  card: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: "15px",
    border: "1px solid #e1e6ec",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  progress: {
    color: "#390879",
    fontSize: "22px",
    fontWeight: 400,
    lineHeight: "26px",
  },
  registration: {
    color: "#0a0412",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    textDecoration: "underline",
    marginBottom: "6px",
    cursor: "pointer"
  },
});

const ProgressBar = (props: Props) => {
  const classes = useStyles();
  const warningTime = props.remainingTime < 601

  return (
    <>
    {props.remainingTime == 0 && 
    <Box className={classes.card}>
      <Typography variant="h4" style={{color: "#390879", fontWeight: "bold", fontSize: "20px"}}>
        <Box>{props.progress}%</Box>
      </Typography>
      <Box padding={"10px"} width={"65%"}>
        <Typography variant="subtitle1">
          <Box data-test-id="navigateToManageShyft"  onClick={()=>props.navigateToManageShyft()} className={classes.registration}>{props.progress <100 ? 'Complete Your Registration... ' : 'Registration Completed'}</Box>
        </Typography>
        <div className={classes.root}>
          <BorderLinearProgress variant="determinate" value={props.progress} />
        </div>
      </Box>
      <Box display="flex" justifyContent="center" onClick={()=>props.navigateToManageShyft()}>
        <img src={KeyArrowRight} style={{ height: "14px" }} />
      </Box>
    </Box>}
    {props.remainingTime > 0  && props.ongoingShyft.length == 0 &&
     <Box className={classes.card}  style={{border: (props.activeShyft > 0 || warningTime) ? '1px solid #f7b500' :'1px solid #e1e6ec'}}>
      <Box  width={"100%"} display={"flex"} alignItems={"center"}>
        <Box>
        <img src={timer} style={{ height: "28px", marginRight: '15px' }} />
        </Box>
        <Box display={"flex"} alignItems={"center"} width={'100%'} justifyContent={'space-between'}>
        <Box>
          <span style={{fontSize: '14px'}}>Upcoming Shyft</span>
        <Typography style={{fontWeight: 'bold',fontSize: '14px'}}>Starts in:
          <span style={{ color: (props.activeShyft > 0 || warningTime) ? '#f7b500' : 'rgb(0, 145, 255)'}}>
          {props.activeShyft > 0 ? ` ${convertSecondsToHoursMinutesTimeFormat(props.remainingTime)}` : ' 00h:00m:00s' }
          </span>
          </Typography>
        </Box>
        <Typography onClick={()=>props.navigateToMyShyft()} style={{cursor: 'pointer',fontSize: '12px',textDecoration: "underline", fontWeight: 'bold',textAlign: 'right', paddingRight: '7px'}}>{((warningTime || props.activeShyft > 0) && 'Punch In Now') }</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" onClick={()=>props.navigateToMyShyft()}>
        <img src={KeyArrowRight} style={{ height: "14px" }} />
      </Box>
    </Box>}
    {props.remainingTime > 0 && props.ongoingShyft.length  > 0 && (
       <Box className={classes.card}>
       <Box  width={"100%"} display={"flex"} alignItems={"center"}>
         <Box display={'flex'} flexDirection={'column'} borderRight={'1px solid #767676'}>
          <span style={{color: "rgb(45, 164, 6)", width: 'max-content', marginRight: '5px', fontSize: '12px', fontWeight: 'bold'}}>Punched In:</span>
          <span  style={{display: 'block', width: 'max-content', fontSize: '14px', fontWeight: 'bold'}}>
            {convertDateIntoTwelveHourFormat(props.ongoingShyft[0]?.attributes.activity?.attributes.punch_in)}
            </span>
         </Box>
         <Box display={"flex"} margin='0 10px' flexDirection={'column'} alignItems={"center"} width={'100%'}>
          <Box marginBottom={'5px'} display={'flex'} alignItems={'center'}>
            <span style={{fontSize: '12px', fontWeight: 'bold'}}>Running Time</span>
            <span style={{color: '#767676', paddingLeft:'4px',  paddingRight:'1px'}}>-----</span>
            <span><img src={clock} style={{ height: "14px",}} /></span>
            <span style={{color: '#767676', paddingRight:'4px', paddingLeft:'1px'}}>-----</span>
            <span style={{fontSize: '12px', color: '#390879', fontWeight: 'bold'}}>{convertSecondsToHoursMinutesFormat(props.shyftEndTime)}</span>
          </Box>
         <Box className={classes.root}>
          <BorderProgress  variant="determinate" value={props.timePercent} />
        </Box>
         <Box>
         </Box>
         </Box>
       </Box>
       <Box display="flex" justifyContent="center" onClick={()=>props.navigateToMyShyft()}>
         <img src={KeyArrowRight} style={{ height: "14px" }} />
       </Box>
     </Box>
    )}
    </>
    
  );
};

export default ProgressBar;
