import React from "react";
import { Box, makeStyles, IconButton, Dialog, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CustomButton from "./CustomButton.web";

const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
  
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    "& div[role='dialog']": {
        overflowY: "visible",
        padding: 30,
        maxWidth: 450,
        borderRadius: "20px"
    },
    "& .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    "& .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    "& .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "10px",
      paddingTop: "15px",
      color: "#0a0412",
    },
    "& .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    "& .actionWrapper": {
      paddingTop: "24px",
    },
    "& button": {
      backgroundColor: "#390879",
      color: '#fff'
    },
    "& button:hover": {
      backgroundColor: "#390879",
      color: '#fff'
    },
  },
}));

interface PopupDialogProps {
  open: boolean;
  handleCancel: (e: any) => void;
  isSuccess: boolean;
  isApiError: boolean;
  isDeleteAccountError?: boolean;
  deleteErrMsg?: string;
}

const ContactUsPopup: React.FC<PopupDialogProps> = ({
  open,
  handleCancel,
  isSuccess,
  isApiError,
  isDeleteAccountError,
  deleteErrMsg,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="sm"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" data-test-id="popUpCloseButtonId" onClick={handleCancel}>
        <CloseIcon height={20} width={20} />
      </IconButton>

        { isSuccess && 
            <>
                <Box justifyContent="center" display="flex">
                    <img src={require("./greencheck.png")} alt="" width={64} />
                </Box>
                <Box className="successDialogTitle">Success!</Box>
                <Box className="description">
                    Your Response has been submitted.
                </Box>
            </>
        }

        { isApiError && 
            <>
                <Box justifyContent="center" display="flex">
                    <img src={require("./assets/images/blockIcon.png")} alt="" width={64} />
                </Box>
                <Box className="successDialogTitle">Error!</Box>
                <Box className="description">
                    { isDeleteAccountError ? deleteErrMsg : "Something went wrong, please try again!!."}
                </Box>
            </>
        }
      <Grid container className="actionWrapper" spacing={2}>
        <Grid item xs={6} style={{ margin: 'auto'}}>
          <CustomButton
            data-test-id="popUpOkButtonId"
            label="Ok"
            isOutlined={true}
            handleClick={handleCancel}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ContactUsPopup;
