import React from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  makeStyles,
  ListItemText,
  Checkbox,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  roundedSelectField: {
    paddingBottom: "16px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & .MuiSelect-root": {
      padding: "14px",
      paddingBottom: "16px",
    },
    "& input": {
      padding: "14px 32px 14px 14px",
    },
    "& input:-internal-autofill-selected": {
      backgroundColor: "-internal-light-dark(rgb(255, 255, 255), rgba(70, 90, 126, 0.4)) !important",
    },
    "& label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink-88": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase !important",
    }
  },
  selectPaper: {
    maxHeight: '300px'
  }
}));

interface ITextFieldProps {
  deleteSelected: (event: any, value?: string) => void;
  selectedValues: [];
  label: string;
  name: string;
  error: boolean;
  options: any;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const CustomMultiSelect: React.FC<ITextFieldProps> = (props: any) => {
  const classes = useStyles();
  const {
    selectedValues,
    label,
    error,
    name,
    options,
    handleChange,
    deleteSelected,
  } = props

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.roundedSelectField}
    >
      <InputLabel error={error}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        value={selectedValues}
        error={error}
        onChange={handleChange}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
          classes: {
            paper: classes.selectPaper,
          }
        }}
        style={{ borderRadius: '20px', height: 'auto' }}
        renderValue={(selected: any) => (
            <div style={{ display: 'flex', flexWrap: 'wrap'}}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={value}
                  clickable
                  deleteIcon={
                    <img 
                      src={require('./clear.png')} 
                      alt="cross" 
                      style={{ width: '20px', height: '20px', marginRight: '8px' }}
                      onMouseDown={(event:any) => event.stopPropagation()}
                    />
                  }
                  style={{ margin:  '2px', backgroundColor: 'lightgrey' }}
                  onDelete={(event:any) => deleteSelected(event, value)}
                />
              ))}
            </div>
          )
        }
        IconComponent={ExpandMoreIcon}
        data-test-id={name}
        multiple={true}
      >
        {options.map((item: any) => (
          <MenuItem key={item.value} value={item.value}>
            <ListItemText primary={item.name} />
            <Checkbox
                icon={<div style={{ width: '20px', height: "20px", borderRadius: '50px',backgroundColor: 'white', border: '2px gray solid'}}/>}
                checkedIcon={<img src={require('./assets/check_circle_dark_blue.png')} alt="chekced_img" style={{ width: '25px', height: '25px' }} />}
                checked={
                    selectedValues?.findIndex((element:any) => element === item.value) >= 0
                }
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMultiSelect;
