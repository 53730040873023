import React, { useState } from "react";
import { TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  multiInputTextField: (props: IMultiInputProps) => ({
    paddingBottom: "16px",
    "& input": {
      padding: "14px 18px",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: props.notRounded ? "12px" : "50px",
    },
    " & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input": {
      padding: props.notRounded ? "8px 4px" : "6px 4px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    "& label.MuiInputLabel-shrink": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
    " & .MuiChip-root": {
      height: "26px",
    },
  }),
}));

interface IMultiInputProps {
  values?: string[];
  label: string;
  name: string;
  error: boolean;
  handleChange: (event: any) => void;
  notRounded?: boolean;
}

const MultiValueInput: React.FC<IMultiInputProps> = ({
  values,
  label,
  error,
  name,
  notRounded,
  handleChange,
}) => {
  const classes = useStyles({
    values,
    label,
    error,
    name,
    notRounded,
    handleChange,
  });
  const [inputValue, setInputValue] = useState("");
  const onBlurEvent = (e: any) => {
    const value = e.target.value.trim();
    if (value !== "") {
      handleChange([...(values as string[]), value]);
    }
    setInputValue("");
  };

  const handleKeyUp = (event: any) => {
    if (event.key === "Enter" || event.key === ",") {
      const trimmedInputValue = inputValue.trim();
      if (trimmedInputValue !== "") {
        handleChange([...(values as string[]), trimmedInputValue]);
        setInputValue("");
      }
    }
  };

  const handleInputChange = (event: any, newInputValue: any) => {
    const updatedValue = newInputValue.replace(/[^a-zA-Z ]/g, "");
    setInputValue(updatedValue);
  };

  const filterEmptyItems = (value: any[]) => {
    const newValues = value.filter((item) => item.trim() !== "");
    handleChange(newValues);
  }

  return (
    <Autocomplete
      fullWidth
      multiple
      value={values}
      inputValue={inputValue}
      selectOnFocus
      handleHomeEndKeys
      options={[]}
      onChange={(e, value) => {
        filterEmptyItems(value);
      }}
      onInputChange={handleInputChange}
      onBlur={onBlurEvent}
      renderOption={(option) => option}
      freeSolo
      className={classes.multiInputTextField}
      renderInput={(params) => (
        <TextField
          name={name}
          label={label}
          {...params}
          variant="outlined"
          error={error}
          onKeyUp={handleKeyUp}
        />
      )}
    />
  );
};

export default MultiValueInput;
