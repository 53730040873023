import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  roundedTextField: {
    paddingBottom: "16px",
    "& input": {
      padding: "14px 18px",
    },
    "& fieldset.MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
    },
    " & label": {
      fontSize: 13,
      fontStyle: "normal",
      transform: "translate(14px, 16px) scale(1)",
    },
    "& label.Mui-focused": {
      fontStyle: "normal",
      fontWeight: "400",
      textTransform: "uppercase",
    },
  },
}));

interface IDateTextFieldProps {
  value: string | Date | null;
  label: string;
  name: string;
  error: boolean;
  maxDate?: string | Date;
  minDate?: string | Date;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomDateInput: React.FC<IDateTextFieldProps> = ({
  value,
  label,
  error,
  name,
  maxDate,
  minDate,
  handleChange,
}) => {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      label={label}
      name={name}
      className={classes.roundedTextField}
      variant="outlined"
      value={value}
      InputLabelProps={{
        shrink: true,
      }}
      type="date"
      inputProps={{
        max: maxDate && moment(maxDate).format("YYYY-MM-DD"),
        min: minDate && moment(minDate).format("YYYY-MM-DD"),
      }}
      error={error}
      onChange={handleChange}
      data-test-id={name}
    />
  );
};

export default CustomDateInput;
