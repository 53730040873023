import React from "react";
import {
  Box,
  makeStyles,
  IconButton,
  Dialog,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import SearchWithFilterandLocation from "./SearchWithFilterandLocation";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import CallMadeIcon from "@material-ui/icons/CallMade";


interface Location {
  id: string;
  name: string;
  iso2: string;
}

const useStyles = makeStyles((theme) => ({
  skillDialogWrapper: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: 530,
    },
    " & .MuiPaper-root": {
      borderRadius: 20,
      overflowY: "visible",
      padding: 30,
    },
    " & .closeIcon": {
      border: "1px rgb(255, 255, 255, 0.3) solid",
      padding: "5px",
      position: "absolute",
      top: "-40px",
      right: "0px",
      borderRadius: "50%",
      backgroundColor: "rgb(0 0 0 / 55%)",
      color: "#fff",
    },
    " & .successDialogTitle": {
      textTransform: "capitalize",
      fontSize: 22,
      fontWeight: "bold",
      textAlign: "center",
      paddingBottom: "24px",
      paddingTop: "24px",
      color: "#0a0412",
    },
    " & .description": {
      fontSize: "14px",
      color: "#6f7c8e",
      textAlign: "center",
    },
    " & .actionWrapper": {
      paddingTop: "24px",
    },
  },
}));

interface ISuccessPopupDialogProps {
  open: boolean;
  isShyfter?: boolean;
  closeModal: (e: any) => void;
  handleCurrentLocation?: any;
  fetchCountries?: any;
  fetchStateByCountry?: any;
  fetchCitiesByCountryAndState?: any;
  handleSearchForLocation?: any;
  locationData?: any;
  isCountrySelected?: boolean;
  isStateSelected?: boolean;
  isCitySelected?: boolean;
  isSearchingLocation?: boolean;
  handleCitySelection?: any;
  locationBreadCrumData?: string[];
  handleAllStatesBreadCrum?: any;
  handleUpdateSearchedLocation?: any;
}

const GetLocationModal: React.FC<ISuccessPopupDialogProps> = ({
  open,
  closeModal,
  handleCurrentLocation,
  fetchCountries,
  fetchStateByCountry,
  fetchCitiesByCountryAndState,
  handleSearchForLocation,
  locationData,
  isCountrySelected,
  isStateSelected,
  isCitySelected,
  handleCitySelection,
  locationBreadCrumData,
  isSearchingLocation,
  handleAllStatesBreadCrum,
  handleUpdateSearchedLocation
}) => {
  const classes = useStyles();
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const handleGeoCurrentLocation = () => {
    navigator.geolocation?.getCurrentPosition((position) => {
      handleCurrentLocation(position.coords.latitude, position.coords.longitude)
    })
  }

  const handleLocationClick = (locationCode: string, locationName: string) => {

    if (isCountrySelected) {
      fetchStateByCountry(locationCode, locationName);
    }

    if (isStateSelected) {
      fetchCitiesByCountryAndState(locationCode, locationName);
    }

    if (isCitySelected) {
      handleCitySelection(locationName)
    }
  }

  const handleLocationBreadCrum = (index: number) => {
    if(isCitySelected && index === 1) {
      handleAllStatesBreadCrum()
    }
  }

  const handleSelectedSearchedLocation = (item: string) => {
    const searchedLocation = item.split(",");
    let cityName = " ";
    let stateName = "";
    let CountryName = "";

    if (searchedLocation.length === 1) {
      cityName = searchedLocation[0];
      stateName = searchedLocation[0];
      CountryName = searchedLocation[0];
    } else if (searchedLocation.length === 2) {
      cityName = searchedLocation[0];
      stateName = searchedLocation[0];
      CountryName = searchedLocation[1];
    } else if (searchedLocation.length === 3) {
      cityName = searchedLocation[0];
      stateName = searchedLocation[1];
      CountryName = searchedLocation[2];
    }

    handleUpdateSearchedLocation(cityName.trim(), stateName.trim(), CountryName.trim())
  }

  return (
    <Dialog
      className={classes.skillDialogWrapper}
      open={open}
      maxWidth="xs"
      scroll="body"
      fullWidth
    >
      <IconButton className="closeIcon" onClick={closeModal} data-test-id="closeModal">
        <CloseIcon height={20} width={20} />
      </IconButton>
      <Box className="successDialogTitle">Select your Location</Box>
      <Box
        justifyContent="center"
        display="flex"
        alignItems={"center"}
        padding={"10px"}
        borderRadius={"50px"}
        bgcolor={"#f7faff"}
        border={"1px solid #390879"}
        color="#390879"
        onClick={() => handleGeoCurrentLocation()}
        data-test-id="currentLocationButton"
      >
        <MyLocationIcon style={{ marginRight: "5px" }}/> Use your current location
      </Box>
      <Box
        width={"100%"}
        maxWidth={"100%"}
        justifyContent="space-between"
        display="flex"
        alignItems={"center"}
      >
        <Divider style={{ width: "45%" }} />
        <Box margin={"10px 0"}>OR</Box>
        <Divider style={{ width: "45%" }} />
      </Box>
      <Box marginTop={"-20px"}>
        <SearchWithFilterandLocation modal={true} isOnChangeText={true} handleChangeSearchText={handleSearchForLocation}
          placeholder="Search for state, city..." />
      </Box>
      <Box display="flex" justifyContent={"center"} alignItems={"center"} marginTop={"10px"} paddingLeft={"15px"}>
        {!isSearchingLocation ? 
            <Breadcrumbs 
              aria-label="breadcrumb" 
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link color="inherit" style={{ fontSize: "12px", color: "gray", cursor: "pointer" }} onClick={!isCountrySelected && fetchCountries}>
                All Countries
              </Link>
              {locationBreadCrumData && locationBreadCrumData.length > 0 &&
                locationBreadCrumData.map((item: string, index: number) => (
                  <Link 
                    key={`${item}-${index}`}
                    color="inherit" 
                    style={{ fontSize: "12px", color: index%2==0 ? "#390879" : "gray", cursor: index%2!=0 ? "pointer" : "default" }} 
                    data-test-id={`breadcrumId-${index}`}
                    onClick={() => handleLocationBreadCrum(index)}
                  >
                    {item}
                  </Link>
                ))

              }
            </Breadcrumbs> 
        :
        <Box width={"100%"}>
          <Typography style={{ fontSize: "12px", color: "gray", textAlign: "center" }}>Search Results</Typography>
          <Box style={{ maxHeight: "200px", overflowY: 'auto' }}>
            <List dense={dense}>
              {locationData && locationData.length > 0 ?
                locationData.map((location: any, index: number) => {
                  return (
                    <>
                      <ListItem key={location.id}>
                        <ListItemText primary={location.description} />
                        <ListItemSecondaryAction data-test-id={`searched-location-click-${index}`}>
                          <IconButton 
                            edge="end" 
                            aria-label="search_location-update" 
                            onClick={() => handleSelectedSearchedLocation(location.description)}
                          >
                            <CallMadeIcon style={{ color: "#390879"}} fontSize="small"/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  );
                })
                :
                <Typography style={{ fontSize: "14px", margin: "10px 0px", textAlign: "center" }}>No Data Found!</Typography>
              }
            </List>
          </Box>
        </Box>
      }
      </Box>
      {
        !isSearchingLocation &&
        <Box style={{ maxHeight: "200px", overflowY: 'auto' }}>
          <List dense={dense}>
            {locationData && locationData.length > 0 ?
              locationData?.map((country: Location, index: number) => {
                return (
                  <>
                    <ListItem key={country.id}>
                      <ListItemText primary={country.name} />
                      <ListItemSecondaryAction data-test-id={`location-click-${index}`} onClick={() => handleLocationClick(country.iso2, country.name)}>
                        <IconButton edge="end" aria-label="delete">
                        {(isCitySelected || isSearchingLocation) ? 
                          <CallMadeIcon style={{ color: "#390879"}} fontSize="small"/>
                          : <ArrowRightAltIcon />
                        }
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
              :
              <Typography style={{ fontSize: "14px", margin: "10px 0px", textAlign: "center" }}>No Data Found!</Typography>
            }
          </List>
        </Box>
      }
    </Dialog>
  );
};

export default GetLocationModal;
