import React from "react";
import { Box, Button, Grid} from "@material-ui/core";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomSelect from "../../../components/src/CustomSelectField.web";
import SkillList from "../../../components/src/SkillList.web";
import MultiValueInput from "../../../components/src/MultiValueInput.web";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import SkillSetDialog from "../../../components/src/SkillSetDialog.web";
interface Props {
  user: any;
  userFormError: any;
  configJSON: any;
  handleShyfterInputChange: any;
  setMaxDate: any;
  deleteSkillItem: any;
  handleLanguageChange: any;
  toggleSkillSetDialog: any;
  moveToNext: any;
  moveBack: any;
  setSkillSetList:any;
  setSelectedSkills:any;
  openSkillSetDialog:any;
  saveSkillData:any;
}
export const UpdateProfessionalDeatils = (props: Props) => {
  const {
    moveToNext,
    moveBack,
    user,
    userFormError,
    configJSON,
    handleShyfterInputChange,
    deleteSkillItem,
    handleLanguageChange,
    toggleSkillSetDialog,
    setSelectedSkills,
    setSkillSetList,
    openSkillSetDialog,
    saveSkillData
  } = props;

  return (
    <Box>
    <Box
    border="1px solid #e1e6ec"
    marginY={"10px"}
    padding={"30px 20px 10px 20px"}
    borderRadius={"10px"}
    bgcolor={"#fff"}
    >
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <CustomSelect
            value={user.experience}
            handleChange={(e) => handleShyfterInputChange(e, "experience")}
            label={configJSON.yourExperience}
            name="experience"
            error={!!userFormError.experience}
            options={configJSON.experienceList}
          />
          </Grid>
        <Grid item xs={6} md={6}>
        <MultiValueInput
            values={user.language_skill}
            handleChange={handleLanguageChange}
            name="languageSkills"
            label={configJSON.languageSkills}
            error={!!userFormError.languageSkills}
          />
         </Grid>
        <Grid item xs={6} md={6}>
        <SkillList
            type="restaurant"
            title="Restaurant"
            items={user.restaurant_skills}
            deleteItem={(skill) => deleteSkillItem("restaurant", skill)}
            openSkillDialog={(type) => toggleSkillSetDialog(type)}
          />
           {user.restaurant_skills.length == 0 && (
                <div style={{  
                  color: "#ff0000",
                fontSize: "12px",
                marginTop: "-10px",
                paddingBottom: "5px"}}>
                 Please select atleast 1 restaurant skill*
                </div>
              )}
            {openSkillSetDialog && (
                <SkillSetDialog
                  open={openSkillSetDialog}
                  onCloseDialog={() => toggleSkillSetDialog("")}
                  saveSkillData={saveSkillData}
                  type={openSkillSetDialog}
                  items={setSkillSetList(openSkillSetDialog)}
                  selectedItems={setSelectedSkills()}
                />
              )}
         </Grid>
        <Grid item xs={6} md={6}>
        <SkillList
            type="hotel"
            title="Hotel"
            items={user.hotel_skills}
            deleteItem={(skill) => deleteSkillItem("hotel", skill)}
            openSkillDialog={(type) => toggleSkillSetDialog(type)}
          />
           {user.hotel_skills.length == 0 && (
                <div style={{  
                  color: "#ff0000",
                fontSize: "12px",
                marginTop: "-10px",
                paddingBottom: "5px"}}>
                 Please select atleast 1 hotel skill*
                </div>
              )}
        </Grid>
      </Grid>
    </Box>
    <Box
        width={"100%"}
        marginY={"10"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
       
      >
        <Box marginRight={"5px"} width={"15%"} >
        <Button
          variant="contained"
          style={{ fontWeight:"bold", padding:"11px 28px",borderRadius: "50px", boxShadow: 'none', backgroundColor: 'transparent', textTransform: 'capitalize' }}
          onClick={moveBack}
        >
          <span style={{
            height: "35px",
            borderRadius: "50%",
            background: "#E1E6EC",
            width: "35px",
            marginRight: "7px"
          }}><ArrowBackIos style={{ marginLeft: "8px", marginTop: "9px", height:"16px"}}/></span> Back
        </Button>
        </Box>
        
        <Box padding={"10px"} width={"230px"}>
         <CustomButton
           label={"Next"}
           isOutlined={false}
           handleClick={moveToNext}
         />
        </Box>
      </Box>
      </Box>
  );
};
