import React from "react";

import PushNotificationsWebController, {
  Props,
} from "./PushNotificationsController.web";
import { Box, Typography } from "@material-ui/core";
import Notification from "../../../components/src/Notification";
import { notification } from "./assets";



export default class PushNotificationsWeb extends PushNotificationsWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {

    return (
      <Box>
      <Box marginBottom="5px">
        <Typography>Push Notification Settings</Typography>
      </Box>
      <Box
        marginY="10px"
        border="1px solid #e1e6ec"
        padding="30px 20px 10px 20px"
        borderRadius="10px"
        bgcolor="#fff"
      >
        <Notification
          checked={this.state.isChecked}
          imageUrl={notification}
          title="Set Push Notifications"
          description="Click on toggle If you want to off any push notifications from Sanagashyft"
          onChange={this.onToggle}
        />
      </Box>
    </Box>
    );
  }
}
