import React from "react";
import { Box, Container, Divider, Grid, Typography } from "@material-ui/core";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import Footer from "../../../components/src/Footer.web";

import CurrentSubscriptionController, {
  Props,
} from "./CurrentSubsCriptionController.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CommonLandingPageWeb from "../../../components/src/CommonLandingPage.web";
import { NoSubscriptionImg, crown } from "./assets";
import { breadCrumSubsPath } from "../../../components/src/Utilities";
import SubscribeNowModal from "./SubscribeNowModal.web";
import { SubscriptionCard } from "./SubscriptionCard.web";
import Spinner from "../../../components/src/Spinner";

export class CurrentSubscription extends CurrentSubscriptionController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { upgradedPlan, allSubscriptionList, isSpinnerLoading }=this.state
    const userCurrentSubs = this.state.userSubscription?.attributes;
    return (
      <Box>
        <LandingPageHeader 
          {...this.props} isBreadcrumbs={true} breadCrumsData={breadCrumSubsPath} 
          currentPage={"Subscription Plans"} pageTitle={"Your Subscription"} isShowDetails={true}
          isUpdateUser={false}
        />
        <Box marginY={"8%"}>
          <Container maxWidth="md">
            { isSpinnerLoading ? 
              <Spinner />
              :
              <>
                {!!userCurrentSubs ? (
                  <>
                    <Box padding={"10px"} textAlign={"center"}>
                      <Typography style={{ fontSize: "20px" }}>
                        Your Current Plan
                      </Typography>
                    </Box>
                    <Box>
                      <SubscriptionCard
                        data-test-id="currentSubs"
                        title={userCurrentSubs.name}
                        month={userCurrentSubs.duration}
                        price={userCurrentSubs.price}
                        type={this.getSubscriptionType(
                          userCurrentSubs?.name
                        )}
                        benefits={userCurrentSubs.benefits}
                        isCurrentSub={true}
                        validtill={this.state.currentPlanExpiryDate} 
                        isChecked={true} 
                        id={""}
                        isFromUpgradePlan={true}
                        isCrownLogo={true}
                        isCurrentPlan={true}
                      />
                    </Box>
                    <Box marginTop={"20px"} paddingTop={"10px"}>
                      <Divider />
                    </Box>
                    <Box marginY={"10px"} textAlign={"center"} padding={"5px"}>
                      <Typography style={{ fontSize: "20px" }}>
                        Upgrade To
                      </Typography>
                    </Box>
                    <Box marginTop={"20px"}>
                      <Grid container spacing={3}>
                        {!!allSubscriptionList&&allSubscriptionList?.map((sub: any) => {
                          if (sub.id != this.state.userSubscription?.id) {
                            return (
                              <Grid item xs={12} md={6} key={sub.id}>
                                <SubscriptionCard
                                  minHeight={"240px"}
                                  data-test-id="availblePlan"
                                  id={sub.id}
                                  title={sub.name}
                                  isModaluse={true}
                                  month={sub.duration}
                                  price={sub.price}
                                  type={this.getSubscriptionType(sub.name)}
                                  benefits={sub.benefits}
                                  isChecked={sub.name==upgradedPlan.name}
                                  handleChecked={() =>
                                    this.handlePlanChecked(sub)
                                  }
                                  isFromUpgradePlan={true}
                                  isUpgradePlan={true}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems={"center"}
                      width={"100%"}
                      padding={"5px"}
                      marginY={"10px"}
                    >
                      <Box width={"25%"}>
                        <CustomButton label={"Upgrade Now"} handleClick={() => this.upgradeSubscription(this.state.upgradedPlan)} disabled={!this.state.upgradedPlan}/>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <Box marginBottom={"10px"}>
                    <CommonLandingPageWeb
                      btnIcon={<img src={crown} alt="" />}
                      imgUrl={NoSubscriptionImg}
                      btnText="Subscribe Now"
                      heading="No Subsciption Found!"
                      paragraph="Please choose a monthly plan to maximize the benefits of
                    the app."
                      handleClick={this.handleSubscribeNowBtn}
                    />
                  </Box>
                )}
              </>
            }
            <SubscribeNowModal
              isOpen={this.state.subscribeNowDialog}
              handleClose={this.handleSubscribeNowBtn}
              allSubscriptionsList={this.state.allSubscriptionList}
              handleSave={(selectedPlan: any) => this.upgradeSubscription(selectedPlan)}
            />
          </Container>
        </Box>
        <Footer />
      </Box>
    );
  }
}
