import React from "react";
import { Container, Box, Typography, Grid, Divider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import ProfileCard from "../../../components/src/ProfileCard";
import ShyfterProfilePageContoller, {
  Props,
  configJSON,
} from "./ShyfterProfilePageController.web";
import { AllReview } from "../../../components/src/AllReview";
import { ProfileQuickLink } from "../../../components/src/ProfileQuickLink";
import CustomButton from "../../../components/src/CustomButton.web";
import { UserProfileBasicBlock } from "../../user-profile-basic/src/UserProfileBasicBlock.web";
import { UserOfficialDocs } from "../../user-profile-basic/src/UserOfficialDocs.web";
import { breadCrumSubsPath } from "../../../components/src/Utilities";
import FormDialogBox from "../../../components/src/FormDialogBox";
import OtpPopUp from "../../../components/src/OtpPopUp";
import NoDocuments from "../../../components/src/NoDocuments.web";

const isOTPDisabled = (otp: string, seconds: number) => {
  return otp.length < 5 || seconds === 0;
};

export default class ShyfterProfilePage extends ShyfterProfilePageContoller {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      isEmailOtpPopUpOpen,
      isPhoneOtpPopUpOpen,
      phoneNumberOTP,
      seconds,
      emailOTP,
      newPhoneShyfter,
      newEmailShyfter,
      countryCode,
      user
    } = this.state;
    const anyDocumentedUploaded = user?.official_docs && Object.values(user.official_docs).some(value => value !== null);
    return (
      <ThemeProvider theme={theme}>
        <LandingPageHeader
          {...this.props}
          isBreadcrumbs={true}
          currentPage="Your Account"
          breadCrumsData={breadCrumSubsPath}
          isShowDetails={false}
          isUpdateUser={false}
        />
        <Box marginTop={"10%"}>
          <Container maxWidth={"lg"}>
            <Box width={"100%"}>
              <ProfileCard
                userData={this.state.user}
                showEditEmailPopUp={this.showShyfterUpdateEmailPopUp}
                showEditPhonePopUp={this.showShyfterUpdatePhonePopUp}
                isProfilePage={true}
                navigateManageProfile={() => this.navigateManageProfile()}
              />
            </Box>
            <Box marginY={"15px"}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Box marginBottom={"14px"} marginTop={"10px"}>
                    <Typography style={webStyle.lable}>Quick Links</Typography>
                  </Box>
                  <Box bgcolor={"#fff"} borderRadius={"20px"}>
                    <ProfileQuickLink
                      linkData={this.state.sideNavTab}
                      getActiveTab={this.handleActiveTab}
                    />
                  </Box>
                  <Box width={"100%"} marginY={"20px"}>
                    <Divider />
                  </Box>
                  <CustomButton
                    label="Logout"
                    islogOutBtn={true}
                    handleClick={this.userLogoutShyfter}
                    isDanger={true}
                    isOutlined={true}
                  />
                </Grid>
                <Grid item xs={12} md={9}>
                  <Box>
                    {this.state.sideNavTab.map((tab: any) => {
                      if (tab.id == 1 && tab.isActive) {
                        return (
                          <UserProfileBasicBlock
                            userData={this.state.user}
                            key={tab.id}
                          />
                        );
                      } else if (tab.id == 2 && tab.isActive) {
                        return (
                          <>
                            {anyDocumentedUploaded ?
                              <UserOfficialDocs
                                key={tab.id}
                                officialDocs={this.state.user?.official_docs}
                                isShyfterProfile={true}
                              /> :
                              <NoDocuments shyfter={true} />
                            }
                          </>
                        );
                      } else if (tab.id == 3 && tab.isActive) {
                        return (
                          <AllReview
                            key={tab.id}
                            isShyfterProfile={true}
                            ratingsData={this.state.ratingsData}
                          />
                        );
                      }
                    })}
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <FormDialogBox
              title={configJSON.updateEmail}
              type={"email"}
              name={"email"}
              isOpen={this.state.updateShyfterEmailPopUp}
              value={newEmailShyfter}
              handleChange={this.onChangeEmail}
              handleClose={this.showShyfterUpdateEmailPopUp}
              handleSubmit={this.getEmailOtp}
              errorMessage={this.state.errorMessageModal}
              errorPopUp={this.state.errorPopUp}
            />

            <FormDialogBox
              title={configJSON.updatePhone}
              type={"phone"}
              name={"phone"}
              isOpen={this.state.updateShyfterPhonePopUp}
              value={newPhoneShyfter}
              countryCode={countryCode}
              handleClose={this.showShyfterUpdatePhonePopUp}
              handleChange={this.onChangePhoneNumber}
              handleSubmit={this.getPhoneNumberOtp}
              handleCountryCodeSelect={this.handleCountryCodeSelectionForUpdate}
              errorMessage={this.state.errorMessageModal}
              errorPopUp={this.state.errorPopUp}
            />

            <OtpPopUp
              isOpen={isEmailOtpPopUpOpen}
              otp={emailOTP}
              phone={newEmailShyfter}
              setOtp={this.onChangeEmailOTP}
              seconds={seconds}
              resend={this.getEmailOtp}
              handleSubmit={this.updateUserEmail}
              handleChange={this.closeEmailOTPModal}
              handleClose={this.closeEmailOTPModal}
              disabled={isOTPDisabled(emailOTP, seconds)}
            />

            <OtpPopUp
              isOpen={isPhoneOtpPopUpOpen}
              phone={newPhoneShyfter}
              otp={phoneNumberOTP}
              setOtp={this.onChangePhoneNumberOTP}
              seconds={seconds}
              handleClose={this.closePhoneOTPModal}
              resend={this.getPhoneNumberOtp}
              handleChange={this.closePhoneOTPModal}
              handleSubmit={this.updateUserPhoneNumber}
              disabled={isOTPDisabled(phoneNumberOTP, seconds)}
            />
          </Container>
        </Box>
      </ThemeProvider>
    );
  }
}

const webStyle = {
  boxContainer: {
    marginY: "10px",
    bgcolor: "#fff",
    borderRadius: "20px",
    border: "1px solid #e1e6ec",
  },
  lable: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 700,
    lineHeight: "18px",
  },
  fadeText: {
    fontSize: "16px",
    color: "#6f7c8e",
    fontWieght: 500,
    lineHeight: "18px",
  },
  contentText: {
    fontSize: "16px",
    color: "#000",
    fontWieght: 500,
    lineHeight: "18px",
  },
  activeText: {
    fontSize: "16px",
    color: "#390879",
    fontWieght: 500,
    lineHeight: "18px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
