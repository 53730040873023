import React from "react";

// Customizable Area Start
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import "./style.css";
import EditIcon from '@material-ui/icons/Edit';
import OtpInput from "react-otp-input";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End

export interface Props {
  // Customizable Area Start
  isOpen: boolean;
  handleClose: (e: any) => void;
  resend: (e: any) => void;
  otp: string;
  setOtp: (otp: string) => void;
  phone: string;
  seconds: number;
  disabled?: boolean;
  handleChange: (e: any) => void;
  handleSubmit?: (e: any) => void;
  // Customizable Area End
}

const popUptheme = createTheme({
  typography: {
    subtitle1: {
      lineHeight: "1.6rem",
      fontWeight: "bold",
      fontSize: "1.4rem",
    },
    subtitle2: {
      lineHeight: "20px",
      fontSize: "14px",
      maxWidth: "60%",
    },
    body1: {
      lineHeight: "20px",
      fontSize: "14px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  palette: {
    primary: {
      main: "#390879",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#E0C2FF",
      light: "#F5EBFF",
      contrastText: "#47008F",
    },
  },
});

export default class OptInPopUp extends React.Component<Props> {
  
  constructor(props: Props) {
    super(props);
  }

  render() {
    const minutes = Math.floor(this.props.seconds / 60);
    const remainingSeconds = this.props.seconds % 60;
    return (
      // Required for all blocks

      <ThemeProvider theme={popUptheme}>
        <Dialog
          open={this.props.isOpen}
          PaperProps={{
            style: {
              width: "530px",
              height: "350px",
              borderRadius: "20px",
              overflowY: "visible"
            },
          }}
        >
          <IconButton data-test-id="close-btn" onClick={this.props.handleClose} style={{border: "1px rgb(255, 255, 255, 0.3) solid", padding: "5px", position: "absolute", top: "-40px", right: 0, borderRadius: "50%", backgroundColor: "rgb(0 0 0 / 55%)"}}>
            <CloseIcon style={{ color: "#fff"}} height={20} width={20}/>
          </IconButton>
          <DialogTitle>
            <strong style={{ fontSize: "22px", fontWeight: 800 }}>
              Verify OTP
            </strong>
          </DialogTitle>
          <DialogContent style={{ flex: "1 1 auto" }}>
            <DialogContentText variant="subtitle2" style={{color: "rgba(0,0,0,.7)"}}>
              We’ve send the 5 digit verification code to mobile no.{" "}
              <ins style={{color:"#390879"}}>{this.props.phone}  </ins>
              <EditIcon style={{height: "14px", width: "14px", color: "black", cursor: "pointer"}} onClick={this.props.handleChange}/>
            </DialogContentText>
            <OtpInput
              containerStyle={{ justifyContent: "space-between" }}
              inputStyle={{
                border: "0.5px solid #E1E6EC",
                width: "5.3rem",
                height: "4rem",
                borderRadius: "20px",
                fontSize: "24px"
              }}
              value={this.props.otp}
              onChange={this.props.setOtp}
              numInputs={5}
              renderInput={(props) => <input {...props} />}
              data-test-id="otp-input"
            />{" "}
          </DialogContent>
          <DialogContent>
            <DialogContentText variant="body1">
              <span style={{ width: "40%", color: "rgba(0,0,0,0.7)" }}>
                Code expires in{" "}
                <strong >
                  {" "}
                  {minutes}:
                  {remainingSeconds < 10
                    ? `0${remainingSeconds}`
                    : remainingSeconds}
                </strong>
              </span>
              <Typography style={{
                fontSize: 14,
                textDecoration: "underline",
                color: "#551A8B",
                cursor: this.props.seconds === 0 ? "pointer" : "auto"
              }} onClick={this.props.resend}>
                Resend Now
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              id="resendBtn"
              style={{
                marginTop: "0px",
                width: "95%",
                height: "50px",
                borderRadius: "20px",
                marginBottom: "15px",
                textTransform: "none",
              }}
              variant="contained"
              color="primary"
              onClick={this.props.handleSubmit}
              data-test-id="submit-otp"
              disabled={this.props.disabled}
            >
              <strong style={{ fontSize: "14px", fontWeight: 800 }}>
                Verify OTP
              </strong>
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}
