import React from "react";

import {
    Box,
    Typography,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import '../../../components/src/style.css'
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

const webStyle = {
  paraStyle: {
    width:'100%',
    fontWeight:500
  },
  benefitsStyle: {
    width:'100%',
    fontWeight:500,
    padding: "0px",
    boxShadow: "none",
    backgroundColor: "transparent",
    alignItems: "start",
    fontSize: "12px"
  },
  home:{
    color:'white'
  },
  header:{
    fontSize:'2.125rem',
    fontWeight:600
  },
  actionButtons:{width:'165px',height:'45px',borderRadius:'25px'}
};
// Customizable Area End


import BenefitsController, { Props, configJSON } from "./BenefitsController";
import GenericFooter from "../../../components/src/GenericFooter.web";
import GenericHeader from "../../../components/src/GenericHeader.web";
import { GreenCheckMark, iphone3, iphone2 } from "./assets";
import { Benefits} from "./constant";
import AccountTypeSelectionDialog from "../../../components/src/AccountTypeSelectionDialog.web";

export default class Benifits extends BenefitsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    async componentDidMount() {
        window.scrollTo(0,0)
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
			let pathName = this.getRedirectUrl();
			let pageBenifitsItems = Benefits.BusinessBenefits;
			let pageTitle = "Business";
			let image = iphone2;
			let titlePara = configJSON.businessPara;
			let benefits = configJSON.businessBenefits;
			let subPara = configJSON.businessSubPara;
			if (pathName ==="/ShyfterBenifits") {
					pageBenifitsItems = Benefits.ShyfterBenifits;
					pageTitle = "Shyfter";
					image = iphone3;
					titlePara = configJSON.shyftersPara;
					benefits = configJSON.shyftersBenefits;
					subPara = configJSON.shyftersSubPara;
			}
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <GenericHeader navigation={this.props.navigation} onNavigate={this.onNavigateFromBusiness} onSignUp={this.toggleSignUpForBusiness} />
                {this.state.openSignUpDialogFromBusiness && (
                    <AccountTypeSelectionDialog
                        isOpen={this.state.openSignUpDialogFromBusiness}
                        handleSave={this.redirectToSignUpPage}
                        handleClose={this.toggleSignUpForBusiness}
                    />
                )}
                <Box className="commonheader">
                    <Box className="firstsection-heading commonheader-heading">
                        <Typography style={webStyle.header} variant="h5" className="heading-main" data-test-id="test-heading">For <span>{pageTitle}</span></Typography>
                        <label className="heading-mainsub">
                            <span style={webStyle.home} className="header-home" onClick={() => window.location.href = "/"}>Home</span> &nbsp;|&nbsp; <span className="header-home" onClick={() => window.location.href = `/${pageTitle}Benifits`} style={webStyle.home}>{pageTitle}</span>
                        </label>
                    </Box>
                </Box>
                <Box className="imageSection benifits-bodyheader">
                    <div className='secondsection-heading'>
                        <Typography variant='h4' style={{fontWeight:600}} className='heading-main'>What <span>Benefits</span> you Get as a {pageTitle}?</Typography>
                        <span style={{width:'500px',fontFamily:"Gotham,sans-serif"}} className='heading-mainsub'>{configJSON.TopFeatureTxt}</span>
                    </div>

                    <div className="benifitssection-body">
                        {
                          pageBenifitsItems.map(item=>{
                            return    <span style={{filter:'drop-shadow(0px 0px 10px rgb(0, 0, 0,0.06))'}} className='right-card benifit-card'>
                            <img style={{width:'45px'}} src={require(`../assets${item.imgUrl}`)}></img>
                            <label style={{fontWeight:600}} className='card-heading'>{item.title}</label>
                            <label style={{width:'unset'}} className='card-details'>Copy
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                            </label>
                        </span>
                           }) 
                        }
                    </div>
                </Box>
                <Box className='body-forthsection' padding={'50px 150px'} style={{background:"white"}}>
                    <div className='forthsection-left'>
                        <div className='forthsection-heading common-heading'>
                            <Typography variant='h5' className='heading-main'> Join as a <span>{pageTitle}</span></Typography>
                            {titlePara.map((title: string) => (
                                <span style={webStyle.paraStyle} className='heading-mainsub' key={title}>{title}</span>
                            ))}
                        </div>
                        <div className='forthleft-items'>
                            {benefits.map((benefit: string) => (
                                <div className='forthsection-listitem' key={benefit}>
                                    <img src={GreenCheckMark} alt=""></img>
                                    <label style={webStyle.benefitsStyle}>
                                        {benefit}
                                    </label>
                                </div>
                            ))}
                        </div>
                        <div className='forthsection-heading common-heading'>
                            {subPara.map((subPara: string) => (
                                <span style={webStyle.paraStyle} className='heading-mainsub' key={subPara}>{subPara}</span>
                            ))}
                        </div>
                        <span className='header-buttongrp btgrp-other'>
                            <span style={webStyle.actionButtons}  className='header-signup' onClick={this.joinNow} data-test-id="join2-test">Join Now</span>
                            <span style={webStyle.actionButtons} className='header-login' onClick={() => { window.location.href = "/ContactUs" }} data-testid="contact2-test">Contact Us</span>
                        </span>
                    </div>
                    <div className='forthsection-right benifitsection-image'>
                        <img src={image}></img>
                    </div>
                </Box>
                <GenericFooter navigation={this.props.navigation} onAboutClick={this.onRedirectToAbout}/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start


// Customizable Area End
