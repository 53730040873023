import React from "react";

import AppliedShyftController, { Props } from "./AppliedShyftsController.web";
import { Box, Container, Grid } from "@material-ui/core";

import { breadCrumSubsPath } from "../../../components/src/Utilities";
import LandingPageHeader from "../../navigationmenu/src/LandingPageHeader.web";
import NoDataFound from "../../../components/src/NoDataFound.web";
import Spinner from "../../../components/src/Spinner";
import ShyftCard from "../../../components/src/ShyftCard";
import ShyfterDetailsCard from "../../../components/src/ShyftDetailCard";
import { FailedIcon, downArrowBlack, crossCircleBlack } from "./assets";
import Footer from "../../../components/src/Footer.web";

const getStatusText = (status: string) => {
  if (status === "rejected" || status === "expired") return status;
};

export default class AppliedShyftPage extends AppliedShyftController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      appliedShyftsList,
      selectedShyft,
      appliedShyftDetails,
      request,
      isLoading,
    } = this.state;

    const options = appliedShyftsList.map((shift) => [
      {
        menuIcon:
          shift.status === "pending" ? downArrowBlack : crossCircleBlack,
        menuText:
          shift.status === "pending" ? "Withdraw Request" : "Remove from list",
        onClick:
          shift.status === "pending"
            ? () => this.withdrawFromTheShyft(shift.id)
            : () => this.removeShyftFromList(),
      },
    ]);

    return (
      <>
        <LandingPageHeader
          {...this.props}
          currentPage="Applied Shyfts"
          breadCrumsData={breadCrumSubsPath}
          isBreadcrumbs={true}
          isUpdateUser={false}
        />
        {isLoading && <Spinner />}

        {appliedShyftsList.length === 0 && !isLoading && (
          <Container>
            <Box marginTop="12%">
              <NoDataFound
                heading="No Shyfts Found!"
                paragraph={
                  "You’ve not assgined to any shyfts yet, Explore new shyfts to get the jobs."
                }
                imgUrl={FailedIcon}
                primaryBtnText="Explore Shyfts"
                primaryBtnClick={this.viewExploreShyfts}
              />
            </Box>
          </Container>
        )}

        {appliedShyftsList.length !== 0 && (
          <Box marginTop={"8%"} width={"100%"}>
            <Container maxWidth="md">
              <Grid container spacing={2} style={{ marginBottom: "20px" }}>
                <Grid item xs={6}>
                  <Box>
                    {appliedShyftsList.map((item, index) => {
                      return (
                        <ShyftCard
                          key={item.id}
                          {...item}
                          onCardClick={() => this.onSelectShift(item.id)}
                          options={options[index]}
                          isActiveShyft={item.id === selectedShyft}
                          isMenu
                          statusText={getStatusText(item.status)}
                        />
                      );
                    })}
                    <div style={{ height: "20px" }} ref={this.eleRef}></div>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box
                    margin={"15px 0px"}
                    style={{ position: "sticky", top: "24%" }}
                  >
                    {appliedShyftDetails && (
                      <ShyfterDetailsCard
                        hideEmployer
                        key={appliedShyftDetails.id}
                        {...appliedShyftDetails}
                        showOverviewSubtext={false}
                        isAppliedShyft
                        onWithdrawShift={() =>
                          this.withdrawFromTheShyft(appliedShyftDetails.id)
                        }
                        onViewProfile={() =>
                          this.onViewBusinessProfile(
                            appliedShyftDetails.businessId
                          )
                        }
                        shyftStatus={request ? request.status : undefined}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        )}

        <Footer />
      </>
    );
  }
}
